import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useGetMeasurementByIdQuery,
  useCreateMeasurementMutation,
  useUpdateMeasurementMutation,
  useGetMeasurementsQuery,
} from "slices/resourceInventory/inventorySlice";

interface MeasurementEditProps {
  measurementId: string | undefined;
  setCardView: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
}

export const MeasurementEdit: React.FC<MeasurementEditProps> = ({
  measurementId,
  setCardView,
}) => {
  const {
    data: measurementData,
    isLoading: isLoadingMeasurement,
    refetch,
  } = useGetMeasurementByIdQuery(measurementId, {
    skip: !measurementId,
  });
  const { refetch: refetchMeasurement } = useGetMeasurementsQuery(undefined);
  const [createMeasurement, { isLoading: createMeasurementLoading }] =
    useCreateMeasurementMutation();
  const [updateMeasurement, { isLoading: updateMeasurementLoading }] =
    useUpdateMeasurementMutation();

  // Update validation schema for new fields
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    unit: Yup.string().required("Unit is required"),
    unit_value: Yup.number()
      .required("Unit value is required")
      .positive("Unit value must be positive"),
    description: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      unit: "",
      unit_value: "",
      description: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (measurementId) {
        // Update existing measurement
        updateMeasurement({ id: measurementId, ...values })
          .unwrap()
          .then(() => {
            refetchMeasurement();
            setCardView({ measurementInfo: true });
            refetch();
          })
          .catch((error) => {
            console.error("Error updating measurement:", error);
          });
      } else {
        // Create new measurement
        createMeasurement(values)
          .unwrap()
          .then(() => {
            refetchMeasurement();
            setCardView({ measurementInfo: true });
            refetch();
          })
          .catch((error) => {
            console.error("Error creating measurement:", error);
          });
      }
    },
  });

  useEffect(() => {
    if (measurementData) {
      formik.setValues({
        name: measurementData.name || "",
        unit: measurementData.unit || "",
        unit_value: measurementData.unit_value || "",
        description: measurementData.description || "",
      });
    }
  }, [measurementData]);

  return (
    <Card>
      <CardHeader className="d-flex">
        <h4 className="card-title flex-grow-1 mb-0">Measurement Edit</h4>
      </CardHeader>
      <CardBody>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="name" className="form-label">
                  Name
                </Label>
                <span className="text-danger">*</span>
                <Input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Enter name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={!!formik.errors.name && formik.touched.name}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="text-danger">{formik.errors.name}</div>
                )}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="unit" className="form-label">
                  Unit
                </Label>
                <span className="text-danger">*</span>
                <Input
                  type="text"
                  className="form-control"
                  id="unit"
                  name="unit"
                  placeholder="Enter unit"
                  value={formik.values.unit}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={!!formik.errors.unit && formik.touched.unit}
                />
                {formik.touched.unit && formik.errors.unit && (
                  <div className="text-danger">{formik.errors.unit}</div>
                )}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="unit_value" className="form-label">
                  Unit Value
                </Label>
                <span className="text-danger">*</span>
                <Input
                  type="number"
                  className="form-control"
                  id="unit_value"
                  name="unit_value"
                  placeholder="Enter unit value"
                  value={formik.values.unit_value}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    !!formik.errors.unit_value && formik.touched.unit_value
                  }
                />
                {formik.touched.unit_value && formik.errors.unit_value && (
                  <div className="text-danger">{formik.errors.unit_value}</div>
                )}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="description" className="form-label">
                  Description
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="description"
                  name="description"
                  placeholder="Enter description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    !!formik.errors.description && formik.touched.description
                  }
                />
                {formik.touched.description && formik.errors.description && (
                  <div className="text-danger">{formik.errors.description}</div>
                )}
              </div>
            </Col>
          </Row>
          <Button
            type="submit"
            color="primary"
            disabled={
              isLoadingMeasurement ||
              createMeasurementLoading ||
              updateMeasurementLoading
            }
          >
            {updateMeasurementLoading || createMeasurementLoading
              ? "Saving..."
              : measurementId
                ? "Update Measurement"
                : "Create Measurement"}
          </Button>
          &nbsp;&nbsp;
          <button
            type="button"
            className="btn btn-danger"
            disabled={createMeasurementLoading || updateMeasurementLoading}
            onClick={() => setCardView({ measurementInfo: true })}
          >
            Close
          </button>
        </Form>
      </CardBody>
    </Card>
  );
};
