import React, { useState } from "react";
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import BreadCrumb from "Components/Common/BreadCrumb";
import { Link, useParams } from "react-router-dom";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react"; // Import the type for narrowing
import { Accordion, AccordionItem, Collapse } from "reactstrap";

import { useGetInventoryQuery } from "slices/resourceInventory/inventorySlice";
import LoadingOverlay from "react-loading-overlay-ts";
import AddSectionModal from "./AddSectionModal/AddSectionModal";
import ProductAddModal from "./ProductAddModal/ProductAddModal";
import {
  useCreateSectionProcuredMutation,
  useCreateSectionDeliveredMutation,
} from "slices/resourceInventory/inventorySlice";

import QuotedModal from "./SectionModals/QuotedModal";
import PlannedModal from "./SectionModals/PlannedModal";
import ImplementedModal from "./SectionModals/ImplementedModal";
import ProcuredModal from "./SectionModals/ProcuredModal";
import DeliveredModal from "./SectionModals/DeliveredModal";
import ProcuredNextModal from "./SectionModals/ProcuredNextModal";
import DeliveredToInitiateModal from "./SectionModals/DeliveredToInitiateModal";
import DeliveredInProgressModal from "./SectionModals/DeliveredInProgressModal";
import DeliveredToDeliveredModal from "./SectionModals/DeliveredToDeliveredModal";
import ImplementedToInitateModal from "./SectionModals/ImplementedToInitateModal";
import ImplementedInProgressModal from "./SectionModals/ImplementedInProgressModal";
import ImplementedToImplementedModal from "./SectionModals/ImplementedToImplementedModal";
import ImportResourceFilterModal from "./FilterModal/FilterModal";

import AuditModal from "./AuditModal/AuditModal";

import ImportResourceExcelModal from "./BulkUploadModal/BulkUploadModal";

import Loader from "Components/Common/Loader";
import { STATUS_CODE } from "../../../Constance";

const InventoryDashBoard = () => {
  const { projectId } = useParams<{ projectId: string }>();

  const [activeAccordion, setActiveAccordion] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [selectedSectionId, setSelectedSectionId] = useState<string | number>(
    "",
  );
  const [selectedProductId, setSelectedProductId] = useState<string | number>(
    "",
  );
  const [selectedResourceProductId, setSelectedResourceProductId] = useState<
    string | number
  >("");
  const [selectedQuantity, setSelectedQuantity] = useState<
    number | undefined
  >();
  const [selectedPlannedQuantity, setSelectedPlannedQuantity] = useState<
    number | undefined
  >();
  const [selectedBalanceQuantity, setSelectedBalanceQuantity] = useState<
    number | undefined
  >();
  const [selectedProductInventory, setSelectedProductInventory] = useState<
    number | undefined
  >();
  const [selectedProcuredAmount, setSelectedProcuredAmount] = useState<
    number | undefined
  >();

  const [productProcured, setProductProcured] = useState<any>();

  const [createSectionProcured, { isLoading: isLoadingProcured }] =
    useCreateSectionProcuredMutation();
  const [createSectionDelivered, { isLoading: isLoadingDelivered }] =
    useCreateSectionDeliveredMutation();

  const [isDeliveredModalOpen, setIsDeliveredModalOpen] = useState(false);
  const [isImplementedModalOpen, setIsImplementedModalOpen] = useState(false);
  const [isPlannedModalOpen, setIsPlannedModalOpen] = useState(false);
  const [isQuotedModalOpen, setIsQuotedModalOpen] = useState(false);
  const [isProcuredModalOpen, setIsProcuredModalOpen] = useState(false);
  const [isProcuredNextModalOpen, setIsProcuredNextModalOpen] = useState(false);
  const [isDeliveredToInitateModalOpen, setIsDeliveredToInitateModalOpen] =
    useState(false);
  const [isDeliveredInProgressModalOpen, setIsDeliveredInProgressModalOpen] =
    useState(false);
  const [isDeliveredToDeliveredModalOpen, setIsDeliveredToDeliveredModalOpen] =
    useState(false);
  const [isImplementedToInitateModalOpen, setIsImplementedToInitateModalOpen] =
    useState(false);
  const [
    isImplementedInProgressModalOpen,
    setIsImplementedInProgressModalOpen,
  ] = useState(false);
  const [
    isImplementedToImplementedModalOpen,
    setIsImplementedToImplementedModalOpen,
  ] = useState(false);

  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);

  const [isAuditModalOpen, setAuditModalOpen] = useState(false);
  const [modalAuditID, setModalAuditID] = useState(null);
  const [modalAuditType, setModalAuditType] = useState("");

  const [isImportExcel, setIsImportExcel] = useState<boolean>(false);
  const [isFilterModal, setIsFilterModal] = useState<boolean>(false);
  const [selectedSection, setSelectedSection] = useState([]);

  const handleOpenFilterModal = (resource: any) => {
    setSelectedSection(resource);
    setIsFilterModal(true);
  };

  // Function to close the modal and reset data
  const handleCloseFilterModal = () => {
    setSelectedSection([]);
    setIsFilterModal(false);
  };

  const handleOpenAuditModal = (id: any, resource: any) => {
    setModalAuditID(id);
    setModalAuditType(resource);
    setAuditModalOpen(true);
  };

  // Function to close the modal and reset data
  const handleCloseAuditModal = () => {
    setModalAuditType("");
    setModalAuditID(null);
    setAuditModalOpen(false);
  };

  const formatQuantity = (quantity: number) => {
    return parseFloat(quantity.toFixed(2));
  };

  const toggleDeliveredModal = () =>
    setIsDeliveredModalOpen(!isDeliveredModalOpen);
  const toggleImplementedModal = () =>
    setIsImplementedModalOpen(!isImplementedModalOpen);
  const togglePlannedModal = () => setIsPlannedModalOpen(!isPlannedModalOpen);
  const toggleQuotedModal = () => setIsQuotedModalOpen(!isQuotedModalOpen);
  const toggleProcuredModal = () =>
    setIsProcuredModalOpen(!isProcuredModalOpen);
  const toggleProcuredNextModal = () =>
    setIsProcuredNextModalOpen(!isProcuredNextModalOpen);
  const toggleDeliveredToInitateModal = () =>
    setIsDeliveredToInitateModalOpen(!isDeliveredToInitateModalOpen);
  const toggleDeliveredInProgressModal = () =>
    setIsDeliveredInProgressModalOpen(!isDeliveredInProgressModalOpen);
  const toggleDeliveredToDeliveredModal = () =>
    setIsDeliveredToDeliveredModalOpen(!isDeliveredToDeliveredModalOpen);
  const toggleImplementedToInitateModal = () =>
    setIsImplementedToInitateModalOpen(!isImplementedToInitateModalOpen);
  const toggleImplementedInProgressModal = () =>
    setIsImplementedInProgressModalOpen(!isImplementedInProgressModalOpen);
  const toggleImplementedToImplementedModal = () =>
    setIsImplementedToImplementedModalOpen(
      !isImplementedToImplementedModalOpen,
    );

  interface Procure {
    id: number;
    procured_status:
      | "To Initiate"
      | "Requested"
      | "Approved"
      | "Rejected"
      | "Procured";
    planned_amount: number;
    quoted_amount: number;
    product_name: string;
    measurement_unit: string;
    procured_quantity_sum: number;
    procured_balance_quantity: number;
    inventory_quantity: number;
    procured: any;
    planned: any;
    quoted: any;
    delivered: any;
    implemented: any;
    status: any;
    next_status: any;
    toinitate_list: [];
  }

  const {
    data,
    isLoading: inventoryLoading,
    error,
    refetch: refetchInventory,
  } = useGetInventoryQuery(projectId);

  const toggleAccordion = (sectionIndex: any) => {
    setActiveAccordion(
      activeAccordion === sectionIndex.toString()
        ? ""
        : sectionIndex.toString(),
    );
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const openProductModal = (sectionId: string | number, products: any[]) => {
    setSelectedSectionId(sectionId);
    // Extract product IDs from the products array and set them in the state
    const productIdsArray = products.map((product) => product.product);
    setSelectedProducts(productIdsArray); // Store the product IDs in the state
    setProductModalOpen(true);
  };

  const toggleProductModal = () => {
    setProductModalOpen(!productModalOpen);
  };

  const handleAddDelivered = (productId: number) => {
    setSelectedProductId(productId);
    toggleDeliveredModal();
  };

  const handleAddQuoted = (productId: number, quantity: number) => {
    setSelectedProductId(productId);
    setSelectedQuantity(quantity);
    toggleQuotedModal();
  };

  const handleAddPlanned = (productId: number, quantity: number) => {
    setSelectedProductId(productId);
    setSelectedPlannedQuantity(quantity);
    togglePlannedModal();
  };

  const handleAddProcured = (product: any) => {
    setSelectedProductId(product?.product);
    setSelectedResourceProductId(product?.id);
    setSelectedQuantity(product?.quoted_amount);
    setSelectedPlannedQuantity(product?.planned_amount);
    setSelectedBalanceQuantity(product?.procured_balance_quantity);
    setSelectedProductInventory(product?.inventory_quantity);
    setSelectedProcuredAmount(product.procured_amount);
    toggleProcuredModal();
  };

  const handleNextProcured = (product: any) => {
    setProductProcured(product);
    console.log("handel next", product);
    toggleProcuredNextModal();
  };

  const handleDeliveredToInitate = (product: any) => {
    setProductProcured(product);
    console.log("handel next", product);
    toggleDeliveredToInitateModal();
  };

  const handleDeliveredInProgress = (product: any) => {
    setProductProcured(product);
    console.log("handel next", product);
    toggleDeliveredInProgressModal();
  };

  const handleDeliveredToDelivered = (product: any) => {
    setProductProcured(product);
    console.log("handel next", product);
    toggleDeliveredToDeliveredModal();
  };

  const handleImplementedToInitate = (product: any) => {
    setProductProcured(product);
    console.log("handel next", product);
    toggleImplementedToInitateModal();
  };

  const handleImplementedInProgress = (product: any) => {
    setProductProcured(product);
    console.log("handel next", product);
    toggleImplementedInProgressModal();
  };

  const handleImplementedToImplemented = (product: any) => {
    setProductProcured(product);
    console.log("handel next", product);
    toggleImplementedToImplementedModal();
  };

  const handleAddImplemented = (productId: number) => {
    setSelectedProductId(productId);
    toggleImplementedModal();
  };

  const handleProductProcured = async (product: any) => {
    try {
      await createSectionProcured({
        product: product?.id,
        next_action: "Procured.Procured",
      });
      refetchInventory();
    } catch (error) {
      console.error("Error adding procured section:", error);
    }
  };

  // const handleDeliveredInitate = async (product: any) => {
  //   try {
  //     await createSectionDelivered({
  //       product: product?.id,
  //       quantity: (product.procured_quantity_sum + product.inventory_quantity),
  //       next_action: "Delivered.To_Initiate",
  //     });
  //     refetchInventory();
  //   } catch (error) {
  //     console.error("Error adding procured section:", error);
  //   }
  // };

  document.title = "Resource Dashboard";

  // Type guard to check if the error is of type FetchBaseQueryError
  const isFetchBaseQueryError = (error: any): error is FetchBaseQueryError => {
    return error && "data" in error;
  };

  // Type guard to check if 'data' inside the error is an object with an 'error' property
  const hasErrorMessage = (
    errorData: unknown,
  ): errorData is { error: string } => {
    return (
      typeof errorData === "object" &&
      errorData !== null &&
      "error" in errorData
    );
  };

  // Handle different rendering conditions
  const renderContent = () => {
    // Loading state
    if (inventoryLoading) {
      return (
        <div className="page-content">
          <Container fluid>
            <Card>
              <CardBody>
                <div className="d-flex align-items-center justify-content-center">
                  <Spinner color="primary" />
                  Loading inventory...
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      );
    }

    if (error) {
      let errorMessage = "An error occurred while fetching data.";
      let isWorkflowError = false;

      // Check if the error is of type FetchBaseQueryError
      if (isFetchBaseQueryError(error)) {
        // Safely cast the error.data to the expected type
        const errorData = error.data as { error?: string };

        // Set error message based on the data
        errorMessage = errorData.error || errorMessage;

        // Check for specific error message
        isWorkflowError =
          errorData.error === "Workflow not assigned to the project";
      }

      return (
        <div className="page-content">
          <Container fluid>
            <Card>
              <CardBody>
                <div>
                  <Alert
                    color="danger"
                    className="col-md-12 d-flex justify-content-center"
                  >
                    <div className="col-md-6 text-center">
                      <i className="ri-alert-line fs-1 text-danger"></i>
                      <h4>{errorMessage}</h4>

                      <p className="mb-2">
                        Please add a workflow to this project.
                      </p>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-center">
                      {/* Additional information for workflow error */}
                      {isWorkflowError && (
                        <div className="mt-3">
                          <Link to="/workflow-list" className="btn btn-primary">
                            Add Workflow
                          </Link>
                        </div>
                      )}
                    </div>
                  </Alert>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title="Resource Dashboard" pageTitle="Dashboard" />
            {inventoryLoading && <Loader />}
            <LoadingOverlay
              active={inventoryLoading}
              spinner
              text="Fetching..."
            >
              <Row>
                <Card>
                  <CardHeader>
                    <div className="d-flex align-items-center flex-wrap gap-2">
                      <div className="flex-grow-1">
                        <button
                          className="btn btn-info add-btn"
                          onClick={toggleModal}
                          disabled={inventoryLoading}
                        >
                          <i className="ri-add-fill me-1 align-bottom"></i> Add
                          Section
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                          type="button"
                          className="btn btn-secondary me-1"
                          onClick={() => setIsImportExcel(true)}
                          disabled={inventoryLoading || data?.length > 0}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          Import
                        </button>
                      </div>
                      <div className="flex-shrink-0">
                        <div className="hstack text-nowrap gap-2">
                          <button
                            className="btn btn-secondary"
                            onClick={() => {
                              handleOpenFilterModal(data);
                            }}
                          >
                            <i className="ri-filter-2-line me-1 align-bottom"></i>{" "}
                            Filters
                          </button>
                          {/* <UncontrolledDropdown>
                          <DropdownToggle
                            href="#"
                            className="btn btn-soft-info"
                            tag="button"
                          >
                            <i className="ri-more-2-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className="dropdown-item" href="#">
                              All
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Week
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Month
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Year
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown> */}
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                </Card>
                <Col lg={12}>
                  <Accordion
                    open={activeAccordion}
                    toggle={() => toggleAccordion(activeAccordion)}
                    className="custom-accordionwithicon accordion-secondary"
                    id="accordionFill"
                  >
                    {data && data.length > 0 ? (
                      data?.map((section: any, sectionIndex: any) => (
                        <AccordionItem key={sectionIndex}>
                          <h2
                            className="accordion-header"
                            id={`accordionFillExample${sectionIndex}`}
                          >
                            <button
                              className={classnames("accordion-button", {
                                collapsed:
                                  activeAccordion !== sectionIndex.toString(),
                              })}
                              type="button"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                toggleAccordion(sectionIndex.toString())
                              }
                            >
                              <h4>{section?.section_name}</h4>
                            </button>
                          </h2>
                          <Collapse
                            isOpen={activeAccordion === sectionIndex.toString()}
                            className="accordion-collapse"
                            id={`accor_fill${sectionIndex}`}
                          >
                            <Card>
                              <CardBody>
                                <div className="live-preview">
                                  <div className="table-responsive">
                                    <table
                                      className="table mb-0"
                                      style={{ minHeight: "750px" }}
                                    >
                                      <thead>
                                        <tr>
                                          <th
                                            scope="col"
                                            style={{ minWidth: "250px" }}
                                          >
                                            Item Name
                                          </th>
                                          <th
                                            className="text-center"
                                            scope="col"
                                            style={{ minWidth: "250px" }}
                                          >
                                            Quoted
                                          </th>
                                          <th
                                            className="text-center"
                                            scope="col"
                                            style={{ minWidth: "250px" }}
                                          >
                                            Planned
                                          </th>
                                          <th
                                            className="text-center"
                                            scope="col"
                                            style={{ minWidth: "250px" }}
                                          >
                                            Procured
                                          </th>
                                          <th
                                            className="text-center"
                                            scope="col"
                                            style={{ minWidth: "250px" }}
                                          >
                                            Delivered
                                          </th>
                                          <th
                                            className="text-center"
                                            scope="col"
                                            style={{ minWidth: "250px" }}
                                          >
                                            Implemented
                                          </th>
                                          <th
                                            className="text-center"
                                            scope="col"
                                            style={{ minWidth: "250px" }}
                                          >
                                            Status
                                          </th>
                                        </tr>
                                        <tr>
                                          <th
                                            scope="col"
                                            style={{ minWidth: "250px" }}
                                          >
                                            <div className="d-flex align-items-center my-4">
                                              <div className="flex-grow-1">
                                                <h4 className="mb-0 fs-11 text-muted text-uppercase">
                                                  Product
                                                </h4>
                                              </div>
                                              <div className="flex-shrink-0">
                                                <div
                                                  data-bs-toggle="tooltip"
                                                  data-bs-trigger="hover"
                                                  data-bs-placement="bottom"
                                                  aria-label="Add Product"
                                                  data-bs-original-title="Add Product"
                                                >
                                                  <button
                                                    type="button"
                                                    className="btn btn-soft-success btn-sm shadow-none"
                                                    onClick={() =>
                                                      openProductModal(
                                                        section?.id,
                                                        section?.product_list,
                                                      )
                                                    }
                                                  >
                                                    <i className="ri-add-line align-bottom"></i>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </th>
                                          <th
                                            scope="col"
                                            style={{ minWidth: "250px" }}
                                          ></th>
                                          <th
                                            scope="col"
                                            style={{ minWidth: "250px" }}
                                          ></th>
                                          <th
                                            scope="col"
                                            style={{ minWidth: "250px" }}
                                          ></th>
                                          <th
                                            scope="col"
                                            style={{ minWidth: "250px" }}
                                          ></th>
                                          <th
                                            scope="col"
                                            style={{ minWidth: "250px" }}
                                          ></th>
                                          <th
                                            scope="col"
                                            style={{ minWidth: "250px" }}
                                          >
                                            <div className="d-flex align-items-center my-4">
                                              <div className="flex-grow-1">
                                                <h4 className="mb-0 fs-11 text-muted text-uppercase">
                                                  Status
                                                </h4>
                                              </div>
                                              <div className="flex-shrink-0">
                                                <div
                                                  data-bs-toggle="tooltip"
                                                  data-bs-trigger="hover"
                                                  data-bs-placement="bottom"
                                                  aria-label="Add Product"
                                                  data-bs-original-title="Add Product"
                                                >
                                                  <button
                                                    type="button"
                                                    className="btn btn-soft-success btn-sm shadow-none"
                                                  >
                                                    <i className="ri-add-line align-bottom"></i>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {section?.product_list?.map(
                                          (
                                            product: Procure,
                                            productIndex: any,
                                          ) => (
                                            <>
                                              <tr
                                              // style={{
                                              //   height: "20px"
                                              // }}
                                              >
                                                <th
                                                  className="res-tab-row-1"
                                                  scope="col"
                                                  style={{
                                                    minWidth: "200px",
                                                    height: "20px",
                                                  }}
                                                >
                                                  <th scope="row">
                                                    <span
                                                      className="badge border border-primary text-primary fs-6"
                                                      color="primary"
                                                    >
                                                      {product?.product_name}
                                                    </span>
                                                  </th>
                                                </th>

                                                {/* Quoted */}
                                                <th
                                                  scope="col"
                                                  className="text-center res-tab-row-2"
                                                  style={{ minWidth: "200px" }}
                                                >
                                                  <div className="fs-4">
                                                    {product?.quoted_amount}{" "}
                                                    {product?.measurement_unit}
                                                  </div>
                                                  {(product.next_status?.[0]
                                                    ?.status ===
                                                    "QuotedPlanned" ||
                                                    product.next_status?.[0]
                                                      ?.status ===
                                                      "Procured To Initiate" ||
                                                    product.next_status?.[0]
                                                      ?.action ===
                                                      "Procured.Rejected") && (
                                                    <button
                                                      type="button"
                                                      className="btn btn-soft-primary btn-sm shadow-none"
                                                      onClick={() =>
                                                        handleAddQuoted(
                                                          product.id,
                                                          product.quoted_amount,
                                                        )
                                                      }
                                                    >
                                                      <i className="ri-add-line align-bottom"></i>{" "}
                                                      QUOTED
                                                    </button>
                                                  )}

                                                  <div className="mt-3 mb-1 fs-8">
                                                    {product?.quoted?.length >
                                                      0 &&
                                                      product?.quoted?.map(
                                                        (
                                                          quote: any,
                                                          quoteIndex: any,
                                                        ) => (
                                                          <>
                                                            <div className="row">
                                                              <div className="col-md-4">
                                                                <div
                                                                  key={
                                                                    quoteIndex
                                                                  }
                                                                  className={`badge d-inline-flex align-items-center mb-2 ${
                                                                    quote?.tag ===
                                                                    "Added"
                                                                      ? "text-bg-info"
                                                                      : "text-bg-danger"
                                                                  }`}
                                                                >
                                                                  {quote?.tag}
                                                                </div>
                                                              </div>
                                                              <div className="col-md-4">
                                                                {quote.quantity}
                                                              </div>
                                                              <div className="col-md-4">
                                                                <i
                                                                  className="ri ri-information-line"
                                                                  title={`${quote?.comments}`}
                                                                ></i>
                                                              </div>
                                                            </div>
                                                          </>
                                                        ),
                                                      )}
                                                  </div>
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="text-center res-tab-row-3"
                                                  style={{ minWidth: "200px" }}
                                                >
                                                  <div className="fs-4">
                                                    {product?.planned_amount}{" "}
                                                    {product?.measurement_unit}
                                                  </div>

                                                  {(product.next_status?.[0]
                                                    ?.status ===
                                                    "QuotedPlanned" ||
                                                    product.next_status?.[0]
                                                      ?.status ===
                                                      "Procured To Initiate" ||
                                                    product.next_status?.[0]
                                                      ?.action ===
                                                      "Procured.Rejected") && (
                                                    <button
                                                      type="button"
                                                      className="btn btn-soft-primary btn-sm shadow-none"
                                                      onClick={() =>
                                                        handleAddPlanned(
                                                          product.id,
                                                          product.planned_amount,
                                                        )
                                                      }
                                                    >
                                                      <i className="ri-add-line align-bottom"></i>{" "}
                                                      PLANNED
                                                    </button>
                                                  )}

                                                  <div className="mt-3 mb-1 fs-8">
                                                    {product?.planned?.length >
                                                      0 &&
                                                      product?.planned?.map(
                                                        (
                                                          plan: any,
                                                          planIndex: any,
                                                        ) => (
                                                          <div className="row">
                                                            <div className="col-md-4">
                                                              <div
                                                                key={planIndex}
                                                                className={`badge d-inline-flex align-items-center mb-2 ${
                                                                  plan?.tag ===
                                                                  "Added"
                                                                    ? "text-bg-info"
                                                                    : "text-bg-danger"
                                                                }`}
                                                              >
                                                                {plan?.tag}
                                                              </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                              {plan.quantity}
                                                            </div>
                                                            <div className="col-md-4">
                                                              <i
                                                                className="ri ri-information-line"
                                                                title={`${plan?.comments}`}
                                                              ></i>
                                                            </div>
                                                          </div>
                                                        ),
                                                      )}
                                                  </div>
                                                </th>
                                                {/* Procured */}
                                                <th
                                                  className="text-center fs-8 res-tab-row-4"
                                                  scope="col"
                                                  style={{ minWidth: "200px" }}
                                                >
                                                  {((product.next_status?.[0]
                                                    ?.action ===
                                                    "Procured.ToInitiate" &&
                                                    product.next_status?.[0]
                                                      ?.show === "Button") ||
                                                    (product.next_status?.[0]
                                                      ?.action ===
                                                      "Procured.Rejected" &&
                                                      product.next_status?.[0]
                                                        ?.show ===
                                                        "Button")) && (
                                                    <button
                                                      type="button"
                                                      className="btn btn-soft-primary btn-sm shadow-none mb-2"
                                                      onClick={() =>
                                                        handleAddProcured(
                                                          product,
                                                        )
                                                      }
                                                    >
                                                      <i className="ri-add-line align-bottom"></i>{" "}
                                                      {
                                                        product.next_status?.[0]
                                                          ?.status
                                                      }
                                                    </button>
                                                  )}

                                                  {/* {(product.next_status?.[0]
                                                  ?.action === "Procured.WaitingForApproval" && product.next_status?.[0]
                                                    ?.show === "Info") && (
                                                    <div className="badge text-bg-primary d-inline-flex align-items-center fs-6 mb-2">
                                                      <i
                                                        style={{
                                                          cursor: "pointer",
                                                          fontSize: "15px",
                                                          marginRight:
                                                            "10px",
                                                        }}
                                                        className=" ri-information-line"
                                                      ></i>{" "}
                                                      {product.next_status?.[0]?.status}
                                                    </div>
                                                  )} */}

                                                  {/* {(product.next_status?.[0]
                                                  ?.action === "Procured.Approved" && product.next_status?.[0]
                                                    ?.show === "Button") && (
                                                    <button
                                                      type="button"
                                                      className="btn btn-soft-primary btn-sm shadow-none"
                                                      disabled={isLoadingProcured}
                                                      onClick={() =>
                                                        handleProductProcured(product)
                                                      }
                                                    >
                                                      <i className="ri-add-line align-bottom"></i>{" "}
                                                      {product.next_status?.[0]?.status}
                                                    </button>
                                                  )} */}

                                                  {/* {product?.toinitate_list ? <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <div style={{ background: 'antiquewhite' }} 
                                                    className={`badge border ${STATUS_CODE[product?.procured_status]} d-inline-flex align-items-center fs-6 mb-2`}
                                                    >
                                                      <i
                                                        style={{
                                                          cursor: "pointer",
                                                          fontSize: "15px",
                                                          marginRight:
                                                            "10px",
                                                        }}
                                                        className=" ri-calendar-check-fill"
                                                      ></i>{" "}
                                                      {product?.procured_status}
                                                    </div>
                                                    <div>{product?.procured_quantity_sum} {product.measurement_unit}</div>
                                                  </div> :
                                                    ''} */}

                                                  {product?.toinitate_list?.some(
                                                    (procure: any) =>
                                                      procure.procured_status ===
                                                      "Approved",
                                                  ) && (
                                                    <button
                                                      type="button"
                                                      className="btn btn-soft-primary btn-sm shadow-none mb-2"
                                                      disabled={
                                                        isLoadingProcured
                                                      }
                                                      onClick={() =>
                                                        handleNextProcured(
                                                          product,
                                                        )
                                                      }
                                                    >
                                                      <i className="ri-add-line align-bottom"></i>{" "}
                                                      Procured
                                                    </button>
                                                  )}

                                                  {product?.toinitate_list?.map(
                                                    (
                                                      procure: {
                                                        procured_status:
                                                          | "To Initiate"
                                                          | "Approved"
                                                          | "Rejected";
                                                        procured_quantity_sum: number;
                                                        id: number;
                                                      },
                                                      procureIndex: number,
                                                    ) => (
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                        }}
                                                      >
                                                        <div
                                                          key={procureIndex}
                                                          className={`badge border ${
                                                            STATUS_CODE[
                                                              procure
                                                                ?.procured_status
                                                            ]
                                                          } d-inline-flex align-items-center fs-8 mb-2`}
                                                        >
                                                          <i
                                                            style={{
                                                              cursor: "pointer",
                                                              fontSize: "15px",
                                                              marginRight:
                                                                "10px",
                                                            }}
                                                            className=" ri-calendar-check-fill"
                                                          ></i>{" "}
                                                          {
                                                            procure?.procured_status
                                                          }
                                                        </div>
                                                        <div>
                                                          {
                                                            procure?.procured_quantity_sum
                                                          }{" "}
                                                          {
                                                            product.measurement_unit
                                                          }
                                                        </div>
                                                        <div>
                                                          <i
                                                            style={{
                                                              cursor: "pointer",
                                                              fontSize: "15px",
                                                              marginRight:
                                                                "10px",
                                                            }}
                                                            title="Audit Log"
                                                            className=" ri-eye-fill"
                                                            onClick={() =>
                                                              handleOpenAuditModal(
                                                                procure.id,
                                                                "procured",
                                                              )
                                                            }
                                                          ></i>
                                                        </div>
                                                      </div>
                                                    ),
                                                  )}
                                                </th>

                                                {/* Delivered */}
                                                <th
                                                  className="text-center fs-8 res-tab-row-5"
                                                  scope="col"
                                                  style={{ minWidth: "200px" }}
                                                >
                                                  {product?.toinitate_list?.some(
                                                    (procure: any) =>
                                                      procure.procured_status ===
                                                      "Procured",
                                                  ) && (
                                                    <button
                                                      type="button"
                                                      className="btn btn-soft-primary btn-sm shadow-none mb-2"
                                                      disabled={
                                                        isLoadingProcured
                                                      }
                                                      onClick={() =>
                                                        handleDeliveredToInitate(
                                                          product,
                                                        )
                                                      }
                                                    >
                                                      <i className="ri-add-line align-bottom"></i>{" "}
                                                      To Initate
                                                    </button>
                                                  )}

                                                  {product?.toinitate_list?.some(
                                                    (procure: any) =>
                                                      procure.delivered_status ===
                                                      "Approved",
                                                  ) && (
                                                    <button
                                                      type="button"
                                                      className="btn btn-soft-primary btn-sm shadow-none mb-2"
                                                      disabled={
                                                        isLoadingProcured
                                                      }
                                                      onClick={() =>
                                                        handleDeliveredInProgress(
                                                          product,
                                                        )
                                                      }
                                                    >
                                                      <i className="ri-add-line align-bottom"></i>{" "}
                                                      To InProgress
                                                    </button>
                                                  )}

                                                  {product?.toinitate_list?.some(
                                                    (procure: any) =>
                                                      procure.delivered_status ===
                                                      "In Progress",
                                                  ) && (
                                                    <button
                                                      type="button"
                                                      className="btn btn-soft-primary btn-sm shadow-none mb-2"
                                                      disabled={
                                                        isLoadingProcured
                                                      }
                                                      onClick={() =>
                                                        handleDeliveredToDelivered(
                                                          product,
                                                        )
                                                      }
                                                    >
                                                      <i className="ri-add-line align-bottom"></i>{" "}
                                                      To Delivered
                                                    </button>
                                                  )}

                                                  {product?.toinitate_list?.map(
                                                    (
                                                      procure: {
                                                        delivered_status:
                                                          | "To Initiate"
                                                          | "Approved"
                                                          | "Rejected";
                                                        delivered_quantity_sum: number;
                                                        id: number;
                                                      },
                                                      procureIndex: number,
                                                    ) =>
                                                      procure?.delivered_quantity_sum >
                                                        0 && (
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            justifyContent:
                                                              "space-between",
                                                          }}
                                                        >
                                                          <div
                                                            key={procureIndex}
                                                            className={`badge border ${
                                                              STATUS_CODE[
                                                                procure
                                                                  ?.delivered_status
                                                              ]
                                                            } d-inline-flex align-items-center fs-8 mb-2`}
                                                          >
                                                            <i
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                                fontSize:
                                                                  "15px",
                                                                marginRight:
                                                                  "10px",
                                                              }}
                                                              className=" ri-calendar-check-fill"
                                                            ></i>{" "}
                                                            {
                                                              procure?.delivered_status
                                                            }
                                                          </div>
                                                          <div>
                                                            {
                                                              procure?.delivered_quantity_sum
                                                            }{" "}
                                                            {
                                                              product.measurement_unit
                                                            }
                                                          </div>
                                                          <div>
                                                            <i
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                                fontSize:
                                                                  "15px",
                                                                marginRight:
                                                                  "10px",
                                                              }}
                                                              title="Audit Log"
                                                              className=" ri-eye-fill"
                                                              onClick={() =>
                                                                handleOpenAuditModal(
                                                                  procure.id,
                                                                  "delivered",
                                                                )
                                                              }
                                                            ></i>
                                                          </div>
                                                        </div>
                                                      ),
                                                  )}
                                                </th>
                                                <th
                                                  className="text-center fs-8 res-tab-row-6"
                                                  scope="col"
                                                  style={{ minWidth: "200px" }}
                                                >
                                                  {product?.toinitate_list?.some(
                                                    (procure: any) =>
                                                      procure.delivered_status ===
                                                      "Delivered",
                                                  ) && (
                                                    <button
                                                      type="button"
                                                      className="btn btn-soft-primary btn-sm shadow-none mb-2"
                                                      disabled={
                                                        isLoadingProcured
                                                      }
                                                      onClick={() =>
                                                        handleImplementedToInitate(
                                                          product,
                                                        )
                                                      }
                                                    >
                                                      <i className="ri-add-line align-bottom"></i>{" "}
                                                      To Initate
                                                    </button>
                                                  )}

                                                  {product?.toinitate_list?.some(
                                                    (procure: any) =>
                                                      procure.implemented_status ===
                                                      "To Initiate",
                                                  ) && (
                                                    <button
                                                      type="button"
                                                      className="btn btn-soft-primary btn-sm shadow-none mb-2"
                                                      disabled={
                                                        isLoadingProcured
                                                      }
                                                      onClick={() =>
                                                        handleImplementedInProgress(
                                                          product,
                                                        )
                                                      }
                                                    >
                                                      <i className="ri-add-line align-bottom"></i>{" "}
                                                      To InProgress
                                                    </button>
                                                  )}

                                                  {product?.toinitate_list?.some(
                                                    (procure: any) =>
                                                      procure.implemented_status ===
                                                      "In Progress",
                                                  ) && (
                                                    <button
                                                      type="button"
                                                      className="btn btn-soft-primary btn-sm shadow-none mb-2"
                                                      disabled={
                                                        isLoadingProcured
                                                      }
                                                      onClick={() =>
                                                        handleImplementedToImplemented(
                                                          product,
                                                        )
                                                      }
                                                    >
                                                      <i className="ri-add-line align-bottom"></i>{" "}
                                                      To Implemented
                                                    </button>
                                                  )}

                                                  {product?.toinitate_list?.map(
                                                    (
                                                      procure: {
                                                        implemented_status:
                                                          | "To Initiate"
                                                          | "Approved"
                                                          | "Rejected";
                                                        implemented_quantity_sum: number;
                                                        id: number;
                                                      },
                                                      procureIndex: number,
                                                    ) =>
                                                      procure?.implemented_quantity_sum >
                                                        0 && (
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            justifyContent:
                                                              "space-between",
                                                          }}
                                                        >
                                                          <div
                                                            key={procureIndex}
                                                            className={`badge border ${
                                                              STATUS_CODE[
                                                                procure
                                                                  ?.implemented_status
                                                              ]
                                                            } d-inline-flex align-items-center fs-8 mb-2`}
                                                          >
                                                            <i
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                                fontSize:
                                                                  "15px",
                                                                marginRight:
                                                                  "10px",
                                                              }}
                                                              className=" ri-calendar-check-fill"
                                                            ></i>{" "}
                                                            {
                                                              procure?.implemented_status
                                                            }
                                                          </div>
                                                          <div>
                                                            {
                                                              procure?.implemented_quantity_sum
                                                            }{" "}
                                                            {
                                                              product.measurement_unit
                                                            }
                                                          </div>
                                                          <div>
                                                            <i
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                                fontSize:
                                                                  "15px",
                                                                marginRight:
                                                                  "10px",
                                                              }}
                                                              title="Audit Log"
                                                              className=" ri-eye-fill"
                                                              onClick={() =>
                                                                handleOpenAuditModal(
                                                                  procure.id,
                                                                  "implemented",
                                                                )
                                                              }
                                                            ></i>
                                                          </div>
                                                        </div>
                                                      ),
                                                  )}
                                                </th>
                                                <th
                                                  className="text-center fs-4 .res-tab-row-7"
                                                  scope="col"
                                                  style={{ minWidth: "200px" }}
                                                >
                                                  <button
                                                    type="button"
                                                    className="btn btn-soft-primary btn-sm shadow-none"
                                                  >
                                                    <i className="ri-add-line align-bottom"></i>{" "}
                                                    STATUS
                                                  </button>
                                                </th>
                                              </tr>
                                            </>
                                          ),
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Collapse>
                        </AccordionItem>
                      ))
                    ) : (
                      <div className="page-content">
                        <Container fluid>
                          <div className="d-flex align-items-center justify-content-center">
                            <p className="fs-18 text-grey">Data not found</p>
                          </div>
                        </Container>
                      </div>
                    )}
                  </Accordion>
                  <br />
                </Col>
              </Row>
            </LoadingOverlay>
          </Container>
        </div>
        <ImportResourceFilterModal
          show={isFilterModal}
          selectedSections={selectedSection}
          onCloseClick={handleCloseFilterModal}
          import_type={"resource_inventory_import"}
          refetch={refetchInventory}
          project_id={projectId}
        />
        <AuditModal
          id={modalAuditID}
          type={modalAuditType}
          isOpen={isAuditModalOpen}
          toggle={handleCloseAuditModal}
        />
        <AddSectionModal
          isOpen={modalOpen}
          toggle={toggleModal}
          projectId={projectId}
          refetchInventory={refetchInventory}
        />
        <ProductAddModal
          isOpen={productModalOpen}
          toggle={toggleProductModal}
          sectionId={selectedSectionId}
          refetchInventory={refetchInventory}
          productIds={selectedProducts}
        />
        {/* Quoted Modal */}
        <QuotedModal
          isOpen={isQuotedModalOpen}
          toggle={toggleQuotedModal}
          productId={selectedProductId}
          refetchInventory={refetchInventory}
          initialQuantity={selectedQuantity}
        />

        {/* Planned Modal */}
        <PlannedModal
          isOpen={isPlannedModalOpen}
          toggle={togglePlannedModal}
          productId={selectedProductId}
          refetchInventory={refetchInventory}
          initialQuantity={selectedPlannedQuantity}
        />

        {/* Procured Modal */}
        <ProcuredModal
          isOpen={isProcuredModalOpen}
          toggle={toggleProcuredModal}
          productId={selectedProductId}
          refetchInventory={refetchInventory}
          plannedAmount={selectedPlannedQuantity}
          quotedAmount={selectedQuantity}
          resourceProduct={selectedResourceProductId}
          selectedBalanceQuantity={selectedBalanceQuantity}
          selectedProductInventory={selectedProductInventory}
          selectedProcuredAmount={selectedProcuredAmount}
        />

        {/* Procured Next Modal */}
        <ProcuredNextModal
          isOpen={isProcuredNextModalOpen}
          toggle={toggleProcuredNextModal}
          productId={selectedProductId}
          refetchInventory={refetchInventory}
          productProcured={productProcured}
        />

        {/* Delivered ToInitate Modal */}
        <DeliveredToInitiateModal
          isOpen={isDeliveredToInitateModalOpen}
          toggle={toggleDeliveredToInitateModal}
          productId={selectedProductId}
          refetchInventory={refetchInventory}
          productProcured={productProcured}
        />

        {/* Delivered InProgress Modal */}
        <DeliveredInProgressModal
          isOpen={isDeliveredInProgressModalOpen}
          toggle={toggleDeliveredInProgressModal}
          productId={selectedProductId}
          refetchInventory={refetchInventory}
          productProcured={productProcured}
        />

        {/* Delivered To Delivered Modal */}
        <DeliveredToDeliveredModal
          isOpen={isDeliveredToDeliveredModalOpen}
          toggle={toggleDeliveredToDeliveredModal}
          productId={selectedProductId}
          refetchInventory={refetchInventory}
          productProcured={productProcured}
        />

        {/* Implemented ToInitate Modal */}
        <ImplementedToInitateModal
          isOpen={isImplementedToInitateModalOpen}
          toggle={toggleImplementedToInitateModal}
          productId={selectedProductId}
          refetchInventory={refetchInventory}
          productProcured={productProcured}
        />

        {/* Implemented InProgress Modal */}
        <ImplementedInProgressModal
          isOpen={isImplementedInProgressModalOpen}
          toggle={toggleImplementedInProgressModal}
          productId={selectedProductId}
          refetchInventory={refetchInventory}
          productProcured={productProcured}
        />

        {/* Implemented To Implemented Modal */}
        <ImplementedToImplementedModal
          isOpen={isImplementedToImplementedModalOpen}
          toggle={toggleImplementedToImplementedModal}
          productId={selectedProductId}
          refetchInventory={refetchInventory}
          productProcured={productProcured}
        />

        {/* Implemented Modal */}
        <ImplementedModal
          isOpen={isImplementedModalOpen}
          toggle={toggleImplementedModal}
          productId={selectedProductId}
          refetchInventory={refetchInventory}
        />

        {/* Delivered Modal */}
        <DeliveredModal
          isOpen={isDeliveredModalOpen}
          toggle={toggleDeliveredModal}
          productId={selectedProductId}
          refetchInventory={refetchInventory}
        />
        {/*BulkImport Modal */}
        <ImportResourceExcelModal
          show={isImportExcel}
          onCloseClick={() => setIsImportExcel(false)}
          import_type={"resource_inventory_import"}
          project_id={projectId}
          refetch={refetchInventory}
        />
      </React.Fragment>
    );
  };
  return renderContent();
};

export default InventoryDashBoard;
