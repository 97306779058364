import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  getToken,
  getUserRole,
  setAuthUser,
  isTokenNearExpiry,
  refreshAuthToken,
} from "./auth-token-header";

// Function to display the error alert
const showAlert = (messages: string[]) => {
  const alertTab = document.getElementById("common-alert-tab");
  if (messages.length > 0 && alertTab) {
    // Clear previous content
    alertTab.innerHTML = "";

    // Append each message as a line break
    messages.forEach((message) => {
      const messageElement = document.createElement("span");
      messageElement.innerHTML = message; // Use innerHTML to render HTML tags like <strong>
      alertTab.appendChild(messageElement);
      alertTab.appendChild(document.createElement("br")); // Add <br/> for line break
    });

    // Add close button functionality
    const closeButton = document.createElement("button");
    closeButton.classList.add("btn-close");
    closeButton.onclick = () => {
      alertTab.style.display = "none"; // Hide the alert when the button is clicked
    };
    alertTab.appendChild(closeButton);

    alertTab.style.display = "block";
    alertTab.classList.add("show");
  } else {
    alertTab && alertTab.classList.remove("show");
  }
};

// Custom base query with response handling
//@ts-ignore
export const customBaseQuery = async (args, api, extraOptions) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: window.__RUNTIME_CONFIG__.REACT_APP_API_URL,
    prepareHeaders: async (headers) => {
      let token = getToken();
      let userRole = getUserRole();
      const refreshToken = JSON.parse(
        localStorage.getItem("authUser") || "{}",
      )?.refresh;

      // Check token expiry and refresh if necessary
      if (isTokenNearExpiry(token) && refreshToken) {
        try {
          const newTokenData = await refreshAuthToken(refreshToken);
          setAuthUser(newTokenData);
          token = newTokenData.access;
        } catch (error) {
          console.error("Token refresh failed:", error);
        }
      }

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      if (userRole) {
        headers.set("AccessRole", userRole);
      }

      return headers;
    },
  });

  // Execute the base query
  const result = await baseQuery(args, api, extraOptions);

  // Handle errors globally
  if (
    result.error?.status === 400 ||
    result.error?.status === 401 ||
    result.error?.status === 500
  ) {
    let errorMessageList: string[] = [];
    // Helper function to format the error code
    const formatErrorCode = (code: string) => {
      return code
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    };

    // Check if a message exists in the result object
    //@ts-ignore
    if (result.error?.data?.message) {
      //@ts-ignore
      errorMessageList.push(result.error?.data?.message);
    }
    //@ts-ignore
    else if (result.error?.data?.error) {
      //@ts-ignore
      errorMessageList.push(result.error?.data?.error);
    } else if (
      //@ts-ignore
      result.error?.data?.errors &&
      //@ts-ignore
      Array.isArray(result.error?.data?.errors)
    ) {
      //@ts-ignore
      errorMessageList = result.error?.data?.errors.map(
        (error: { code: string; detail: string }) => {
          return `<i class="ri-error-warning-line me-1 align-middle fs-16"></i><strong>${formatErrorCode(error.code)}</strong> - ${error.detail}`;
        },
      );
    } //@ts-ignore
    else if (result.error?.data) {
      //@ts-ignore
      errorMessageList.push(result.error?.data);
    } else {
      errorMessageList.push("An unknown error occurred.");
    }

    // Display the alert with the constructed list of messages
    showAlert(errorMessageList);
  }

  return result;
};
