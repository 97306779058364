import { logoutUserSuccess } from "./reducer";

export const logoutUser = () => async (dispatch: any) => {
  try {
    sessionStorage.removeItem("authUser");
    sessionStorage.removeItem("selectedRole");
    localStorage.clear();
    dispatch(logoutUserSuccess(true));
  } catch (error: any) {
    console.error("error: ", error);
  }
};
