import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalFooter,
  Row,
  UncontrolledDropdown,
  FormGroup,
  FormFeedback,
  Spinner,
  Label,
  ModalBody,
  ModalHeader,
  Button,
  Table,
} from "reactstrap";
import { Tooltip } from "react-tooltip";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  useGetTeamByProjectIdQuery,
  useCreateTeamMutation,
  useUpdateTeamMutation,
  useDeleteTeamByIdMutation,
} from "slices/projects/projectSlice";

interface ProjectProps {
  projectData: any;
}

interface FormValues {
  team_name: string;
  project: string;
}

const TeamTab: React.FC<ProjectProps> = ({ projectData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState<number | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState<number | null>(
    null,
  );

  const {
    data: teamData,
    isLoading,
    refetch,
  } = useGetTeamByProjectIdQuery(projectData.id, {
    skip: !projectData.id,
  });

  const [createTeam, { isLoading: postLoading }] = useCreateTeamMutation();
  const [updtaeTeam, { isLoading: updateLoading }] = useUpdateTeamMutation();
  const [deleteTeam, { isLoading: deleteLoading }] =
    useDeleteTeamByIdMutation();

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  console.log("teadData", teamData);

  const validationSchema = Yup.object().shape({
    team_name: Yup.string().required("Team Name is required"),
  });

  const handleEditTeam = (team: any) => {
    setSelectedTeamId(team.id);
    formik.setFieldValue("team_name", team.team_name);
    formik.setFieldValue("short_name", team.short_name);
    handleModalToggle();
  };

  const handleAddTeam = () => {
    setSelectedTeamId(null);
    formik.resetForm();
    handleModalToggle();
  };

  const handleDeleteConfirmation = (id: number) => {
    console.log("id-----", id);
    setSelectedTeamId(id);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedTeamId) {
      try {
        await deleteTeam(selectedTeamId.toString()).unwrap();
        refetch();
        setDeleteModalOpen(false);
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    }
  };

  // Initialize Formik
  const formik = useFormik<FormValues>({
    initialValues: {
      team_name: "",
      project: projectData.id,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        if (selectedTeamId) {
          const payload = {
            id: selectedTeamId,
            newTeam: {
              team_name: values.team_name,
            },
          };
          await updtaeTeam(payload).unwrap();
        } else {
          const payload = {
            newTeam: values,
          };
          await createTeam(payload).unwrap();
        }
        refetch();
        handleModalToggle();
        formik.resetForm();
      } catch (error) {
        console.error("Error submitting document:", error);
      }
    },
    enableReinitialize: true,
  });

  return (
    <React.Fragment>
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".delete-task-status-class"
        place="bottom"
        content="Delete"
      />
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".delete-not-task-status-class"
        place="bottom"
        content="Team Already Assigned"
      />
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".edit-task-status-class"
        place="bottom"
        content="Edit"
      />
      <Card>
        <CardBody>
          <div className="d-flex align-items-center mb-4">
            <h5 className="card-title flex-grow-1 mb-0">Team Information</h5>
            <Button color="danger" onClick={handleAddTeam}>
              <i className="ri-add-line me-1 align-bottom"></i> Add Team
            </Button>
          </div>
          <Row>
            {/* Check if project_list has data */}
            <div className="table-responsive">
              <Table className="table-borderless align-middle mb-0">
                <thead className="table-light">
                  <tr>
                    <th scope="col">Team Name</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {teamData && teamData.length > 0 ? (
                    teamData?.map((team: any, index: any) => (
                      <tr key={index}>
                        <td>{team.team_name}</td>
                        <td>
                          <Link
                            to="#"
                            className="me-1 text-primary d-inline-block edit-item-btn edit-task-status-class"
                            onClick={() => handleEditTeam(team)}
                          >
                            <i className="ri-pencil-fill fs-16"></i>
                          </Link>
                          {team?.is_delete_check ? (
                            <Link
                              to="#"
                              className="text-primary d-inline-block edit-item-btn delete-task-status-class"
                              onClick={() => handleDeleteConfirmation(team?.id)}
                            >
                              <i className="ri-delete-bin-fill fs-16"></i>
                            </Link>
                          ) : (
                            <i className="ri-delete-bin-fill fs-16 text-muted delete-not-task-status-class"></i>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={2} className="text-center">
                        No Team Data Available
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Row>
        </CardBody>
      </Card>

      {/* Add/Edit Document Modal */}
      <Modal
        backdrop={"static"}
        isOpen={isModalOpen}
        toggle={handleModalToggle}
      >
        <ModalHeader>
          {isEditing ? "Edit Document" : "Add Document"}
        </ModalHeader>
        <ModalBody>
          <LoadingOverlay active={isLoading} spinner text="Loading...">
            <form onSubmit={formik.handleSubmit}>
              <FormGroup>
                <Label for="team_name-field" className="form-label">
                  Team Name
                </Label>
                <Input
                  name="team_name"
                  id="team_name-field"
                  className="form-control"
                  placeholder="Team Name"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.team_name || ""}
                  invalid={
                    formik.touched.team_name && formik.errors.team_name
                      ? true
                      : false
                  }
                />
                {formik.touched.team_name && formik.errors.team_name ? (
                  <FormFeedback type="invalid">
                    {formik.errors.team_name}
                  </FormFeedback>
                ) : null}
              </FormGroup>
              <Button
                color="primary"
                type="submit"
                disabled={postLoading || updateLoading}
              >
                {postLoading || updateLoading ? (
                  <Spinner size="sm" />
                ) : (
                  "Submit"
                )}
              </Button>
              &nbsp;&nbsp;
              <Button
                color="secondary"
                disabled={postLoading || updateLoading}
                onClick={() => {
                  formik.resetForm(); // Reset the form when user clicks cancel
                  handleModalToggle();
                }}
              >
                Cancel
              </Button>
            </form>
          </LoadingOverlay>
        </ModalBody>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={deleteModalOpen}
        toggle={() => setDeleteModalOpen(false)}
        centered
      >
        <ModalHeader toggle={() => setDeleteModalOpen(false)}>
          Confirm Delete
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete Team? This action cannot be undone.
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            disabled={deleteLoading}
            onClick={() => setDeleteModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={handleConfirmDelete}
            disabled={deleteLoading}
          >
            {deleteLoading ? <Spinner size="sm" /> : "Delete"}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default TeamTab;
