import React, { useState, useCallback, useEffect } from "react";
import { Container, Col, Row, Spinner, UncontrolledAlert } from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import Summary from "./Section";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useGetIncidentReportByNumberQuery } from "slices/incidentReport/incidentReportSlice";

const AccidentReportDetails = () => {
  document.title = "Accident Report View";

  const { incidentId: incidentIdFromURL } = useParams<{ incidentId: string }>();

  const {
    data: incidentReportDataById,
    isLoading,
    isFetching,
    refetch: refetchTooboxById,
  } = useGetIncidentReportByNumberQuery(incidentIdFromURL);

  useEffect(() => {
    refetchTooboxById();
  }, []);

  if (isLoading) {
    return (
      <React.Fragment>
        <Container fluid>
          <div className="page-content" style={{ height: "80vh" }}>
            {" "}
            {/* Full viewport height */}
            <Container fluid className="h-100">
              <Row className="h-100 d-flex justify-content-center align-items-center">
                <Col lg={12} className="text-center">
                  <Spinner color="primary"></Spinner> <br /> <br />
                  <span className="text-muted">
                    Fetching Accident Report Information...
                  </span>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Container fluid>
        <BreadCrumb
          title="Accident Report Details"
          pageTitle="Accident Report"
          style={{ "margin-top": "70px" }}
        />

        <Summary
          toolboxMeetingByIdData={incidentReportDataById}
          isFetching={isFetching}
          refetchTooboxById={refetchTooboxById}
        />
        <ToastContainer closeButton={false} limit={1} />
      </Container>
    </React.Fragment>
  );
};

export default AccidentReportDetails;
