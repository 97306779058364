import React, { useMemo, useState } from 'react';
import { useGetEmployeesRolesQuery } from 'slices/employee/employeeSlice';
import { useGetAllProjectsQuery } from 'slices/projects/projectSlice';
import { useGetSubContractCompanyQuery } from 'slices/pageAccess/PageAccessSlice';
import { useGetCompaniesQuery } from 'slices/InspectionReport/InspectionReportSlice';

export const EmployeeListGlobalFilter = ({setPageState, globalValue}: any) => {

    const { data: Projects } = useGetAllProjectsQuery(undefined);
    const { data: Positions } = useGetEmployeesRolesQuery(undefined);
    const { data: contractCompanyData } = useGetSubContractCompanyQuery(undefined);
    const { data: CompanyData } = useGetCompaniesQuery(undefined);

    console.log('CompanyData---filter',CompanyData)


    const mergedCompanies = useMemo(() => {
        let companies = [];
    
        // Ensure CompanyData is an array and extract the first object
        if (Array.isArray(CompanyData) && CompanyData.length > 0) {
            companies.push({ id: CompanyData[0].id, name: CompanyData[0].company_name });
        }
    
        // Ensure contractCompanyData is an array before mapping
        if (Array.isArray(contractCompanyData)) {
            companies = [...companies, ...contractCompanyData.map((sub) => ({
                id: sub.id,
                name: sub.subcompany_name
            }))];
        }
    
        return companies;
    }, [CompanyData, contractCompanyData]);
    

    const handleCompanyChange = (value: string) => {
        setPageState((prev: any) => ({
            ...prev,
            sub_company_name: value,
            page: 1
        }));
    };

    

    const [dateRange, setDateRange] = useState([null, null]);

    const statusOnChange = (value: string) => {
        setPageState((prev: any)=>({
            ...prev,
            report_status: value,
            page: 1
        }))
    }

    const projectsOnChange = (value: string) => {
        setPageState((prev: any)=>({
            ...prev,
            projects : value,
            page: 1
        }))
    }

    const postionsOnChange = (value: string) => {
        setPageState((prev: any)=>({
            ...prev,
            position : value,
            page: 1
        }))
    }

    // const handleDateChange = (selectedDates: any) => {
    //     if (selectedDates.length === 2) {
    //         const [startDate, endDate] = selectedDates;
    //         setDateRange([startDate, endDate]);

    //         let startDateString = startDate.toISOString().split('T')[0];

    //         setPageState((prev: any)=>({
    //             ...prev,
    //             start_date: startDateString,
    //         }))
    //     }
    // };

    // const handleBlur = () => {
    //     // If the date range is not set, clear the date range
    //     if (dateRange[0] && dateRange[1]) {
    //         setDateRange([null, null]);
    //         setPageState((prev: any)=>({
    //             ...prev,
    //             start_date: '',
    //         }))
    //     }
    // };


    return (
        <React.Fragment>
            {/* <div className="col-xxl-3 col-sm-4">
                <Flatpickr
                    placeholder="Select start & due date range"
                    className="form-control bg-light border-light"
                    options={{
                        mode: "range",
                        dateFormat: "d-m-Y",
                    }}
                    value={dateRange}
                    onChange={handleDateChange}
                    onClose={handleBlur}
                />
            </div> */}

            <div className="col-xxl-3 col-sm-4">
                <div className="input-light">
                    <select onChange={(e) => postionsOnChange(e.target.value)} className="form-control" data-choices data-choices-search-false name="status" id="idStatus">
                    <option value="" >Position</option>
                    {Positions?.map((options: any) => (
                        <option key={options.id} value={options.position_name}>
                        {options.position_name}
                        </option>
                    ))}
                    </select>
                </div>
            </div>
            <div className="col-xxl-3 col-sm-4">
                <div className="input-light">
                    <select onChange={(e) => projectsOnChange(e.target.value)} className="form-control" data-choices data-choices-search-false name="status" id="idStatus">
                    <option value="" >Projects</option>
                    {Projects?.map((options: any) => (
                        <option key={options.id} value={options.project_name}>
                        {options.project_name}
                        </option>
                    ))}
                    </select>
                </div>
            </div>
            <div className="col-xxl-3 col-sm-4">
                <div className="input-light">
                    <select onChange={(e) => handleCompanyChange(e.target.value)} className="form-control" data-choices data-choices-search-false>
                        <option value="">Select Company</option>
                        {mergedCompanies.map((company) => (
                            <option key={company.id} value={company.name}>
                                {company.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </React.Fragment>
    );
};
