import React, { useState, useCallback, useEffect } from "react";
import { Container, Col, Row, Spinner } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
// import Summary from './Summary';
import Summary from "./TaskOverview/Section";
import TaskTracking from "./TaskTracking";
import { useParams } from "react-router-dom";
// Formik
import { ToastContainer } from "react-toastify";
import { TaskFormModal } from "../TaskList/Modal/TaskForm";
import { ChangeStatusFormModal } from "../TaskList/Modal/ChangeStatusForm";
import {
  useGetTasksByIdQuery,
  useUpdateTaskMutation,
} from "slices/tasks/taskSlice";

const TaskDetails = () => {
  document.title = "Task Details";
  const [task, setTask] = useState<any>([]);
  //Dropzone file upload
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [isEdit] = useState<boolean>(true);
  const { task_id } = useParams<{ task_id: string }>();
  const [modal, setModal] = useState<boolean>(false);
  const [modalChangeStatus, setModalChangeStatus] = useState<boolean>(false);

  const {
    data: getTaskById,
    isLoading,
    refetch: refetchTasks,
    error: taskError,
  } = useGetTasksByIdQuery(task_id);

  const [updateTask, { isLoading: isLoadingUpdateTask }] =
    useUpdateTaskMutation();

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setTask(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const toggleChangeStatus = useCallback(() => {
    if (modalChangeStatus) {
      setModalChangeStatus(false);
      setTask(null);
    } else {
      setModalChangeStatus(true);
    }
  }, [modalChangeStatus]);

  useEffect(() => {
    refetchTasks();
  }, []);

  if (isLoading) {
    return (
      <React.Fragment>
        <Container fluid>
          <div className="page-content" style={{ height: "80vh" }}>
            {" "}
            {/* Full viewport height */}
            <Container fluid className="h-100">
              <Row className="h-100 d-flex justify-content-center align-items-center">
                <Col lg={12} className="text-center">
                  <Spinner color="primary"></Spinner> <br /> <br />
                  <span className="text-muted">
                    Fetching Task Information...
                  </span>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Container fluid>
        <BreadCrumb
          title="Task Details"
          pageTitle="Tasks"
          style={{ "margin-top": "70px" }}
        />

        <Summary
          getTaskById={getTaskById}
          setTask={setTask}
          toggle={toggle}
          toggleChangeStatus={toggleChangeStatus}
        />

        <TaskFormModal
          modal={modal}
          toggle={toggle}
          isEdit={isEdit}
          isLoadingCreateTask={isLoadingUpdateTask}
          selectedFiles={selectedFiles}
          setselectedFiles={setselectedFiles}
          setModal={setModal}
          task={task}
          createTask={null}
          updateTask={updateTask}
          refetchTasks={refetchTasks}
        />

        <ChangeStatusFormModal
          modal={modalChangeStatus}
          toggle={toggleChangeStatus}
          isEdit={isEdit}
          isLoadingCreateTask={isLoadingUpdateTask}
          selectedFiles={selectedFiles}
          setselectedFiles={setselectedFiles}
          setModal={setModalChangeStatus}
          task={task}
          createTask={null}
          updateTask={updateTask}
          refetchTasks={refetchTasks}
        />
        <ToastContainer closeButton={false} limit={1} />
      </Container>
    </React.Fragment>
  );
};

export default TaskDetails;
