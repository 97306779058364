import { createApi } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "helpers/jwt-token-access/customBaseQuery";

export const pageAccessSlice = createApi({
  reducerPath: "pageAccess",
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    // Page Access Endpoints
    getPageAccesses: builder.query({
      query: () => "/organization/api/v1/page_access/",
    }),
    getPageAccessById: builder.query({
      query: (id) => `/organization/api/v1/page_access/${id}/`,
    }),
    getRoleById: builder.query({
      query: (id) => `/organization/api/v1/roles/${id}/`,
    }),
    createRole: builder.mutation({
      query: (newRole) => ({
        url: "/organization/api/v1/roles/",
        method: "POST",
        body: newRole,
      }),
    }),
    updateRoleById: builder.mutation({
      query: ({ id, updateData }) => ({
        url: `/organization/api/v1/roles/${id}/`,
        method: "PATCH",
        body: updateData,
      }),
    }),
    deleteRoleById: builder.mutation({
      query: (id) => ({
        url: `/organization/api/v1/roles/${id}/`,
        method: "DELETE",
      }),
    }),
    //Role policy Endpoints
    getPosition: builder.query({
      query: () => `/employee/api/v1/employee_role_policy/`,
    }),
    getPositionById: builder.query({
      query: (id) => `/employee/api/v1/employee_role_policy/${id}/`,
    }),
    createPosition: builder.mutation({
      query: ({ newPosition }) => ({
        url: `/employee/api/v1/employee_role_policy/`,
        method: "POST",
        body: newPosition,
      }),
    }),
    updatePosition: builder.mutation({
      query: ({ id, updateData }) => ({
        url: `/employee/api/v1/employee_role_policy/${id}/`,
        method: "PATCH",
        body: updateData,
      }),
    }),
    // WorkLocation Endpoints
    getWorkLocation: builder.query({
      query: () => `/employee/api/v1/employee_work_location/`,
    }),
    getWorkLocationById: builder.query({
      query: (id) => `/employee/api/v1/employee_work_location/${id}/`,
    }),
    createWorkLocation: builder.mutation({
      query: ({ newWorkLocation }) => ({
        url: `/employee/api/v1/employee_work_location/`,
        method: "POST",
        body: newWorkLocation,
      }),
    }),
    updateWorkLocation: builder.mutation({
      query: ({ id, updateData }) => ({
        url: `/employee/api/v1/employee_work_location/${id}/`,
        method: "PATCH",
        body: updateData,
      }),
    }),
    // Country of Issue Endpoints
    getCountryOfIssue: builder.query({
      query: () => `/employee/api/v1/employee_country_of_issue/`,
    }),
    getCountryOfIssueById: builder.query({
      query: (id) => `/employee/api/v1/employee_country_of_issue/${id}/`,
    }),
    createCountryOfIssue: builder.mutation({
      query: ({ newCountryOfIssue }) => ({
        url: `/employee/api/v1/employee_country_of_issue/`,
        method: "POST",
        body: newCountryOfIssue,
      }),
    }),
    updateCountryOfIssue: builder.mutation({
      query: ({ id, updateData }) => ({
        url: `/employee/api/v1/employee_country_of_issue/${id}/`,
        method: "PATCH",
        body: updateData,
      }),
    }),
    // primary company Endpoints
    getPrimaryCompany: builder.query({
      query: () => `/organization/api/v1/company/`,
    }),
    getPrimaryCompanyById: builder.query({
      query: (id) => `/organization/api/v1/company/${id}/`,
    }),
    createPrimaryCompany: builder.mutation({
      query: ({ newPrimaryCompany }) => ({
        url: `/organization/api/v1/company/`,
        method: "POST",
        body: newPrimaryCompany,
      }),
    }),
    updatePrimaryCompany: builder.mutation({
      query: ({ id, updateData }) => ({
        url: `/organization/api/v1/company/${id}/`,
        method: "PATCH",
        body: updateData,
      }),
    }),
     // Sub Contract Company Endpoints
     getSubContractCompany: builder.query({
      query: () => `/organization/api/v1/subcontractor_company/`,
    }),
    getSubContractCompanyById: builder.query({
      query: (id) => `/organization/api/v1/subcontractor_company/${id}/`,
    }),
    createSubContractCompany: builder.mutation({
      query: ({ newSubContractCompany }) => ({
        url: `/organization/api/v1/subcontractor_company/`,
        method: "POST",
        body: newSubContractCompany,
      }),
    }),
    updateSubContractCompany: builder.mutation({
      query: ({ id, updateData }) => ({
        url: `/organization/api/v1/subcontractor_company/${id}/`,
        method: "PATCH",
        body: updateData,
      }),
    }),
  }),
});

export const {
  useGetPageAccessesQuery,
  useGetPageAccessByIdQuery,
  useGetRoleByIdQuery,
  useCreateRoleMutation,
  useUpdateRoleByIdMutation,
  useDeleteRoleByIdMutation,

  //Position
  useGetPositionQuery,
  useGetPositionByIdQuery,
  useCreatePositionMutation,
  useUpdatePositionMutation,

  // Work Location
  useCreateWorkLocationMutation,
  useUpdateWorkLocationMutation,
  useGetWorkLocationQuery,
  useGetWorkLocationByIdQuery,

  //Country of Issue
  useCreateCountryOfIssueMutation,
  useUpdateCountryOfIssueMutation,
  useGetCountryOfIssueQuery,
  useGetCountryOfIssueByIdQuery,

  //Primary Company Endpoints
  useCreatePrimaryCompanyMutation,
  useGetPrimaryCompanyByIdQuery,
  useGetPrimaryCompanyQuery,
  useUpdatePrimaryCompanyMutation,

  //Sub Contarctor Company Endpoints
  useGetSubContractCompanyQuery,
  useGetSubContractCompanyByIdQuery,
  useCreateSubContractCompanyMutation,
  useUpdateSubContractCompanyMutation,

} = pageAccessSlice;
