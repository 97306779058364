import React, { useState, useMemo, useCallback, useEffect } from "react";
import TableContainer from "../../../Components/Common/TableContainer";
import DeleteModal from "../../../Components/Common/DeleteModal";

import { Col } from "reactstrap";
import { Tooltip } from "react-tooltip";
import {
  OrdersId,
  Project,
  CreateBy,
  DueDate,
  Status,
  Priority,
} from "./TaskListCol";

import { Link } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Components/Common/Loader";
import { TaskFormModal } from "./Modal/TaskForm";
import { useSearchParams } from "react-router-dom";
import {
  useGetAllTasksQuery,
  useCreateTaskMutation,
  useUpdateTaskMutation,
} from "slices/tasks/taskSlice";
import { IsValidPermissions } from "../../../Routes/AuthProtected";
import ExportCSVModal from "Components/Common/ExportCSVModal";

const AllTasks = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [task, setTask] = useState<any>([]);

  const [isExportCSV, setIsExportCSV] = useState<boolean>(false);

  //Dropzone file upload
  const [selectedFiles, setselectedFiles] = useState<any>([]);

  // Delete Task
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState<any>([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] =
    useState<boolean>(false);

  const [page, setPageState] = useState<{
    page: number;
    pageSize: number;
    search: string;
    status_name: string;
    start_date: string;
    due_date: string;
  }>({
    page: 1,
    pageSize: 20,
    search: "",
    status_name: "",
    start_date: "",
    due_date: "",
  });

  const {
    data: allTask,
    isLoading: isTaskLoading,
    isFetching: isTaskFetching,
    refetch: refetchTasks,
    error: taskError,
  } = useGetAllTasksQuery(page);
  const [createTask, { isLoading: isLoadingCreateTask }] =
    useCreateTaskMutation();
  const [updateTask, { isLoading: isLoadingUpdateTask }] =
    useUpdateTaskMutation();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // Check if create_task is true in the URL search params
    if (searchParams.get("create_task") === "true") {
      setModal(true);
      // Update the URL to set create_task to false
      searchParams.set("create_task", "false");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setTask(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  // Delete Data
  const onClickDelete = (task: any) => {
    setTask(task);
    setDeleteModal(true);
  };

  // Delete Data
  const handleDeleteTask = () => {
    if (task) {
      // dispatch(deleteTask(task.id));
      setDeleteModal(false);
    }
  };

  // Update Data
  const handleCustomerClick = useCallback(
    (arg: any) => {
      const task = arg;

      setTask({
        id: task.id,
        title: task.title,
        description: task.description,
        projects: task.projects,
        priority: task.priority,
        assignee: task.assignee,
        reporter: task.reporter,
        task_status: task.task_status,
        start_date: task.start_date,
        due_date: task.due_date,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle],
  );

  // Add Data
  const handleTaskClicks = () => {
    setTask("");
    setIsEdit(false);
    toggle();
  };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall: any = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".taskCheckBox");

    if (checkall.checked) {
      ele.forEach((ele: any) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele: any) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const deleteMultiple = () => {
    const checkall: any = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element: any) => {
      // dispatch(deleteTask(element.value));
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".taskCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const truncateDescription = (description: string, maxLength: number) => {
    if (description.length > maxLength) {
      return `${description.substring(0, maxLength)}...`;
    }
    return description;
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps: any) => {
          return (
            <input
              type="checkbox"
              className="taskCheckBox form-check-input"
              value={cellProps.row.original.id}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      },
      {
        Header: "Task ID",
        accessor: "task_id",
        filterable: false,
        Cell: (cellProps: any) => {
          return <OrdersId {...cellProps} />;
        },
      },
      {
        Header: "Project",
        accessor: "get_employee_projects",
        filterable: false,
        Cell: (cellProps: any) => {
          return <Project {...cellProps} />;
        },
      },
      {
        Header: "Tasks",
        accessor: "title",
        filterable: false,
        Cell: (cellProps: any) => {
          return (
            <React.Fragment>
              <div className="d-flex">
                <div className="flex-grow-1 tasks_name">{cellProps.value}</div>
                <div className="flex-shrink-0 ms-4">
                  <ul className="list-inline tasks-list-menu mb-0">
                    <li className="list-inline-item">
                      <Link
                        className="view-task-class"
                        to={`/apps-tasks-details/${cellProps?.row?.original?.id}`}
                      >
                        <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        className="update-task-class"
                        to="#"
                        onClick={() => {
                          const taskData = cellProps.row.original;
                          handleCustomerClick(taskData);
                        }}
                      >
                        <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to="#"
                        className="remove-item-btn delete-task-class"
                        onClick={() => {
                          const taskData = cellProps.row.original;
                          onClickDelete(taskData);
                        }}
                      >
                        <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        Header: "Tasks Notes",
        accessor: "description",
        filterable: false,
        Cell: (cellProps: any) => {
          return (
            <React.Fragment>
              <div className="d-flex">
                <div className="flex-grow-1 tasks_name">
                  {truncateDescription(cellProps.value, 30)}
                </div>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        Header: "Reporter",
        accessor: "get_employee_reporter",
        filterable: false,
        Cell: (cellProps: any) => {
          return <CreateBy {...cellProps} />;
        },
      },
      {
        Header: "Assigned To",
        accessor: "get_employee_assignee_obj",
        filterable: false,
        Cell: (cell: any) => {
          return (
            <React.Fragment>
              <div className="avatar-group">
                {cell.value.map((item: any, index: any) =>
                  item.assignee_img_url ? (
                    <Link
                      key={index}
                      to="#"
                      className="avatar-group-item"
                      title={item.assignee_name}
                    >
                      <img
                        src={item.assignee_img_url}
                        alt=""
                        className="rounded-circle avatar-xxs"
                      />
                    </Link>
                  ) : (
                    <Link
                      key={index}
                      to="#"
                      className="avatar-group-item"
                      title={item.assignee_name}
                    >
                      <div className="flex-shrink-0 avatar-xxs">
                        <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                          {item.assignee_name.charAt(0)}
                        </div>
                      </div>
                    </Link>
                  ),
                )}
              </div>
            </React.Fragment>
          );
        },
      },
      {
        Header: "Due Date",
        accessor: "due_date",
        filterable: false,
        Cell: (cellProps: any) => {
          return <DueDate {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "task_status_name",
        filterable: false,
        Cell: (cellProps: any) => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "Priority",
        accessor: "get_employee_priority",
        filterable: false,
        Cell: (cellProps: any) => {
          return <Priority {...cellProps} />;
        },
      },
    ],
    [handleCustomerClick, checkedAll],
  );

  return (
    <React.Fragment>
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".new-task-class"
        content="New Task"
      />
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".export-task-class"
        content="Export Report"
      />
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".view-task-class"
        content="View Task"
      />
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".update-task-class"
        content="Update Task"
      />
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".delete-task-class"
        content="Delete Task"
      />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTask}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <div className="row">
        <Col lg={12}>
          <div className="card" id="tasksList">
            <div className="card-header border-0">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0 flex-grow-1">
                  All Tasks Information
                </h5>
                <div className="flex-shrink-0">
                  <div className="d-flex flex-wrap gap-2">
                    {IsValidPermissions(["ADD_TASK"]) && (
                      <button
                        className="btn btn-primary add-btn me-1 new-task-class"
                        disabled={isTaskLoading}
                        onClick={() => {
                          setIsEdit(false);
                          toggle();
                        }}
                      >
                        <i className="ri-add-line align-bottom me-1"></i> New
                        Task
                      </button>
                    )}

                    {isMultiDeleteButton && (
                      <button
                        className="btn btn-soft-danger"
                        onClick={() => setDeleteModalMulti(true)}
                      >
                        <i className="ri-delete-bin-2-line"></i>
                      </button>
                    )}

                    <button
                      type="button"
                      className="btn btn-secondary export-task-class"
                      onClick={() => setIsExportCSV(true)}
                      disabled={isTaskLoading || allTask?.results.length === 0}
                    >
                      <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                      Export
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body pt-0">
              {!isTaskLoading ? (
                <TableContainer
                  columns={columns}
                  data={allTask?.results || []}
                  isLoading={isTaskFetching}
                  isGlobalFilter={true}
                  isAddUserList={false}
                  customPageSize={page.pageSize}
                  className="custom-header-css"
                  divClass="table-responsive table-card mb-3"
                  tableClass="align-middle table-nowrap mb-0"
                  theadClass="table-light text-muted"
                  thClass="table-light text-muted"
                  handleTaskClick={handleTaskClicks}
                  isTaskListFilter={true}
                  SearchPlaceholder="Search for tasks..."
                  isPagination={false}
                  isServerPagination={true}
                  serverPaginationProps={{
                    count: allTask?.count || 0,
                    next: allTask?.next || null,
                    page_size: allTask?.page_size || 10,
                    previous: allTask?.previous || null,
                    current_page: allTask?.current_page || 1,
                    total_page: allTask?.total_page || 0,
                  }}
                  setPageState={setPageState}
                />
              ) : (
                <Loader error={taskError} />
              )}
              <ToastContainer closeButton={false} limit={1} />
            </div>
          </div>
        </Col>
      </div>

      <TaskFormModal
        modal={modal}
        toggle={toggle}
        isEdit={isEdit}
        isLoadingCreateTask={
          !isEdit ? isLoadingCreateTask : isLoadingUpdateTask
        }
        selectedFiles={selectedFiles}
        setselectedFiles={setselectedFiles}
        setModal={setModal}
        task={task}
        createTask={createTask}
        updateTask={updateTask}
        refetchTasks={refetchTasks}
      />

      <ExportCSVModal
        show={isExportCSV}
        onCloseClick={() => setIsExportCSV(false)}
        data={allTask?.results || []}
        columns={columns}
        name={"Task"}
      />
    </React.Fragment>
  );
};

export default AllTasks;
