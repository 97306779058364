import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const ExportCSVModal = ({ show, onCloseClick, data, columns, name }: any) => {
  const [selectedColumns, setSelectedColumns] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  // Handle column checkbox change
  const handleColumnChange = (column: any) => {
    if (selectedColumns.includes(column)) {
      setSelectedColumns(selectedColumns?.filter((col) => col !== column));
    } else {
      setSelectedColumns([...selectedColumns, column]);
    }
  };

  // Handle "Select All" checkbox change
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedColumns([]);
    } else {
      const allColumnAccessors = columns
        .filter((col: any) => col?.accessor) // Filter out columns without accessors
        .map((col: any) => col?.accessor);
      setSelectedColumns(allColumnAccessors);
    }
    setSelectAll(!selectAll);
  };

  // Prepare filtered data based on selected columns
  const filteredData = data?.map((row: any) => {
    let filteredRow: any = {};
    selectedColumns?.forEach((col: string) => {
      filteredRow[col] = row[col];
    });
    return filteredRow;
  });

  // Excel export function
  const handleExportExcel = () => {
    const worksheetData = filteredData?.map((row: any) =>
      selectedColumns?.reduce((acc, col) => {
        acc[col] = row[col];
        return acc;
      }, {}),
    );

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX?.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, `${name}.xlsx`);
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader toggle={onCloseClick}>Export Columns</ModalHeader>
      <ModalBody className="py-3 px-5">
        <div className="mt-2">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="selectAllColumns"
              checked={selectAll}
              onChange={handleSelectAll}
            />
            <label className="form-check-label" htmlFor="selectAllColumns">
              Select All
            </label>
          </div>

          {columns
            .filter((col: any) => col?.accessor && col?.accessor !== "actions") // Render only columns with accessors
            .map((col: any) => (
              <div className="form-check" key={col?.accessor}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={col?.accessor}
                  checked={selectedColumns.includes(col?.accessor)}
                  onChange={() => handleColumnChange(col?.accessor)}
                />
                <label className="form-check-label" htmlFor={col?.accessor}>
                  {col?.Header}
                </label>
              </div>
            ))}

          <div className="text-center mt-4">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={onCloseClick}
            >
              Close
            </button>

            {/* CSV Export */}
            <CSVLink
              data={filteredData}
              headers={columns
                .filter((col: any) => selectedColumns.includes(col?.accessor))
                .map((col: any) => ({
                  label: col?.Header,
                  key: col?.accessor,
                }))}
              filename={`${name}.csv`}
              className="btn w-sm btn-success ms-2"
            >
              Export CSV
            </CSVLink>

            {/* Excel Export */}
            <button
              type="button"
              className="btn w-sm btn-success ms-2"
              onClick={handleExportExcel}
            >
              Export Excel
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

ExportCSVModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
};

export default ExportCSVModal;
