import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
  Spinner,
  FormFeedback,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import {
  useGetEmployeeCountryOfIssueQuery,
  usePatchEmergencyContactInfoMutation,
} from "slices/employee/employeeSlice";
import LoadingOverlay from "react-loading-overlay-ts";
import { toast } from "react-toastify";
import sgFlag from "../../../assets/images/flags/sg.svg";
import { country } from "../../../common/data";
import SimpleBar from "simplebar-react";

interface OptionType {
  value: string;
  label: string;
  country_of_issue: string;
  id: string;
}

interface EmergencyContactData {
  full_name: string;
  email: string;
  relationship: string;
  address2: string;
  mobile_phone: string;
  state: string;
  zip_code: string;
  work_phone: string;
  address1: string;
  home_phone: string;
  city: string;
  country: number | null;
}

interface EmergencyContactEditProps {
  employee_uuid: string | undefined;
  setCardView: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  emergencyContactInfoData: any;
  emergencyContactInfoLoading: boolean;
}

export const EmergencyContactEdit: React.FC<EmergencyContactEditProps> = ({
  employee_uuid,
  setCardView,
  emergencyContactInfoData,
  emergencyContactInfoLoading,
}) => {
  const [
    patchEmergencyContactInfo,
    { isLoading: patchEmergencyContactInfoLoading },
  ] = usePatchEmergencyContactInfoMutation();
  const { data: countryData } = useGetEmployeeCountryOfIssueQuery(undefined);

  const countryOptions =
    countryData?.map((country: OptionType) => ({
      value: country.id?.toString(),
      label: country.country_of_issue,
    })) || [];

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const [seletedCountry, setseletedCountry] = useState({
    id: 240,
    flagImg: sgFlag,
    countryName: "Singapore",
    countryCode: "+65",
    length: 8,
  });

  const getPhoneNumber = (workPhone: string) => {
    if (!workPhone) return ""; // Return empty string if no phone number provided
    // Check if the number contains a dash
    if (workPhone.includes(" ")) {
      // Split by dash and return the second part
      return workPhone.split(" ")[1];
    }
    // Return the original number if no dash is present
    return workPhone;
  };

  // Yup validation schema
  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required("Full Name is required"),
    email: Yup.string().email("Invalid email address").optional(),
    relationship: Yup.string().required("Relationship is required"),
    mobile_phone: Yup.string()
      .min(
        seletedCountry.length,
        `Phone number must be ${seletedCountry.length} digits`,
      )
      .max(
        seletedCountry.length,
        `Phone number must be ${seletedCountry.length} digits`,
      )
      .matches(/^\d+$/, "Phone phone must be digits only")
      .optional(),
    address1: Yup.string().required("Address Line 1 is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zip_code: Yup.string().required("Zip Code is required"),
    country: Yup.string().required("Country is required"),
  });

  // Initialize Formik
  const formik = useFormik<EmergencyContactData>({
    initialValues: {
      full_name: "",
      email: "",
      relationship: "",
      address2: "",
      mobile_phone: "",
      state: "",
      zip_code: "",
      work_phone: "",
      address1: "",
      home_phone: "",
      city: "",
      country: null,
    },
    validationSchema,
    onSubmit: (values) => {
      const updatedData = {
        ...values,
        mobile_phone: values.mobile_phone
          ? `${seletedCountry.countryCode} ${values.mobile_phone}`
          : "",
      };

      try {
        patchEmergencyContactInfo({
          employee_uuid: employee_uuid,
          ...updatedData,
        });
        toast.success("Employee Emergency Contact updated successfully!");
        setCardView({ summary: true });
      } catch (error) {
        // Handle error
        toast.success("Failed to update Emergency Contact!");
      }
    },
  });

  useEffect(() => {
    if (emergencyContactInfoData) {
      formik.setValues({
        full_name: emergencyContactInfoData.full_name || "",
        email: emergencyContactInfoData.email || "",
        relationship: emergencyContactInfoData.relationship || "",
        address2: emergencyContactInfoData.address2 || "",
        mobile_phone: getPhoneNumber(emergencyContactInfoData?.mobile_phone),
        state: emergencyContactInfoData.state || "",
        zip_code: emergencyContactInfoData.zip_code || "",
        work_phone: emergencyContactInfoData.work_phone || "",
        address1: emergencyContactInfoData.address1 || "",
        home_phone: emergencyContactInfoData.home_phone || "",
        city: emergencyContactInfoData.city || "",
        country: emergencyContactInfoData.country || null,
      });
    }
  }, [emergencyContactInfoData]);

  return (
    <Card>
      <CardHeader className="d-flex">
        <h4 className="card-title flex-grow-1 mb-0">Emergency Contact Edit</h4>
      </CardHeader>
      <LoadingOverlay
        active={emergencyContactInfoLoading}
        spinner
        text="Fetching..."
      >
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="full_name" className="form-label">
                    Full Name
                  </Label>
                  <span className="text-danger">*</span>
                  <Input
                    type="text"
                    id="full_name"
                    name="full_name"
                    placeholder="Enter full name"
                    value={formik.values.full_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      !!formik.errors.full_name && formik.touched.full_name
                    }
                  />
                  {formik.touched.full_name && formik.errors.full_name ? (
                    <FormFeedback>{formik.errors.full_name}</FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="email" className="form-label">
                    Email
                  </Label>
                  {/* <span className="text-danger">*</span> */}
                  <Input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={!!formik.errors.email && formik.touched.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <FormFeedback>{formik.errors.email}</FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="relationship" className="form-label">
                    Relationship
                  </Label>
                  <span className="text-danger">*</span>
                  <Input
                    type="text"
                    id="relationship"
                    name="relationship"
                    placeholder="Enter relationship"
                    value={formik.values.relationship}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      !!formik.errors.relationship &&
                      formik.touched.relationship
                    }
                  />
                  {formik.touched.relationship && formik.errors.relationship ? (
                    <FormFeedback>{formik.errors.relationship}</FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="mobile_phone" className="form-label">
                    Mobile Phone
                  </Label>
                  {/* <span className="text-danger">*</span> */}
                  <div>
                    <Dropdown
                      className="input-group"
                      isOpen={dropdownOpen}
                      toggle={toggleDropdown}
                    >
                      <DropdownToggle
                        as="button"
                        className="btn btn-light border arrow-none"
                      >
                        <img
                          src={seletedCountry.flagImg}
                          alt="country flag"
                          className="options-flagimg"
                          height="20"
                        />
                        <span className="countrylist-codeno text-muted">
                          {seletedCountry.countryCode}
                        </span>
                      </DropdownToggle>
                      <Input
                        name="mobile_phone"
                        type="text"
                        placeholder="Enter number"
                        className="form-control rounded-end flag-input"
                        id="mobile_phone"
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.mobile_phone || ""}
                        invalid={
                          formik.touched.mobile_phone &&
                          formik.errors.mobile_phone
                            ? true
                            : false
                        }
                      />
                      {formik.touched.mobile_phone &&
                      formik.errors.mobile_phone ? (
                        <FormFeedback type="invalid">
                          {typeof formik.errors.mobile_phone === "string"
                            ? formik.errors.mobile_phone
                            : null}
                        </FormFeedback>
                      ) : null}
                      <DropdownMenu
                        as="ul"
                        className="list-unstyled w-100 dropdown-menu-list mb-0"
                      >
                        <SimpleBar
                          style={{ maxHeight: "220px" }}
                          className="px-3"
                        >
                          {(country || []).map((item, key) => (
                            <DropdownItem
                              as="li"
                              onClick={() => setseletedCountry(item)}
                              key={key}
                              className="dropdown-item d-flex"
                            >
                              <div className="flex-shrink-0 me-2">
                                <img
                                  src={item.flagImg}
                                  alt="country flag"
                                  className="options-flagimg"
                                  height="20"
                                />
                              </div>
                              <div className="flex-grow-1">
                                <div className="d-flex">
                                  <div className="country-name me-1">
                                    {item.countryName}
                                  </div>
                                  <span className="countrylist-codeno text-muted">
                                    {item.countryCode}
                                  </span>
                                </div>
                              </div>
                            </DropdownItem>
                          ))}
                        </SimpleBar>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  {/* <Input
                    type="text"
                    id="mobile_phone"
                    name="mobile_phone"
                    placeholder="Enter mobile phone number"
                    value={formik.values.mobile_phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      !!formik.errors.mobile_phone &&
                      formik.touched.mobile_phone
                    }
                  />
                  {formik.touched.mobile_phone && formik.errors.mobile_phone ? (
                    <FormFeedback>{formik.errors.mobile_phone}</FormFeedback>
                  ) : null} */}
                </div>
              </Col>
              {/* <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="home_phone" className="form-label">
                    Home Phone
                  </Label>
                  <Input
                    type="text"
                    id="home_phone"
                    name="home_phone"
                    placeholder="Enter home phone number"
                    value={formik.values.home_phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      !!formik.errors.home_phone && formik.touched.home_phone
                    }
                  />
                  {formik.touched.home_phone && formik.errors.home_phone ? (
                    <FormFeedback>{formik.errors.home_phone}</FormFeedback>
                  ) : null}
                </div>
              </Col> */}
              {/* <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="work_phone" className="form-label">
                    Work Phone
                  </Label>
                  <Input
                    type="text"
                    id="work_phone"
                    name="work_phone"
                    placeholder="Enter work phone number"
                    value={formik.values.work_phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      !!formik.errors.work_phone && formik.touched.work_phone
                    }
                  />
                  {formik.touched.work_phone && formik.errors.work_phone ? (
                    <FormFeedback>{formik.errors.work_phone}</FormFeedback>
                  ) : null}
                </div>
              </Col> */}
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="address1" className="form-label">
                    Address Line 1
                  </Label>
                  <span className="text-danger">*</span>
                  <Input
                    type="text"
                    id="address1"
                    name="address1"
                    placeholder="Enter address line 1"
                    value={formik.values.address1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      !!formik.errors.address1 && formik.touched.address1
                    }
                  />
                  {formik.touched.address1 && formik.errors.address1 ? (
                    <FormFeedback>{formik.errors.address1}</FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="address2" className="form-label">
                    Address Line 2
                  </Label>
                  <Input
                    type="text"
                    id="address2"
                    name="address2"
                    placeholder="Enter address line 2"
                    value={formik.values.address2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      !!formik.errors.address2 && formik.touched.address2
                    }
                  />
                  {formik.touched.address2 && formik.errors.address2 ? (
                    <FormFeedback>{formik.errors.address2}</FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="city" className="form-label">
                    City
                  </Label>
                  <span className="text-danger">*</span>
                  <Input
                    type="text"
                    id="city"
                    name="city"
                    placeholder="Enter city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={!!formik.errors.city && formik.touched.city}
                  />
                  {formik.touched.city && formik.errors.city ? (
                    <FormFeedback>{formik.errors.city}</FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="state" className="form-label">
                    State
                  </Label>
                  <span className="text-danger">*</span>
                  <Input
                    type="text"
                    id="state"
                    name="state"
                    placeholder="Enter state"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={!!formik.errors.state && formik.touched.state}
                  />
                  {formik.touched.state && formik.errors.state ? (
                    <FormFeedback>{formik.errors.state}</FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="zip_code" className="form-label">
                    Zip Code
                  </Label>
                  <span className="text-danger">*</span>
                  <Input
                    type="text"
                    id="zip_code"
                    name="zip_code"
                    placeholder="Enter zip code"
                    value={formik.values.zip_code}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      !!formik.errors.zip_code && formik.touched.zip_code
                    }
                  />
                  {formik.touched.zip_code && formik.errors.zip_code ? (
                    <FormFeedback>{formik.errors.zip_code}</FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="country" className="form-label">
                    Country
                  </Label>
                  <span className="text-danger">*</span>
                  <Select
                    id="country"
                    className={`${formik.touched.country && formik.errors.country ? "is-invalid" : ""}`}
                    name="country"
                    placeholder="Select country"
                    options={countryOptions}
                    value={countryOptions.find(
                      (option: any) =>
                        option.value === formik.values.country?.toString(),
                    )}
                    onChange={(selectedOption: any) => {
                      formik.setFieldValue("country", selectedOption?.value);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.country && formik.errors.country ? (
                    <FormFeedback className="d-block">
                      {formik.errors.country}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-4">
              <Button
                color="primary"
                type="submit"
                className="me-2"
                disabled={patchEmergencyContactInfoLoading}
              >
                {patchEmergencyContactInfoLoading ? (
                  <>
                    <Spinner size="sm" />
                    &nbsp;Updating...
                  </>
                ) : (
                  "Update Emergency Contact"
                )}
              </Button>
              <Button
                type="button"
                color="light"
                disabled={patchEmergencyContactInfoLoading}
                onClick={() => {
                  setCardView({ summary: true });
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </CardBody>
      </LoadingOverlay>
    </Card>
  );
};
