// tokenUtils.js
export const getToken = () => {
  const authUser = JSON.parse(localStorage.getItem("authUser") || "{}");
  return authUser?.access || null;
};

export const getUserRole = () => {
  const userRole = JSON.parse(localStorage.getItem("selectedRole") || "{}");
  return userRole?.role_id || null;
};

export const setAuthUser = (newTokenData: string) => {
  localStorage.setItem("authUser", JSON.stringify(newTokenData));
};

export const isTokenNearExpiry = (token: string): boolean => {
  if (!token) return false;

  try {
    // Decode and handle URL-safe Base64
    const base64Payload = token
      .split(".")[1]
      .replace(/-/g, "+")
      .replace(/_/g, "/");
    const payload = JSON.parse(atob(base64Payload));

    // Ensure the payload has an 'exp' field
    if (!payload.exp) {
      console.warn("Token payload missing 'exp' field.");
      return false;
    }

    const currentTime = Math.floor(Date.now() / 1000);
    const timeUntilExpiry = payload.exp - currentTime;

    console.log("Time left until expiry (seconds):", timeUntilExpiry);
    console.log("Token expiry timestamp:", payload.exp);

    // If the token is about to expire within the next 5 minutes, trigger a refresh
    if (timeUntilExpiry <= 5 * 60) {
      console.log("Token is near expiry, refreshing...");
      // await refreshToken(); // Refetch token
      return true; // Token is near expiry
    }

    return false; // Token is still valid
  } catch (error) {
    console.error("Failed to decode or parse token:", error);
    return false;
  }
};

export const refreshAuthToken = async (refreshToken: string) => {
  const response = await fetch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}auth/token/refresh/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh: refreshToken }),
    },
  );

  if (!response.ok) {
    throw new Error("Failed to refresh token");
  }
  return response.json();
};
