import React, { useState } from 'react';
import Flatpickr from "react-flatpickr";
import { PROJECT_STATUS } from 'utils/CommonUtils';
import { useGetAllProjectsQuery } from 'slices/projects/projectSlice';

export const ProjectListGlobalFilter = ({setPageState, globalValue}: any) => {

    const { data: Projects } = useGetAllProjectsQuery(undefined);
    
    const statusOnChange = (value: string) => {
        setPageState((prev: any)=>({
            ...prev,
            status: value,
            page: 1
        }))
    }

    // const projectsOnChange = (value: string) => {
    //     setPageState((prev: any)=>({
    //         ...prev,
    //         projects : value,
    //         page: 1
    //     }))
    // }


    return (
        <React.Fragment>
            {/* <div className="col-xxl-3 col-sm-4">
                <Flatpickr
                    placeholder="Select start & due date range"
                    className="form-control bg-light border-light"
                    options={{
                        mode: "range",
                        dateFormat: "d-m-Y",
                    }}
                    value={dateRange}
                    onChange={handleDateChange}
                    onClose={handleBlur}
                />
            </div> */}

            <div className="col-xxl-3 col-sm-4">
                <div className="input-light">
                    <select onChange={(e) => statusOnChange(e.target.value)} className="form-control" data-choices data-choices-search-false name="status" id="idStatus">
                    <option value="" >Status</option>
                    {PROJECT_STATUS?.map((options: any) => (
                        <option key={options.id} value={options.id}>
                        {options.status_name}
                        </option>
                    ))}
                    </select>
                </div>
            </div>
            {/* <div className="col-xxl-3 col-sm-4">
                <div className="input-light">
                    <select onChange={(e) => projectsOnChange(e.target.value)} className="form-control" data-choices data-choices-search-false name="status" id="idStatus">
                    <option value="" >Projects</option>
                    {Projects?.map((options: any) => (
                        <option key={options.id} value={options.id}>
                        {options.project_name}
                        </option>
                    ))}
                    </select>
                </div>
            </div> */}
        </React.Fragment>
    );
};
