import React, { useState, useRef } from "react";
// @ts-ignore
import SignatureCanvas from "react-signature-canvas";
import {
  Card,
  CardBody,
  Col,
  Table,
  Row,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import {
  usePostDigitalSignatureMutation,
  useGetDigitalSignatureByEmployeeUuidQuery,
  useDeleteSignatureByIdMutation,
} from "slices/employee/employeeSlice";

import LoadingOverlay from "react-loading-overlay-ts";
import { IsValidPermissions } from "../../../Routes/AuthProtected";

interface EmployeeDocumentProps {
  employee_uuid: string | undefined;
}

const SignatureForm: React.FC<EmployeeDocumentProps> = ({ employee_uuid }) => {
  const sigCanvas = useRef<SignatureCanvas>(null);

  const { data: signatureData, isLoading } =
    useGetDigitalSignatureByEmployeeUuidQuery(employee_uuid!, {
      skip: !employee_uuid,
    });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSignatureId, setSelectedSignatureId] = useState<string | null>(
    null,
  );
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [postSignature, { isLoading: postSignatureLoading }] =
    usePostDigitalSignatureMutation();
  const [deleteSignature, { isLoading: deleteLoading }] =
    useDeleteSignatureByIdMutation();

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleAddDocument = () => {
    setSelectedSignatureId(null);
    handleModalToggle();
  };

  const handleDeleteConfirmation = (id: string) => {
    setSelectedSignatureId(id);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedSignatureId) {
      try {
        await deleteSignature(selectedSignatureId).unwrap();
        setDeleteModalOpen(false);
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleClear = () => {
    sigCanvas.current?.clear();
  };

  const handleSave = async () => {
    if (sigCanvas.current) {
      const dataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      try {
        await postSignature({
          employee_employee_uuid: employee_uuid,
          signature: dataURL,
          current_active: true,
        });
        handleModalToggle();
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <Card>
        <CardBody>
          <div className="d-flex align-items-center mb-4">
            <h5 className="card-title flex-grow-1 mb-0">Digital Signature</h5>
            {IsValidPermissions(["EDIT_MEMBERS"]) && (
              <Button
                color="danger"
                onClick={handleAddDocument}
                disabled={signatureData?.length != 0}
              >
                <i className="ri-upload-2-fill me-1 align-bottom"></i> Add
                Signature
              </Button>
            )}
          </div>
          <Row>
            <Col lg={12}>
              {signatureData && signatureData.length > 0 ? (
                <div className="table-responsive">
                  <Table className="table-borderless align-middle mb-0">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">Signature</th>
                        <th scope="col">Active</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(signatureData || []).map((item: any) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <img
                                src={item?.signature}
                                alt="signature"
                                width={300}
                              />
                            </td>
                            <td>
                              {item?.current_active ? (
                                <span className="badge text-uppercase bg-success-subtle text-success">
                                  {" "}
                                  Yes{" "}
                                </span>
                              ) : (
                                <span className="badge text-uppercase bg-danger-subtle text-danger">
                                  {" "}
                                  No{" "}
                                </span>
                              )}
                            </td>
                            <td>{item?.created_at_text}</td>
                            <td>
                              <UncontrolledDropdown direction="start">
                                <DropdownToggle
                                  tag="a"
                                  className="btn btn-light btn-icon"
                                  id="dropdownMenuLink15"
                                  role="button"
                                >
                                  <i className="ri-equalizer-fill"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                  {/* <DropdownItem
                                                                        onClick={() =>
                                                                            handleFileDownload(item.attachment_url)
                                                                        }
                                                                    >
                                                                        <i className="ri-download-2-fill me-2 align-middle text-muted" />
                                                                        Download
                                                                    </DropdownItem>
                                                                    <DropdownItem divider /> */}
                                  <DropdownItem
                                    onClick={() =>
                                      handleDeleteConfirmation(item.id)
                                    }
                                  >
                                    <i className="ri-delete-bin-5-line me-2 align-middle text-muted" />
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  No documents available.
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Modal isOpen={isModalOpen} toggle={handleModalToggle} centered size="lg">
        <ModalHeader toggle={handleModalToggle}>{"Add Signature"}</ModalHeader>
        <ModalBody>
          <LoadingOverlay
            active={postSignatureLoading}
            spinner
            text="Loading..."
          >
            {/* Ensure form has onSubmit handler */}
            <form>
              <div style={{ border: "1px solid" }} className="mb-4">
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor="black"
                  canvasProps={{
                    width: 750,
                    height: 200,
                    className: "sigCanvas",
                  }}
                />
              </div>
              {/* Add a submit button within the form */}
              <Button
                color="primary"
                type="submit"
                disabled={postSignatureLoading}
                onClick={handleSave}
              >
                {postSignatureLoading ? <Spinner size="sm" /> : "Submit"}
              </Button>
              &nbsp;&nbsp;
              <Button color="secondary" onClick={handleModalToggle}>
                Cancel
              </Button>
              &nbsp;&nbsp;
              <Button color="danger" onClick={handleClear}>
                Clear
              </Button>
            </form>
          </LoadingOverlay>
        </ModalBody>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={deleteModalOpen}
        toggle={() => setDeleteModalOpen(false)}
        centered
      >
        <ModalHeader toggle={() => setDeleteModalOpen(false)}>
          Confirm Delete
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete this Signature? This action cannot be
          undone.
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setDeleteModalOpen(false)}>
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={handleConfirmDelete}
            disabled={deleteLoading}
          >
            {deleteLoading ? <Spinner size="sm" /> : "Delete"}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SignatureForm;
