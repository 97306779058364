import { userForgetPasswordSuccess, userForgetPasswordError } from "./reducer";

//Include Both Helper File with needed methods
// import { getFirebaseBackend } from "../../../helpers/firebase_helper";

import {
  postForgetPwd,
  postJwtForgetPwd,
} from "../../../helpers/fakebackend_helper";

// const fireBaseBackend : any = getFirebaseBackend();

export const userForgetPassword =
  (user: any, history: any) => async (dispatch: any) => {
    try {
      let response;
      if (window.__RUNTIME_CONFIG__.REACT_APP_DEFAULTAUTH === "firebase") {
        //   response = fireBaseBackend.forgetPassword(
        //       user.email
        //   )
        console.log("no firebase");
      } else if (window.__RUNTIME_CONFIG__.REACT_APP_DEFAULTAUTH === "jwt") {
        response = postJwtForgetPwd(user.email);
      } else {
        response = postForgetPwd(user.email);
      }

      const data = await response;

      if (data) {
        dispatch(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first",
          ),
        );
      }
    } catch (forgetError) {
      dispatch(userForgetPasswordError(forgetError));
    }
  };
