import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  Spinner,
} from "reactstrap";
import {
  useGetWorkLocationQuery,
  useGetWorkLocationByIdQuery,
  useCreateWorkLocationMutation,
  useUpdateWorkLocationMutation,
} from "slices/pageAccess/PageAccessSlice";

interface WorkLocationData {
  id?: number;
  location_name: string;
  location_code: string;
  description: string;
}

const WorkLocationTab: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(
    null,
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [workLocation, setWorkLocation] = useState<WorkLocationData>({
    location_name: "",
    location_code: "",
    description: "",
  });

  const {
    data: workLocationData,
    refetch,
    isLoading,
  } = useGetWorkLocationQuery(undefined);
  const [createWorkLocation] = useCreateWorkLocationMutation();
  const [updateWorkLocation] = useUpdateWorkLocationMutation();
  const { data: workLocationDetails } = useGetWorkLocationByIdQuery(
    selectedLocationId,
    {
      skip: !selectedLocationId, // Skip fetching if no ID is provided
    },
  );

  useEffect(() => {
    if (workLocationDetails) {
      setWorkLocation(workLocationDetails);
    }
  }, [workLocationDetails]);

  const toggleModal = () => setModalOpen(!modalOpen);

  const handleAddLocation = () => {
    setSelectedLocationId(null);
    setWorkLocation({ location_name: "", location_code: "", description: "" });
    toggleModal();
  };

  const handleEditLocation = (locationId: number) => {
    setSelectedLocationId(locationId);
    toggleModal();
  };

  const handleSaveLocation = async () => {
    setLoading(true);
    try {
      if (workLocation.id) {
        const id = workLocation.id;
        const updateData = workLocation;
        await updateWorkLocation({ id, updateData }).unwrap();
      } else {
        await createWorkLocation({ newLocation: workLocation }).unwrap();
      }
      refetch();
      toggleModal();
    } catch (error) {
      console.error("Error saving location:", error);
    } finally {
      setLoading(false);
    }
  };

  const filteredLocations =
    workLocationData?.filter((l: WorkLocationData) =>
      l.location_name?.toLowerCase()?.includes(searchTerm.toLowerCase()),
    ) || [];

  return (
    <Card>
      <CardBody>
        <div className="d-flex align-items-center mb-4">
          <h5 className="card-title flex-grow-1 mb-0">Work Locations</h5>
          <Input
            type="text"
            placeholder="Search location..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="me-2"
            style={{ width: "300px" }}
          />
          <Button color="primary" onClick={handleAddLocation}>
            Add Location
          </Button>
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <Table striped>
            <thead>
              <tr>
                <th>#</th>
                <th>Location Name</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredLocations.length > 0 ? (
                filteredLocations.map((l: WorkLocationData, index: number) => (
                  <tr key={l.id}>
                    <td>{index + 1}</td>
                    <td>{l.location_name}</td>
                    <td>{l.description}</td>
                    <td>
                      <Button
                        color="warning"
                        size="sm"
                        onClick={() => handleEditLocation(l.id!)}
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className="text-center">
                    No Locations Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
        <WorkLocationModal
          isOpen={modalOpen}
          toggle={toggleModal}
          onSave={handleSaveLocation}
          workLocation={workLocation}
          setWorkLocation={setWorkLocation}
          loading={loading}
        />
      </CardBody>
    </Card>
  );
};

interface WorkLocationModalProps {
  isOpen: boolean;
  toggle: () => void;
  onSave: () => void;
  workLocation: WorkLocationData;
  setWorkLocation: React.Dispatch<React.SetStateAction<WorkLocationData>>;
  loading: boolean;
}

const WorkLocationModal: React.FC<WorkLocationModalProps> = ({
  isOpen,
  toggle,
  onSave,
  workLocation,
  setWorkLocation,
  loading,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWorkLocation({ ...workLocation, [e.target.name]: e.target.value });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {workLocation.id ? "Update" : "Create"} Work Location
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <FormGroup>
              <Label for="location_name">
                Location Name <span className="text-danger">*</span>
              </Label>
              <Input
                id="location_name"
                name="location_name"
                value={workLocation.location_name}
                onChange={handleInputChange}
                disabled={loading}
              />
            </FormGroup>
            <FormGroup>
              <Label for="description">Description</Label>
              <Input
                id="description"
                name="description"
                type="textarea"
                value={workLocation.description}
                onChange={handleInputChange}
                disabled={loading}
              />
            </FormGroup>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={onSave}
          disabled={loading || !workLocation.location_name}
        >
          {loading ? <Spinner size="sm" /> : "Save"}
        </Button>
        <Button color="secondary" onClick={toggle} disabled={loading}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WorkLocationTab;
