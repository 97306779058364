import React, { useState } from "react";
import { Button, Card, CardBody } from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetIncidentReportByNumberQuery,
  useUpdateIncidentReportMutation,
} from "slices/incidentReport/incidentReportSlice";
import {
  useGetNearMissIncidentReportByNumberQuery,
  useUpdateNearMissIncidentReportMutation,
} from "slices/incidentReport/incidentReportSlice";
import { formatDateTime } from "helpers/date_utils";
import Step6PreviewSubmit from "./PreviewReport";

interface Step3PreviewSubmitIndexProps {
  location: { latitude: number | null; longitude: number | null };
  previousStep: () => void;
}

const Step3PreviewSubmitIndex: React.FC<Step3PreviewSubmitIndexProps> = ({
  location,
  previousStep,
}) => {
  const [submitted, setSubmitted] = useState(false);

  const { incidentId: incidentIdFromURL } = useParams<{ incidentId: string }>();
  const navigate = useNavigate();

  // Queries to fetch homework permit, employees, projects, and companies data
  const {
    data: permitData,
    isLoading: isPermitLoading,
    refetch: refetchPermit,
  } = useGetNearMissIncidentReportByNumberQuery(incidentIdFromURL);

  const [updateNearmissReport, { isLoading: isSubmitting }] =
    useUpdateNearMissIncidentReportMutation();

  const handleSubmit = async () => {
    if (location.latitude === null || location.longitude === null) {
      alert("Unable to fetch your location. Please try again or Reload Page.");
      return;
    }

    let payload = {
      status: 2,
      ...location,
    };

    try {
      await updateNearmissReport({
        incident_no: incidentIdFromURL,
        updatedReportData: payload,
      }).unwrap();
      await refetchPermit();
      setSubmitted(true);
    } catch (error) {
      console.error("Failed to submit", error);
    }
  };

  if (submitted) {
    return (
      <div>
        <Card className="text-center">
          <CardBody>
            <h3>Your Accident Report has been Successfully Submitted!</h3>
            <p>Near Miss Report No: {incidentIdFromURL}</p>
            <Button
              color="primary"
              onClick={() =>
                navigate(`/nearmiss-report-detail-view/${incidentIdFromURL}/`)
              }
            >
              View Report
            </Button>
          </CardBody>
        </Card>
      </div>
    );
  }

  const permitDetail = () => {
    return (
      <div>
        <h5>Near Miss Report</h5>
        <hr />
        <table className="table table-borderless table-nowrap ">
          <tbody>
            <tr>
              <td className="fw-medium" style={{ width: "300px" }}>
                Near Miss Report No :
              </td>
              <td>{permitData?.incident_no}</td>
            </tr>
            {/* <tr>
                    <td className="fw-medium" style={{ width: "300px" }}>
                      Work Performed By :
                    </td>
                    <td>{permitData?.work_performed_by}</td>
                  </tr> */}
            <tr>
              <td className="fw-medium" style={{ width: "300px" }}>
                Reported By:
              </td>
              <td>{permitData?.reported_by_name}</td>
            </tr>
            <tr>
              <td className="fw-medium" style={{ width: "300px" }}>
                Project :
              </td>
              <td>{permitData?.project_name}</td>
            </tr>

            <tr>
              <td className="fw-medium" style={{ width: "300px" }}>
                Contact Number :
              </td>
              <td>{permitData?.contact_number}</td>
            </tr>
            <tr>
              <td className="fw-medium" style={{ width: "300px" }}>
                Incident Date :
              </td>
              <td>{formatDateTime(permitData?.incident_date, "datetime")}</td>
            </tr>
            <tr>
              <td className="fw-medium" style={{ width: "300px" }}>
                Reported Date :
              </td>
              <td>{formatDateTime(permitData?.date_reported, "datetime")}</td>
            </tr>
            <tr>
              <td className="fw-medium" style={{ width: "300px" }}>
                Description :
              </td>
              <td>{permitData?.description}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <Step6PreviewSubmit
      previousStep={previousStep}
      location={location}
      permitData={permitData}
      handleSubmit={handleSubmit}
      PermitDetail={permitDetail}
      isPermitLoading={isPermitLoading}
      isSubmitting={isSubmitting}
    />
  );
};

export default Step3PreviewSubmitIndex;
