import { createApi } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "helpers/jwt-token-access/customBaseQuery";

const convertToFormData = (
  obj: Record<string, any> | undefined | null,
  parentKey: string = "",
): FormData => {
  const formData = new FormData();

  const appendData = (key: string, value: any) => {
    if (value instanceof File) {
      formData.append(key, value);
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        if (item instanceof File) {
          formData.append(`${key}[${index}]`, item);
        } else {
          formData.append(`${key}[${index}]`, item);
        }
      });
    } else if (value && typeof value === "object") {
      if (value instanceof Date) {
        formData.append(key, value.toISOString());
      } else {
        Object.keys(value).forEach((subKey) => {
          appendData(`${key}[${subKey}]`, value[subKey]);
        });
      }
    } else {
      formData.append(key, value);
    }
  };

  if (obj) {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      appendData(parentKey ? `${parentKey}[${key}]` : key, value);
    });
  }

  return formData;
};

export const incidentReportSlice = createApi({
  reducerPath: "incidentReport",
  baseQuery: customBaseQuery,
  tagTypes: [
    "GetIncidentReport",
    "GetIncidentReportByNumber",
    "GetNearMissIncidentReport",
    "GetNearMissIncidentReportByNumber",
  ],
  endpoints: (builder) => ({
    // --- Incident Accident Endpoints ---
    getIncidentReport: builder.query({
      query: (page) =>
        `/incident/api/v1/api/accident-incident-reports/?page=${page.page}&page_size=${page.pageSize}`,
      providesTags: ["GetIncidentReport"],
    }),
    getIncidentReportByNumber: builder.query({
      query: (incident_no) =>
        `/incident/api/v1/api/accident-incident-reports/${incident_no}/`,
      providesTags: (result, error, incident_no) => [
        { type: "GetIncidentReportByNumber", id: incident_no },
      ],
    }),
    createIncidentReport: builder.mutation({
      query: (newReportData) => ({
        url: "/incident/api/v1/api/accident-incident-reports/",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: newReportData,
      }),
      invalidatesTags: ["GetIncidentReport"],
    }),
    updateIncidentReport: builder.mutation({
      query: ({ incident_no, updatedReportData }) => ({
        url: `/incident/api/v1/api/accident-incident-reports/${incident_no}/`,
        method: "PATCH",
        body: updatedReportData,
      }),
      invalidatesTags: (result, error, { incident_no }) => [
        { type: "GetIncidentReportByNumber", id: incident_no },
        { type: "GetIncidentReport" },
      ],
    }),
    uploadIncidentDocument: builder.mutation({
      query: ({ formData }) => {
        const userData = convertToFormData(formData);
        return {
          url: `/incident/api/v1/api/accident-incident-documents/`,
          method: "POST",
          body: userData,
        };
      },
    }),
    uploadIncidentDocumentById: builder.mutation({
      query: ({ id, formData }) => ({
        url: `/incident/api/v1/api/accident-incident-documents/${id}/`,
        method: "PATCH",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    }),
    deleteIncidentDocument: builder.mutation({
      query: ({ id }) => ({
        url: `/incident/api/v1/api/accident-incident-documents/${id}/`,
        method: "DELETE",
      }),
    }),
    getIncidentReportStatus: builder.query({
      query: () => "/incident/api/v1/api/incident-status/",
    }),
    getIncidentReportStatusById: builder.query({
      query: (id) => `/incident/api/v1/api/incident-status/${id}/`,
    }),
    createIncidentReportStatus: builder.mutation({
      query: (newStatusData) => ({
        url: "/incident/api/v1/api/incident-status/",
        method: "POST",
        body: newStatusData,
      }),
    }),
    updateIncidentReportStatusById: builder.mutation({
      query: ({ id, newStatusData }) => ({
        url: `/incident/api/v1/api/incident-status/${id}/`,
        method: "PATCH",
        body: newStatusData,
      }),
    }),

    getIncidentReportDocuments: builder.query({
      query: () => `/incident/api/v1/api/accident-incident-documents/`,
      providesTags: ["GetIncidentReport"],
    }),
    getIncidentReportDocumentsByNumber: builder.query({
      query: (id) => `/incident/api/v1/api/accident-incident-documents/${id}/`,
      providesTags: (result, error, id) => [
        { type: "GetIncidentReportByNumber", id: id },
      ],
    }),

    //Near Miss Incident Report

    getNearMissIncidentReport: builder.query({
      query: (page) =>
        `/incident/api/v1/api/near-miss-incident-reports/?page=${page.page}&page_size=${page.pageSize}`,
      providesTags: ["GetNearMissIncidentReport"],
    }),
    getNearMissIncidentReportByNumber: builder.query({
      query: (incident_no) =>
        `/incident/api/v1/api/near-miss-incident-reports/${incident_no}/`,
      providesTags: (result, error, incident_no) => [
        { type: "GetNearMissIncidentReportByNumber", id: incident_no },
      ],
    }),
    createNearMissIncidentReport: builder.mutation({
      query: (newReportData) => ({
        url: "/incident/api/v1/api/near-miss-incident-reports/",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: newReportData,
      }),
      invalidatesTags: ["GetNearMissIncidentReport"],
    }),
    updateNearMissIncidentReport: builder.mutation({
      query: ({ incident_no, updatedReportData }) => ({
        url: `/incident/api/v1/api/near-miss-incident-reports/${incident_no}/`,
        method: "PATCH",
        body: updatedReportData,
      }),
      invalidatesTags: (result, error, { incident_no }) => [
        { type: "GetNearMissIncidentReportByNumber", id: incident_no },
        { type: "GetNearMissIncidentReport" },
      ],
    }),
    uploadNearMissIncidentDocument: builder.mutation({
      query: ({ formData }) => {
        const userData = convertToFormData(formData);
        return {
          url: `/incident/api/v1/api/near-miss-incident-documents/`,
          method: "POST",
          body: userData,
        };
      },
    }),
    deleteNearMissIncidentDocument: builder.mutation({
      query: ({ id }) => ({
        url: `/incident/api/v1/api/near-miss-incident-documents/${id}/`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  //Incident Report
  useGetIncidentReportQuery,
  useGetIncidentReportByNumberQuery,
  useCreateIncidentReportMutation,
  useUpdateIncidentReportMutation,
  useUploadIncidentDocumentMutation,
  useUploadIncidentDocumentByIdMutation,
  useDeleteIncidentDocumentMutation,
  useGetIncidentReportStatusQuery,
  useGetIncidentReportStatusByIdQuery,
  useCreateIncidentReportStatusMutation,
  useUpdateIncidentReportStatusByIdMutation,
  useGetIncidentReportDocumentsQuery,
  useGetIncidentReportDocumentsByNumberQuery,

  //Near Miss Incident Report
  useGetNearMissIncidentReportQuery,
  useGetNearMissIncidentReportByNumberQuery,
  useCreateNearMissIncidentReportMutation,
  useUpdateNearMissIncidentReportMutation,
  useUploadNearMissIncidentDocumentMutation,
  useDeleteNearMissIncidentDocumentMutation,
} = incidentReportSlice;
