import React, { useState } from "react";
import { Card, CardBody, Row, Table } from "reactstrap";
import { Link } from "react-router-dom";
import EmployeeProjectModal from "./EmployeeProjectModal";

interface EmployeeProjectsListProps {
  employee_uuid: string | undefined;
  emoloyeeData: any;
}

const EmployeeProjectsList: React.FC<EmployeeProjectsListProps> = ({
  employee_uuid,
  emoloyeeData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Card>
        <CardBody>
          <div className="d-flex align-items-center mb-4">
            <h5 className="card-title flex-grow-1 mb-0">Assigned Projects</h5>
            {/* <Button color="danger" onClick={handleModalToggle}>
                        <i className="ri-user-add-line me-1 align-bottom"></i> Assign
                        Project
                    </Button> */}
          </div>
          <Row>
            {/* Check if project_list has data */}
            {emoloyeeData?.project_list &&
            emoloyeeData?.project_list.length > 0 ? (
              <div className="table-responsive">
                <Table className="table-borderless align-middle mb-0">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">Project</th>
                      <th scope="col">Teams</th>
                      <th scope="col">Assigned Date</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {emoloyeeData.project_list.map(
                      (project: any, index: number) => (
                        <tr key={index}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="avatar-xs flex-shrink-0 me-3">
                                {project.project_url ? (
                                  <img
                                    src={project?.project_url}
                                    alt=""
                                    className="rounded-circle avatar-sm"
                                    style={{
                                      width: "2rem",
                                      height: "2rem",
                                    }}
                                  />
                                ) : (
                                  <>{project?.project_name.charAt(0)}</>
                                )}
                              </div>
                              <div className="flex-grow-1 ms-2 name">
                                <Link to={`/profile/`}>
                                  {project.project_name}
                                </Link>
                              </div>
                            </div>
                          </td>
                          <td>
                            {project.teams?.map((n: any) => {
                              return (
                                <p className={`badge bg-primary me-1`}>
                                  {n.team_name}
                                </p>
                              );
                            })}
                          </td>
                          <td> {project.assigned_date}</td>
                          <td>
                            <p
                              className={`badge bg-${
                                project.current_active ? "success" : "danger"
                              }`}
                            >
                              {project.current_active ? "Active" : "Inactive"}
                            </p>
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </Table>
              </div>
            ) : (
              // Fallback content if no projects are found
              <p className="text-muted">No projects assigned</p>
            )}
          </Row>
        </CardBody>
      </Card>
      <EmployeeProjectModal
        isModalOpen={isModalOpen}
        handleModalToggle={handleModalToggle}
        employeeData={emoloyeeData}
        employee_uuid={employee_uuid}
      />
    </>
  );
};

export default EmployeeProjectsList;
