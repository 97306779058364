import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useGetCategoryByIdQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useGetCategoriesQuery,
} from "slices/resourceInventory/inventorySlice";

interface CategoryEditProps {
  categoryId: string | undefined;
  setCardView: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
}

export const CategoryEdit: React.FC<CategoryEditProps> = ({
  categoryId,
  setCardView,
}) => {
  const {
    data: categoryData,
    isLoading: isLoadingCategory,
    refetch,
  } = useGetCategoryByIdQuery(categoryId, {
    skip: !categoryId,
  });

  const { refetch: refetchCategory } = useGetCategoriesQuery(undefined);

  const [createCategory, { isLoading: createCategoryLoading }] =
    useCreateCategoryMutation();
  const [updateCategory, { isLoading: updateCategoryLoading }] =
    useUpdateCategoryMutation();

  // Validation schema for the category fields
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    description: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (categoryId) {
        // Update existing category
        updateCategory({ id: categoryId, data: values })
          .unwrap()
          .then(() => {
            refetchCategory();
            setCardView({ categoryInfo: true });
            refetch();
          })
          .catch((error) => {
            console.error("Error updating category:", error);
          });
      } else {
        // Create new category
        createCategory(values)
          .unwrap()
          .then(() => {
            refetchCategory();
            setCardView({ categoryInfo: true });
            refetch();
          })
          .catch((error) => {
            console.error("Error creating category:", error);
          });
      }
    },
  });

  useEffect(() => {
    if (categoryData) {
      formik.setValues({
        name: categoryData.name || "",
        description: categoryData.description || "",
      });
    }
  }, [categoryData]);

  return (
    <Card>
      <CardHeader className="d-flex">
        <h4 className="card-title flex-grow-1 mb-0">Category Edit</h4>
      </CardHeader>
      <CardBody>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="name" className="form-label">
                  Name
                </Label>
                <span className="text-danger">*</span>
                <Input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Enter name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={!!formik.errors.name && formik.touched.name}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="text-danger">{formik.errors.name}</div>
                )}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="description" className="form-label">
                  Description
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="description"
                  name="description"
                  placeholder="Enter description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    !!formik.errors.description && formik.touched.description
                  }
                />
                {formik.touched.description && formik.errors.description && (
                  <div className="text-danger">{formik.errors.description}</div>
                )}
              </div>
            </Col>
          </Row>
          <Button
            type="submit"
            color="primary"
            disabled={
              isLoadingCategory ||
              createCategoryLoading ||
              updateCategoryLoading
            }
          >
            {updateCategoryLoading || createCategoryLoading
              ? "Saving..."
              : categoryId
                ? "Update Category"
                : "Create Category"}
          </Button>
          &nbsp;&nbsp;
          <button
            type="button"
            className="btn btn-danger"
            disabled={createCategoryLoading || updateCategoryLoading}
            onClick={() => setCardView({ categoryInfo: true })}
          >
            Close
          </button>
        </Form>
      </CardBody>
    </Card>
  );
};
