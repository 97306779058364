import axios from "axios";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Table,
  Spinner,
  Alert,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import IntiateProcuredModal from "./IntiateProcuredModal";
import { useGetProductsByIdsQuery } from "slices/resourceInventory/inventorySlice";

const ImportResourceFilterModal = ({
  show,
  onCloseClick,
  selectedSections,
  import_type,
  project_id,
  refetch,
}: any) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [responseData, setResponseData] = useState<any>(null);
  const [isValid, setIsValid] = useState<boolean | null>(null);

  // State for chain-select options
  const [selectedSectionId, setSelectedSectionId] = useState<number | null>(
    null,
  );
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [availableStatuses, setAvailableStatuses] = useState<string[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  // State for selected rows
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProductIds, setSelectedProductIds] = useState<number[]>([]);

  console.log("filteredData", filteredData);

  // Reset the modal state
  const resetModal = () => {
    setFile(null);
    setResponseData(null);
    setIsValid(null);
    setSelectedSectionId(null);
    setSelectedFilter("");
    setSelectedStatus("");
    setAvailableStatuses([]);
    setFilteredData([]);

    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset file input
    }
  };

  // Call resetModal when modal is closed
  const handleClose = () => {
    resetModal();
    onCloseClick();
  };

  // Update available statuses based on the selected section and filter
  const updateAvailableStatuses = () => {
    if (selectedSectionId && selectedFilter) {
      const selectedSection = selectedSections.find(
        (section: { id: number; product_list: any[] }) =>
          section.id === selectedSectionId,
      );

      if (selectedSection && selectedSection.product_list.length > 0) {
        const toInitiateLists = selectedSection.product_list.flatMap(
          (product: { toinitate_list: any[]; next_status: any[] }) =>
            product.toinitate_list.length > 0
              ? product.toinitate_list
              : product.next_status.map((statusObj) => ({
                  [`${selectedFilter}_status`]: statusObj.status,
                })), // Map the next status if no toinitate_list exists
        );

        // Map to the status, ensuring we handle unknown types safely
        const uniqueStatuses = [
          ...new Set<string>(
            toInitiateLists
              .map(
                (item: { [key: string]: string | undefined }) =>
                  item[`${selectedFilter}_status`],
              ) // Access the status dynamically
              .filter(
                (status: string): status is string =>
                  typeof status === "string" && status !== "",
              ), // Filter out undefined and empty strings
          ),
        ];

        setAvailableStatuses(uniqueStatuses);
      }
    }
  };

  const handleFilterData = () => {
    if (selectedSectionId && selectedFilter && selectedStatus) {
      const selectedSection = selectedSections.find(
        (section: any) => section.id === selectedSectionId,
      );

      if (selectedSection && selectedSection.product_list.length > 0) {
        const filtered = selectedSection.product_list.flatMap(
          (product: any) => {
            const filteredToInitiateList = product.toinitate_list.filter(
              (item: any) =>
                item[`${selectedFilter}_status`] === selectedStatus,
            );

            const filteredNextStatus = product.next_status
              .filter(
                (statusObj: any) =>
                  statusObj.action.includes("Procured") &&
                  statusObj.status === "Procured To Initiate",
              )
              .map((statusObj: any) => ({
                [`${selectedFilter}_status`]: statusObj.status,
                procured_next_action: statusObj.action, // Ensure action is mapped here
                quoted_quantity_sum: product.quoted_quantity_sum
                  ? product.quoted_quantity_sum
                  : product.quoted_amount, // Fallback to quoted_amount
                planned_quantity_sum: product.planned_quantity_sum
                  ? product.planned_quantity_sum
                  : product.planned_amount, // Fallback to planned_amount

                // Additional required fields
                id: product.product, // product ID
                product: product.product, // Reference product ID
                product_name: product.product_name, // Product name
                measurement_id: product.measurement_id, // Measurement unit ID
                measurement_unit: product.measurement_unit, // Measurement unit
                quoted_amount: product.quoted_amount, // Quoted amount
                planned_amount: product.planned_amount, // Planned amount
                procured_amount: product.procured_amount || 0, // Procured amount (default to 0 if not available)
                inventory_quantity: product.inventory_quantity || 0, // Inventory quantity (default to 0)
                procured_balance_quantity:
                  product.procured_balance_quantity || 0, // Procured balance quantity (default to 0)
              }));

            // Combine both filteredToInitiateList and filteredNextStatus
            return [...filteredToInitiateList, ...filteredNextStatus].filter(
              (item: any) =>
                item[`${selectedFilter}_status`] === selectedStatus,
            );
          },
        );

        setFilteredData(filtered);
      }
    }
  };

  // Define a mapping between action values and their display names
  const actionDisplayNames: Record<string, string> = {
    "procured.complete": "Procured to initiate",
    "procured.procured": "Procure",
    "delivered.complete": "Delivered to initiate",
    "delivered.delivered": "Delivered",
    "implemented.complete": "Implementation to initiate",
    "implemented.implemented": "Implemented",
  };

  const getNextAction = (): string | null => {
    if (filteredData.length === 0) return null;

    const firstRow = filteredData[0]; // Use the first row to determine the next action

    let nextAction: string | null = null;

    // Normalize both status and next action for comparison
    const procuredStatus = firstRow.procured_status?.toLowerCase().trim();
    const procuredNextAction = firstRow.procured_next_action
      ?.toLowerCase()
      .trim();

    switch (selectedFilter) {
      case "procured":
        if (
          procuredStatus === "procured to initiate" &&
          procuredNextAction === "procured.toinitiate"
        ) {
          nextAction = firstRow.procured_next_action;
        }
        break;

      case "delivered":
        nextAction = firstRow.delivered_next_action;
        break;

      case "implemented":
        nextAction = firstRow.implemented_next_action;
        break;

      default:
        nextAction = null;
    }

    return nextAction ? actionDisplayNames[nextAction] || nextAction : null;
  };

  const nextAction = getNextAction();

  // Function to reset the filters
  const resetFilters = () => {
    setSelectedSectionId(null);
    setSelectedFilter("");
    setSelectedStatus("");
    setAvailableStatuses([]);
    setFilteredData([]);
  };

  // Update available statuses when the selected section or filter changes
  useEffect(() => {
    updateAvailableStatuses();
    setSelectedStatus("");
    setFilteredData([]);
  }, [selectedSectionId, selectedFilter]);

  useEffect(() => {
    handleFilterData();
  }, [selectedStatus]);

  const getStatusClass = (status: string) => {
    switch (status) {
      case "Approved":
        return "bg-success text-light";
      case "Completed":
        return "bg-info text-light";
      case "In Progress":
        return "bg-warning text-dark";
      case "Rejected":
        return "bg-danger text-light";
      default:
        return "bg-primary  text-light";
    }
  };

  // Render the validation result table
  const renderTable = () => {
    if (!filteredData?.length) return null;

    return (
      <Table responsive className="table-borderless align-middle mb-0">
        <thead className="table-light">
          <tr>
            <th style={{ width: "50px" }} scope="col">
              <input type="checkbox" onChange={handleSelectAll} />
            </th>
            <th style={{ width: "200px" }} scope="col">
              Quoted Quantity
            </th>
            <th style={{ width: "200px" }} scope="col">
              Planned Quantity
            </th>
            <th style={{ width: "200px" }} scope="col">
              {selectedFilter === "procured" && "Procured Status"}
              {selectedFilter === "delivered" && "Delivered Status"}
              {selectedFilter === "implemented" && "Implemented Status"}
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedRows.has(row.id)}
                  onChange={() => handleSelectRow(row)}
                />
              </td>
              <td>{row?.quoted_quantity_sum}</td>
              <td>{row?.planned_quantity_sum}</td>
              <td>
                {selectedFilter === "procured" && (
                  <span
                    className={`badge ${getStatusClass(row.procured_status)}`}
                  >
                    {row.procured_status}
                  </span>
                )}
                {selectedFilter === "delivered" && (
                  <span
                    className={`badge ${getStatusClass(row.delivered_status)}`}
                  >
                    {row.delivered_status}
                  </span>
                )}
                {selectedFilter === "implemented" && (
                  <span
                    className={`badge ${getStatusClass(
                      row.implemented_status,
                    )}`}
                  >
                    {row.implemented_status}
                  </span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  // Handle row selection
  const handleSelectRow = (row: any) => {
    const newSelectedRows = new Set(selectedRows);
    if (newSelectedRows.has(row.id)) {
      newSelectedRows.delete(row.id); // Deselect the row
    } else {
      newSelectedRows.add(row.id); // Select the row
    }
    setSelectedRows(newSelectedRows);
  };

  // Handle selecting all rows
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const newSelectedRows = new Set<number>();
    if (checked) {
      filteredData.forEach((row) => newSelectedRows.add(row.id));
    }
    setSelectedRows(newSelectedRows);
  };

  const handleNextAction = () => {
    const selectedIds = Array.from(selectedRows);

    if (selectedIds.length > 0) {
      setSelectedProductIds(selectedIds); // Set selected product IDs
      console.log("Next action triggered with productIds:", selectedIds);
      setIsModalOpen(true); // Open the modal to proceed
    } else {
      console.warn("No rows selected for next action.");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const modalSize = filteredData.length > 0 ? "xl" : "md";

  return (
    <Modal isOpen={show} toggle={onCloseClick} size={modalSize} centered={true}>
      <ModalHeader toggle={onCloseClick}></ModalHeader>
      <LoadingOverlay active={false} spinner text="Processing...">
        <ModalBody className="py-3 px-5">
          {/* Chain-select fields */}
          <div className="mb-3">
            <label htmlFor="sectionSelect" className="form-label">
              Select Section
            </label>
            <select
              id="sectionSelect"
              className="form-select"
              value={selectedSectionId || ""}
              onChange={(e) => setSelectedSectionId(Number(e.target.value))}
            >
              <option value="">Select Section</option>
              {selectedSections.map((section: any) => (
                <option key={section?.id} value={section?.id}>
                  {section?.section_name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-3">
            <label htmlFor="filterSelect" className="form-label">
              Select Filter
            </label>
            <select
              id="filterSelect"
              className="form-select"
              value={selectedFilter}
              onChange={(e) => setSelectedFilter(e.target.value)}
              disabled={!selectedSectionId}
            >
              <option value="">Select Filter</option>
              <option value="procured">Procured</option>
              <option value="delivered">Delivered</option>
              <option value="implemented">Implemented</option>
            </select>
          </div>

          <div className="mb-3">
            <label htmlFor="statusSelect" className="form-label">
              Filter Status
            </label>
            <select
              id="statusSelect"
              className="form-select"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              disabled={!selectedFilter || !selectedSectionId}
            >
              <option value="">Select Status</option>
              {availableStatuses?.map((status, index) => (
                <option key={index} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>

          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={resetFilters}
            >
              Reset Filters
            </button>
          </div>

          <div>
            {filteredData.length > 0 && (
              <div className="mt-4">
                <div className="d-flex gap-2 justify-content-between mt-4 mb-2">
                  <h5>Filtered Results</h5>

                  <div>
                    {nextAction && (
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={handleNextAction} // Updated here
                        disabled={selectedRows.size === 0} // Disable if no rows are selected
                      >
                        {nextAction}
                      </button>
                    )}
                  </div>
                </div>
                {renderTable()}
              </div>
            )}
          </div>

          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </ModalBody>
      </LoadingOverlay>
      <IntiateProcuredModal
        isOpen={isModalOpen}
        toggle={handleCloseModal}
        nextAction={nextAction}
        selectedProductIds={selectedProductIds}
        filteredData={filteredData}
        // productsData={productsData}
        // isLoading={isLoading}
        // isError={isError}
      />
    </Modal>
  );
};

ImportResourceFilterModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  selectedSections: PropTypes.any,
  import_type: PropTypes.any,
  project_id: PropTypes.any,
  refetch: PropTypes.any,
};

export default ImportResourceFilterModal;
