import React, { useState } from "react";
import {
  Col,
  Row,
  Button,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  Table,
} from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import { BadgeStatus } from "helpers/badgeStatus";
import { STATUS_COLORS } from "helpers/common_helper";

interface Step3PreviewSubmitProps {
  // projectsData: any;
  location: { latitude: number | null; longitude: number | null };
  previousStep: () => void;
  permitData: any;
  PermitDetail: any;
  handleSubmit: any;
  isPermitLoading: boolean;
  isSubmitting: boolean;
}

const imageExtensions = ["jpeg", "jpg", "png"];

const fileIconMap: { [key: string]: string } = {
  pdf: "ri-file-pdf-line",
  doc: "ri-file-word-line",
  docx: "ri-file-word-line",
  zip: "ri-folder-zip-line",
  rar: "ri-folder-zip-line",
  default: "ri-file-line",
};

const Step3PreviewSubmit: React.FC<Step3PreviewSubmitProps> = ({
  previousStep,
  permitData,
  PermitDetail,
  handleSubmit,
  isPermitLoading,
  isSubmitting,
}) => {
  const [submitted, setSubmitted] = useState(false);

  const { incidentId: hwPermitNoFromURL } = useParams<{ incidentId: string }>();
  const navigate = useNavigate();

  // Extract file extension and return corresponding icon or image
  const getFileTypeIconOrImage = (documentUrl: string) => {
    const fileName = documentUrl.split("/").pop()?.split("?")[0] || "";
    const fileExtension = fileName.split(".").pop()?.toLowerCase() || "default";

    if (imageExtensions.includes(fileExtension)) {
      // Show the actual image if it's an image file
      return (
        <CardImg
          top
          width="150px"
          height="150px"
          src={documentUrl}
          alt={fileName}
          style={{ position: "relative" }}
        />
      );
    } else {
      // Return appropriate icon for non-image files
      const iconClass = fileIconMap[fileExtension] || fileIconMap["default"];
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "150px" }}
        >
          <i className={`${iconClass} ri-4x`} />
        </div>
      );
    }
  };

  const truncateFileName = (fileName: string, maxLength = 20) => {
    if (fileName.length > maxLength) {
      const truncatedName = fileName.substring(0, maxLength) + "...";
      return truncatedName;
    }
    return fileName;
  };

  const extractDocumentName = (url: string) => {
    const parts = url?.split("/");
    return parts[parts?.length - 1];
  };

  if (submitted) {
    return (
      <div>
        <Card className="text-center">
          <CardBody>
            <h3>Your Report has been Successfully Submitted!</h3>
            <p>Near Miss Report No: {hwPermitNoFromURL}</p>
            <Button
              color="primary"
              onClick={() =>
                navigate(`"/nearmiss-report-detail-view/${hwPermitNoFromURL}/`)
              }
            >
              View Report
            </Button>
          </CardBody>
        </Card>
      </div>
    );
  }

  return (
    <div>
      <Row>
        <Col md={12} className="align-items-center justify-content-center">
          {isPermitLoading ? (
            <Spinner color="primary" />
          ) : (
            <div>
              <PermitDetail />

              {/* Display Documents */}
              <h5 className="mt-5">Near Miss Report Documents</h5>
              <hr />
              <Row>
                {permitData?.documents?.map((document: any, index: number) => (
                  <Col key={index} md={3} className="mb-4">
                    <Card>
                      {getFileTypeIconOrImage(document.attachment_url)}
                      <CardBody>
                        {/* Extract document name from URL */}
                        <CardTitle tag="h5">
                          {truncateFileName(
                            extractDocumentName(document.attachment),
                          )}
                        </CardTitle>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              {/* Display Status */}
              <p>
                <strong>Current Status: </strong>
                {permitData?.status_name ? (
                  <BadgeStatus
                    value={permitData?.status_name}
                    color={STATUS_COLORS[permitData?.status_name]}
                  />
                ) : null}
              </p>
            </div>
          )}
        </Col>
      </Row>

      {/* Navigation Buttons */}
      <div className="d-flex justify-content-between mt-4">
        <Button
          color="secondary"
          disabled={isSubmitting}
          onClick={previousStep}
        >
          Previous
        </Button>
        <Button color="success" onClick={handleSubmit} disabled={isSubmitting}>
          {isSubmitting ? <Spinner size="sm" /> : "Submit"}
        </Button>
      </div>
    </div>
  );
};

export default Step3PreviewSubmit;
