import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import BreadCrumb from "Components/Common/BreadCrumb";
import Step2ToolBoxAttendance from "./WizardSteps/Step2ToolBoxAttendance";
import ValidationAlertStatic from "pages/SafetyApp/ValidationAlertStatic";
import { getErrorText } from "helpers/error_message";
import { incidentReportSlice } from "slices/incidentReport/incidentReportSlice";
import { useCheckToolBoxStatusMutation } from "slices/workflow/WorkFlowSlice";
import Step1IncidentReport from "./WizardSteps/Step1ToolBoxDetails";
import Step3PreviewSubmitIndex from "./WizardSteps/ReportPreview";

const NearMissReportTab: React.FC = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<number>(1);
  const [validationMessages, setValidationMessages] = useState<string[]>([]);
  const { selectedProject, incidentId } = useParams<{
    selectedProject: string;
    incidentId: string;
  }>();
  const [location, setLocation] = useState<{
    latitude: number | null;
    longitude: number | null;
  }>({ latitude: null, longitude: null });
  const [modalBackDrop, setModalBackDrop] = useState<boolean>(false);
  const [geoPermission, setGeoPermission] = React.useState<boolean>(false);

  const [checkToolboxMeeting] = useCheckToolBoxStatusMutation();

  const toggleTab = (tab: number) => {
    if (activeTab !== tab) {
      if (incidentId) {
        dispatch(
          incidentReportSlice.util.invalidateTags([
            { type: "GetNearMissIncidentReport", id: incidentId },
          ]),
        );
      }
      setActiveTab(tab);
    }
  };

  const getLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setGeoPermission(true);
        },
        (err) => {
          setValidationMessages((prev) => {
            const errorMessage = getErrorText("location-disabled");
            return prev.includes(errorMessage) ? prev : [...prev, errorMessage];
          });
          setModalBackDrop(true);
          setGeoPermission(false);
        },
        { enableHighAccuracy: true },
      );
    } else {
      setValidationMessages((prev) => {
        const errorMessage = getErrorText("location-not-supported");
        return prev.includes(errorMessage) ? prev : [...prev, errorMessage];
      });
      setModalBackDrop(true);
      setGeoPermission(false);
    }
  };

  // const validateToolBoxMeeting = async () => {
  //   const checkStatus: any = await checkToolboxMeeting({projectId: selectedProject});
  //   console.log(checkStatus);

  //   if (checkStatus.data.length !== 0) {

  //     setValidationMessages((prev: any) => {
  //             const existingKeys = new Set(prev.map((message: any) => message.key)); // Track existing keys
  //             const newMessages: any[] = [];

  //             checkStatus.data.forEach((report: any) => {
  //               const permitNo = report.toolbox_id;
  //               const permitStatus = report.report_status;

  //               // Generate a unique key for this report
  //               const errorMessageKey = `${permitNo}-${permitStatus}`;

  //               // Add error message only if the key doesn't already exist
  //               if (
  //                 !existingKeys.has(errorMessageKey) &&
  //                 (incidentId !== permitNo) //&&
  //                 // (permitStatus !== 'Draft' && permitStatus !== 'Resubmission')
  //               ) {
  //                 const errorMessage = (
  //                   <span key={errorMessageKey}>
  //                     {getErrorText('toolbox-status-exist')} <b>{permitNo} - {permitStatus}</b>
  //                   </span>
  //                 );
  //                 newMessages.push(errorMessage);
  //               }
  //             });

  //             // If there are new messages, show the modal
  //             if (newMessages.length > 0) {
  //               setModalBackDrop(true);
  //             }

  //             // Return the combined array of previous and new messages
  //             return [...prev, ...newMessages];
  //           });

  //   }
  // }

  useEffect(() => {
    setValidationMessages([]);
    getLocation();
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Near Miss Report" breadcrumbItem="Wizard" />
        <Row>
          <Col xl={12}>
            <Card>
              <CardBody className="form-steps">
                <div className="step-arrow-nav mb-4">
                  <Nav
                    className="nav-pills custom-nav nav-justified"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === 1 })}
                        // onClick={() => toggleTab(1)}
                      >
                        Near Miss report Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === 2 })}
                        // onClick={() => toggleTab(2)}
                      >
                        Near Miss Report Documents
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === 3 })}
                        // onClick={() => toggleTab(2)}
                      >
                        Near Miss Report Preview
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={1}>
                    <Step1IncidentReport
                      nextStep={() => toggleTab(2)}
                      geoPermission={geoPermission}
                      location={location}
                    />
                  </TabPane>
                  <TabPane tabId={2}>
                    <Step2ToolBoxAttendance
                      previousStep={() => toggleTab(1)}
                      location={location}
                      nextStep={() => toggleTab(3)}
                    />
                  </TabPane>
                  <TabPane tabId={3}>
                    <Step3PreviewSubmitIndex
                      previousStep={() => toggleTab(2)}
                      location={location}
                    />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <ValidationAlertStatic
        validationMessages={validationMessages}
        previousPageNavLink="/toolboxmeeting-list"
        modalBackDrop={modalBackDrop}
        setModalBackDrop={setModalBackDrop}
      />
    </div>
  );
};

export default NearMissReportTab;
