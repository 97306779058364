import React from "react";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Download from "yet-another-react-lightbox/plugins/download";

interface ImageLightboxProps {
  open: boolean;
  slides: { src: string; title?: string }[];
  currentIndex: number;
  onClose: () => void;
}

const ImageLightbox: React.FC<ImageLightboxProps> = ({
  open,
  slides,
  currentIndex,
  onClose,
}) => {
  return (
    <Lightbox
      open={open}
      close={onClose}
      slides={slides}
      index={currentIndex}
      plugins={[Download]}
    />
  );
};

export default ImageLightbox;
