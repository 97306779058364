import React, { useState } from "react";
import { Button, Card, CardBody } from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetPillingPermitByNumberQuery,
  useUpdatePillingPermitMutation
} from "slices/safetyApp/safetyappSlice";
import { formatDateTime } from "helpers/date_utils";
import Step6PreviewSubmit from "../../../CommonWizardSteps/Wizards/Step6PreviewSubmit";

interface Step6PreviewSubmitIndexProps {
  location: { latitude: number | null; longitude: number | null };
  previousStep: () => void;
}

const Step6PreviewSubmitIndex: React.FC<Step6PreviewSubmitIndexProps> = ({
  location,
  previousStep,
}) => {
  const [submitted, setSubmitted] = useState(false);

  const { hwPermitNo: hwPermitNoFromURL } = useParams<{ hwPermitNo: string }>();
  const navigate = useNavigate();

  // Queries to fetch homework permit, employees, projects, and companies data
  const {
    data: permitData,
    isLoading: isPermitLoading,
    refetch: refetchPermit,
  } = useGetPillingPermitByNumberQuery(hwPermitNoFromURL);

  const [updatePilingPermit, { isLoading: isSubmitting }] =
    useUpdatePillingPermitMutation();

  const handleSubmit = async () => {
    if (location.latitude === null || location.longitude === null) {
      alert("Unable to fetch your location. Please try again or Reload Page.");
      return;
    }

    let payload = {
      report_status: 3,
      ...location,
    };

    try {
      await updatePilingPermit({
        permit_no: hwPermitNoFromURL,
        updatedPermitData: payload,
      }).unwrap();
      await refetchPermit();
      setSubmitted(true);
    } catch (error) {
      console.error("Failed to submit", error);
    }
  };

  if (submitted) {
    return (
      <div>
        <Card className="text-center">
          <CardBody>
            <h3>Your Report has been Successfully Submitted!</h3>
            <p>Piling Work Permit No: {hwPermitNoFromURL}</p>
            <Button
              color="primary"
              onClick={() =>
                navigate(`/piling-permit-details/${hwPermitNoFromURL}/`)
              }
            >
              View Report
            </Button>
          </CardBody>
        </Card>
      </div>
    );
  }

  const permitDetail = () => {
    return (
      <div>
        <h5>Piling Work Permit</h5>
        <hr />
        <table className="table table-borderless table-nowrap ">
          <tbody>
            <tr>
              <td className="fw-medium" style={{ width: "300px" }}>
                Piling Work Permit No :
              </td>
              <td>{permitData?.permit_no}</td>
            </tr>
            {/* <tr>
                    <td className="fw-medium" style={{ width: "300px" }}>
                      Work Performed By :
                    </td>
                    <td>{permitData?.work_performed_by}</td>
                  </tr> */}
            <tr>
              <td className="fw-medium" style={{ width: "300px" }}>
                Work Supervisor By:
              </td>
              <td>
                {permitData?.supervised_by_name}
                {/* {getSupervisorNames()?.map(
                        (name: string, index: number) => (
                          <Badge key={index} color="warning" className="me-2">
                            {name}
                          </Badge>
                        )
                      )} */}
              </td>
            </tr>
            {/* <tr>
                    <td className="fw-medium" style={{ width: "300px" }}>
                      Company :
                    </td>
                    <td>
                      <Badge color="info">{getCompanyName()}</Badge>
                    </td>
                  </tr> */}
            {/* {hwPermitData?.company_others && (
                    <tr>
                      <td className="fw-medium" style={{ width: "300px" }}>
                        Other Company :
                      </td>
                      <td>{hwPermitData?.company_others}</td>
                    </tr>
                  )} */}
            <tr>
              <td className="fw-medium" style={{ width: "300px" }}>
                Project :
              </td>
              <td>{permitData?.project_name}</td>
            </tr>
            <tr>
              <td className="fw-medium" style={{ width: "300px" }}>
                Start Date of Work :
              </td>
              <td>{formatDateTime(permitData?.start_date_of_work, "date")}</td>
            </tr>
            <tr>
              <td className="fw-medium" style={{ width: "300px" }}>
                Start Time of Work :
              </td>
              <td>{formatDateTime(permitData?.start_time_of_work, "time")}</td>
            </tr>
            <tr>
              <td className="fw-medium" style={{ width: "300px" }}>
                End Date of Work :
              </td>
              <td>{formatDateTime(permitData?.end_date_of_work, "date")}</td>
            </tr>
            <tr>
              <td className="fw-medium" style={{ width: "300px" }}>
                End Time of Work :
              </td>
              <td>{formatDateTime(permitData?.end_time_of_work, "time")}</td>
            </tr>
            <tr>
              <td className="fw-medium" style={{ width: "300px" }}>
                Work Location :
              </td>
              <td>{permitData?.work_location}</td>
            </tr>
            <tr>
              <td className="fw-medium" style={{ width: "300px" }}>
                Work Description :
              </td>
              <td>{permitData?.job_description}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <Step6PreviewSubmit
      previousStep={previousStep}
      location={location}
      permitData={permitData}
      handleSubmit={handleSubmit}
      PermitDetail={permitDetail}
      isPermitLoading={isPermitLoading}
      isSubmitting={isSubmitting}
    />
  );
};

export default Step6PreviewSubmitIndex;
