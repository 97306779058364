import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetExcavationPermitByNumberQuery,
  useUploadExcavationPermitDocumentMutation,
  useDeleteExcavationPermitDocumentMutation,
} from "slices/safetyApp/safetyappSlice";
import Step5FileUpload from "../../../CommonWizardSteps/Wizards/Step5FileUpload";

interface Step5FileUploadProps {
  previousStep: () => void;
  nextStep: () => void;
}

const Step4FileUpload: React.FC<Step5FileUploadProps> = ({
  previousStep,
  nextStep,
}) => {
  const { hwPermitNo: hwPermitNoFromURL } = useParams<{ hwPermitNo: string }>();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteDocumentId, setDeleteDocumentId] = useState<string | null>(null);
  const [documentToDeleteName, setDocumentToDeleteName] = useState<string>("");

  const {
    data: permitData,
    isLoading: isPermitLoading,
    isFetching: isPermitFetching,
    refetch: refetchPermit,
  } = useGetExcavationPermitByNumberQuery(hwPermitNoFromURL);

  const [uploadHotworkPermitDocument, { isLoading: isUploading }] =
    useUploadExcavationPermitDocumentMutation();
  const [deleteHotworkPermitDocument, { isLoading: isDeleting }] =
    useDeleteExcavationPermitDocumentMutation();

   const [selectedFile, setSelectedFile] = useState<{
        file: File | null;
        name: string;
        comment: string;
      }>({
        file: null,
        name: "",
        comment: "",
      });
   
      const handleAcceptedFile = (file: File) => {
        setSelectedFile({
          file,
          name: file.name, // Default to original name
          comment: "", // Empty comment initially
        });
      };

  const handleFileUpload = async () => {
    if (!selectedFile.file) {
      console.error("No file selected");
      return;
    }
  
    const formData = new FormData();
    formData.append("attachment", selectedFile.file);
    formData.append("name", selectedFile.name);
    formData.append("comments", selectedFile.comment);
    try {
      await uploadHotworkPermitDocument({
        ew_permit_no: hwPermitNoFromURL,
        documentData: formData,
      });
      setIsUploadModalOpen(false);
      refetchPermit();
      setSelectedFile({ file: null, name: "", comment: "" }); // Reset after upload
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleDeleteDocument = async () => {
    if (deleteDocumentId) {
      try {
        await deleteHotworkPermitDocument({ id: deleteDocumentId });
        await refetchPermit();
        setIsDeleteModalOpen(false);
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    }
  };

  return (
    <Step5FileUpload
      nextStep={nextStep}
      previousStep={previousStep}
      permitData={permitData}
      handleFileUpload={handleFileUpload}
      handleDeleteDocument={handleDeleteDocument}
      handleAcceptedFiles={handleAcceptedFile}
      setDeleteDocumentId={setDeleteDocumentId}
      selectedFiles={selectedFile}
      setSelectedFiles={setSelectedFile}
      setIsUploadModalOpen={setIsUploadModalOpen}
      setDocumentToDeleteName={setDocumentToDeleteName}
      setIsDeleteModalOpen={setIsDeleteModalOpen}
      documentToDeleteName={documentToDeleteName}
      isPermitLoading={isPermitLoading}
      isUploadModalOpen={isUploadModalOpen}
      isDeleteModalOpen={isDeleteModalOpen}
      isUploading={isUploading}
      isDeleting={isDeleting}
      isPermitFetching={isPermitFetching}
    />
  );
};

export default Step4FileUpload;
