import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import {
  Button,
  Col,
  Row,
  Input,
  Form,
  Label,
  FormGroup,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import { format, addDays } from "date-fns";
import {
  useCreateHotworkPermitMutation,
  useUpdateHotworkPermitMutation,
  useGetHotworkPermitByNumberQuery,
} from "slices/safetyApp/safetyappSlice";
import { useGetEmployeeDropDownByProjectQuery, useGetEmployeeByUuidQuery } from "slices/employee/employeeSlice";
import { useGetCompaniesQuery } from "slices/InspectionReport/InspectionReportSlice";

import LoadingOverlay from "react-loading-overlay-ts";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { useParams, useNavigate } from "react-router-dom";
import { ServiceContext } from "context/ServiceContextProvider";

interface Step1HotWorkPermitProps {
  selectedProject: string;
  projectsData: any;
  nextStep: () => void;
  // setHwPermitNo: (no: string) => void;
  // hwPermitNo: string | null;
}

interface SupervisorOption {
  value: number;
  label: string;
}

interface FormValues {
  work_performed_by: string;
  project: string;
  name_of_hotwork_supervisor: number[];
  names_of_other_workers_involved_in_hotwork: string;
  contact_number: string;
  type_of_hotwork_equipment_used: string;
  name_of_hotwork_equipment_operator: string;
  work_location: string;
  work_description: string;
  start_date_of_work: string;
  start_time_of_work: string;
  end_date_of_work: string;
  end_time_of_work: string;
}

const Step1HotWorkPermit: React.FC<Step1HotWorkPermitProps> = ({
  selectedProject,
  projectsData,
  nextStep,
}) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [companyName, setCompanyName] = useState('');

  const { getEmployeeUUID } = useContext(ServiceContext);
  const { selectedProject: urlProjectId, hwPermitNo: hwPermitNoFromURL } =
    useParams<{ selectedProject: string; hwPermitNo: string }>();
  const navigate = useNavigate();
  const { data: supervisorsData, isLoading: supervisorsDataLoading } =
    useGetEmployeeDropDownByProjectQuery(urlProjectId, {
      skip: !urlProjectId,
    });
  const [createHotworkPermit, { isLoading: hotpermitisLoading }] =
    useCreateHotworkPermitMutation();
  const [updateHotworkPermit, { isLoading: patchisLoading }] =
    useUpdateHotworkPermitMutation();
  //@ts-ignore
  const { data: employeeData } = useGetEmployeeByUuidQuery(currentUser?.employee_uuid, { skip: !currentUser?.employee_uuid });

  console.log('employee_data', employeeData)

  const { data: companyData } = useGetCompaniesQuery(undefined);

  const {
    data: hwPermitData,
    isLoading: isPermitLoading,
    isFetching: isPermitFetching,
    refetch: refetchHotWorkPermit,
  } = useGetHotworkPermitByNumberQuery(hwPermitNoFromURL);

  useEffect(() => {
    const fetchAuthUser = () => {
      const authUserString = localStorage.getItem('authUser');
      if (authUserString) {
        setCurrentUser(JSON.parse(authUserString));
      }
    };
    fetchAuthUser();
  }, []);

  console.log('current-user', currentUser)

  useEffect(() => {
    if (employeeData?.[0]?.is_external_employee === true) {
      setCompanyName(employeeData?.[0]?.external_company);
    } else if (companyData?.[0]?.company_name) {
      setCompanyName(companyData[0].company_name);
    }
  }, [employeeData])

  const getEmployeeNameById = (id: any) => {
    if (!employeeData) return "N/A"; // If employeeData is null/undefined
    if (Array.isArray(employeeData)) {
      const employee = employeeData.find((emp) => emp.id === id);
      return employee ? employee.employee_fullname : "N/A";
    } else if (employeeData.id === id) {
      return employeeData.employee_fullname || "N/A";
    }
    return "N/A";
  };

  const supervisorOptions: SupervisorOption[] =
    supervisorsData?.map((supervisor) => ({
      value: supervisor.id,
      label: supervisor.employee_fullname,
    })) || [];

  const postSupervisor = () => {
    //@ts-ignore
    return currentUser?.employee_id ? [currentUser.employee_id] : [];
  };

  console.log('post-superavisor', postSupervisor())

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Add 1 to month since it's zero-indexed
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      work_performed_by: hwPermitData?.work_performed_by || "",
      name_of_hotwork_equipment_operator:
        hwPermitData?.name_of_hotwork_equipment_operator || "",
      project: selectedProject,
      name_of_hotwork_supervisor: hwPermitNoFromURL
        ? Array.isArray(hwPermitData?.name_of_hotwork_supervisor) && hwPermitData?.name_of_hotwork_supervisor.length > 0
          ? hwPermitData.name_of_hotwork_supervisor
          : []
        : postSupervisor(), names_of_other_workers_involved_in_hotwork:
        hwPermitData?.names_of_other_workers_involved_in_hotwork || "",
      contact_number: hwPermitData?.contact_number || "",
      type_of_hotwork_equipment_used:
        hwPermitData?.type_of_hotwork_equipment_used || "",
      work_location: hwPermitData?.work_location || "",
      work_description: hwPermitData?.work_description || "",
      start_date_of_work: hwPermitData?.start_date_of_work || getCurrentDate(),
      start_time_of_work: hwPermitData?.start_time_of_work || "",
      end_date_of_work: hwPermitData?.end_date_of_work || "",
      end_time_of_work: hwPermitData?.end_time_of_work || "",
    },
    validationSchema: Yup.object({
      name_of_hotwork_supervisor: Yup.array()
        .nullable()
        .test(
          "is-not-empty",
          "At least one supervisor is required",
          (value) => {
            console.log("Validation Check - name_of_hotwork_supervisor:", value);
            return Array.isArray(value) && value.length > 0;
          }
        ),
      work_performed_by: Yup.string().optional(),
      work_location: Yup.string().required('Please enter at least one work location. Use commas (,) to create tags for multiple locations.'),
      work_description: Yup.string().optional(),
      contact_number: Yup.string().required('Contact number is required. Use commas (,) to create tags for multiple numbers.'),
      names_of_other_workers_involved_in_hotwork: Yup.string().optional(),
      name_of_hotwork_equipment_operator: Yup.string().required(
        'Equipment operator name is required.'
      ),
      type_of_hotwork_equipment_used: Yup.string().required(
        'Type of hotwork equipment is required. If entering multiple types, separate them with commas (e.g., Welding Machine, Cutting Torch).'
      ),
      start_date_of_work: Yup.string()
        .required("Start date is required")
        .test(
          "is-not-in-past",
          "Start date cannot be in the past",
          function (value) {
            console.log("value", value);

            if (!value) return true; // Skip validation if no date is provided
            const inputDate = moment(value, "YYYY-MM-DD").startOf("day"); // Only date part
            const today = moment().startOf("day");
            return inputDate.isSameOrAfter(today); // Compare only dates
          },
        ),
      start_time_of_work: Yup.string().required("Start time is required"),
      end_date_of_work: Yup.date()
        .required("End date is required")
        .test(
          "is-same-or-next-day",
          "End date must be the same or the next day as the start date",
          function (value) {
            const { start_date_of_work } = this.parent; // Access other fields
            if (!start_date_of_work || !value) return true; // Skip if either date is empty

            const startDate = moment(start_date_of_work, "YYYY-MM-DD").startOf(
              "day",
            );
            const endDate = moment(value, "YYYY-MM-DD").startOf("day");

            // Check if the endDate is same or next day compared to startDate
            return (
              endDate.isSame(startDate) ||
              endDate.isSame(startDate.add(1, "day"))
            );
          },
        ),
      end_time_of_work: Yup.string()
        .required("End time is required")
        .test(
          "is-valid-time",
          "End time must be at least 1 hour after start time",
          function (value) {
            const { start_date_of_work, start_time_of_work, end_date_of_work } =
              this.parent;

            // Return true if any required field is missing (skipping validation)
            if (
              !start_date_of_work ||
              !start_time_of_work ||
              !end_date_of_work ||
              !value
            ) {
              return true;
            }

            // Parse start and end date-time strings
            const formattedStartDate = moment(
              new Date(start_date_of_work),
            ).format("DD/MM/YYYY");
            const formattedStartTime = start_time_of_work.slice(0, 5);
            const startDateTime = moment(
              `${formattedStartDate} ${formattedStartTime}`,
              "DD/MM/YYYY HH:mm",
              true,
            );

            const formattedEndDate = moment(new Date(end_date_of_work)).format(
              "DD/MM/YYYY",
            );
            const formattedEndTime = value.slice(0, 5);
            const endDateTime = moment(
              `${formattedEndDate} ${formattedEndTime}`,
              "DD/MM/YYYY HH:mm",
              true,
            );

            // Ensure dates are valid
            if (!startDateTime.isValid() || !endDateTime.isValid()) {
              console.error("Invalid date inputs:", {
                startDateTime,
                endDateTime,
              });
              return false;
            }

            // Compare end time: ensure it's at least 1 hour after start time
            return endDateTime.isSameOrAfter(
              startDateTime.clone().add(1, "hour"),
            );
          },
        ),
    }),
    onSubmit: async (values) => {
      try {
        const payload = { ...values };
        let response;

        if (hwPermitNoFromURL) {
          response = await updateHotworkPermit({
            hw_permit_no: hwPermitNoFromURL,
            updatedPermitData: payload,
          }).unwrap();
        } else {
          response = await createHotworkPermit(payload).unwrap();
          localStorage.setItem("hwPermitNo", response.hw_permit_no);
          navigate(
            `/hotwork-permit-wizard/${selectedProject}/${response.hw_permit_no}`,
          );
        }
        formik.resetForm();
        nextStep();
      } catch (error) {
        console.error("Failed to submit the form", error);
      }
    },
  });

  // useEffect(() => {
  //   if (supervisorsData && getEmployeeUUID) {
  //     const defaultSupervisor = supervisorsData.find(
  //       (supervisor) => supervisor.employee_uuid === getEmployeeUUID,
  //     );
  //     if (defaultSupervisor) {
  //       formik.setFieldValue("name_of_hotwork_supervisor", [
  //         defaultSupervisor.id,
  //       ]);
  //     }
  //   }
  // }, [supervisorsData, getEmployeeUUID]);

  useEffect(() => {
    if (hwPermitNoFromURL) {
      refetchHotWorkPermit();
    }
  }, [hwPermitNoFromURL, refetchHotWorkPermit]);

  useEffect(() => {
    // Ensure data is fetched and not currently loading
    if (!isPermitLoading && hwPermitData) {
      const {
        work_performed_by,
        name_of_hotwork_supervisor,
        names_of_other_workers_involved_in_hotwork,
        contact_number,
        type_of_hotwork_equipment_used,
        name_of_hotwork_equipment_operator,
        work_location,
        work_description,
        start_date_of_work,
        start_time_of_work,
        end_date_of_work,
        end_time_of_work,
      } = hwPermitData;

      // Set form values with fallback defaults
      formik.setValues({
        work_performed_by: work_performed_by || "",
        project: selectedProject,
        name_of_hotwork_supervisor: hwPermitNoFromURL
          ? Array.isArray(hwPermitData?.name_of_hotwork_supervisor) && hwPermitData?.name_of_hotwork_supervisor.length > 0
            ? hwPermitData.name_of_hotwork_supervisor
            : []
          : postSupervisor(),
        names_of_other_workers_involved_in_hotwork:
          names_of_other_workers_involved_in_hotwork || "",
        contact_number: contact_number || "",
        type_of_hotwork_equipment_used: type_of_hotwork_equipment_used || "",
        name_of_hotwork_equipment_operator:
          name_of_hotwork_equipment_operator || "",
        start_date_of_work: start_date_of_work || getCurrentDate(),
        start_time_of_work: start_time_of_work || "",
        end_date_of_work: end_date_of_work || "",
        end_time_of_work: end_time_of_work || "",
        work_location: work_location || "",
        work_description: work_description || "",
      });
    }
  }, [isPermitLoading, isPermitFetching, hwPermitData]);

  // const handleSupervisorChange = (selectedOptions: SupervisorOption[]) => {
  //   const selectedIds = selectedOptions
  //     ? selectedOptions.map((option) => option.value)
  //     : [];
  //   formik.setFieldValue("name_of_hotwork_supervisor", selectedIds);
  // };

  const handleSupervisorOthersChange = (tags: string[]) => {
    formik.setFieldValue(
      "names_of_other_workers_involved_in_hotwork",
      tags.join(","),
    );
  };

  const handleSupervisorContactNumberChange = (tags: string[]) => {
    formik.setFieldValue("contact_number", tags.join(","));
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <LoadingOverlay active={isPermitLoading} spinner text="Fetching...">
        <Row>
          <Col md={12}>
            <Label className="card-title"> Site Information</Label>
            <hr />
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="name_of_hotwork_supervisor">
                Work Supervised By <span className="text-danger">*</span>
              </Label>
              <Input
                id="name_of_hotwork_supervisor"
                type="text"
                value={
                  formik.values.name_of_hotwork_supervisor?.length
                    ? formik.values.name_of_hotwork_supervisor
                      .map((id) => getEmployeeNameById(id))
                      .join(", ")
                    : currentUser
                      //@ts-ignore
                      ? getEmployeeNameById(currentUser?.employee_id)
                      : "No Supervisor Selected"
                }
                disabled
              />
              {formik.touched.name_of_hotwork_supervisor &&
                formik.errors.name_of_hotwork_supervisor ? (
                <div className="text-danger">
                  {formik.errors.name_of_hotwork_supervisor as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          {/* <Col md={6}>
            <FormGroup>
              <Label for="name_of_hotwork_supervisor">
                Work Supervised By <span className="text-danger">*</span>
              </Label>
              <Select
                id="name_of_hotwork_supervisor"
                isMulti
                options={supervisorOptions}
                value={supervisorOptions.filter((option) =>
                  formik.values.name_of_hotwork_supervisor.includes(
                    option.value,
                  ),
                )}
                onChange={handleSupervisorChange}
                isLoading={supervisorsDataLoading}
                isDisabled={true}
              />
              {formik.touched.name_of_hotwork_supervisor &&
              formik.errors.name_of_hotwork_supervisor ? (
                <div className="text-danger">
                  {formik.errors.name_of_hotwork_supervisor as string}
                </div>
              ) : null}
            </FormGroup>
          </Col> */}

          {/* company Name (read-only) */}
          <Col md={6}>
            <FormGroup>
              <Label for="company_name">Company Name</Label>
              <Input
                id="project_name"
                type="text"
                value={companyName || ""}
                placeholder="Loading company name..."
                readOnly
                disabled
              />
            </FormGroup>
          </Col>

          {/* Project Name (read-only) */}
          <Col md={6}>
            <FormGroup>
              <Label for="project_name">Project Name</Label>
              <Input
                id="project_name"
                name="project_name"
                type="text"
                value={projectsData?.project_name || ""}
                readOnly
                disabled
              />
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="names_of_other_workers_involved_in_hotwork">
                Other Workers Involved{" "}
              </Label>
              <TagsInput
                addKeys={[9, 13, 188]}
                value={
                  formik.values.names_of_other_workers_involved_in_hotwork
                    ? formik.values.names_of_other_workers_involved_in_hotwork.split(
                      ",",
                    )
                    : []
                }
                // value={formik.values.work_supervisor_others}
                onChange={handleSupervisorOthersChange}
                inputProps={{
                  placeholder: "Add a Workers",
                  style: {
                    padding: "6px",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    minWidth: "150px",
                    border: "none",
                    outline: "none",
                    flex: 1,
                  },
                }}
              />
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="supervisor_contact_number_others">
                Contact Number<span className="text-danger">*</span>
              </Label>
              <TagsInput
                addKeys={[9, 13, 188]}
                value={
                  formik.values.contact_number
                    ? formik.values.contact_number.split(",")
                    : []
                }
                // value={formik.values.supervisor_contact_number_others}
                onChange={handleSupervisorContactNumberChange}
                inputProps={{
                  placeholder: "Add contact number",
                  style: {
                    padding: "6px",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    minWidth: "150px",
                    border: "none",
                    outline: "none",
                    flex: 1,
                  },
                }}
              />

              {formik.touched.contact_number &&
                formik.errors.contact_number ? (
                <div className="text-danger">
                  {formik.errors.contact_number as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="type_of_hotwork_equipment_used">Equipment Used <span className="text-danger">*</span></Label>
              <TagsInput
                addKeys={[9, 13, 188]}
                value={
                  formik.values.type_of_hotwork_equipment_used
                    ? formik.values.type_of_hotwork_equipment_used.split(",")
                    : []
                }
                onChange={(tags) =>
                  formik.setFieldValue(
                    "type_of_hotwork_equipment_used",
                    tags.join(","),
                  )
                }
                inputProps={{
                  placeholder: "Add equipment",
                  style: {
                    padding: "6px",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    minWidth: "150px",
                    border: "none",
                    outline: "none",
                    flex: 1,
                  },
                }}
              />
              {formik.touched.type_of_hotwork_equipment_used &&
                formik.errors.type_of_hotwork_equipment_used ? (
                <div className="text-danger">
                  {formik.errors.type_of_hotwork_equipment_used}
                </div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="name_of_hotwork_equipment_operator">
                Equipment Operator <span className="text-danger">*</span>
              </Label>
              <Input
                id="name_of_hotwork_equipment_operator"
                name="name_of_hotwork_equipment_operator"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.name_of_hotwork_equipment_operator}
                invalid={
                  formik.touched.name_of_hotwork_equipment_operator &&
                  !!formik.errors.name_of_hotwork_equipment_operator
                }
              />
              {formik.touched.name_of_hotwork_equipment_operator &&
                formik.errors.name_of_hotwork_equipment_operator ? (
                <div className="text-danger">
                  {formik.errors.name_of_hotwork_equipment_operator}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="start_date_of_work">
                Start Date <span className="text-danger">*</span>
              </Label>
              <Flatpickr
                id="start_date_of_work"
                name="start_date_of_work"
                className={`form-control ${formik.touched.start_date_of_work && formik.errors.start_date_of_work ? "is-invalid" : ""}`}
                options={{
                  minDate: "today",
                  maxDate: "today",
                }}
                value={formik.values.start_date_of_work}
                onChange={(date: Date[]) => {
                  formik.setFieldValue(
                    "start_date_of_work",
                    format(date[0], "yyyy-MM-dd"),
                  );
                }}
              />
              {formik.touched.start_date_of_work &&
                formik.errors.start_date_of_work ? (
                <div className="text-danger">
                  {formik.errors.start_date_of_work}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="start_time_of_work">
                Start Time <span className="text-danger">*</span>
              </Label>
              <Input
                id="start_time_of_work"
                name="start_time_of_work"
                type="time"
                onChange={formik.handleChange}
                value={formik.values.start_time_of_work}
                invalid={
                  formik.touched.start_time_of_work &&
                  !!formik.errors.start_time_of_work
                }
              />
              {formik.touched.start_time_of_work &&
                formik.errors.start_time_of_work ? (
                <div className="text-danger">
                  {formik.errors.start_time_of_work as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="end_date_of_work">
                End Date <span className="text-danger">*</span>
              </Label>
              <Flatpickr
                id="end_date_of_work"
                name="end_date_of_work"
                className={`form-control ${formik.touched.end_date_of_work && formik.errors.end_date_of_work ? "is-invalid" : ""}`}
                options={{
                  minDate: "today",
                  maxDate: addDays(new Date(), 1),
                }}
                value={formik.values.end_date_of_work}
                onChange={(date: Date[]) => {
                  formik.setFieldValue(
                    "end_date_of_work",
                    format(date[0], "yyyy-MM-dd"),
                  );
                }}
              />
              {formik.touched.end_date_of_work &&
                formik.errors.end_date_of_work ? (
                <div className="text-danger">
                  {formik.errors.end_date_of_work}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="end_time_of_work">
                End Time <span className="text-danger">*</span>
              </Label>
              <Input
                id="end_time_of_work"
                name="end_time_of_work"
                type="time"
                onChange={formik.handleChange}
                value={formik.values.end_time_of_work}
                invalid={
                  formik.touched.end_time_of_work &&
                  !!formik.errors.end_time_of_work
                }
              />
              {formik.touched.end_time_of_work &&
                formik.errors.end_time_of_work ? (
                <div className="text-danger">
                  {formik.errors.end_time_of_work as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="location">Location</Label>
              <TagsInput
                addKeys={[9, 13, 188]}
                value={
                  formik.values.work_location
                    ? formik.values.work_location.split(",")
                    : []
                }
                onChange={(tags) =>
                  formik.setFieldValue("work_location", tags.join(","))
                }
                inputProps={{
                  placeholder: "Add locations",
                  style: {
                    padding: "6px",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    minWidth: "150px",
                    border: "none",
                    outline: "none",
                    flex: 1,
                  },
                }}
              />
              {formik.touched.work_location &&
                formik.errors.work_location ? (
                <div className="text-danger">
                  {formik.errors.work_location}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={12}>
            <FormGroup>
              <Label for="work_description">Work Description</Label>
              <Input
                id="work_description"
                name="work_description"
                type="textarea"
                onChange={(e) =>
                  formik.setFieldValue("work_description", e.target.value)
                }
                value={formik.values.work_description}
                invalid={
                  formik.touched.work_description &&
                  !!formik.errors.work_description
                }
              />
              {formik.touched.work_description &&
                formik.errors.work_description ? (
                <div className="text-danger">
                  {formik.errors.work_description as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={12}>
            <Button
              style={{ float: "right" }}
              type="submit"
              color="primary"
              disabled={hotpermitisLoading || patchisLoading}
            >
              {hotpermitisLoading || patchisLoading ? (
                <Spinner size="sm" />
              ) : (
                "Next"
              )}
            </Button>
          </Col>
        </Row>
      </LoadingOverlay>
    </Form>
  );
};

export default Step1HotWorkPermit;
