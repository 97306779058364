import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

interface DeleteConfirmationModalProps {
  isOpen: boolean;
  toggle: () => void;
  onDelete: () => void;
  documentName: string;
  isDeleting: boolean;
  isPermitFetching: boolean;
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  isOpen,
  toggle,
  onDelete,
  documentName,
  isDeleting,
  isPermitFetching,
}) => {
  return (
    <Modal backdrop={"static"} isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Confirm Delete</ModalHeader>
      <ModalBody>
        Are you sure you want to delete the document:{" "}
        <strong>{documentName}</strong>?
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={isDeleting || isPermitFetching}
          color="secondary"
          onClick={toggle}
        >
          Cancel
        </Button>
        <Button
          color="danger"
          onClick={onDelete}
          disabled={isDeleting || isPermitFetching}
        >
          {isDeleting || isPermitFetching ? <Spinner size="sm" /> : "Delete"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteConfirmationModal;
