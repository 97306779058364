import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Input,
  FormGroup,
  Label,
  FormFeedback,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

interface FileUploadModalProps {
  isOpen: boolean;
  toggle: () => void;
  onFileUpload: () => void;
  isUploading: boolean;
  isPermitFetching: boolean;
  selectedFiles: { file: File | null; name: string; comment: string };
  setSelectedFiles: (files: { file: File | null; name: string; comment: string }) => void;
}

// Validation Schema using Yup
const FileSchema = Yup.object().shape({
  name: Yup.string().required("File name is required"),
  comment: Yup.string().nullable(),
  file: Yup.mixed().required("File is required"),
});

const FileUploadModal: React.FC<FileUploadModalProps> = ({
  isOpen,
  toggle,
  onFileUpload,
  selectedFiles,
  isUploading,
  isPermitFetching,
  setSelectedFiles,
}) => {
  return (
    <Modal backdrop={"static"} isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Upload File</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            file: selectedFiles.file,
            name: selectedFiles.name,
            comment: selectedFiles.comment,
          }}
          validationSchema={FileSchema}
          onSubmit={onFileUpload}
          enableReinitialize
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form>
              <FormGroup>
                <Label>File Name <span className="text-danger">*</span></Label>
                <Field
                  type="text"
                  name="name"
                  as={Input}
                  className={errors.name && touched.name ? "is-invalid" : ""}
                  placeholder="File Name"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue("name", e.target.value);
                    setSelectedFiles({ ...selectedFiles, name: e.target.value });
                  }}
                />
                <ErrorMessage name="name" component={FormFeedback} />
              </FormGroup>

              <FormGroup>
                <Label>Comment</Label>
                <Field
                  type="textarea"
                  name="comment"
                  as={Input}
                  className={errors.comment && touched.comment ? "is-invalid" : ""}
                  placeholder="Add a comment"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue("comment", e.target.value);
                    setSelectedFiles({ ...selectedFiles, comment: e.target.value });
                  }}
                />
                <ErrorMessage name="comment" component={FormFeedback} />
              </FormGroup>

              <FormGroup>
                <Label for="fileInput">Select File <span className="text-danger">*</span></Label>
                <Input
                  type="file"
                  id="fileInput"
                  name="file"
                  onChange={(event) => {
                    const file = event.currentTarget.files?.[0] || null;
                    setFieldValue("file", file);
                    setSelectedFiles({ ...selectedFiles, file });
                  }}
                  invalid={!!errors.file && touched.file}
                />
                {errors.file && touched.file && <FormFeedback>{errors.file}</FormFeedback>}
              </FormGroup>

              <ModalFooter>
                <Button disabled={isUploading || isPermitFetching} color="secondary" onClick={toggle}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={!values.file || isUploading || isPermitFetching}
                >
                  {isUploading || isPermitFetching ? <Spinner size="sm" /> : "Upload"}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default FileUploadModal;
