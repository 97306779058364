// WorkflowProjectModal.tsx
import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Spinner,
} from "reactstrap";
import Select from "react-select";

interface WorkflowProjectModalProps {
  isOpen: boolean;
  toggle: () => void;
  workflowName: string;
  workflowId: number | null;
  projects: Array<{ value: number; label: string }>;
  projectID: Array<number>;
  selectedProjects: number[]; // Array of selected project IDs
  onSave: (workflowId: number | null, selectedProjects: number[]) => void;
  InspectionReportProjectLoading: any;
}

const WorkflowProjectModal: React.FC<WorkflowProjectModalProps> = ({
  isOpen,
  toggle,
  workflowName,
  workflowId,
  projects,
  projectID,
  selectedProjects,
  onSave,
  InspectionReportProjectLoading,
}) => {
  const [selectedProjectOptions, setSelectedProjectOptions] = useState<
    Array<{ value: number; label: string }>
  >([]);

  console.log("selectedProjectOptions", selectedProjectOptions);
  console.log("projectID", projectID);

  useEffect(() => {
    if (selectedProjects && projects) {
      const initialProjects = projects.filter((project) =>
        selectedProjects.includes(project.value),
      );
      setSelectedProjectOptions(initialProjects);
    }
  }, [selectedProjects, projects]);

  const handleSave = () => {
    const selectedProjectIds = selectedProjectOptions.map(
      (project) => project.value,
    );
    onSave(workflowId, selectedProjectIds);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>add Workflow Projects</ModalHeader>
      <ModalBody>
        <h5>{workflowName}</h5>
        <Select
          isMulti
          options={projects}
          value={selectedProjectOptions}
          onChange={(selected: any) =>
            setSelectedProjectOptions(selected || [])
          }
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>
          {InspectionReportProjectLoading ? (
            <span className="d-flex align-items-spacebetween">
              <Spinner size="sm" className="flex-shrink-0" />
              <span className="flex-grow-1 ms-2">Loading...</span>
            </span>
          ) : (
            <> Save</>
          )}
        </Button>
        <Button
          disabled={InspectionReportProjectLoading}
          color="secondary"
          onClick={toggle}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WorkflowProjectModal;
