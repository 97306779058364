import React, { useMemo, useState } from "react";
import { Container, Row, Col, Card, CardHeader, Button } from "reactstrap";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import {
  useGetStocksQuery,
  useGetProductsQuery,
} from "slices/resourceInventory/inventorySlice";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { StockEdit } from "./StockEdit";

const StockListView = () => {
  const [editingStockId, setEditingStockId] = useState<string | undefined>(
    undefined,
  ); // State to manage add/edit mode
  const [isAdding, setIsAdding] = useState(false); // State to track if adding a new stock

  // Fetch stock data
  const {
    data: stockData,
    isLoading: isLoadingStocks,
    refetch: refetchStocks,
  } = useGetStocksQuery(undefined);

  // Fetch product data
  const { data: productData, isLoading: isLoadingProducts } =
    useGetProductsQuery(undefined);

  // Create a map of product IDs to product names
  const productMap = useMemo(() => {
    const map: { [key: number]: string } = {};
    if (productData?.results) {
      productData.results.forEach((product: any) => {
        map[product.id] = product.name;
      });
    }
    return map;
  }, [productData]);

  // Handle Add and Edit actions
  const handleAddStock = () => {
    setEditingStockId(undefined); // Clear editing ID for new stock
    setIsAdding(true); // Set to adding mode
  };

  const handleEditStock = (id: string) => {
    setEditingStockId(id); // Set the ID for editing
    setIsAdding(false); // Set to editing mode
  };

  // Handle returning to list view
  const handleBackToList = () => {
    setEditingStockId(undefined); // Clear the state
    setIsAdding(false); // Exit adding/editing mode
  };

  // Define table columns for stocks
  const columns = useMemo(
    () => [
      {
        Header: "Product",
        accessor: "product",
        filterable: false,
        Cell: ({ row }: any) => {
          const productId = row.original.product;
          return productMap[productId] || "Unknown Product";
        },
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        filterable: false,
      },
      {
        Header: "Price",
        accessor: "price",
        filterable: false,
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }: any) => {
          const stockId = row.original.id;
          return (
            <div>
              <ul className="list-inline hstack gap-2 mb-0">
                <li className="list-inline-item edit" title="Edit">
                  <Link
                    to="#"
                    className="text-primary d-inline-block edit-item-btn"
                    onClick={() => handleEditStock(stockId)}
                  >
                    <i className="ri-pencil-fill fs-16"></i>
                  </Link>
                </li>
              </ul>
            </div>
          );
        },
      },
    ],
    [productMap], // Recreate the columns when the productMap changes
  );

  document.title = "Stocks | Dashboard";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Stock List" pageTitle="Stocks" />
          {(editingStockId || isAdding) && (
            <Row className="mt-4">
              <Col lg={12}>
                {/* Replace with StockEdit component if necessary */}
                <StockEdit
                  stockId={editingStockId}
                  setCardView={handleBackToList}
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                  <h5 className="card-title mb-0">Stock List</h5>
                  <Button
                    color="success"
                    onClick={handleAddStock}
                    disabled={isLoadingStocks || isLoadingProducts}
                  >
                    <i className="ri-add-line align-bottom me-1"></i> Add Stock
                  </Button>
                </CardHeader>
                <div className="card-body pt-0">
                  {isLoadingStocks || isLoadingProducts ? (
                    <Loader />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={stockData}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      className="custom-header-css"
                      // isCustomerFilter={true}
                      SearchPlaceholder={"Search for stocks..."}
                      isPagination={true}
                    />
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default StockListView;
