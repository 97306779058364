import { createApi } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "helpers/jwt-token-access/customBaseQuery";
import { ProjectType } from "./type";

const convertToFormData = (obj: any) => {
  const formData = new FormData();
  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      obj[key].forEach((item: any) => formData.append(key, item));
    } else {
      formData.append(key, obj[key]);
    }
  });
  return formData;
};

export const projectSlice = createApi({
  reducerPath: "project",
  baseQuery: customBaseQuery,
  tagTypes: [
    "GetAllProjects",
    "GetProjectsByUserRole",
    "GetTeamByProject",
    "GetProjectsById",
  ],
  endpoints: (builder) => ({
    createProject: builder.mutation({
      query: (newTask) => {
        const formData = convertToFormData(newTask);
        return {
          url: "/organization/api/v1/projects/",
          method: "POST",
          body: formData,
        };
      },
    }),
    updateProject: builder.mutation({
      query: ({ id, newProject }: { id: number; newProject: any }) => {
        if (newProject.thumbnail === null) {
          delete newProject.thumbnail;
        }

        const formData = convertToFormData(newProject);
        return {
          url: `/organization/api/v1/projects/${id}/`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["GetAllProjects", "GetProjectsByUserRole"],
    }),
    getAllProjects: builder.query<ProjectType[], void>({
      query: () => "/organization/api/v1/projects/",
      providesTags: ["GetAllProjects"],
    }),
    getAllProjectsByUserRole: builder.query({
      query: (page) => `/organization/api/v1/projects_by_user_role/?page=${page.page}&page_size=${page.pageSize}&search=${page.search}&status=${page.status}`,
      providesTags: ["GetProjectsByUserRole"],
    }),
    getProjectsByAssignedUser: builder.query({
      query: () => `/organization/api/v1/project_by_assigned_user/`,
      providesTags: ["GetProjectsByUserRole"],
    }),
    getprojectById: builder.query<ProjectType, string | undefined>({
      query: (id) => `/organization/api/v1/projects/${id}/`,
      providesTags: ["GetProjectsById"],
    }),
    getProjectByEmployeeId: builder.query<ProjectType[], string | undefined>({
      query: (employee_id) =>
        `/organization/api/v1/projects/?employee_id=${employee_id}`,
    }),
    getTeamByProjectId: builder.query<ProjectType[], string | undefined>({
      query: (project_id) => `/organization/api/v1/team/?project=${project_id}`,
      providesTags: ["GetTeamByProject"],
    }),
    getProjectsByDropDown: builder.query({
      query: () => `/organization/api/v1/projects_by_user_drop_down/`,
      providesTags: ["GetProjectsByUserRole"],
    }),
    createTeam: builder.mutation({
      query: ({ newTeam }: { newTeam: any }) => {
        return {
          url: `/organization/api/v1/team/`,
          method: "POST",
          body: newTeam,
        };
      },
      invalidatesTags: ["GetTeamByProject"],
    }),
    updateTeam: builder.mutation({
      query: ({ id, newTeam }: { id: any; newTeam: any }) => {
        return {
          url: `/organization/api/v1/team/${id}/`,
          method: "PATCH",
          body: newTeam,
        };
      },
      invalidatesTags: ["GetTeamByProject", "GetProjectsById"],
    }),
    // getAllPriority: builder.query<PriorityType[], void>({
    //   query: () => "/task/api/v1/task_priority/",
    // }),
    getProjectAttachmentById: builder.query<any[], string | undefined>({
      query: (id) => `/organization/api/v1/projects_attachment/${id}/`,
    }),

    getAllProjectAttachments: builder.query<any[], string | undefined>({
      query: (id) => `/organization/api/v1/projects_attachment/?project=${id}`,
    }),

    createProjectAttachment: builder.mutation({
      query: (newAttachment) => {
        const formData = convertToFormData(newAttachment);
        return {
          url: "/organization/api/v1/projects_attachment/",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["GetAllProjects", "GetProjectsByUserRole"],
    }),

    deleteProjectAttachmentById: builder.mutation({
      query: (id: string) => ({
        url: `/organization/api/v1/projects_attachment/${id}/`,
        method: "DELETE",
      }),
    }),

    deleteTeamById: builder.mutation({
      query: (id: string) => ({
        url: `/organization/api/v1/team/${id}/`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useGetAllProjectsQuery,
  useGetAllProjectsByUserRoleQuery,
  useGetProjectsByAssignedUserQuery,
  useGetprojectByIdQuery,
  useGetProjectAttachmentByIdQuery,
  useGetAllProjectAttachmentsQuery,
  useGetProjectsByDropDownQuery,
  useGetTeamByProjectIdQuery,
  useCreateTeamMutation,
  useUpdateTeamMutation,
  useGetProjectByEmployeeIdQuery,
  useCreateProjectAttachmentMutation,
  useDeleteProjectAttachmentByIdMutation,
  useDeleteTeamByIdMutation,
} = projectSlice;
