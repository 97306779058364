import React, { useState, useMemo } from "react";
import { Card, CardHeader, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TableContainer from "../../Components/Common/TableContainer";

import { useGetEmployeesQuery } from "slices/employee/employeeSlice";
import Loader from "Components/Common/Loader";

const TeamMembers = () => {
  const [page, setPageState] = useState<{
    page: number;
    pageSize: number;
    search: string;
    status_name: string;
    start_date: string;
    due_date: string;
  }>({
    page: 1,
    pageSize: 10,
    search: "",
    status_name: "",
    start_date: "",
    due_date: "",
  });

  const {
    data: employeeData,
    isLoading: employeeLoading,
    isFetching: employeeFetching,
    // refetch: refetchemployees,
    error: employeeError,
  } = useGetEmployeesQuery(page);

  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      // {
      //   Header: (
      //     <input
      //       type="checkbox"
      //       className="form-check-input"
      //       id="checkBoxAll"
      //       onClick={() => checkedAll()}
      //     />
      //   ),
      //   Cell: (cellProps: any) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         className="contactCheckBox form-check-input"
      //         value={cellProps.row.original.id}
      //         onChange={() => deleteCheckbox()}
      //       />
      //     );
      //   },
      //   id: "#",
      // },
      {
        Header: "Name",
        accessor: "employee_fullname",
        filterable: false,
        Cell: (contact: any) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {
                  contact.row.original?.employee_profile_image_url &&
                  contact.row.original?.employee_profile_image_url[
                    "profile_image_url"
                  ] ? (
                    <img
                      src={
                        contact.row.original?.employee_profile_image_url &&
                        contact.row.original?.employee_profile_image_url[
                          "profile_image_url"
                        ]
                      }
                      // process.env.REACT_APP_API_URL + "/images/users/" +
                      alt=""
                      className="avatar-xs rounded-circle"
                    />
                  ) : (
                    <div className="flex-shrink-0 avatar-xs me-2">
                      <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                        {contact.row.original.employee_fullname.charAt(0)}
                      </div>
                    </div>
                  )
                  // <img src={dummyImg} alt="" className="avatar-xxs rounded-circle" />
                }
              </div>
              <div className="flex-grow-1 ms-2 name">
                <Link to={`/profile/${contact.row.original.employee_uuid}`}>
                  {contact.row.original.employee_fullname}
                </Link>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Employee#",
        accessor: "employee_number",
        filterable: false,
      },
      // {
      //   Header: "User Name",
      //   accessor: "username",
      //   filterable: false,
      // },
      {
        Header: "Position",
        accessor: "position_name",
        Cell: (cell: any) => (
          <>
            <span className="badge bg-primary-subtle text-primary me-1">
              {cell.row.original?.position_name}
            </span>
          </>
        ),
      },
      {
        Header: "Assigned Project",
        accessor: "project_name",
        Cell: (cell: any) =>
          cell.row.original?.project_list.map((n: any) => {
            return (
              <span className="badge bg-secondary-subtle text-primary me-1">
                {n.project_name}
              </span>
            );
          }),
      },
      //   {
      //     Header: "Start Date",
      //     accessor: "employee_start_date",
      //     filterable: false,
      //     Cell: (cell: any) => (
      //       <>
      //           {formatDateTime(cell.row.original?.employee_start_date, 'date')}
      //       </>
      //     ),
      //   },
      //   {
      //     Header: "Work Phone",
      //     accessor: "work_phone",
      //     filterable: false,
      //   },
      //   {
      //     Header: "Active",
      //     accessor: "active",
      //     filterable: false,
      //     Cell: (cell: { value: any }) =>
      //       cell.value ? (
      //         <span className="badge text-uppercase bg-success-subtle text-success">
      //           {" "}
      //           Yes{" "}
      //         </span>
      //       ) : (
      //         <span className="badge text-uppercase bg-danger-subtle text-danger">
      //           {" "}
      //           No{" "}
      //         </span>
      //       ),
      //   },
      //   {
      //     Header: "Actions",
      //     accessor: "actions",
      //     Cell: ({ row }: any) => {
      //       const handleView = () => {
      //         navigate(`/profile/${row.original.employee_uuid}`);
      //       };

      //       const handleEdit = () => {
      //         // Your edit logic here
      //         console.log("Edit:", row.original);
      //       };

      //       const handleDelete = () => {
      //         // Your delete logic here
      //         console.log("Delete:", row.original);
      //       };

      //       return (
      //         <ul className="list-inline hstack gap-2 mb-0">
      //           <li className="list-inline-item edit" title="View">
      //             <Link
      //               to={`/profile/${row?.original?.employee_uuid}`}
      //               className="text-primary d-inline-block edit-item-btn"
      //             // onClick={handleView}
      //             >
      //               <i className="ri-eye-fill fs-16"></i>
      //             </Link>
      //           </li>
      //           {/* <li className="list-inline-item edit" title="Edit">
      //             <Link
      //               to="#"
      //               className="text-primary d-inline-block edit-item-btn"
      //               onClick={() => {
      //                 const customerData = cellProps.row.original;
      //                 handleCustomerClick(customerData);
      //               }}
      //             >
      //               <i className="ri-pencil-fill fs-16"></i>
      //             </Link>
      //           </li> */}
      //           {/* <li className="list-inline-item" title="Remove">
      //             <Link
      //               to="#"
      //               className="text-danger d-inline-block remove-item-btn"
      //               onClick={() => {
      //                 const customerData = cellProps.row.original;
      //                 onClickDelete(customerData);
      //               }}
      //             >
      //               <i className="ri-delete-bin-5-fill fs-16"></i>
      //             </Link>
      //           </li> */}
      //         </ul>
      //       );
      //     },
      //   },
    ],
    [],
  );

  return (
    <React.Fragment>
      <Col xxl={12}>
        <Card className="card-height-100 pb-0">
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Members</h4>
            {/* <div className="flex-shrink-0">
                            <UncontrolledDropdown className="card-header-dropdown">
                                <DropdownToggle tag="a" className="text-reset dropdown-btn" role="button">
                                    <span className="fw-semibold text-uppercase fs-12">Sort by: </span><span className="text-muted">Last 30 Days<i className="mdi mdi-chevron-down ms-1"></i></span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu dropdown-menu-end">
                                    <DropdownItem>Today</DropdownItem>
                                    <DropdownItem>Yesterday</DropdownItem>
                                    <DropdownItem>Last 7 Days</DropdownItem>
                                    <DropdownItem>Last 30 Days</DropdownItem>
                                    <DropdownItem>This Month</DropdownItem>
                                    <DropdownItem>Last Month</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div> */}
          </CardHeader>

          <div className="card-body">
            <div className="table-responsive table-card mb-0 pb-0">
              {!employeeLoading ? (
                <TableContainer
                  columns={columns}
                  data={employeeData?.results || []}
                  isLoading={employeeFetching}
                  isGlobalFilter={false}
                  isAddUserList={false}
                  customPageSize={page.pageSize}
                  className="custom-header-css"
                  divClass="mb-3 pb-0"
                  tableClass="align-middle table-nowrap mb-0"
                  theadClass="table-light text-muted"
                  thClass="table-light text-muted"
                  SearchPlaceholder="Search for Members..."
                  isPagination={false}
                  isServerPagination={true}
                  serverPaginationProps={{
                    count: employeeData?.count || 0,
                    next: employeeData?.next || null,
                    page_size: employeeData?.page_size || 10,
                    previous: employeeData?.previous || null,
                    current_page: employeeData?.current_page || 1,
                    total_page: employeeData?.total_page || 0,
                  }}
                  setPageState={setPageState}
                />
              ) : (
                <Loader error={employeeError} />
              )}

              {/* <table className="table table-borderless table-nowrap align-middle mb-0">
                                <thead className="table-light text-muted">
                                    <tr>
                                        <th scope="col">Member</th>
                                        <th scope="col">Hours</th>
                                        <th scope="col">Tasks</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(teamMembers || []).map((item : any, key : any) => (<tr key={key}>
                                        <td className="d-flex">
                                            <img src={item.img} alt="" className="avatar-xs rounded-3 me-2" />
                                            <div>
                                                <h5 className="fs-13 mb-0">{item.name}</h5>
                                                <p className="fs-12 mb-0 text-muted">{item.position}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <h6 className="mb-0">{item.hours}h : <span className="text-muted"> 150h </span></h6>
                                        </td>
                                        <td>
                                            {item.tasks}
                                        </td>
                                        <td style={{ width: "5%" }}>
                                            <TeamMembersCharts seriesData={item.series} chartsColor={item.chartsColor} />
                                        </td>
                                    </tr>))}
                                </tbody>
                            </table> */}
            </div>
          </div>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default TeamMembers;
