import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
  Spinner,
  FormFeedback,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import {
  usePatchEmployeeMutation,
  useGetWorkLocationsQuery,
  useGetEmployeeDropDownQuery,
  useGetPositionsQuery,
  useGetProjectsQuery,
} from "slices/employee/employeeSlice";
import { Title } from "utils/CommonUtils";
import LoadingOverlay from "react-loading-overlay-ts";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import { format } from "date-fns";
import Cleave from "cleave.js/react";
import sgFlag from "../../../assets/images/flags/sg.svg";
import { country } from "../../../common/data";
import { useGetSubContractCompanyQuery } from "slices/pageAccess/PageAccessSlice";

interface OptionType {
  value: string;
  label: string;
  id: string;
  name: string;
  project_name: string;
  employee_fullname: string;
}

interface FormValues {
  title: string | null;
  first_name: string;
  middle_name: string;
  last_name: string;
  work_phone: string;
  work_email: string;
  employee_start_date: string | null;
  skills: string;
  position: number | null;
  work_location: number | null;
  // onboarding_mentor: number | null;
  // direct_manager: number | null;
  // projects_m2m: string[]; // Array of project IDs as strings
  weekly_working_hours: string;
  nric: string;
  is_external_employee: boolean;
  external_company: string;
  sub_company: number | null;
}

interface WorkProfileEditProps {
  employee_uuid: string | undefined;
  setCardView: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  employeeData: any;
  isLoadingEmployee: boolean;
}

export const WorkProfileEdit: React.FC<WorkProfileEditProps> = ({
  employee_uuid,
  setCardView,
  employeeData,
  isLoadingEmployee,
}) => {
  const [patchEmployee, { isLoading: patchEmployeeloading }] =
    usePatchEmployeeMutation();
  const { data: projectsData } = useGetProjectsQuery(undefined);
  const { data: positionData } = useGetPositionsQuery(undefined);
  const { data: subCompanyData } = useGetSubContractCompanyQuery(undefined);
  const { data: employeedropdownData } = useGetEmployeeDropDownQuery(undefined);
  const { data: workLocationData } = useGetWorkLocationsQuery(undefined);
  const [isExternal, setIsExternal] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const [seletedCountry, setseletedCountry] = useState({
    id: 240,
    flagImg: sgFlag,
    countryName: "Singapore",
    countryCode: "+65",
    length: 8,
  });

  const getPhoneNumber = (workPhone: string) => {
    if (!workPhone) return ""; // Return empty string if no phone number provided
    // Check if the number contains a dash
    if (workPhone.includes(" ")) {
      // Split by dash and return the second part
      return workPhone.split(" ")[1];
    }
    // Return the original number if no dash is present
    return workPhone;
  };

  const titleOptions = Title?.map((title) => ({
    value: title.id.toString(),
    label: title.name,
  }));

  const subContractCompanyOptions = subCompanyData
    ?.filter((com: any) => com.status === 1) // Filter companies with status 1
    .map((com: any) => ({
      value: com.id.toString(),
      label: com.subcompany_name,
    }));

  const projectOptions =
    projectsData?.map((project) => ({
      value: project.id.toString(), // Convert to string
      label: project.project_name,
    })) || [];

  useEffect(() => {
    if (employeeData) {
      formik.setValues({
        title: employeeData?.title || null,
        first_name: employeeData?.first_name || "",
        middle_name: employeeData.middle_name || "",
        last_name: employeeData?.last_name || "",
        work_phone: getPhoneNumber(employeeData?.work_phone),
        work_email: employeeData?.work_email || "",
        employee_start_date: employeeData?.employee_start_date || null,
        skills: employeeData?.skills || "",
        position: employeeData?.position_role_id || null,
        work_location: employeeData?.work_location || null,
        // onboarding_mentor: employeeData?.onboarding_mentor || null,
        // direct_manager: employeeData?.direct_manager || null,
        // projects_m2m: employeeData?.project_list.map((project: { id: number }) => project.id.toString()) || [],
        weekly_working_hours: employeeData.weekly_working_hours || "",
        nric: employeeData.nric || "",
        is_external_employee: employeeData.is_external_employee || false,
        external_company: employeeData.external_company || "",
        sub_company: employeeData.sub_company || "", // Ensure it's always a string
      });
    }
  }, [employeeData]);

  // const handleSingleSelectChange = (
  //   name: string,
  //   selectedOption: OptionType | null,
  // ): void => {
  //   formik.setFieldValue(name, selectedOption ? selectedOption.value : "");
  // };

  // Handle change for Select dropdown
  const handleSingleSelectChange = (
    name: string,
    selectedOption: OptionType | null,
  ): void => {
    formik.setFieldValue(
      name,
      selectedOption ? Number(selectedOption.value) : null,
    );
  };

  const handleMultiSelectChange = (
    name: string,
    selectedOptions: OptionType[] | null,
  ): void => {
    formik.setFieldValue(
      name,
      selectedOptions?.map((option) => option.value) || [],
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().min(1, "Please select Title"),
    first_name: Yup.string().required("First Name is required"),
    middle_name: Yup.string().optional(),
    last_name: Yup.string().required("Last Name is required"),
    work_phone: Yup.string()
      .min(
        seletedCountry.length,
        `Phone number must be ${seletedCountry.length} digits`,
      )
      .max(
        seletedCountry.length,
        `Phone number must be ${seletedCountry.length} digits`,
      )
      .matches(/^\d+$/, "Phone phone must be digits only")
      .optional(),
    work_email: Yup.string().email("Invalid email address").optional(),
    employee_start_date: Yup.string().required(
      "Employee Start Date is required",
    ),
    skills: Yup.string().optional(),
    position: Yup.string().min(1, "Please select Position"),
    work_location: Yup.string().min(1, "Please select Worl Location"),
    // onboarding_mentor: Yup.string().optional(),
    // direct_manager: Yup.string().optional(),
    // projects_m2m: Yup.array().min(1, 'Please select at least one Project'),
    weekly_working_hours: Yup.string().optional(),
    nric: Yup.string()
      .matches(
        /^[STFMG]\d{7}[A-Z]$/,
        "Must start with S, T, F, M or G, followed by 7 digits and 1 letter",
      )
      .required("NRIC / FIN is required"),
    is_external_employee: Yup.boolean(),
    sub_company: Yup.number()
      .nullable()
      .when("is_external_employee", {
        is: true,
        then: (schema) => schema.required("External Company is required"),
        otherwise: (schema) => schema.optional(),
      }),

    external_company: Yup.string().when(
      "is_external_employee",
      (isExternalEmployee, schema) => {
        console.log("isExternalEmployee", isExternalEmployee);
        return isExternal
          ? schema.required("External Company is required")
          : schema.optional();
      },
    ),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      title: null,
      first_name: "",
      middle_name: "",
      last_name: "",
      work_phone: "",
      work_email: "",
      employee_start_date: "",
      skills: "",
      position: null,
      work_location: null,
      // onboarding_mentor: null,
      // direct_manager: null,
      // projects_m2m: [],
      weekly_working_hours: "",
      nric: "",
      is_external_employee: false,
      external_company: "",
      sub_company: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      const updatedData = {
        ...values,
        work_phone: values.work_phone
          ? `${seletedCountry.countryCode} ${values.work_phone}`
          : "",
      };

      try {
        await patchEmployee({ employee_uuid, updatedData });
        toast.success("Employee Profile Info updated successfully!");
        setCardView({ summary: true });
      } catch (error) {
        console.error("Error submitting document:", error);
        toast.error("Failed to update Employee Projects!");
      }
    },
  });

  return (
    <Card>
      <CardHeader className="d-flex">
        <h4 className="card-title flex-grow-1 mb-0">Work Profile Edit</h4>
      </CardHeader>
      <LoadingOverlay active={isLoadingEmployee} spinner text="Fetching...">
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="title" className="form-label">
                    Title
                  </Label>
                  <span className="text-danger">*</span>
                  <Select
                    className={`${formik.touched.title && formik.errors.title ? "is-invalid" : ""}`}
                    name="title"
                    options={titleOptions}
                    value={
                      employeeData.title
                        ? {
                            value: employeeData?.title.toString(),
                            label: Title.find(
                              (title) =>
                                title.id.toString() ===
                                (
                                  formik.values.title as unknown as string
                                )?.toString(),
                            )?.name,
                          }
                        : null
                    }
                    onChange={(option: OptionType | null) =>
                      handleSingleSelectChange("title", option)
                    }
                  />
                  {formik.errors.title &&
                    typeof formik.errors.title === "string" && (
                      <FormFeedback>{formik.errors.title}</FormFeedback>
                    )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="first_name" className="form-label">
                    First Name
                  </Label>
                  <span className="text-danger">*</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="first_name"
                    name="first_name"
                    placeholder="Enter First Name"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      !!formik.errors.first_name && formik.touched.first_name
                    }
                  />
                  {formik.touched.first_name && formik.errors.first_name && (
                    <div className="text-danger">
                      {formik.errors.first_name}
                    </div>
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="middle_name" className="form-label">
                    Middle Name
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="middle_name"
                    name="middle_name"
                    placeholder="Enter Middle Name"
                    value={formik.values.middle_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      !!formik.errors.middle_name && formik.touched.middle_name
                    }
                  />
                  {formik.touched.middle_name && formik.errors.middle_name && (
                    <div className="text-danger">
                      {formik.errors.middle_name}
                    </div>
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="last_name" className="form-label">
                    Last Name
                  </Label>
                  <span className="text-danger">*</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="last_name"
                    name="last_name"
                    placeholder="Enter Last Name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      !!formik.errors.last_name && formik.touched.last_name
                    }
                  />
                  {formik.touched.last_name && formik.errors.last_name && (
                    <div className="text-danger">{formik.errors.last_name}</div>
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="work_phone" className="form-label">
                    Work Phone
                  </Label>
                  <div>
                    <Dropdown
                      className="input-group"
                      isOpen={dropdownOpen}
                      toggle={toggleDropdown}
                    >
                      <DropdownToggle
                        as="button"
                        className="btn btn-light border arrow-none"
                      >
                        <img
                          src={seletedCountry.flagImg}
                          alt="country flag"
                          className="options-flagimg"
                          height="20"
                        />
                        <span className="countrylist-codeno text-muted">
                          {seletedCountry.countryCode}
                        </span>
                      </DropdownToggle>
                      <Input
                        name="work_phone"
                        type="text"
                        placeholder="Enter number"
                        className="form-control rounded-end flag-input"
                        id="work_phone"
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.work_phone || ""}
                        invalid={
                          formik.touched.work_phone && formik.errors.work_phone
                            ? true
                            : false
                        }
                      />
                      {formik.touched.work_phone && formik.errors.work_phone ? (
                        <FormFeedback type="invalid">
                          {typeof formik.errors.work_phone === "string"
                            ? formik.errors.work_phone
                            : null}
                        </FormFeedback>
                      ) : null}
                      <DropdownMenu
                        as="ul"
                        className="list-unstyled w-100 dropdown-menu-list mb-0"
                      >
                        <SimpleBar
                          style={{ maxHeight: "220px" }}
                          className="px-3"
                        >
                          {(country || []).map((item, key) => (
                            <DropdownItem
                              as="li"
                              onClick={() => setseletedCountry(item)}
                              key={key}
                              className="dropdown-item d-flex"
                            >
                              <div className="flex-shrink-0 me-2">
                                <img
                                  src={item.flagImg}
                                  alt="country flag"
                                  className="options-flagimg"
                                  height="20"
                                />
                              </div>
                              <div className="flex-grow-1">
                                <div className="d-flex">
                                  <div className="country-name me-1">
                                    {item.countryName}
                                  </div>
                                  <span className="countrylist-codeno text-muted">
                                    {item.countryCode}
                                  </span>
                                </div>
                              </div>
                            </DropdownItem>
                          ))}
                        </SimpleBar>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  {/* <Input
                    type="text"
                    className="form-control"
                    id="work_phone"
                    name="work_phone"
                    placeholder="Enter Work Phone"
                    value={formik.values.work_phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      !!formik.errors.work_phone &&
                      formik.touched.work_phone
                    }
                  /> */}
                  {formik.touched.work_phone && formik.errors.work_phone && (
                    <div className="text-danger">
                      {formik.errors.work_phone}
                    </div>
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="work_email" className="form-label">
                    Work Email
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="work_email"
                    name="work_email"
                    placeholder="Enter Work Email"
                    value={formik.values.work_email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      !!formik.errors.work_email && formik.touched.work_email
                    }
                  />
                  {formik.touched.work_email && formik.errors.work_email && (
                    <div className="text-danger">
                      {formik.errors.work_email}
                    </div>
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="position" className="form-label">
                    Position
                  </Label>
                  <span className="text-danger">*</span>
                  <Select
                    className={`${formik.touched.position && formik.errors.position ? "is-invalid" : ""}form-control`}
                    name="position"
                    options={positionData?.map((position) => ({
                      value: position.id.toString(),
                      label: position.position_name,
                    }))}
                    value={
                      employeeData.position
                        ? {
                            value: employeeData?.position.toString(),
                            label: positionData?.find(
                              (option) =>
                                option.id.toString() ===
                                (
                                  formik.values?.position as unknown as string
                                )?.toString(),
                            )?.position_name,
                          }
                        : null
                    }
                    onChange={(option: any) =>
                      handleSingleSelectChange("position", option)
                    }
                  />
                  {formik.errors.position && (
                    <FormFeedback>{formik.errors.position}</FormFeedback>
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="work_location" className="form-label">
                    Work Location
                  </Label>
                  <Select
                    className={`${formik.touched.work_location && formik.errors.work_location ? "is-invalid" : ""}`}
                    name="work_location"
                    options={workLocationData?.map((work_location) => ({
                      value: work_location.id.toString(),
                      label: work_location.location_name,
                    }))}
                    value={
                      employeeData.work_location
                        ? {
                            value: employeeData?.work_location.toString(),
                            label: workLocationData?.find(
                              (option) =>
                                option.id.toString() ===
                                (
                                  formik.values
                                    .work_location as unknown as string
                                )?.toString(),
                            )?.location_name,
                          }
                        : null
                    }
                    onChange={(option: any) =>
                      handleSingleSelectChange("work_location", option)
                    }
                  />
                  {formik.errors.work_location && (
                    <FormFeedback>{formik.errors.work_location}</FormFeedback>
                  )}
                </div>
              </Col>
              {/* <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="onboarding_mentor" className="form-label">
                    Onboarding Mentor
                  </Label>
                  <Select
                    className={`${formik.touched.onboarding_mentor && formik.errors.onboarding_mentor ? 'is-invalid' : ''
                      }`}
                    name="onboarding_mentor"
                    options={employeedropdownData?.map(emp_dropdown => ({
                      value: String(emp_dropdown.id), // Ensure it's always a string
                      label: emp_dropdown.employee_fullname,
                    }))}
                    value={
                      employeedropdownData
                        ?.map(emp => ({
                          value: String(emp.id),
                          label: emp.employee_fullname,
                        }))
                        .find(option => option.value === String(formik.values.onboarding_mentor)) || null
                    }
                    onChange={(selectedOption: { value: string; label: string }) =>
                      formik.setFieldValue('onboarding_mentor', selectedOption ? selectedOption.value : null)
                    }
                  />
                  {formik.errors.onboarding_mentor && (
                    <FormFeedback>{formik.errors.onboarding_mentor}</FormFeedback>
                  )}
                </div>
              </Col> */}

              {/* <Col lg={6}>

                <div className="mb-3">
                  <Label htmlFor="direct_manager" className="form-label">
                    Direct Manager
                  </Label>
                  <Select
                    className={`${formik.touched.direct_manager && formik.errors.direct_manager ? 'is-invalid' : ''
                      }`}
                    name="direct_manager"
                    options={employeedropdownData?.map(emp_dropdown => ({
                      value: String(emp_dropdown.id),
                      label: emp_dropdown.employee_fullname,
                    }))}
                    value={
                      employeedropdownData
                        ?.map(emp => ({
                          value: String(emp.id),
                          label: emp.employee_fullname,
                        }))
                        .find(option => option.value === String(formik.values.direct_manager)) || null
                    }
                    onChange={(selectedOption: { value: string; label: string }) =>
                      formik.setFieldValue('direct_manager', selectedOption ? selectedOption.value : null)
                    }
                  />
                  {formik.errors.direct_manager && (
                    <FormFeedback>{formik.errors.direct_manager}</FormFeedback>
                  )}
                </div>
              </Col> */}

              {/* <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="projects_m2m" className="form-label">
                    Projects
                  </Label>
                  <span className="text-danger">*</span>
                  <Select
                    className={`${formik.touched.projects_m2m && formik.errors.projects_m2m ? 'is-invalid' : ''}`}
                    isMulti
                    options={projectOptions}
                    value={projectOptions.filter((option: any) => {
                      //@ts-ignore
                      return formik.values?.projects_m2m ? formik.values?.projects_m2m.includes(option.value) : null
                    }
                    )}
                    onChange={(selectedOptions: OptionType[] | null) =>
                      handleMultiSelectChange("projects_m2m", selectedOptions)
                    }
                  />
                  {formik.errors.projects_m2m && <FormFeedback>{formik.errors.projects_m2m}</FormFeedback>}
                </div>
              </Col> */}
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="employee_start_date" className="form-label">
                    Employee Start Date
                  </Label>
                  <span className="text-danger">*</span>
                  <Flatpickr
                    className={`form-control ${formik.touched.employee_start_date && formik.errors.employee_start_date ? "is-invalid" : ""}`}
                    id="employee_start_date"
                    name="employee_start_date"
                    value={formik.values.employee_start_date}
                    onChange={(date: Date[]) => {
                      formik.setFieldValue(
                        "employee_start_date",
                        format(date[0], "yyyy-MM-dd"),
                      );
                    }}
                    onBlur={formik.handleBlur}
                    invalid={
                      !!formik.errors.employee_start_date &&
                      formik.touched.employee_start_date
                    }
                  />
                  {formik.touched.employee_start_date &&
                    formik.errors.employee_start_date && (
                      <div className="text-danger">
                        {formik.errors.employee_start_date}
                      </div>
                    )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="nric" className="form-label">
                    NRIC / FIN Number
                  </Label>
                  <span className="text-danger">*</span>
                  <Cleave
                    className={`form-control ${
                      formik.touched.nric && formik.errors.nric
                        ? "is-invalid"
                        : ""
                    }`}
                    id="nric"
                    name="nric"
                    placeholder="X XXXXXXX X"
                    value={formik.values.nric}
                    onChange={(e: any) => {
                      const rawValue = e.target.rawValue
                        .replace(/\s+/g, "")
                        .toUpperCase();
                      formik.setFieldValue("nric", rawValue);
                    }}
                    onBlur={formik.handleBlur}
                    options={{
                      blocks: [1, 7, 1], // 1 letter, 7 digits, 1 letter
                      uppercase: true, // Convert to uppercase automatically
                      delimiters: [], // No delimiters
                      numericOnly: false, // Allow both letters and numbers
                    }}
                  />
                  {formik.touched.nric && formik.errors.nric && (
                    <div className="text-danger">{formik.errors.nric}</div>
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="skills" className="form-label">
                    Skills
                  </Label>
                  <TagsInput
                    value={
                      formik.values.skills
                        ? formik.values.skills.split(",")
                        : []
                    }
                    onChange={(newTags: string[]) => {
                      formik.setFieldValue("skills", newTags.join(","));
                    }}
                    inputProps={{ placeholder: "Add a skill" }}
                  />
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="skills" className="form-label">
                    Is External Employee
                  </Label>
                  {formik.values.is_external_employee && (
                    <span className="text-danger">*</span>
                  )}
                  <div className="input-group">
                    <div className="input-group-text">
                      <Input
                        className="form-check-input mt-0"
                        type="checkbox"
                        name="is_external_employee"
                        checked={formik.values.is_external_employee}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          formik.setFieldValue(
                            "is_external_employee",
                            isChecked,
                          );
                          if (!isChecked) {
                            formik.setFieldValue("sub_company", null); // Reset sub_company if unchecked
                          }
                        }}
                      />
                    </div>

                    <Select
                      className={`${formik.touched.sub_company && formik.errors.sub_company ? "is-invalid" : ""} form-control p-0`}
                      name="sub_company"
                      options={subContractCompanyOptions}
                      isDisabled={!formik.values.is_external_employee} // Disable when checkbox is false
                      value={
                        formik.values.sub_company
                          ? subContractCompanyOptions?.find(
                              (option: any) =>
                                option?.value ===
                                formik?.values?.sub_company?.toString(),
                            ) || null
                          : null
                      }
                      onChange={(option: OptionType | null) =>
                        handleSingleSelectChange("sub_company", option)
                      }
                    />
                  </div>

                  {formik.touched.sub_company && formik.errors.sub_company && (
                    <FormFeedback>{formik.errors.sub_company}</FormFeedback>
                  )}
                </div>
              </Col>

              <Col lg={12}>
                <div className="hstack gap-2 justify-content-end">
                  <Button
                    type="submit"
                    color="primary"
                    disabled={patchEmployeeloading}
                  >
                    {patchEmployeeloading ? (
                      <span className="d-flex align-items-center">
                        <>
                          <Spinner size="sm" />
                          &nbsp;Updating....
                        </>
                      </span>
                    ) : (
                      <>Update Work Profile</>
                    )}
                  </Button>
                  <Button
                    type="button"
                    color="light"
                    onClick={() => {
                      setCardView({ summary: true });
                    }}
                    disabled={patchEmployeeloading}
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </LoadingOverlay>
    </Card>
  );
};
