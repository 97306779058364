import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useGetStockByIdQuery,
  useCreateStockMutation,
  useUpdateStockMutation,
  useGetProductsQuery,
  useGetStocksQuery,
} from "slices/resourceInventory/inventorySlice";

interface StockEditProps {
  stockId: string | undefined;
  setCardView: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
}

export const StockEdit: React.FC<StockEditProps> = ({
  stockId,
  setCardView,
}) => {
  const {
    data: stockData,
    isLoading: isLoadingStock,
    refetch,
  } = useGetStockByIdQuery(stockId, { skip: !stockId });
  const { refetch: refetchStocks } = useGetStocksQuery(undefined);
  const [createStock, { isLoading: createStockLoading }] =
    useCreateStockMutation();
  const [updateStock, { isLoading: updateStockLoading }] =
    useUpdateStockMutation();

  // Fetching products for the select dropdown
  const { data: products } = useGetProductsQuery(undefined);

  // Validation schema for the stock fields
  const validationSchema = Yup.object({
    product: Yup.number().required("Product is required"),
    quantity: Yup.number()
      .required("Quantity is required")
      .positive("Quantity must be a positive number"),
    price: Yup.string().required("Price is required"),
  });

  const formik = useFormik({
    initialValues: {
      product: "",
      quantity: "",
      price: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (stockId) {
        // Update existing stock
        updateStock({ id: stockId, data: values })
          .unwrap()
          .then(() => {
            refetchStocks();
            setCardView({ stockInfo: true });
          })
          .catch((error) => {
            console.error("Error updating stock:", error);
          });
      } else {
        // Create new stock
        createStock(values)
          .unwrap()
          .then(() => {
            refetchStocks();
            setCardView({ stockInfo: true });
          })
          .catch((error) => {
            console.error("Error creating stock:", error);
          });
      }
    },
  });

  useEffect(() => {
    if (stockData) {
      formik.setValues({
        product: stockData.product || "",
        quantity: stockData.quantity || "",
        price: stockData.price || "",
      });
    }
  }, [stockData]);

  return (
    <Card>
      <CardHeader className="d-flex">
        <h4 className="card-title flex-grow-1 mb-0">Stock Edit</h4>
      </CardHeader>
      <CardBody>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="product" className="form-label">
                  Product
                </Label>
                <span className="text-danger">*</span>
                <Input
                  type="select"
                  className="form-select"
                  id="product"
                  name="product"
                  value={formik.values.product}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={!!formik.errors.product && formik.touched.product}
                >
                  <option value="">Select product</option>
                  {products?.results?.map((product: any) => (
                    <option key={product.id} value={product.id}>
                      {product.name}
                    </option>
                  ))}
                </Input>
                {formik.touched.product && formik.errors.product && (
                  <div className="text-danger">{formik.errors.product}</div>
                )}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="quantity" className="form-label">
                  Quantity
                </Label>
                <span className="text-danger">*</span>
                <Input
                  type="number"
                  className="form-control"
                  id="quantity"
                  name="quantity"
                  placeholder="Enter quantity"
                  value={formik.values.quantity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={!!formik.errors.quantity && formik.touched.quantity}
                />
                {formik.touched.quantity && formik.errors.quantity && (
                  <div className="text-danger">{formik.errors.quantity}</div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="price" className="form-label">
                  Price
                </Label>
                <span className="text-danger">*</span>
                <Input
                  type="text"
                  className="form-control"
                  id="price"
                  name="price"
                  placeholder="Enter price"
                  value={formik.values.price}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={!!formik.errors.price && formik.touched.price}
                />
                {formik.touched.price && formik.errors.price && (
                  <div className="text-danger">{formik.errors.price}</div>
                )}
              </div>
            </Col>
          </Row>
          <Button
            type="submit"
            color="primary"
            disabled={
              isLoadingStock || createStockLoading || updateStockLoading
            }
          >
            {updateStockLoading || createStockLoading
              ? "Saving..."
              : stockId
                ? "Update Stock"
                : "Create Stock"}
          </Button>
          &nbsp;&nbsp;
          <button
            type="button"
            className="btn btn-danger"
            disabled={createStockLoading || updateStockLoading}
            onClick={() => setCardView({ stockInfo: true })}
          >
            Close
          </button>
        </Form>
      </CardBody>
    </Card>
  );
};
