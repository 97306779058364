import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useGetProductByIdQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useGetCategoriesQuery,
  useGetMeasurementsQuery,
  useGetSuppliersQuery,
  useGetProductsQuery,
} from "slices/resourceInventory/inventorySlice";

interface ProductEditProps {
  productId: string | undefined;
  setCardView: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
}

export const ProductEdit: React.FC<ProductEditProps> = ({
  productId,
  setCardView,
}) => {
  const {
    data: productData,
    isLoading: isLoadingProduct,
    refetch,
  } = useGetProductByIdQuery(productId, { skip: !productId });
  const { refetch: refetchProducts } = useGetProductsQuery(undefined);
  const [createProduct, { isLoading: createProductLoading }] =
    useCreateProductMutation();
  const [updateProduct, { isLoading: updateProductLoading }] =
    useUpdateProductMutation();

  // Fetching related data for select options
  const { data: categories } = useGetCategoriesQuery(undefined);
  const { data: measurements } = useGetMeasurementsQuery(undefined);
  const { data: suppliers } = useGetSuppliersQuery(undefined);

  // Validation schema for the product fields
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    description: Yup.string().nullable(),
    category: Yup.number().required("Category is required"),
    measurement: Yup.number().required("Measurement is required"),
    supplier: Yup.number().required("Supplier is required"),
    supplier_address: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      category: "",
      measurement: "",
      supplier: "",
      supplier_address: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (productId) {
        // Update existing product
        updateProduct({ id: productId, ...values })
          .unwrap()
          .then(() => {
            refetchProducts();
            setCardView({ productInfo: true });
            refetch();
          })
          .catch((error) => {
            console.error("Error updating product:", error);
          });
      } else {
        // Create new product
        createProduct(values)
          .unwrap()
          .then(() => {
            refetchProducts();
            setCardView({ productInfo: true });
            refetch();
          })
          .catch((error) => {
            console.error("Error creating product:", error);
          });
      }
    },
  });

  useEffect(() => {
    if (productData) {
      formik.setValues({
        name: productData.name || "",
        description: productData.description || "",
        category: productData.category || "",
        measurement: productData.measurement || "",
        supplier: productData.supplier || "",
        supplier_address: productData.supplier_address || "",
      });
    }
  }, [productData]);

  return (
    <Card>
      <CardHeader className="d-flex">
        <h4 className="card-title flex-grow-1 mb-0">Product Edit</h4>
      </CardHeader>
      <CardBody>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="name" className="form-label">
                  Name
                </Label>
                <span className="text-danger">*</span>
                <Input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Enter name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={!!formik.errors.name && formik.touched.name}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="text-danger">{formik.errors.name}</div>
                )}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="description" className="form-label">
                  Description
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="description"
                  name="description"
                  placeholder="Enter description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    !!formik.errors.description && formik.touched.description
                  }
                />
                {formik.touched.description && formik.errors.description && (
                  <div className="text-danger">{formik.errors.description}</div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="category" className="form-label">
                  Category
                </Label>
                <span className="text-danger">*</span>
                <Input
                  type="select"
                  className="form-select"
                  id="category"
                  name="category"
                  value={formik.values.category}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={!!formik.errors.category && formik.touched.category}
                >
                  <option value="">Select category</option>
                  {categories?.results?.map((category: any) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Input>
                {formik.touched.category && formik.errors.category && (
                  <div className="text-danger">{formik.errors.category}</div>
                )}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="measurement" className="form-label">
                  Measurement
                </Label>
                <span className="text-danger">*</span>
                <Input
                  type="select"
                  className="form-select"
                  id="measurement"
                  name="measurement"
                  value={formik.values.measurement}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    !!formik.errors.measurement && formik.touched.measurement
                  }
                >
                  <option value="">Select measurement</option>
                  {measurements?.results?.map((measurement: any) => (
                    <option key={measurement.id} value={measurement.id}>
                      {measurement.name}
                    </option>
                  ))}
                </Input>
                {formik.touched.measurement && formik.errors.measurement && (
                  <div className="text-danger">{formik.errors.measurement}</div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="supplier" className="form-label">
                  Supplier
                </Label>
                <span className="text-danger">*</span>
                <Input
                  type="select"
                  className="form-select"
                  id="supplier"
                  name="supplier"
                  value={formik.values.supplier}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={!!formik.errors.supplier && formik.touched.supplier}
                >
                  <option value="">Select supplier</option>
                  {suppliers?.results?.map((supplier: any) => (
                    <option key={supplier.id} value={supplier.id}>
                      {supplier.name}
                    </option>
                  ))}
                </Input>
                {formik.touched.supplier && formik.errors.supplier && (
                  <div className="text-danger">{formik.errors.supplier}</div>
                )}
              </div>
            </Col>
            {/* <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="supplier_address" className="form-label">
                  Supplier Address
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="supplier_address"
                  name="supplier_address"
                  placeholder="Enter supplier address"
                  value={formik.values.supplier_address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    !!formik.errors.supplier_address &&
                    formik.touched.supplier_address
                  }
                />
                {formik.touched.supplier_address &&
                  formik.errors.supplier_address && (
                    <div className="text-danger">
                      {formik.errors.supplier_address}
                    </div>
                  )}
              </div>
            </Col> */}
          </Row>
          <Button
            type="submit"
            color="primary"
            disabled={
              isLoadingProduct || createProductLoading || updateProductLoading
            }
          >
            {updateProductLoading || createProductLoading
              ? "Saving..."
              : productId
                ? "Update Product"
                : "Create Product"}
          </Button>
          &nbsp;&nbsp;
          <button
            type="button"
            className="btn btn-danger"
            disabled={createProductLoading || updateProductLoading}
            onClick={() => setCardView({ productInfo: true })}
          >
            Close
          </button>
        </Form>
      </CardBody>
    </Card>
  );
};
