import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
} from "reactstrap";

import { useGetLiftingWorkPermitByNumberQuery,useUploadLiftingWorkPermitDocumentMutation,useDeleteLiftingWorkPermitDocumentMutation } from "slices/safetyApp/safetyappSlice";

import DocumentPreview from "pages/SafetyApp/CommonWizardSteps/Wizards/DocumentsPreview";
import { IsValidPermissions } from "Routes/AuthProtected";

interface TaskDocumentProps {
  getLiftingPermitById: any;
}
const DocumentsTab: React.FC<TaskDocumentProps> = ({
  getLiftingPermitById,
}) => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteDocumentId, setDeleteDocumentId] = useState<string | null>(null);
    const [documentToDeleteName, setDocumentToDeleteName] = useState<string>("");
    const hasValidPermissions = IsValidPermissions(['EDIT_LIFTING_PERMIT']); 

     const {
        data: permitData,
        isLoading: isPermitLoading,
        isFetching: isPermitFetching,
        refetch: refetchPermit,
      } = useGetLiftingWorkPermitByNumberQuery(getLiftingPermitById?.lp_permit_no);
    
      const [uploadHotworkPermitDocument, { isLoading: isUploading }] =
        useUploadLiftingWorkPermitDocumentMutation();
      const [deleteHotworkPermitDocument, { isLoading: isDeleting }] =
        useDeleteLiftingWorkPermitDocumentMutation();
    
     const [selectedFile, setSelectedFile] = useState<{
           file: File | null;
           name: string;
           comment: string;
         }>({
           file: null,
           name: "",
           comment: "",
         });
        
         const handleAcceptedFile = (file: File) => {
          setSelectedFile({
            file,
            name: file.name, // Default to original name
            comment: "", // Empty comment initially
          });
        };
    
      const handleFileUpload = async () => {
        if (!selectedFile.file) {
          console.error("No file selected");
          return;
        }
      
        const formData = new FormData();
        formData.append("attachment", selectedFile.file);
        formData.append("name", selectedFile.name);
        formData.append("comments", selectedFile.comment);
    
        try {
          await uploadHotworkPermitDocument({
            lp_permit_no: getLiftingPermitById?.lp_permit_no,
            documentData: formData,
          });
          setIsUploadModalOpen(false);
          refetchPermit();
          setSelectedFile({ file: null, name: "", comment: "" }); // Reset after upload
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      };
    
      const handleDeleteDocument = async () => {
        if (deleteDocumentId) {
          try {
            await deleteHotworkPermitDocument({ id: deleteDocumentId });
            await refetchPermit();
            setIsDeleteModalOpen(false);
          } catch (error) {
            console.error("Error deleting document:", error);
          }
        }
      };

  return (

    <React.Fragment>
    <Card>
       <CardBody>
      <DocumentPreview
        permitData={permitData}
        handleFileUpload={handleFileUpload}
        handleDeleteDocument={handleDeleteDocument}
        handleAcceptedFiles={handleAcceptedFile}
        setDeleteDocumentId={setDeleteDocumentId}
        selectedFiles={selectedFile}
        setSelectedFiles={setSelectedFile}
        setIsUploadModalOpen={setIsUploadModalOpen}
        setDocumentToDeleteName={setDocumentToDeleteName}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        documentToDeleteName={documentToDeleteName}
        isPermitLoading={isPermitLoading}
        isUploadModalOpen={isUploadModalOpen}
        isDeleteModalOpen={isDeleteModalOpen}
        isUploading={isUploading}
        isDeleting={isDeleting}
        isPermitFetching={isPermitFetching}
        hasValidPermissions={hasValidPermissions}
      />
      </CardBody>
    </Card>
  </React.Fragment>
    
  );
};

export default DocumentsTab;
