import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import PositionsTab from "./Settings/positions";
import WorkLocationTab from "./Settings/worklocation";
import CountryOfIssueTab from "./Settings/countryofissue";
import PrimaryCompanyTab from "./Settings/primarycompany";
import SubContractCompanyTab from "./Settings/subcontractcompany";

interface ProjectProps {
  projectData: any;
}

interface OptionType {
  value: string;
  label: string;
  id: string;
  name: string;
  project_name: string;
  employee_fullname: string;
}

const AppSettings: React.FC<ProjectProps> = ({ projectData }) => {
  const [verticalTab, setVerticalTab] = useState("primarycompany");

  const toggleVertical = (tab: any) => {
    if (verticalTab !== tab) {
      setVerticalTab(tab);
    }
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <Col md={3}>
              <Nav pills className="flex-column" id="v-pills-tab">
                <NavItem key={1}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalTab === "primarycompany",
                    })}
                    onClick={() => toggleVertical("primarycompany")}
                    id={`v-pills-${"primarycompany".toLowerCase()}-tab`}
                  >
                    Company Profile
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav pills className="flex-column" id="v-pills-tab">
                <NavItem key={1}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalTab === "sub_contract_company",
                    })}
                    onClick={() => toggleVertical("sub_contract_company")}
                    id={`v-pills-${"sub_contract_company".toLowerCase()}-tab`}
                  >
                    Sub-Contract Company
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav pills className="flex-column" id="v-pills-tab">
                <NavItem key={1}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalTab === "positions",
                    })}
                    onClick={() => toggleVertical("positions")}
                    id={`v-pills-${"positions".toLowerCase()}-tab`}
                  >
                    Positions
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav pills className="flex-column" id="v-pills-tab">
                <NavItem key={1}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalTab === "Work Location",
                    })}
                    onClick={() => toggleVertical("Work Location")}
                    id={`v-pills-${"Work Location".toLowerCase()}-tab`}
                  >
                    Work Location
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav pills className="flex-column" id="v-pills-tab">
                <NavItem key={1}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalTab === "Country Of Issue",
                    })}
                    onClick={() => toggleVertical("Country Of Issue")}
                    id={`v-pills-${"Country Of Issue".toLowerCase()}-tab`}
                  >
                    Country
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>

            <Col md={9}>
              <TabContent
                activeTab={verticalTab}
                className="text-muted mt-4 mt-md-0"
                id="v-pills-tabContent"
              >
                <TabPane tabId="primarycompany">
                  <PrimaryCompanyTab />
                </TabPane>
              </TabContent>
              <TabContent
                activeTab={verticalTab}
                className="text-muted mt-4 mt-md-0"
                id="v-pills-tabContent"
              >
                {/* Default "ALL" Tab Content */}
                <TabPane tabId="sub_contract_company">
                  <SubContractCompanyTab />
                </TabPane>
              </TabContent>
              <TabContent
                activeTab={verticalTab}
                className="text-muted mt-4 mt-md-0"
                id="v-pills-tabContent"
              >
                {/* Default "ALL" Tab Content */}
                <TabPane tabId="positions">
                  <PositionsTab />
                </TabPane>
              </TabContent>
              <TabContent
                activeTab={verticalTab}
                className="text-muted mt-4 mt-md-0"
                id="v-pills-tabContent"
              >
                <TabPane tabId="Work Location">
                  <WorkLocationTab />
                </TabPane>
              </TabContent>
              <TabContent
                activeTab={verticalTab}
                className="text-muted mt-4 mt-md-0"
                id="v-pills-tabContent"
              >
                <TabPane tabId="Country Of Issue">
                  <CountryOfIssueTab />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default AppSettings;
