import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import * as url from "../../../helpers/url_helper";

export const loginSlice = createApi({
  reducerPath: "login",
  baseQuery: fetchBaseQuery({
    baseUrl: window.__RUNTIME_CONFIG__.REACT_APP_API_URL,
  }),
  endpoints: (builder) => ({
    postLogin: builder.mutation({
      query: (loginData) => {
        return {
          url: `${url.POST_JWT_LOGIN}`,
          method: "POST",
          body: loginData,
        };
      },
    }),
  }),
});

export const { usePostLoginMutation } = loginSlice;
