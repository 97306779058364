import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  Spinner,
  Badge,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useGetSubContractCompanyQuery,
  useCreateSubContractCompanyMutation,
  useUpdateSubContractCompanyMutation,
  useGetSubContractCompanyByIdQuery,
  useGetPrimaryCompanyQuery,
} from "slices/pageAccess/PageAccessSlice";
import { UncontrolledTooltip } from "reactstrap";

const GENERIC_STATUS_CHOICES = [
  { value: 1, label: "Active", color: "success" },
  { value: 2, label: "Inactive", color: "warning" },
  { value: 3, label: "Disabled", color: "danger" },
];

const validationSchema = Yup.object({
  subcompany_name: Yup.string().required("Company Name is required"),
  subcompany_short_name: Yup.string().required("Short Name is required"),
  contact_address: Yup.string().optional(),
  contact_mail: Yup.string().email("Invalid email format").optional(),
  contact_manager: Yup.string().optional(),
  contact_phone_number: Yup.string().optional(),
  company: Yup.string().required("Primary Company is required"),
});

const SubContractCompanyTab: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(
    null,
  );
  const [searchTerm, setSearchTerm] = useState("");

  const {
    data: companyData,
    refetch,
    isLoading,
  } = useGetSubContractCompanyQuery(undefined);
  const { data: primaryCompanies } = useGetPrimaryCompanyQuery(undefined);
  const [createCompany] = useCreateSubContractCompanyMutation();
  const [updateCompany] = useUpdateSubContractCompanyMutation();
  const { data: companyDetails } = useGetSubContractCompanyByIdQuery(
    selectedCompanyId,
    {
      skip: !selectedCompanyId,
    },
  );

  const formik = useFormik({
    initialValues: {
      status: 1,
      subcompany_name: "",
      subcompany_short_name: "",
      contact_address: "",
      contact_mail: "",
      contact_manager: "",
      contact_phone_number: "",
      company: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        if (selectedCompanyId) {
          await updateCompany({
            id: selectedCompanyId,
            updateData: values,
          }).unwrap();
        } else {
          await createCompany({ newSubContractCompany: values }).unwrap();
        }
        refetch();
        resetForm();
        toggleModal();
      } catch (error) {
        console.error("Error saving company:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (companyDetails) {
      formik.setValues(companyDetails);
    }
  }, [companyDetails]);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    if (!modalOpen) formik.resetForm();
  };

  const handleAddCompany = () => {
    setSelectedCompanyId(null);
    formik.resetForm();
    toggleModal();
  };

  const handleEditCompany = (companyId: number) => {
    setSelectedCompanyId(companyId);
    toggleModal();
  };

  const filteredCompanies =
    companyData?.filter((c: any) =>
      c.subcompany_name?.toLowerCase()?.includes(searchTerm.toLowerCase()),
    ) || [];

  return (
    <Card>
      <CardBody>
        <div className="d-flex align-items-center mb-4">
          <h5 className="card-title flex-grow-1 mb-0">
            Sub-Contract Companies
          </h5>
          <Input
            type="text"
            placeholder="Search company..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="me-2"
            style={{ width: "300px" }}
          />
          <Button color="primary" onClick={handleAddCompany}>
            Add Sub-Contract
          </Button>
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <Table striped>
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Display Name</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredCompanies.length > 0 ? (
                filteredCompanies.map((c: any) => (
                  <tr key={c.id}>
                    <td>{c.subcompany_name}</td>
                    <td>{c.subcompany_short_name}</td>
                    <td>
                      <Badge
                        color={
                          GENERIC_STATUS_CHOICES.find(
                            (s) => s.value === c.status,
                          )?.color || "secondary"
                        }
                      >
                        {GENERIC_STATUS_CHOICES.find(
                          (s) => s.value === c.status,
                        )?.label || "Unknown"}
                      </Badge>
                    </td>
                    <td>
                      <Button
                        color="warning"
                        size="sm"
                        onClick={() => handleEditCompany(c.id!)}
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="text-center">
                    No Companies Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}

        {/* Modal for Add/Edit SubContract Company */}
        <Modal isOpen={modalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            {selectedCompanyId
              ? "Edit Subcontract Company"
              : "Add Subcontract Company"}
          </ModalHeader>
          <ModalBody>
            <form onSubmit={formik.handleSubmit}>
              <FormGroup>
                <Label for="subcompany_name">
                  Company Name <span className="text-danger">*</span>
                </Label>
                <Input
                  id="subcompany_name"
                  name="subcompany_name"
                  value={formik.values.subcompany_name}
                  onChange={formik.handleChange}
                />
                {formik.touched.subcompany_name &&
                  formik.errors.subcompany_name && (
                    <div className="text-danger">
                      {formik.errors.subcompany_name}
                    </div>
                  )}
              </FormGroup>

              <FormGroup>
                <Label for="subcompany_short_name">
                  Display Name <span className="text-danger">*</span>{" "}
                  <>
                    <i
                      id="displayNameTooltip"
                      className="ri-information-fill p-2 ms-1 text-secondary"
                      style={{ cursor: "pointer" }}
                    ></i>
                    <UncontrolledTooltip
                      placement="top"
                      target="displayNameTooltip"
                      innerClassName="custom-tooltip"
                    >
                      Display Name is used to show in the dropdown for all
                      Permits and Reports.
                    </UncontrolledTooltip>
                  </>
                </Label>
                <Input
                  id="subcompany_short_name"
                  name="subcompany_short_name"
                  value={formik.values.subcompany_short_name}
                  onChange={formik.handleChange}
                />
                {formik.touched.subcompany_short_name &&
                  formik.errors.subcompany_short_name && (
                    <div className="text-danger">
                      {formik.errors.subcompany_short_name}
                    </div>
                  )}
              </FormGroup>

              <FormGroup>
                <Label for="contact_address">Address</Label>
                <Input
                  id="contact_address"
                  name="contact_address"
                  value={formik.values.contact_address}
                  onChange={formik.handleChange}
                />
                {formik.touched.contact_address &&
                  formik.errors.contact_address && (
                    <div className="text-danger">
                      {formik.errors.contact_address}
                    </div>
                  )}
              </FormGroup>

              <FormGroup>
                <Label for="contact_mail">Email</Label>
                <Input
                  id="contact_mail"
                  name="contact_mail"
                  type="email"
                  value={formik.values.contact_mail}
                  onChange={formik.handleChange}
                />
                {formik.touched.contact_mail && formik.errors.contact_mail && (
                  <div className="text-danger">
                    {formik.errors.contact_mail}
                  </div>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="contact_manager">Contact Manager</Label>
                <Input
                  id="contact_manager"
                  name="contact_manager"
                  value={formik.values.contact_manager}
                  onChange={formik.handleChange}
                />
                {formik.touched.contact_manager &&
                  formik.errors.contact_manager && (
                    <div className="text-danger">
                      {formik.errors.contact_manager}
                    </div>
                  )}
              </FormGroup>

              <FormGroup>
                <Label for="contact_phone_number">Phone Number</Label>
                <Input
                  id="contact_phone_number"
                  name="contact_phone_number"
                  value={formik.values.contact_phone_number}
                  onChange={formik.handleChange}
                />
                {formik.touched.contact_phone_number &&
                  formik.errors.contact_phone_number && (
                    <div className="text-danger">
                      {formik.errors.contact_phone_number}
                    </div>
                  )}
              </FormGroup>

              <FormGroup>
                <Label for="company">
                  Primary Company <span className="text-danger">*</span>
                </Label>
                <Input
                  type="select"
                  id="company"
                  value={formik.values.company}
                  onChange={formik.handleChange}
                >
                  <option value="">Select a Company</option>
                  {primaryCompanies?.map((comp: any) => (
                    <option key={comp.id} value={comp.id}>
                      {comp.company_name}
                    </option>
                  ))}
                </Input>
                {formik.touched.company && formik.errors.company && (
                  <div className="text-danger">{formik.errors.company}</div>
                )}
              </FormGroup>

              <ModalFooter>
                <Button
                  color="primary"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? "Saving..." : "Save"}
                </Button>
                <Button
                  color="secondary"
                  disabled={formik.isSubmitting}
                  onClick={toggleModal}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </ModalBody>
        </Modal>
      </CardBody>
    </Card>
  );
};

export default SubContractCompanyTab;
