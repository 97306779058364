import React, { useMemo, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import {
  useGetMeasurementsQuery,
  useGetMeasurementByIdQuery,
} from "slices/resourceInventory/inventorySlice";
import { toast, ToastContainer } from "react-toastify";
import { MeasurementEdit } from "./MeasurementsEdit";
import { Link } from "react-router-dom";

const MeasurementsListView = () => {
  const [editingMeasurementId, setEditingMeasurementId] = useState<
    string | undefined
  >(undefined);
  const [isAdding, setIsAdding] = useState(false);
  const [viewingMeasurementId, setViewingMeasurementId] = useState<
    string | null
  >(null); // State for viewing modal

  // Fetch measurement data
  const { data: measurementData, isLoading: isLoadingMeasurements } =
    useGetMeasurementsQuery(undefined);

  // Fetch single measurement data for viewing
  const { data: selectedMeasurement, isLoading: isLoadingSelectedMeasurement } =
    useGetMeasurementByIdQuery(
      viewingMeasurementId ?? "",
      { skip: !viewingMeasurementId }, // Skip the query if no measurement is selected
    );

  // Handle Add and Edit actions
  const handleAddMeasurement = () => {
    setEditingMeasurementId(undefined);
    setIsAdding(true);
  };

  const handleEditMeasurement = (id: string) => {
    setEditingMeasurementId(id);
    setIsAdding(false);
  };

  // Handle View action
  const handleViewMeasurement = (id: string) => {
    setViewingMeasurementId(id); // Set the selected measurement ID to trigger the modal
  };

  // Handle closing the modal
  const handleCloseModal = () => {
    setViewingMeasurementId(null); // Clear the selected measurement ID and close the modal
  };

  // Handle returning to list view
  const handleBackToList = () => {
    setEditingMeasurementId(undefined);
    setIsAdding(false);
  };

  // Define table columns
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
      },
      {
        Header: "Unit",
        accessor: "unit",
        filterable: false,
      },
      {
        Header: "Unit Value",
        accessor: "unit_value",
        filterable: false,
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: false,
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }: any) => {
          const measurementId = row.original.id;
          return (
            <div>
              <ul className="list-inline hstack gap-2 mb-0">
                <li className="list-inline-item view" title="View">
                  <Link
                    to="#"
                    className="text-primary d-inline-block view-item-btn"
                    onClick={() => handleViewMeasurement(measurementId)}
                  >
                    <i className="ri-eye-fill fs-16"></i>
                  </Link>
                </li>
                <li className="list-inline-item edit" title="Edit">
                  <Link
                    to="#"
                    className="text-primary d-inline-block edit-item-btn"
                    onClick={() => handleEditMeasurement(measurementId)}
                  >
                    <i className="ri-pencil-fill fs-16"></i>
                  </Link>
                </li>
              </ul>
            </div>
          );
        },
      },
    ],
    [],
  );

  document.title = "Measurements | Dashboard";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Measurements List" pageTitle="Measurements" />
          {(editingMeasurementId || isAdding) && (
            <Row className="mt-4">
              <Col lg={12}>
                <MeasurementEdit
                  measurementId={editingMeasurementId}
                  setCardView={handleBackToList}
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                  <h5 className="card-title mb-0">Measurements List</h5>
                  <Button
                    color="success"
                    onClick={handleAddMeasurement}
                    disabled={isLoadingMeasurements}
                  >
                    <i className="ri-add-line align-bottom me-1"></i> Add
                    Measurement
                  </Button>
                </CardHeader>
                <div className="card-body pt-0">
                  {isLoadingMeasurements ? (
                    <Loader />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={measurementData?.results || []}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={10}
                      className="custom-header-css"
                      // isCustomerFilter={true}
                      SearchPlaceholder={"Search for measurements..."}
                      isPagination={true}
                    />
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* View Modal */}
      <Modal isOpen={!!viewingMeasurementId} toggle={handleCloseModal}>
        <ModalHeader toggle={handleCloseModal}>Measurement Details</ModalHeader>
        <ModalBody>
          {isLoadingSelectedMeasurement ? (
            <Loader />
          ) : selectedMeasurement ? (
            <div className="table-card">
              <table className="table mb-0">
                <tbody>
                  <tr>
                    <td className="fw-medium">Name</td>
                    <td>{selectedMeasurement?.name}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Unit</td>
                    <td>{selectedMeasurement?.unit}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Unit Value</td>
                    <td> {selectedMeasurement?.unit_value}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Description</td>
                    <td>{selectedMeasurement?.description}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <p>No details available.</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <ToastContainer />
    </React.Fragment>
  );
};

export default MeasurementsListView;
