import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Spinner,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetToolboxMeetingByIdQuery,
  useUpdateToolboxAttendanceMutation,
  useUpdateToolboxMeetingMutation,
} from "slices/safetyApp/safetyappSlice";
import { useGetEmployeeDropDownByProjectQuery } from "slices/employee/employeeSlice";
import { useDeleteToolboxAttendanceMutation } from "slices/safetyApp/safetyappSlice";
import AddMultipleEmployeeModal from "../../../CommonWizardSteps/SteperModals/AddMultipleEmployeeModal";

interface Step2PreviewSubmitProps {
  previousStep: () => void;
  location: { latitude: number | null; longitude: number | null };
}

const Step2ToolBoxAttendance: React.FC<Step2PreviewSubmitProps> = ({
  previousStep,
  location,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number | null>(
    null,
  );
  const [addedEmployees, setAddedEmployees] = useState<any[]>([]);
  const [comments, setComments] = useState<string>("");

  const { selectedProject, toolBoxId } = useParams<{
    selectedProject: string;
    toolBoxId: string;
  }>();
  const navigate = useNavigate();

  const {
    data: toolboxData,
    isLoading: isToolboxLoading,
    refetch,
  } = useGetToolboxMeetingByIdQuery(toolBoxId);

  // const { data: employeeData } = useGetEmployeeDropDownQuery();
  const [
    updateToolboxAttendance,
    { isLoading: updateToolboxAttendanceLoading },
  ] = useUpdateToolboxAttendanceMutation();
  const [updateToolboxMeeting, { isLoading: isSubmitting }] =
    useUpdateToolboxMeetingMutation();
  const [deleteToolboxMeeting, { isLoading: isdeleting }] =
    useDeleteToolboxAttendanceMutation();

  // Toggle Modal
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  useEffect(() => {
    setComments(toolboxData?.toolbox_comments);
  }, [toolboxData?.toolbox_comments]);

  // const employeeOptions = employeeData?.map((employee) => ({
  //   value: employee?.id,
  //   label: employee?.employee_fullname,
  // }));

  const handleAddEmployee = async (employeeList: any) => {
    try {
      const response = await updateToolboxAttendance({
        toolbox_id: toolBoxId,
        attendanceData: {
          employee_list: employeeList,
        },
      }).unwrap();
      refetch();
      // setAddedEmployees((prev) => [...prev, response]);
      setSelectedEmployeeId(null);
      toggleModal();
    } catch (error) {
      console.error("Error adding employee:", error);
    }
  };

  const handleDeleteEmployee = async (employeeId: number) => {
    try {
      await deleteToolboxMeeting({
        id: employeeId,
      }).unwrap();
      refetch();
    } catch (error) {
      console.error("Error deleting employee:", error);
    }
  };

  const handleSubmit = async () => {
    if (location.latitude === null || location.longitude === null) {
      alert("Unable to fetch your location. Please try again or Reload Page.");
      return;
    }

    try {
      await updateToolboxMeeting({
        toolbox_id: toolBoxId,
        updatedMeetingData: {
          report_status: 3,
          toolbox_comments: comments,
          ...location,
        },
      }).unwrap();
      setSubmitted(true);
    } catch (error) {
      console.error("Failed to submit", error);
    }
  };

  if (submitted) {
    return (
      <Card className="text-center">
        <CardBody>
          <h3>Toolbox Meeting Submitted Successfully!</h3>
          <p>ToolBox No: {toolBoxId}</p>
          <Button
            color="primary"
            onClick={() =>
              navigate(`/toolbox-meeting-detail-view/${toolBoxId}`)
            }
          >
            Toolbox Meeting List
          </Button>
        </CardBody>
      </Card>
    );
  }

  return (
    <div>
      <Row>
        <Col md={12}>
          {isToolboxLoading ? (
            <Spinner color="primary" />
          ) : (
            <div>
              <h5>Toolbox Meeting Details</h5>
              <hr />
              <Table className="table table-borderless table-nowrap">
                <tbody>
                  <tr>
                    <td className="fw-medium">Toolbox ID:</td>
                    <td>{toolboxData?.toolbox_id}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Contractor:</td>
                    <td>{toolboxData?.contractor}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Conducted By:</td>
                    <td>{toolboxData?.conducted_by_name}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Project:</td>
                    <td>{toolboxData?.project_name}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Work Description:</td>
                    <td>{toolboxData?.work_description}</td>
                  </tr>
                </tbody>
              </Table>

              <hr className="mt-4" />
              <Col md={12} className="mt-2">
                <FormGroup>
                  <Label for="comments">
                    Item Discussed
                    {/* <span className="text-danger">*</span> */}
                  </Label>
                  <Input
                    id="comments"
                    name="comments"
                    type="textarea"
                    onChange={(e) => setComments(e.target.value)}
                    value={comments}
                    // invalid={!!formik.errors.work_description}
                  />
                  {/* {formik.errors.work_description && (
                <div className="text-danger">{formik.errors.work_description}</div>
              )} */}
                </FormGroup>
              </Col>

              <h5 className="mt-4">Attendance</h5>
              <hr />
              <Button
                color="success"
                onClick={toggleModal}
                className="mb-3 float-end"
              >
                Add Employee
              </Button>

              <Table className="table-borderless align-middle mb-0">
                <thead className="table-light">
                  <tr>
                    <th scope="col">Employee Name</th>
                    <th scope="col">Employee Number</th>
                    <th scope="col">Position</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {toolboxData?.toolbox_attendance?.length > 0 ? (
                    toolboxData?.toolbox_attendance?.map((attendance: any) => (
                      <tr key={attendance.id}>
                        <td>{attendance.employee?.employee_fullname}</td>
                        <td>{attendance.employee?.employee_number}</td>
                        <td>{attendance.employee?.position_name}</td>
                        <td>
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() => handleDeleteEmployee(attendance?.id)}
                          >
                            {isdeleting ? <Spinner size="sm" /> : "Delete"}
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4} className="text-center">
                        No employees added yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <Button className="mt-4" color="secondary" onClick={previousStep}>
                Previous
              </Button>
              <Button
                color="primary"
                onClick={handleSubmit}
                disabled={
                  isSubmitting || toolboxData?.toolbox_attendance?.length === 0
                }
                className="mt-4 float-end"
              >
                {isSubmitting ? <Spinner size="sm" /> : "Submit"}
              </Button>
            </div>
          )}
        </Col>
      </Row>

      <AddMultipleEmployeeModal
        isModalOpen={isModalOpen}
        handleModalToggle={toggleModal}
        projectId={selectedProject}
        submitLoading={updateToolboxAttendanceLoading}
        handleSubmit={handleAddEmployee}
      />
    </div>
  );
};

export default Step2ToolBoxAttendance;
