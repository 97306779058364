import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Container, Row, Col, Card, CardHeader } from "reactstrap";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { formatDateTime } from "helpers/date_utils";
// Import Breadcrumb
import BreadCrumb from "../../../Components/Common/BreadCrumb";

import { useGetEmployeesQuery } from "slices/employee/employeeSlice";

// Redux
import TableContainer from "../../../Components/Common/TableContainer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IsValidPermissions } from "../../../Routes/AuthProtected";

// Export Modal
import ImportExcelModal from "../../../Components/Common/ImportExcelModal";
import OnboardEmployeeModal from "../modal/onboardEmployee";
import Loader from "../../../Components/Common/Loader";

const EmployeeListView = () => {
  const [page, setPageState] = useState<{
    page: number,
    pageSize: number,
    search: string,
    status_name: string,
    start_date: string,
    due_date: string,
    projects:string,
    position:string,
    sub_company_name:string,
  }>({
    page: 1,
    pageSize: 20,
    search: '',
    status_name: '',
    start_date: '',
    due_date: '',
    projects:'',
    position:'',
    sub_company_name:'',
  });

  const {
    data: employees,
    isLoading: employeeLoading,
    isFetching: employeeFetching,
    refetch: refetchemployees,
    error: employeeError,
  } = useGetEmployeesQuery(page);

  const navigate = useNavigate();

  const [modal, setModal] = useState<boolean>(false);
  const [employeeData, setEmployeeData] = useState<any>([]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // Check if create_task is true in the URL search params
    if (searchParams.get("create_members") === "true") {
      setModal(true);
      // Update the URL to set create_task to false
      searchParams.set("create_members", "false");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  }, [modal]);

  useEffect(() => {
    if (employees) {
      setEmployeeData(employees);
    }
  }, [employees]);

  // const toggle = useCallback(() => {
  //   setModal((prevModal) => !prevModal);
  // }, [modal]);

  // Add Customer
  const handleCreateMemberClicks = () => {
    toggle();
  };

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall: any = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element: any) => {
      console.log("value", element.value);
      // dispatch(onDeleteContact(element.value));
      // setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall: any = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele: any) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele: any) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const deleteCheckbox = () => {
    const ele: any = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  // Customers Column
  const columns = useMemo(
    () => [
      // {
      //   Header: (
      //     <input
      //       type="checkbox"
      //       className="form-check-input"
      //       id="checkBoxAll"
      //       onClick={() => checkedAll()}
      //     />
      //   ),
      //   Cell: (cellProps: any) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         className="contactCheckBox form-check-input"
      //         value={cellProps.row.original.id}
      //         onChange={() => deleteCheckbox()}
      //       />
      //     );
      //   },
      //   id: "#",
      // },
      {
        Header: "Employee Name",
        accessor: "employee_fullname",
        filterable: false,
        Cell: (contact: any) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {
                  contact.row.original?.employee_profile_image_url ? (
                    <img
                      src={contact.row.original?.employee_profile_image_url}
                      // process.env.REACT_APP_API_URL + "/images/users/" +
                      alt=""
                      className="avatar-xs rounded-circle"
                    />
                  ) : (
                    <div className="flex-shrink-0 avatar-xs me-2">
                      <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                        {contact.row.original.employee_fullname.charAt(0)}
                      </div>
                    </div>
                  )
                  // <img src={dummyImg} alt="" className="avatar-xxs rounded-circle" />
                }
              </div>
              <div className="flex-grow-1 ms-2 name">
                <Link to={`/profile/${contact.row.original.employee_uuid}`}>
                  {contact.row.original.employee_fullname}
                </Link>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "#Number",
        accessor: "employee_number",
        filterable: false,
      },
      // {
      //   Header: "User Name",
      //   accessor: "username",
      //   filterable: false,
      // },
      {
        Header: "Position",
        accessor: "position_name",
        Cell: (cell: any) => (
          <>
            <span className="badge bg-primary-subtle text-primary me-1">
              {cell.row.original?.position_name}
            </span>
          </>
        ),
      },
      {
        Header: "Assigned Project",
        accessor: "project_name",
        Cell: (cell: any) =>
          cell.row.original?.project_list.map((n: any) => {
            return (
              <span className="badge bg-secondary-subtle text-primary me-1">
                {n.project_name}
              </span>
            );
          }),
      },
      {
        Header: "Start Date",
        accessor: "employee_start_date",
        filterable: false,
        Cell: (cell: any) => (
          <>{formatDateTime(cell.row.original?.employee_start_date, "date")}</>
        ),
      },
      {
        Header: "Work Phone",
        accessor: "work_phone",
        filterable: false,
      },
      {
        Header: "Active",
        accessor: "active",
        filterable: false,
        Cell: (cell: { value: any }) =>
          cell.value ? (
            <span className="badge text-uppercase bg-success-subtle text-success">
              {" "}
              Yes{" "}
            </span>
          ) : (
            <span className="badge text-uppercase bg-danger-subtle text-danger">
              {" "}
              No{" "}
            </span>
          ),
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }: any) => {
          const handleView = () => {
            navigate(`/profile/${row.original.employee_uuid}`);
          };

          const handleEdit = () => {
            // Your edit logic here
            console.log("Edit:", row.original);
          };

          const handleDelete = () => {
            // Your delete logic here
            console.log("Delete:", row.original);
          };

          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="View">
                <Link
                  to={`/profile/${row?.original?.employee_uuid}`}
                  className="text-primary d-inline-block edit-item-btn"
                  // onClick={handleView}
                >
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li>
              {/* <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    handleCustomerClick(customerData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li> */}
              {/* <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    onClickDelete(customerData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li> */}
            </ul>
          );
        },
      },
    ],
    [],
  );

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState<boolean>(false);
  const [isImportExcel, setIsImportExcel] = useState<boolean>(false);

  document.title = "Members List View";
  return (
    <React.Fragment>
      <div className="page-content">
        {/* <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={employeeData}
          columns={columns}
          name={"Members"}
        /> */}
        <ImportExcelModal
          show={isImportExcel}
          onCloseClick={() => setIsImportExcel(false)}
          import_type={"members_import"}
          refetch={refetchemployees}
        />
        {/* <DeleteModal
          show={modal}
          onDeleteClick={handleCreateMemberClicks}
          onCloseClick={() => setModal(false)}
        /> */}
        <Container fluid>
          <BreadCrumb title="Members List" pageTitle="Members" />
          <Row>
            <Col lg={12}>
              <Card id="customerList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Members List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        {IsValidPermissions(["VIEW_ALL_MEMBERS"]) && (
                          <>
                            {IsValidPermissions(["ADD_MEMBERS"]) && (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-success add-btn me-1"
                                  id="create-btn"
                                  onClick={handleCreateMemberClicks}
                                  disabled={employeeLoading}
                                >
                                  <i className="ri-add-line align-bottom me-1"></i>{" "}
                                  New Member
                                </button>{" "}
                              </>
                            )}
                            <button
                              type="button"
                              className="btn btn-secondary me-1"
                              onClick={() => setIsImportExcel(true)}
                              disabled={employeeLoading}
                            >
                              <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                              Import
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => setIsExportCSV(true)}
                              disabled={
                                employeeLoading || employeeData?.length === 0
                              }
                            >
                              <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                              Export
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  {!employeeLoading ? (
                    <TableContainer
                      columns={columns}
                      data={employeeData?.results || []}
                      isLoading={employeeFetching}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={page.pageSize}
                      className="custom-header-css"
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                      theadClass="table-light text-muted"
                      thClass="table-light text-muted"
                      SearchPlaceholder="Search for Members..."
                      isPagination={false}
                      isServerPagination={true}
                      isEmployeeFilter={true}
                      serverPaginationProps={{
                        count: employeeData?.count || 0,
                        next: employeeData?.next || null,
                        page_size: employeeData?.page_size || 10,
                        previous: employeeData?.previous || null,
                        current_page: employeeData?.current_page || 1,
                        total_page: employeeData?.total_page || 0,
                      }}
                      setPageState={setPageState}
                    />
                  ) : (
                    <Loader error={employeeError} />
                  )}

                  <OnboardEmployeeModal modal={modal} toggle={toggle} />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default EmployeeListView;
