import * as Yup from "yup";
import React, { useState, useEffect, useMemo } from "react";
import {
  Row,
  Col,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
  Spinner,
  Button,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import Flatpickr from "react-flatpickr";
import {
  useGetPositionsQuery,
  useGetWorkLocationsQuery,
  useGetEmployeeByUsernameExistMutation,
} from "slices/employee/employeeSlice";
import { useFormik } from "formik";
import { useCreateEmployeeMutation } from "slices/employee/employeeSlice";
import { useGetSubContractCompanyQuery } from "slices/pageAccess/PageAccessSlice";
import { toast } from "react-toastify";
import Select from "react-select";
import { useGetRolesQuery } from "slices/workflow/WorkFlowSlice";
import sgFlag from "../../../assets/images/flags/sg.svg";
import { country } from "../../../common/data";
import { useGetProjectsByDropDownQuery } from "slices/projects/projectSlice";

const titles = [
  { label: "Mr.", value: 1 },
  { label: "Ms.", value: 2 },
  { label: "Mrs.", value: 3 },
];

interface OptionType {
  value: string;
  label: string;
  id: string;
  name: string;
  project_name: string;
  employee_fullname: string;
}

interface OnboardEmployeeModalProps {
  modal: any;
  toggle: any;
}

const OnboardEmployeeModal: React.FC<OnboardEmployeeModalProps> = ({
  modal,
  toggle,
}) => {
  const { data: employeeRole, refetch: refetchEmployeeRole } =
    useGetPositionsQuery(undefined);

  const { data: employeeLocation, refetch: refetchLocation } =
    useGetWorkLocationsQuery(undefined);

  const [step, setCurrentStep] = useState<number>(1);
  const [usernameExists, setUsernameExists] = useState(false);
  const { data: subCompanyData } = useGetSubContractCompanyQuery(undefined);

  const { data: rolesData } = useGetRolesQuery(undefined);

  // const [selectedRoles, setSelectedRoles] = useState<any>([]);
  const [isExternal, setIsExternal] = useState(false);

  const [createEmployee, { isLoading: postEmployeeLoading }] =
    useCreateEmployeeMutation();

  const [checkUsername, { isLoading: isLoadingUserExist }] =
    useGetEmployeeByUsernameExistMutation();

  const { data: projectsData } = useGetProjectsByDropDownQuery(undefined);

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const [seletedCountry, setseletedCountry] = useState({
    id: 240,
    flagImg: sgFlag,
    countryName: "Singapore",
    countryCode: "+65",
    length: 8,
  });

  useEffect(() => {
    if (toggle) {
      validation.resetForm();
      refetchEmployeeRole();
      refetchLocation();
    }
  }, [toggle]);

  const onCancel = () => {
    validation.resetForm();
    toggle();
    setCurrentStep(1);
  };

  const roleOptions = useMemo(() => {
    return (
      rolesData?.map((role: any) => ({
        value: role.id,
        label: role.name,
      })) || []
    );
  }, [rolesData]);

  const projectOptions = useMemo(() => {
    return (
      projectsData?.map((project: any) => ({
        value: project.id,
        label: `${project.project_name} (${project.project_short_name})`,
      })) || []
    );
  }, [projectsData]);

  const subContractCompanyOptions = subCompanyData
    ?.filter((com: any) => com.status === 1) // Filter companies with status 1
    .map((com: any) => ({
      value: com.id.toString(),
      label: com.subcompany_name,
    }));

  const validationSchemas: Record<number, Yup.ObjectSchema<any>> = {
    1: Yup.object().shape({
      title: Yup.string()
        .required("Please select a title")
        .notOneOf([""], "Please select a title"),
      first_name: Yup.string().required("Please enter the first name"),
      middle_name: Yup.string().nullable(),
      last_name: Yup.string().required("Please enter the last name"),
      work_phone: Yup.string()
        .min(
          seletedCountry.length,
          `Phone number must be ${seletedCountry.length} digits`,
        )
        .max(
          seletedCountry.length,
          `Phone number must be ${seletedCountry.length} digits`,
        )
        .matches(/^\d+$/, "Phone phone must be digits only")
        .nullable(),
      work_location: Yup.string().nullable(),
      employee_start_date: Yup.mixed().required("Please select the start date"),
      position: Yup.string()
        .required("Please select the position")
        .min(1)
        .notOneOf([""], "Please select the position"),
      is_external_employee: Yup.boolean(),
      sub_company: Yup.number()
        .nullable()
        .when("is_external_employee", {
          is: true,
          then: (schema) => schema.required("External Company is required"),
          otherwise: (schema) => schema.optional(),
        }),
      external_company: Yup.string().when(
        "is_external_employee",
        (isExternalEmployee, schema) => {
          console.log("isExternalEmployee", isExternalEmployee);
          return isExternal
            ? schema.required("External Company is required")
            : schema.optional();
        },
      ),
      projects: Yup.array()
        .of(
          Yup.object().shape({
            value: Yup.string().required("Project value is required"),
            label: Yup.string().required("Project label is required"),
          }),
        )
        .min(1, "Please assign at least one project")
        .required("Assigning a project is required"),
    }),
    2: Yup.object({
      username: Yup.string().required("Please enter a username"),
      password: Yup.string()
        .required("Please enter a password")
        .min(8, "Password must be at least 8 characters")
        .matches(/\d/, "Password must contain at least one number"),
      assign_role: Yup.array()
        .of(
          Yup.object().shape({
            value: Yup.string().required("Role value is required"),
            label: Yup.string().required("Role label is required"),
          }),
        )
        .min(1, "Please assign at least one role")
        .required("Assigning a role is required"),
    }),
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      work_phone: "",
      work_email: "",
      employee_number: "",
      work_location: "",
      employee_start_date: null,
      position: "",
      username: "",
      password: "",
      assign_role: [],
      projects: [],
      is_external_employee: false,
      sub_company: "",
    },
    validationSchema: validationSchemas[step as keyof typeof validationSchemas],
    onSubmit: async (values) => {
      if (step === 1) {
        setCurrentStep(2);
      } else {
        try {
          // Use await directly here instead of chaining with .then()
          const n: any = await checkUsername(values.username);
          if (!n.data) {
            setUsernameExists(true);
          } else {
            values = {
              ...values,
              work_phone: values.work_phone
                ? `${seletedCountry.countryCode} ${values.work_phone}`
                : "",
              //@ts-ignore
              assign_role: values.assign_role.map((n: any) => n.value),
              //@ts-ignore
              projects: values.projects.map((n: any) => n.value),
            };
            // Use unwrap for handling promises with RTK Query
            await createEmployee(values).unwrap();
            toast.success("Employee added successfully!");
            validation.resetForm();
            setUsernameExists(false);
            setCurrentStep(1);
            toggle();
          }
        } catch (error) {
          toast.error("Error creating employee");
          validation.resetForm();
          setUsernameExists(false);
          setCurrentStep(1);
          toggle();
        }
      }
    },
  });

  const handleSingleSelectChange = (
    name: string,
    selectedOption: OptionType | null,
  ): void => {
    validation.setFieldValue(
      name,
      selectedOption ? Number(selectedOption.value) : null,
    );
  };

  return (
    <Modal
      id="showModal"
      backdrop="static"
      size="lg"
      isOpen={modal}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3">Onboard New Member</ModalHeader>
      <Form onSubmit={validation.handleSubmit} autoComplete="off">
        <ModalBody>
          {step === 1 && (
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label for="title">Title</Label>
                  <span className="text-danger">*</span>
                  <select
                    className={`form-select ${validation.touched.title && validation.errors.title ? "is-invalid" : ""}`}
                    name="title"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.title || ""}
                  >
                    <option value="">Select</option>
                    {titles.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {validation.touched.title && validation.errors.title ? (
                    <FormFeedback type="invalid">
                      {typeof validation.errors.title === "string"
                        ? validation.errors.title
                        : null}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label for="middle_name">Middle Name</Label>
                  <Input
                    name="middle_name"
                    type="text"
                    className="form-control"
                    id="middle_name"
                    autoComplete="off"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.middle_name || ""}
                    invalid={
                      validation.touched.middle_name &&
                      validation.errors.middle_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.middle_name &&
                  validation.errors.middle_name ? (
                    <FormFeedback type="invalid">
                      {typeof validation.errors.middle_name === "string"
                        ? validation.errors.middle_name
                        : null}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label for="work_phone">Work Phone</Label>

                  <div>
                    <Dropdown
                      className="input-group"
                      isOpen={dropdownOpen}
                      toggle={toggleDropdown}
                    >
                      <DropdownToggle
                        as="button"
                        className="btn btn-light border arrow-none"
                      >
                        <img
                          src={seletedCountry.flagImg}
                          alt="country flag"
                          className="options-flagimg"
                          height="20"
                        />
                        <span className="countrylist-codeno text-muted">
                          {seletedCountry.countryCode}
                        </span>
                      </DropdownToggle>
                      <Input
                        name="work_phone"
                        type="text"
                        placeholder="Enter number"
                        className="form-control rounded-end flag-input"
                        id="work_phone"
                        autoComplete="off"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.work_phone || ""}
                        invalid={
                          validation.touched.work_phone &&
                          validation.errors.work_phone
                            ? true
                            : false
                        }
                      />
                      {validation.touched.work_phone &&
                      validation.errors.work_phone ? (
                        <FormFeedback type="invalid">
                          {typeof validation.errors.work_phone === "string"
                            ? validation.errors.work_phone
                            : null}
                        </FormFeedback>
                      ) : null}
                      <DropdownMenu
                        as="ul"
                        className="list-unstyled w-100 dropdown-menu-list mb-0"
                      >
                        <SimpleBar
                          style={{ maxHeight: "220px" }}
                          className="px-3"
                        >
                          {(country || []).map((item, key) => (
                            <DropdownItem
                              as="li"
                              onClick={() => setseletedCountry(item)}
                              key={key}
                              className="dropdown-item d-flex"
                            >
                              <div className="flex-shrink-0 me-2">
                                <img
                                  src={item.flagImg}
                                  alt="country flag"
                                  className="options-flagimg"
                                  height="20"
                                />
                              </div>
                              <div className="flex-grow-1">
                                <div className="d-flex">
                                  <div className="country-name me-1">
                                    {item.countryName}
                                  </div>
                                  <span className="countrylist-codeno text-muted">
                                    {item.countryCode}
                                  </span>
                                </div>
                              </div>
                            </DropdownItem>
                          ))}
                        </SimpleBar>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
                <div className="mb-3">
                  <Label for="work_location">Work Location</Label>
                  <select
                    className="form-select"
                    name="work_location"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.work_location || ""}
                  >
                    <option value="">Select</option>
                    {employeeLocation?.map((options: any) => (
                      <option key={options.id} value={options.id}>
                        {options.location_name}
                      </option>
                    ))}
                  </select>
                  {validation.touched.work_location &&
                  validation.errors.work_location ? (
                    <FormFeedback type="invalid">
                      {typeof validation.errors.work_location === "string"
                        ? validation.errors.work_location
                        : null}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label for="password">Projects</Label>
                  <span className="text-danger">*</span>
                  <Select
                    isMulti
                    className={`${validation.touched.projects && validation.errors.projects ? "is-invalid" : ""}`}
                    options={projectOptions}
                    value={validation.values.projects}
                    onChange={(selectedProjects: any) =>
                      validation.setFieldValue("projects", selectedProjects)
                    }
                    onBlur={() => validation.setFieldTouched("projects", true)}
                  />
                  {validation.touched.projects && validation.errors.projects ? (
                    <FormFeedback type="invalid">
                      {typeof validation.errors.projects === "string"
                        ? validation.errors.projects
                        : null}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label for="first_name">First Name</Label>
                  <span className="text-danger">*</span>
                  <Input
                    name="first_name"
                    type="text"
                    className="form-control"
                    id="first_name"
                    autoComplete="off"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.first_name || ""}
                    invalid={
                      validation.touched.first_name &&
                      validation.errors.first_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.first_name &&
                  validation.errors.first_name ? (
                    <FormFeedback type="invalid">
                      {typeof validation.errors.first_name === "string"
                        ? validation.errors.first_name
                        : null}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label for="last_name">Last Name</Label>
                  <span className="text-danger">*</span>
                  <Input
                    name="last_name"
                    type="text"
                    className="form-control"
                    id="last_name"
                    autoComplete="off"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.last_name || ""}
                    invalid={
                      validation.touched.last_name &&
                      validation.errors.last_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.last_name &&
                  validation.errors.last_name ? (
                    <FormFeedback type="invalid">
                      {typeof validation.errors.last_name === "string"
                        ? validation.errors.last_name
                        : null}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label for="employee_start_date">Employment Start Date</Label>
                  <span className="text-danger">*</span>
                  <Flatpickr
                    className={`form-control ${validation.touched.employee_start_date && validation.errors.employee_start_date ? "is-invalid" : ""}`}
                    name="employee_start_date"
                    id="employee_start_date"
                    placeholder="yyyy-mm-dd"
                    onChange={(selectedDates: Date[]) => {
                      if (selectedDates[0] instanceof Date) {
                        const formattedDate = selectedDates[0]
                          .toISOString()
                          .split("T")[0];
                        validation.setFieldValue(
                          "employee_start_date",
                          formattedDate,
                        );
                      }
                    }}
                    // onBlur={validation.handleBlur}
                    value={validation.values.employee_start_date || ""}
                    options={{
                      dateFormat: "Y-m-d",
                    }}
                  />
                  {validation.touched.employee_start_date &&
                  validation.errors.employee_start_date ? (
                    <FormFeedback type="invalid">
                      {validation.errors.employee_start_date}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label for="position">Position</Label>
                  <span className="text-danger">*</span>
                  <select
                    className={`form-select ${validation.touched.position && validation.errors.position ? "is-invalid" : ""}`}
                    name="position"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.position || ""}
                  >
                    <option value="">Select</option>
                    {employeeRole?.map((options: any) => (
                      <option key={options.id} value={options.id}>
                        {options.position_name}
                      </option>
                    ))}
                  </select>
                  {validation.touched.position && validation.errors.position ? (
                    <FormFeedback type="invalid">
                      {validation.errors.position}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label htmlFor="skills" className="form-label">
                    Is External Employee
                  </Label>
                  {validation.values.is_external_employee && (
                    <span className="text-danger">*</span>
                  )}
                  <div className="input-group">
                    <div className="input-group-text">
                      <Input
                        className="form-check-input mt-0"
                        type="checkbox"
                        name="is_external_employee"
                        checked={validation.values.is_external_employee}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          validation.setFieldValue(
                            "is_external_employee",
                            isChecked,
                          );
                          if (!isChecked) {
                            validation.setFieldValue("sub_company", null); // Reset sub_company if unchecked
                          }
                        }}
                      />
                    </div>

                    <Select
                      className={`${validation.touched.sub_company && validation.errors.sub_company ? "is-invalid" : ""} form-control p-0`}
                      name="sub_company"
                      options={subContractCompanyOptions}
                      isDisabled={!validation.values.is_external_employee} // Disable when checkbox is false
                      value={
                        validation.values.sub_company
                          ? subContractCompanyOptions?.find(
                              (option: any) =>
                                option?.value ===
                                validation?.values?.sub_company?.toString(),
                            ) || null
                          : null
                      }
                      onChange={(option: OptionType | null) =>
                        handleSingleSelectChange("sub_company", option)
                      }
                    />
                  </div>
                </div>
              </Col>
            </Row>
          )}

          {step === 2 && (
            <>
              <h4 className="text-center text-muted">Create Member Account</h4>
              <br />
              <Container fluid>
                <Row style={{ padding: "0 100px 0 100px" }}>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label for="username">Username</Label>
                      <span className="text-danger">*</span>
                      <Input
                        name="username"
                        type="text"
                        className="form-control"
                        id="username"
                        autoComplete="new-password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.username || ""}
                        invalid={
                          usernameExists ||
                          (validation.touched.username &&
                            !!validation.errors.username)
                        }
                      />
                      {usernameExists ? (
                        <FormFeedback type="invalid">
                          Username already exists
                        </FormFeedback>
                      ) : validation.touched.username &&
                        validation.errors.username ? (
                        <FormFeedback type="invalid">
                          {validation.errors.username}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label for="password">Password</Label>
                      <span className="text-danger">*</span>
                      <Input
                        name="password"
                        type="password"
                        className="form-control"
                        id="password"
                        autoComplete="new-password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ""}
                        invalid={
                          validation.touched.password &&
                          validation.errors.password
                            ? true
                            : false
                        }
                      />
                      {validation.touched.password &&
                      validation.errors.password ? (
                        <FormFeedback type="invalid">
                          {typeof validation.errors.password === "string"
                            ? validation.errors.password
                            : null}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label for="password">Members Login Role</Label>
                      <span className="text-danger">*</span>
                      <Select
                        isMulti
                        className={`${validation.touched.assign_role && validation.errors.assign_role ? "is-invalid" : ""}`}
                        options={roleOptions}
                        value={validation.values.assign_role}
                        onChange={(selectedRoles: any) =>
                          validation.setFieldValue("assign_role", selectedRoles)
                        }
                        onBlur={() =>
                          validation.setFieldTouched("assign_role", true)
                        }
                      />
                      {validation.touched.assign_role &&
                      validation.errors.assign_role ? (
                        <FormFeedback type="invalid">
                          {typeof validation.errors.assign_role === "string"
                            ? validation.errors.assign_role
                            : null}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </Container>
            </>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          {step === 1 && (
            <>
              <div></div>
            </>
          )}
          {step === 2 && (
            <>
              <Button
                color="secondary"
                disabled={postEmployeeLoading}
                onClick={() => setCurrentStep(1)}
              >
                Previous
              </Button>
            </>
          )}

          <div>
            <Button
              type="submit"
              color="primary"
              disabled={postEmployeeLoading}
            >
              {postEmployeeLoading || isLoadingUserExist ? (
                <span className="d-flex align-items-spacebetween">
                  <Spinner size="sm" className="flex-shrink-0" />
                  {postEmployeeLoading && (
                    <span className="flex-grow-1 ms-2">Loading...</span>
                  )}
                  {isLoadingUserExist && (
                    <span className="flex-grow-1 ms-2">Validating...</span>
                  )}
                </span>
              ) : (
                <> {step === 1 ? "Next" : "Submit"}</>
              )}
            </Button>
            &nbsp;
            <Button
              onClick={onCancel}
              color="danger"
              disabled={postEmployeeLoading}
            >
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default OnboardEmployeeModal;
