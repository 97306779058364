import { createApi } from "@reduxjs/toolkit/query/react";
import { PriorityType, TaskType, TaskTypeAll } from "./type";
import { customBaseQuery } from "helpers/jwt-token-access/customBaseQuery";

const convertToFormData = (obj: any) => {
  const formData = new FormData();
  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      obj[key].forEach((item: any) => formData.append(key, item));
    } else {
      formData.append(key, obj[key]);
    }
  });
  return formData;
};

export const taskSlice = createApi({
  reducerPath: "task",
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    createTask: builder.mutation({
      query: (newTask) => {
        const formData = convertToFormData(newTask);
        return {
          url: "/task/api/v1/task/",
          method: "POST",
          body: formData,
        };
      },
    }),
    updateTask: builder.mutation({
      query: ({ id, newTask }: { id: number; newTask: any }) => {
        const formData = convertToFormData(newTask);
        return {
          url: `/task/api/v1/task/${id}/`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
    getAllTasks: builder.query<
      TaskTypeAll,
      {
        page: number;
        pageSize: number;
        search: string;
        status_name: string;
        start_date: string;
        due_date: string;
      }
    >({
      query: (page) => {
        return `/task/api/v1/task/?page=${page.page}&page_size=${page.pageSize}&search=${page.search}&status_name=${page.status_name}&start_date=${page.start_date}&due_date=${page.due_date}`;
      },
    }),
    getTasksById: builder.query<TaskType, string | undefined>({
      query: (id) => `/task/api/v1/task/${id}/`,
    }),
    getAllPriority: builder.query<PriorityType[], void>({
      query: () => "/task/api/v1/task_priority/",
    }),
    getAllTaskStatus: builder.query({
      query: () => "/task/api/v1/task_status/",
    }),
    getTaskSummary: builder.query({
      query: () => "/task/api/v1/task_summary/",
    }),
    getTaskAttachmentById: builder.query<any[], string | undefined>({
      query: (id) => `/task/api/v1/task_attachment/${id}/`,
    }),

    getAllTaskAttachments: builder.query<any[], string | undefined>({
      query: (id) => `/task/api/v1/task_attachment/?task=${id}`,
    }),

    createTaskAttachment: builder.mutation({
      query: (newAttachment) => {
        const formData = convertToFormData(newAttachment);
        return {
          url: "/task/api/v1/task_attachment/",
          method: "POST",
          body: formData,
        };
      },
    }),

    deleteTaskAttachmentById: builder.mutation({
      query: (id: string) => ({
        url: `/task/api/v1/task_attachment/${id}/`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useGetAllTasksQuery,
  useGetTasksByIdQuery,
  useGetAllPriorityQuery,
  useGetAllTaskStatusQuery,
  useGetTaskSummaryQuery,
  useGetTaskAttachmentByIdQuery,
  useGetAllTaskAttachmentsQuery,
  useCreateTaskAttachmentMutation,
  useDeleteTaskAttachmentByIdMutation,
} = taskSlice;
