import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { useCreateSectionMutation } from "slices/resourceInventory/inventorySlice";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

interface AddSectionModalProps {
  isOpen: boolean;
  toggle: () => void;
  projectId: string | undefined;
  refetchInventory: () => void;
}

const AddSectionModal: React.FC<AddSectionModalProps> = ({
  isOpen,
  toggle,
  projectId,
  refetchInventory,
}) => {
  const [createSection, { isLoading: sectionLoading }] =
    useCreateSectionMutation();

  const validationSchema = Yup.object({
    sectionName: Yup.string().required("Section name is required"),
  });

  const initialValues = {
    sectionName: "",
  };

  const handleSubmit = async (
    values: { sectionName: string },
    { resetForm }: { resetForm: () => void },
  ) => {
    try {
      await createSection({
        section_name: values.sectionName,
        project: projectId,
      });
      toggle();
      refetchInventory();
      resetForm();
    } catch (error) {
      console.error("Error creating section:", error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add Section</ModalHeader>
      <hr />
      <ModalBody>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleChange, handleBlur, values, handleSubmit }) => (
            <FormikForm onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="sectionName">Section Name</Label>
                <Input
                  type="text"
                  id="sectionName"
                  name="sectionName"
                  placeholder="Enter section name"
                  value={values.sectionName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="sectionName"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <ModalFooter>
                <Button color="primary" type="submit" disabled={sectionLoading}>
                  {sectionLoading ? (
                    <>
                      <Spinner size="sm" />
                      &nbsp;Saving...
                    </>
                  ) : (
                    "Save Changes"
                  )}
                </Button>
                <Button
                  color="secondary"
                  onClick={toggle}
                  disabled={sectionLoading}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </FormikForm>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default AddSectionModal;
