import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import {
  useLazyGetToolboxMeetingByFilterQuery,
  useLinkToolboxMeetingMutation,
  useGetWorkAtHeightPermitByNumberQuery,
} from "slices/safetyApp/safetyappSlice";
import Step4ToolBoxMeeting from "../../../CommonWizardSteps/Wizards/Step4ToolBoxMeeting";

interface Step4ToolBoxMeetingIndexProps {
  previousStep: () => void;
  nextStep: () => void;
}

const Step4ToolBoxMeetingIndex: React.FC<Step4ToolBoxMeetingIndexProps> = ({
  previousStep,
  nextStep,
}) => {
  const { selectedProject: urlProjectId, hwPermitNo } = useParams<{
    selectedProject: string;
    hwPermitNo: string;
  }>();

  const {
    data: permitData,
    isLoading: isPermitLoading,
    isFetching: isPermitFetching,
    refetch: refetchPermit,
  } = useGetWorkAtHeightPermitByNumberQuery(hwPermitNo);

  const [
    triggerToolboxMeetingQuery,
    { data: toolboxMeetings, isLoading: toolboxLoading },
  ] = useLazyGetToolboxMeetingByFilterQuery();

  const [linkToolboxMeeting, { isLoading: patchisLoading }] =
    useLinkToolboxMeetingMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedToolbox, setSelectedToolbox] = useState<{
    value: string;
    label: string;
  } | null>(null);

  useEffect(() => {
    if (
      urlProjectId &&
      permitData?.start_date_of_work &&
      permitData?.end_date_of_work
    ) {
      triggerToolboxMeetingQuery({
        project_id: urlProjectId,
        start_date: permitData?.start_date_of_work,
        end_date: permitData?.end_date_of_work,
      });
    }
  }, [
    urlProjectId,
    permitData?.start_date_of_work,
    permitData?.end_date_of_work,
  ]);

  const handleOnSubmit = async () => {
    if (!hwPermitNo || !selectedToolbox) return;

    const payload = {
      action_type: "PATCH",
      permit_type: "WORK_AT_HEIGHT_PERMIT",
      permit_no: hwPermitNo,
    };

    try {
      await linkToolboxMeeting({
        toolbox_id: selectedToolbox.value,
        linkData: payload,
      });

      refetchPermit();
      setIsModalOpen(false);
      setSelectedToolbox(null);
    } catch (error) {
      console.error("Error linking toolbox meeting:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      selectedToolbox: "",
    },
    validationSchema: Yup.object({
      selectedToolbox: Yup.string().required("Please select a Toolbox Meeting"),
    }),
    onSubmit: handleOnSubmit,
  });

  const handleSelectChange = (selected: any) => {
    setSelectedToolbox(selected);
    formik.setFieldValue("selectedToolbox", selected?.value);
  };

  const handleDelete = async (toolboxId: string) => {
    if (!hwPermitNo) return;

    const payload = {
      action_type: "DELETE",
      permit_no: hwPermitNo,
      permit_type: "WORK_AT_HEIGHT_PERMIT",
    };

    try {
      await linkToolboxMeeting({
        toolbox_id: toolboxId,
        linkData: payload,
      });

      refetchPermit();
    } catch (error) {
      console.error("Error deleting toolbox meeting:", error);
    }
  };

  return (
    <Step4ToolBoxMeeting
      nextStep={nextStep}
      previousStep={previousStep}
      formik={formik}
      handleSelectChange={handleSelectChange}
      handleDelete={handleDelete}
      refetchPermit={refetchPermit}
      permitData={permitData}
      toolboxMeetings={toolboxMeetings}
      setIsModalOpen={setIsModalOpen}
      isModalOpen={isModalOpen}
      selectedToolbox={selectedToolbox}
      patchisLoading={patchisLoading}
      toolboxLoading={toolboxLoading}
      isPermitLoading={isPermitLoading}
      isPermitFetching={isPermitFetching}
    />
  );
};

export default Step4ToolBoxMeetingIndex;
