import React from "react";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import ActiveProjects from "./ActiveProjects";
import EmployeeWidgets from "./EmployeeWidgets";
import ActiveReportSummary from "./ActiveReportSummary";
import ActiveToolboxSummary from "./ActiveToolboxSummary";

const EmployeeDashboard = () => {
  document.title = "Admin Dashboard";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Projects" pageTitle="Dashboards" />
          <Row className="project-wrapper">
            <Col xxl={8}>
              <EmployeeWidgets />
            </Col>
          </Row>
          <Row>
            <ActiveReportSummary />
            <ActiveToolboxSummary />

            <ActiveProjects />
            {/* <TeamMembers /> */}
          </Row>
          {/* <Row>
                        <TeamMembers />
                        <PermitDashboard />
                        <Chat />
                         <MyTasks />
                        <ProjectsStatus />
                    </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmployeeDashboard;
