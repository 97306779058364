import React, { useState, useCallback, useEffect } from "react";
import { Container, Col, Row, Spinner } from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import Summary from "./Section";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useGetExcavationPermitByNumberQuery } from "slices/safetyApp/safetyappSlice";

const ExcavationPermitDetails = () => {
  document.title = "ExcavationWork Permit View";
  const [task, setTask] = useState<any>([]);
  //Dropzone file upload
  const { hwPermitNo: hwPermitNoFromURL } = useParams<{ hwPermitNo: string }>();
  const [modal, setModal] = useState<boolean>(false);

  const {
    data: getExcavationPermitById,
    isLoading,
    refetch: refetchExcavationworkPermit,
  } = useGetExcavationPermitByNumberQuery(hwPermitNoFromURL);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setTask(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  useEffect(() => {
    refetchExcavationworkPermit();
  }, []);

  if (isLoading) {
    return (
      <React.Fragment>
        <Container fluid>
          <div className="page-content" style={{ height: "80vh" }}>
            {" "}
            {/* Full viewport height */}
            <Container fluid className="h-100">
              <Row className="h-100 d-flex justify-content-center align-items-center">
                <Col lg={12} className="text-center">
                  <Spinner color="primary"></Spinner> <br /> <br />
                  <span className="text-muted">
                    Fetching Excavation Permit Information...
                  </span>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Container fluid>
        <BreadCrumb
          title="Excavation Permit Details"
          pageTitle="Tasks"
          style={{ "margin-top": "70px" }}
        />

        <Summary
          getExcavationPermitById={getExcavationPermitById}
          setTask={setTask}
          toggle={toggle}
        />
        <ToastContainer closeButton={false} limit={1} />
      </Container>
    </React.Fragment>
  );
};

export default ExcavationPermitDetails;
