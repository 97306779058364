import React, { useState } from "react";
import {
    Col,
    Row,
    Button,
    Card,
    CardImg,
    CardBody,
    CardTitle,
    Spinner,
} from "reactstrap";
import FileUploadModal from "../SteperModals/FileUploadModal";
import DeleteConfirmationModal from "../SteperModals/DeleteConfirmationModal";
import ImageLightbox from "Components/Common/ImageLightbox";

const imageExtensions = ["jpeg", "jpg", "png"];

const fileIconMap: { [key: string]: string } = {
    pdf: "ri-file-pdf-line",
    doc: "ri-file-word-line",
    docx: "ri-file-word-line",
    zip: "ri-folder-zip-line",
    rar: "ri-folder-zip-line",
    default: "ri-file-line",
};

interface Step5FileUploadProps {
    permitData: any;
    handleFileUpload: any;
    handleDeleteDocument: any;
    handleAcceptedFiles: any;
    setIsUploadModalOpen: any;
    setDeleteDocumentId: any;
    setDocumentToDeleteName: any;
    setIsDeleteModalOpen: any;
    documentToDeleteName: any;
    isPermitLoading: boolean;
    isUploadModalOpen: boolean;
    isUploading: boolean;
    isDeleteModalOpen: boolean;
    isDeleting: boolean;
    isPermitFetching: boolean;
    hasValidPermissions: boolean;
    selectedFiles: { file: File | null; name: string; comment: string };
    setSelectedFiles: (files: { file: File | null; name: string; comment: string }) => void;
}

const DocumentPreview: React.FC<Step5FileUploadProps> = ({
    permitData,
    handleFileUpload,
    handleDeleteDocument,
    handleAcceptedFiles,
    setDeleteDocumentId,
    setIsUploadModalOpen,
    setDocumentToDeleteName,
    setIsDeleteModalOpen,
    documentToDeleteName,
    isPermitLoading,
    isUploadModalOpen,
    isDeleteModalOpen,
    isUploading,
    isDeleting,
    isPermitFetching,
    selectedFiles,
    setSelectedFiles,
    hasValidPermissions,
}) => {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handleDeleteClick = (documentId: string, documentName: string) => {
        setDeleteDocumentId(documentId);
        setDocumentToDeleteName(documentName);
        setIsDeleteModalOpen(true);
    };

    const getFileTypeIconOrImage = (documentUrl: string) => {
        const fileName = documentUrl.split("/").pop()?.split("?")[0] || "";
        const fileExtension = fileName.split(".").pop()?.toLowerCase() || "default";

        if (imageExtensions.includes(fileExtension)) {
            return (
                <CardImg
                    top
                    width="100%"
                    height="150px"
                    src={documentUrl}
                    alt={fileName}
                    style={{
                        objectFit: "cover",
                        borderRadius: "5px",
                    }}
                />
            );
        } else {
            const iconClass = fileIconMap[fileExtension] || fileIconMap["default"];
            return (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "150px", fontSize: "2rem" }}
                >
                    <i className={`${iconClass}`} />
                </div>
            );
        }
    };

    const truncateFileName = (fileName: string, maxLength = 20) => {
        if (fileName.length > maxLength) {
            return fileName.substring(0, maxLength) + "...";
        }
        return fileName;
    };

    const extractDocumentName = (url: string) => {
        const parts = url.split("/");
        return parts[parts.length - 1];
    };

    const imageDocuments = permitData?.documents?.filter((doc: any) =>
        doc.attachment?.match(/\.(jpeg|jpg|png|gif)$/i),
    );

    const slides = imageDocuments?.map((doc: any) => ({
        src: doc.attachment_url,
        title: extractDocumentName(doc.attachment),
    }))

    const handleDownload = (documentUrl: string, fileName: string) => {
        // Create an anchor element
        const link = document.createElement("a");

        // Ensure the link points to the correct file URL
        link.href = documentUrl;

        // Set the download attribute to force download
        link.setAttribute("download", fileName);

        // Append the link to the body, click it, and remove it after triggering the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div>
            {/* Upload Button */}
            <Row>
                <div className="d-flex" style={{ justifyContent: "space-between" }}>
                    <h5>Documents</h5>
                    {hasValidPermissions &&
                        <Button
                            color="primary"
                            className="d-flex mb-1"
                            onClick={() => setIsUploadModalOpen(true)}
                        >
                            {isUploading ? <Spinner size="sm" /> : "Upload Files"}
                        </Button>
                    }
                </div>
                <hr />
            </Row>

            {/* Preview Section */}
            <Row className="mt-4">
                {isPermitLoading ? (
                    <Spinner color="primary" />
                ) : permitData?.documents?.length > 0 ? (
                    permitData.documents.map((document: any, index: number) => {
                        const documentName = extractDocumentName(document?.attachment);
                        const isImage = document.attachment.match(
                            /\.(jpeg|jpg|png|gif)$/i,
                        );

                        return (
                            <Col key={index} md={3} className="mb-4">
                                <Card
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        if (isImage) {
                                            const imgIndex = imageDocuments.findIndex(
                                                (imgDoc: any) =>
                                                    imgDoc.attachment_url ===
                                                    document.attachment_url,
                                            );
                                            setCurrentImageIndex(imgIndex);
                                            setLightboxOpen(true);
                                        }
                                    }}

                                >
                                    {getFileTypeIconOrImage(document.attachment_url)}

                                    <CardBody className="text-center">
                                        <CardTitle tag="h6" className="mb-3">
                                            {truncateFileName(extractDocumentName(document.attachment))}
                                        </CardTitle>
                                        <CardBody className="text-center">
                                            <Row>
                                                <Col md={6}>
                                                    <h6>File Name:</h6>
                                                </Col>
                                                <Col md={6}>
                                                    <p>{document?.name}</p>
                                                </Col>
                                                {document?.comments &&
                                                    <>
                                                        <Col md={6}>
                                                            <h6>Comments:</h6>
                                                        </Col>
                                                        <Col md={6}>
                                                            <p>{document?.comments}</p>
                                                        </Col>
                                                    </>
                                                }
                                                <Col md={12}>
                                                    {hasValidPermissions &&
                                                        <Button
                                                            color="danger"
                                                            size="sm"
                                                            onClick={() =>
                                                                handleDeleteClick(
                                                                    document.id,
                                                                    extractDocumentName(document.attachment),
                                                                )
                                                            }
                                                            disabled={isDeleting}
                                                        >
                                                            {isDeleting ? (
                                                                <Spinner size="sm" />
                                                            ) : (
                                                                <>
                                                                    <i className="ri-delete-bin-5-line align-middle "></i>
                                                                </>
                                                            )}
                                                        </Button>
                                                    }
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })
                ) : (
                    <Col md={12}>
                        <div className="d-flex align-items-center justify-content-center">
                            <h4>No files available for preview</h4>
                        </div>
                    </Col>
                )}
                <ImageLightbox
                    open={lightboxOpen}
                    slides={slides}
                    currentIndex={currentImageIndex}
                    onClose={() => setLightboxOpen(false)}
                />
            </Row>

            {/* Modals */}
            <FileUploadModal
                isOpen={isUploadModalOpen}
                toggle={() => setIsUploadModalOpen(!isUploadModalOpen)}
                onFileUpload={handleFileUpload}
                // onFileDrop={handleAcceptedFiles}
                selectedFiles={selectedFiles}
                isUploading={isUploading}
                isPermitFetching={isPermitFetching}
                setSelectedFiles={setSelectedFiles}
            />

            <DeleteConfirmationModal
                isOpen={isDeleteModalOpen}
                toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                onDelete={handleDeleteDocument}
                documentName={documentToDeleteName}
                isDeleting={isDeleting}
                isPermitFetching={isPermitFetching}
            />
        </div>
    );
};

export default DocumentPreview;
