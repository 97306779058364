import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  Spinner,
} from "reactstrap";
import {
  useGetPositionQuery,
  useCreatePositionMutation,
  useUpdatePositionMutation,
  useGetPositionByIdQuery,
} from "slices/pageAccess/PageAccessSlice";

interface PositionData {
  id?: number;
  position_name: string;
  position_code: string;
  description: string;
}

const PositionsTab: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedPositionId, setSelectedPositionId] = useState<number | null>(
    null,
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [position, setPosition] = useState<PositionData>({
    position_name: "",
    position_code: "",
    description: "",
  });

  const {
    data: positionData,
    refetch,
    isLoading,
  } = useGetPositionQuery(undefined);
  const [createPosition] = useCreatePositionMutation();
  const [updatePosition] = useUpdatePositionMutation();
  const { data: positionDetails } = useGetPositionByIdQuery(
    selectedPositionId,
    {
      skip: !selectedPositionId, // Skip fetching if no ID is provided
    },
  );

  useEffect(() => {
    if (positionDetails) {
      setPosition(positionDetails);
    }
  }, [positionDetails]);

  const toggleModal = () => setModalOpen(!modalOpen);

  const handleAddPosition = () => {
    setSelectedPositionId(null);
    setPosition({ position_name: "", position_code: "", description: "" });
    toggleModal();
  };

  const handleEditPosition = (positionId: number) => {
    setSelectedPositionId(positionId);
    toggleModal();
  };

  const handleSavePosition = async () => {
    setLoading(true);
    try {
      if (position.id) {
        // let position_id = position.id;
        const id = position.id;
        const updateData = position;
        await updatePosition({ id, updateData }).unwrap();
      } else {
        await createPosition({ newPosition: position }).unwrap();
      }
      refetch();
      toggleModal();
    } catch (error) {
      console.error("Error saving position:", error);
    } finally {
      setLoading(false);
    }
  };

  const filteredPositions =
    positionData?.filter((p: PositionData) =>
      p.position_name?.toLowerCase()?.includes(searchTerm.toLowerCase()),
    ) || [];

  return (
    <Card>
      <CardBody>
        <div className="d-flex align-items-center mb-4">
          <h5 className="card-title flex-grow-1 mb-0">Positions</h5>
          <Input
            type="text"
            placeholder="Search position..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="me-2"
            style={{ width: "300px" }}
          />
          <Button color="primary" onClick={handleAddPosition}>
            Add Position
          </Button>
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <Table striped>
            <thead>
              <tr>
                <th>#</th>
                <th>Position Name</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredPositions.length > 0 ? (
                filteredPositions.map((p: PositionData, index: number) => (
                  <tr key={p.id}>
                    <td>{index + 1}</td>
                    <td>{p.position_name}</td>
                    <td>{p.description}</td>
                    <td>
                      <Button
                        color="warning"
                        size="sm"
                        onClick={() => handleEditPosition(p.id!)}
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className="text-center">
                    No Positions Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
        <PositionModal
          isOpen={modalOpen}
          toggle={toggleModal}
          onSave={handleSavePosition}
          position={position}
          setPosition={setPosition}
          loading={loading}
        />
      </CardBody>
    </Card>
  );
};

interface PositionModalProps {
  isOpen: boolean;
  toggle: () => void;
  onSave: () => void;
  position: PositionData;
  setPosition: React.Dispatch<React.SetStateAction<PositionData>>;
  loading: boolean;
}

const PositionModal: React.FC<PositionModalProps> = ({
  isOpen,
  toggle,
  onSave,
  position,
  setPosition,
  loading,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPosition({ ...position, [e.target.name]: e.target.value });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {position.id ? "Update" : "Create"} Position
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <FormGroup>
              <Label for="position_name">
                Position Name <span className="text-danger">*</span>
              </Label>
              <Input
                id="position_name"
                name="position_name"
                value={position.position_name}
                onChange={handleInputChange}
                disabled={loading}
              />
            </FormGroup>
            <FormGroup>
              <Label for="description">Description</Label>
              <Input
                id="description"
                name="description"
                type="textarea"
                value={position.description}
                onChange={handleInputChange}
                disabled={loading}
              />
            </FormGroup>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={onSave}
          disabled={loading || !position.position_name}
        >
          {loading ? <Spinner size="sm" /> : "Save"}
        </Button>
        <Button color="secondary" onClick={toggle} disabled={loading}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PositionsTab;
