import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Container,
} from "reactstrap";
import classnames from "classnames";
import OverviewTab from "./OverviewTab";
import DocumentsTab from "./DocumentsTab";
import { STATUS_COLORS } from "helpers/common_helper";
import { Tooltip } from "react-tooltip";
import { BadgeStatus } from "helpers/badgeStatus";

interface IncidentReportProps {
  toolboxMeetingByIdData: any;
  refetchTooboxById: any;
  isFetching: any;
}

const Summary: React.FC<IncidentReportProps> = ({
  toolboxMeetingByIdData,
  refetchTooboxById,
  isFetching,
}) => {
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".edit-task-class"
        place="bottom"
        content="Update Permit"
      />
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".close-report-class"
        place="bottom"
        content="Close Report"
      />
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".upadte-task-status-class"
        place="bottom"
        content="Update Task Status"
      />
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".export-task-status-class"
        place="bottom"
        content="Export Permit"
      />

      <div className="page-content pt-3">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card className="mt-n4 mx-n4">
                <div>
                  <CardBody className="pb-0 px-4">
                    <Row className="mb-3">
                      <div className="col-md">
                        <Row className="align-items-center g-3">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h4 className="fw-bold">
                                {" "}
                                Accident Report Information
                              </h4>
                            </div>
                          </div>
                          <hr />
                          <div className="col-md">
                            <div className="d-flex justify-content-between">
                              <div>
                                <h4 className="fw-bold">
                                  Incident ID :{" "}
                                  <span className="text-muted">
                                    ({toolboxMeetingByIdData?.incident_no})
                                  </span>
                                </h4>
                              </div>

                              <div className="hstack gap-3 flex-wrap">
                                {toolboxMeetingByIdData?.status_name ? (
                                  <>
                                    <div className="vr"></div>
                                    <div>
                                      Report Status :{" "}
                                      <BadgeStatus
                                        value={
                                          toolboxMeetingByIdData?.status_name
                                        }
                                        color={
                                          STATUS_COLORS[
                                            toolboxMeetingByIdData?.status_name
                                          ]
                                        }
                                      />
                                    </div>
                                  </>
                                ) : null}
                                <div className="vr"></div>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </Row>

                    <Nav
                      className="nav-tabs-custom border-bottom-0"
                      role="tablist"
                      style={{ background: "lavender" }}
                    >
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "1" },
                            "fw-semibold",
                          )}
                          onClick={() => {
                            toggleTab("1");
                          }}
                          href="#"
                        >
                          Overview
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "2" },
                            "fw-semibold",
                          )}
                          onClick={() => {
                            toggleTab("2");
                          }}
                          href="#"
                        >
                          Documents
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardBody>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <TabContent activeTab={activeTab} className="text-muted">
                <TabPane tabId="1">
                  <OverviewTab
                    toolboxMeetingByIdData={toolboxMeetingByIdData}
                  />
                </TabPane>
                <TabPane tabId="2">
                  <DocumentsTab
                    getToolboxMeetingById={toolboxMeetingByIdData.incident_no}
                  />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Summary;
