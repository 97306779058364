import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  Spinner,
} from "reactstrap";
import {
  useGetCountryOfIssueQuery,
  useGetCountryOfIssueByIdQuery,
  useCreateCountryOfIssueMutation,
  useUpdateCountryOfIssueMutation,
} from "slices/pageAccess/PageAccessSlice";

interface CountryOfIssueData {
  id?: number;
  country_of_issue: string;
  description: string | null;
}

const CountryOfIssueTab: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(
    null,
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [CountryOfIssue, setCountryOfIssue] = useState<CountryOfIssueData>({
    country_of_issue: "",
    description: "",
  });

  const {
    data: CountryOfIssueData,
    refetch,
    isLoading,
  } = useGetCountryOfIssueQuery(undefined);
  const [createCountryOfIssue] = useCreateCountryOfIssueMutation();
  const [updateCountryOfIssue] = useUpdateCountryOfIssueMutation();
  const { data: CountryOfIssueDetails } = useGetCountryOfIssueByIdQuery(
    selectedLocationId,
    {
      skip: !selectedLocationId,
    },
  );

  useEffect(() => {
    if (CountryOfIssueDetails) {
      setCountryOfIssue(CountryOfIssueDetails);
    }
  }, [CountryOfIssueDetails]);

  const toggleModal = () => setModalOpen(!modalOpen);

  const handleAddLocation = () => {
    setSelectedLocationId(null);
    setCountryOfIssue({ country_of_issue: "", description: "" });
    toggleModal();
  };

  const handleEditLocation = (locationId: number) => {
    setSelectedLocationId(locationId);
    toggleModal();
  };

  const handleSaveLocation = async () => {
    setLoading(true);
    try {
      if (CountryOfIssue.id) {
        await updateCountryOfIssue({
          id: CountryOfIssue.id,
          updateData: CountryOfIssue,
        }).unwrap();
      } else {
        await createCountryOfIssue({ newLocation: CountryOfIssue }).unwrap();
      }
      refetch();
      toggleModal();
    } catch (error) {
      console.error("Error saving location:", error);
    } finally {
      setLoading(false);
    }
  };

  const filteredLocations =
    CountryOfIssueData?.filter((l: CountryOfIssueData) =>
      l.country_of_issue?.toLowerCase()?.includes(searchTerm.toLowerCase()),
    ) || [];

  return (
    <Card>
      <CardBody>
        <div className="d-flex align-items-center mb-4">
          <h5 className="card-title flex-grow-1 mb-0">Country</h5>
          <Input
            type="text"
            placeholder="Search country..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="me-2"
            style={{ width: "300px" }}
          />
          <Button color="primary" onClick={handleAddLocation}>
            Add Country
          </Button>
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <Table striped>
            <thead>
              <tr>
                <th>#</th>
                <th>Country Of Issue</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredLocations.length > 0 ? (
                filteredLocations.map(
                  (l: CountryOfIssueData, index: number) => (
                    <tr key={l.id}>
                      <td>{index + 1}</td>
                      <td>{l.country_of_issue}</td>
                      <td>{l.description}</td>
                      <td>
                        <Button
                          color="warning"
                          size="sm"
                          onClick={() => handleEditLocation(l.id!)}
                        >
                          Edit
                        </Button>
                      </td>
                    </tr>
                  ),
                )
              ) : (
                <tr>
                  <td colSpan={4} className="text-center">
                    No Countries Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
        <CountryOfIssueModal
          isOpen={modalOpen}
          toggle={toggleModal}
          onSave={handleSaveLocation}
          CountryOfIssue={CountryOfIssue}
          setCountryOfIssue={setCountryOfIssue}
          loading={loading}
        />
      </CardBody>
    </Card>
  );
};

interface CountryOfIssueModalProps {
  isOpen: boolean;
  toggle: () => void;
  onSave: () => void;
  CountryOfIssue: CountryOfIssueData;
  setCountryOfIssue: React.Dispatch<React.SetStateAction<CountryOfIssueData>>;
  loading: boolean;
}

const CountryOfIssueModal: React.FC<CountryOfIssueModalProps> = ({
  isOpen,
  toggle,
  onSave,
  CountryOfIssue,
  setCountryOfIssue,
  loading,
}) => {
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setCountryOfIssue({ ...CountryOfIssue, [e.target.name]: e.target.value });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {CountryOfIssue.id ? "Update" : "Create"} Country
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <FormGroup>
              <Label for="country_of_issue">
                Country <span className="text-danger">*</span>
              </Label>
              <Input
                id="country_of_issue"
                name="country_of_issue"
                value={CountryOfIssue.country_of_issue}
                onChange={handleInputChange}
                disabled={loading}
              />
            </FormGroup>
            <FormGroup>
              <Label for="description">Description</Label>
              <Input
                id="description"
                name="description"
                type="textarea"
                value={CountryOfIssue.description || ""}
                onChange={handleInputChange}
                disabled={loading}
              />
            </FormGroup>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={onSave}
          disabled={loading || !CountryOfIssue.country_of_issue}
        >
          {loading ? <Spinner size="sm" /> : "Save"}
        </Button>
        <Button color="secondary" onClick={toggle} disabled={loading}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CountryOfIssueTab;
