import React, { useState, useContext, useEffect } from "react";
import classnames from "classnames";
import { Formik, Field, Form as YupForm } from "formik";
import * as Yup from "yup";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Spinner,
  Badge,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Accordion,
  AccordionItem,
  AccordionBody,
  AccordionHeader,
  Container,
} from "reactstrap";
import { useDispatch } from "react-redux";
import ValidationAlertStatic from "../../ValidationAlertStatic";
import {
  useCreateSafetyAppApproveWorkflowMutation,
  useCreateSafetyAppClosedRevokedWorkflowMutation,
  usePostApprovalCommentsMutation,
  usePostApprovalCommentsAttachmentMutation,
} from "slices/workflow/WorkFlowSlice";
import { ServiceContext } from "context/ServiceContextProvider";
import { BadgeStatus } from "helpers/badgeStatus";
import { safetyAppSlice } from "slices/safetyApp/safetyappSlice";
import { STATUS_COLORS } from "helpers/common_helper";
import { getErrorText } from "helpers/error_message";
import { Link } from "react-router-dom";
import { IsValidPermissions } from "Routes/AuthProtected";
import { formatDateTime } from "helpers/date_utils";

interface ProjectProps {
  permitNo: string;
  workFlowData: any;
  workFlowLoading: any;
  refetchProject: any;
  confirmCloseModal: boolean;
  confirmRevokeModal: boolean;
  toggleConfirmCloseModal: any;
  toggleConfirmRevokeModal: any;
  toggleConfirmCancelModal: any;
  confirmCancelModal: any;
  isLoadingCancelPermit: boolean;
  cancelPermit: any;
  permitType: string;
  setIsPageLoading: any;
}

const SafetyAppOverviewTab: React.FC<ProjectProps> = ({
  permitNo,
  workFlowData,
  workFlowLoading,
  refetchProject,
  confirmCloseModal,
  confirmRevokeModal,
  toggleConfirmCloseModal,
  toggleConfirmRevokeModal,
  toggleConfirmCancelModal,
  confirmCancelModal,
  isLoadingCancelPermit,
  cancelPermit,
  permitType,
  setIsPageLoading,
}) => {
  const context = useContext(ServiceContext);

  if (!context) {
    throw new Error(
      "ProfileDropdown must be used within a ServiceContextProvider",
    );
  }

  const { selectedRoleName } = context;
  const dispatch = useDispatch();

  const [rejectModal, setRejectModal] = useState(false);
  const [nestedRejectModal, setNestedRejectModal] = useState(false);
  const [approvalModal, setApprovalModal] = useState(false);
  const [confirmApproveModal, setConfirmApproveModal] = useState(false); // Confirmation modal for approval

  const [selectedApprovalWorkflow, setSelectedApprovalWorkflow] =
    useState<any>(null);
  const [reason, setReason] = useState("");
  const [workflowToReject, setWorkflowToReject] = useState<any>(null);

  const [activeTab, setactiveTab] = useState("1");

  // const [latitude, setLatitude] = useState<number | null>(null);
  // const [longitude, setLongitude] = useState<number | null>(null);
  const [location, setLocation] = useState<{
    latitude: number | null;
    longitude: number | null;
  }>({ latitude: null, longitude: null });
  const [validationMessages, setValidationMessages] = useState<string[]>([]);
  const [modalBackDrop, setModalBackDrop] = useState<boolean>(false);

  const [createApproveWorkflow, { isLoading: createApprovelLoading }] =
    useCreateSafetyAppApproveWorkflowMutation();

  const [createCloseRevokeWorkflow, { isLoading: createCloseRevokeLoading }] =
    useCreateSafetyAppClosedRevokedWorkflowMutation();

  const [postCommentsAction, { isLoading: postCommentsLoading }] =
    usePostApprovalCommentsMutation();
  const [postCommentsAttachment, { isLoading: postCommentsAttachmentLoading }] =
    usePostApprovalCommentsAttachmentMutation();

  const toggleRejectModal = () => setRejectModal(!rejectModal);
  const toggleNestedRejectModal = () =>
    setNestedRejectModal(!nestedRejectModal);
  const toggleApprovalModal = () => setApprovalModal(!approvalModal);
  const toggleConfirmApproveModal = () =>
    setConfirmApproveModal(!confirmApproveModal);

  const handleReject = (workflow: any) => {
    setSelectedApprovalWorkflow(workflow);
    toggleRejectModal();
  };

  const [openAccordionItem, setOpenAccordionItem] = useState<string>("");

  // Function to toggle accordion items
  const toggleAccordion = (id: string) => {
    setOpenAccordionItem((prev) => (prev === id ? "" : id));
  };

  // const handleAccept = (workflow: any) => {
  //   if (workflow.role_name.includes(selectedRoleName)) {
  //     setSelectedApprovalWorkflow(workflow);
  //     getLocation();
  //     toggleConfirmApproveModal(); // Open confirmation modal
  //   } else {
  //     alert("You do not have the required role to approve.");
  //   }
  // };

  const handleFileDownload = async (url: string) => {
    try {
      // Create a URL object
      const urlObject = new URL(url);
      const pathname = urlObject.pathname;
      let filename = pathname.split("/").pop();

      // Handle the case where filename is undefined
      if (!filename) {
        console.error("Filename could not be extracted from the URL.");
        return; // Optionally handle this case differently
      }

      // Fetch the file
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Failed to download file: ${response.statusText}`);
      }

      // Convert response to blob
      const blob = await response.blob();

      // Create an object URL for the blob
      const fileURL = window.URL.createObjectURL(blob);

      // Create an anchor element and trigger the download
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = filename; // Assign a string value
      link.click();

      // Cleanup: Revoke the object URL to free up memory
      window.URL.revokeObjectURL(fileURL);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const getLocation = async () => {
    if ("geolocation" in navigator) {
      setIsPageLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setIsPageLoading(false);
        },
        (err) => {
          setValidationMessages((prev) => {
            const errorMessage = getErrorText("location-disabled");
            return prev.includes(errorMessage) ? prev : [...prev, errorMessage];
          });
          setModalBackDrop(true);
          setIsPageLoading(false);
        },
        { enableHighAccuracy: true },
      );
    } else {
      setValidationMessages((prev) => {
        const errorMessage = getErrorText("location-not-supported");
        return prev.includes(errorMessage) ? prev : [...prev, errorMessage];
      });
      setModalBackDrop(true);
      setIsPageLoading(false);
    }
  };

  const openNestedRejectModal = (workflow: any) => {
    setWorkflowToReject(workflow);
    toggleNestedRejectModal();
  };

  const isRoleAllowed = (next_id: any, workflow: any) => {
    return (
      workflow.role_name.includes(selectedRoleName) &&
      parseInt(next_id) === parseInt(workflow.id)
    );
  };

  const isNextAction = (next_id: any, workflow: any) => {
    return parseInt(next_id) === parseInt(workflow.id);
  };

  const inValidateQuery = async () => {
    switch (permitType) {
      case "HOT_WORK_PERMIT":
        return dispatch(
          safetyAppSlice.util.invalidateTags([
            { type: "GetHotworkPermitByNumber", id: workFlowData.approval_id },
            { type: "GetHotworkPermit" },
          ]),
        );
      case "GENERAL_WORK_PERMIT":
        return dispatch(
          safetyAppSlice.util.invalidateTags([
            {
              type: "GetGeneralworkPermitByNumber",
              id: workFlowData.approval_id,
            },
            { type: "GetGeneralworkPermit" },
          ]),
        );
      case "WORK_AT_HEIGHT_PERMIT":
        return dispatch(
          safetyAppSlice.util.invalidateTags([
            {
              type: "GetWorkAtHeightPermitByNumber",
              id: workFlowData.approval_id,
            },
            { type: "GetWorkAtHeightPermit" },
          ]),
        );
      case "LIFTING_PERMIT":
        return dispatch(
          safetyAppSlice.util.invalidateTags([
            {
              type: "GetLiftingWorkPermitByNumber",
              id: workFlowData.approval_id,
            },
            { type: "GetLiftingWorkPermit" },
          ]),
        );
      case "EXCAVATION_PERMIT":
        return dispatch(
          safetyAppSlice.util.invalidateTags([
            {
              type: "GetExcavationPermitByNumber",
              id: workFlowData.approval_id,
            },
            { type: "GetExcavationPermit" },
          ]),
        );
      case "CONFINED_SPACE_PERMIT":
        return dispatch(
          safetyAppSlice.util.invalidateTags([
            {
              type: "GetConfinedSpacePermitByNumber",
              id: workFlowData.approval_id,
            },
            { type: "GetConfinedSpacePermit" },
          ]),
        );
      case "PIPE_JACKING_PERMIT":
        return dispatch(
          safetyAppSlice.util.invalidateTags([
            {
              type: "GetPipeJackingPermitByNumber",
              id: workFlowData.approval_id,
            },
            { type: "GetPipeJackingPermit" },
          ]),
        );
      case "PILING_PERMIT":
        return dispatch(
          safetyAppSlice.util.invalidateTags([
            {
              type: "GetPillingPermitByNumber",
              id: workFlowData.approval_id,
            },
            { type: "GetPillingPermit" },
          ]),
        );
      default:
        return;
    }
  };

  const IsEditable = () => {
    return IsValidPermissions(["EDIT_SAFETY_APP_APPROVALS"]);
  };

  const handleSubmitReject = async (e: any) => {
    e.preventDefault();
    // if (!workflowToReject) return;

    if (location.latitude === null || location.longitude === null) {
      alert("Unable to fetch your location. Please try again or Reload Page.");
      return;
    }

    const rejectData = {
      approval_id: selectedApprovalWorkflow.approval_id,
      approval_request_id: selectedApprovalWorkflow.resourceId,
      approval_list_id: selectedApprovalWorkflow?.id,
      approval_status: "Rejected",
      user_comments: reason,
      latitude: location.latitude,
      longitude: location.longitude,
    };

    try {
      await createApproveWorkflow(rejectData).unwrap();
      await refetchProject();
      await inValidateQuery();
      setReason("");
      console.log("Rejection submitted successfully");
    } catch (error) {
      console.error("Error rejecting workflow", error);
    }
    toggleNestedRejectModal();
    // toggleRejectModal();
    // setReason("");
  };

  const handleAcceptApproval = (resourceId: any, workflow: any) => {
    console.log("cccc", resourceId, "www", workflow);
    setSelectedApprovalWorkflow({
      ...workflow,
      resourceId: resourceId,
    });
    toggleConfirmApproveModal();
  };

  const handleRejectApproval = (resourceId: any, workflow: any) => {
    console.log("www", workflow);
    setSelectedApprovalWorkflow({
      ...workflow,
      resourceId: resourceId,
    });
    toggleNestedRejectModal();
  };

  const handleApprove = async (e: any) => {
    e.preventDefault();
    // if (!selectedApprovalWorkflow) return;

    if (location.latitude === null || location.longitude === null) {
      alert("Unable to fetch your location. Please try again or Reload Page.");
      return;
    }

    const approveData = {
      approval_id: selectedApprovalWorkflow.approval_id,
      approval_request_id: selectedApprovalWorkflow.resourceId,
      approval_list_id: selectedApprovalWorkflow?.id,
      user_comments: reason,
      latitude: location.latitude,
      longitude: location.longitude,
      approval_status: "Approved",
    };

    try {
      await createApproveWorkflow(approveData).unwrap();
      await refetchProject();
      await inValidateQuery();
      setReason("");
      toggleConfirmApproveModal(); // Close the confirmation modal
      console.log("Approval submitted successfully");
    } catch (error) {
      console.error("Error approving workflow", error);
      toggleConfirmApproveModal(); // Close the confirmation modal
    }
  };

  const handleClosed = async (e: any) => {
    e.preventDefault();
    // if (!selectedApprovalWorkflow) return;

    if (location.latitude === null || location.longitude === null) {
      alert("Unable to fetch your location. Please try again or Reload Page.");
      return;
    }

    const approveData = {
      approval_id: workFlowData.approval_id,
      user_comments: reason,
      latitude: location.latitude,
      longitude: location.longitude,
      approval_status: "Closed",
    };

    try {
      await createCloseRevokeWorkflow(approveData).unwrap();
      await refetchProject();
      await inValidateQuery();
      setReason("");
      toggleConfirmCloseModal();
      console.log("Approval closed successfully");
    } catch (error) {
      console.error("Error approving workflow", error);
      toggleConfirmCloseModal();
    }
  };

  const handleRevoked = async (e: any) => {
    e.preventDefault();
    // if (!selectedApprovalWorkflow) return;

    if (location.latitude === null || location.longitude === null) {
      alert("Unable to fetch your location. Please try again or Reload Page.");
      return;
    }

    const approveData = {
      approval_id: workFlowData.approval_id,
      user_comments: reason,
      latitude: location.latitude,
      longitude: location.longitude,
      approval_status: "Revoked",
    };

    try {
      await createCloseRevokeWorkflow(approveData).unwrap();
      await refetchProject();
      await inValidateQuery();
      setReason("");
      toggleConfirmRevokeModal();
      console.log("Approval revoked successfully");
    } catch (error) {
      console.error("Error revoked workflow", error);
      toggleConfirmRevokeModal();
    }
  };

  const handleCancel = async (e: any) => {
    e.preventDefault();
    // if (!selectedApprovalWorkflow) return;

    if (location.latitude === null || location.longitude === null) {
      alert("Unable to fetch your location. Please try again or Reload Page.");
      return;
    }

    const permitData = {
      user_comments: reason,
      latitude: location.latitude,
      longitude: location.longitude,
    };

    console.log("approveData", permitData);
    console.log("permitNo", permitNo);

    try {
      await cancelPermit({ permitNo, permitData }).unwrap();
      await refetchProject();
      await inValidateQuery();
      setReason("");
      toggleConfirmCancelModal();
      console.log("Approval cancelled successfully");
    } catch (error) {
      console.error("Error approving workflow", error);
      toggleConfirmCancelModal();
    }
  };

  useEffect(() => {
    if (permitNo) {
      setValidationMessages([]);
      getLocation();
    }
  }, [permitNo]);

  const toggle = (tab: any) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const postComments = async (values: any, resetForm: any) => {
    const comments = Object.keys(values).reduce((acc: any, key) => {
      const [fieldName] = key.split("-");
      acc[fieldName] = values[key];
      return acc;
    }, {});
    await postCommentsAction(comments);
    resetForm();
  };

  const customAfterStyles = `
    .timeline-2::after {
      height: 100% !important;
    }
  `;

  if (workFlowLoading) {
    return (
      <Card>
        <CardBody>
          <Container fluid>
            <div className="page-content" style={{ height: "80vh" }}>
              {" "}
              {/* Full viewport height */}
              <Container fluid className="h-100">
                <Row className="h-100 d-flex justify-content-center align-items-center">
                  <Col lg={12} className="text-center">
                    <Spinner color="primary"></Spinner> <br /> <br />
                    <span className="text-muted">
                      Fetching Approval Information...
                    </span>
                  </Col>
                </Row>
              </Container>
            </div>
          </Container>
        </CardBody>
      </Card>
    );
  }

  return (
    <React.Fragment>
      <style>{customAfterStyles}</style>

      <>
        <Card>
          <CardBody>
            <Nav
              tabs
              className="nav nav-tabs nav-tabs-custom nav-primary  mb-3"
            >
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Active Approval List{" "}
                  {workFlowData?.resource_approval_request?.filter(
                    (item: any) => item.is_active === true,
                  )?.length > 0 && (
                      <span className="badge bg-info rounded-circle">
                        {
                          workFlowData?.resource_approval_request?.filter(
                            (item: any) => item.is_active === true,
                          )?.length
                        }
                      </span>
                    )}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  Rejected Approval List{" "}
                  {workFlowData?.resource_approval_request?.filter(
                    (item: any) => item.is_active === false,
                  )?.length > 0 && (
                      <span className="badge bg-danger rounded-circle">
                        {
                          workFlowData?.resource_approval_request?.filter(
                            (item: any) => item.is_active === false,
                          )?.length
                        }
                      </span>
                    )}
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab} className="text-muted">
              <TabPane tabId="1" id="home">
                {workFlowData?.resource_approval_request?.filter(
                  (item: any) => item.is_active === true,
                )?.length > 0 ? (
                  <>
                    {workFlowData?.resource_approval_request?.map(
                      (resource: any, index: any) => {
                        return (
                          <>
                            {resource?.is_active === true ? (
                              <Col xl={12} lg={12}>
                                <Card>
                                  <h5 className="py-2 px-2">Approvals</h5>
                                  <CardBody style={{ background: "#dfdfdf" }}>
                                    {resource?.resource_approval?.map(
                                      (workflow: any, index: any) => {
                                        return (
                                          <Row>
                                            <div>
                                              <div className="timeline-2">
                                                <div className="timeline-year text-primary">
                                                  {/* <p className='shadow'>Level {workflow?.approval_level}</p> */}
                                                  <p
                                                    className="shadow"
                                                    style={{
                                                      width: "15%",
                                                      height: "10px",
                                                      borderRadius: "0",
                                                    }}
                                                  >
                                                    {workflow?.step_name}
                                                  </p>
                                                </div>
                                                <div className="timeline-continue">
                                                  <Row className="timeline-right">
                                                    <Col xs={12}>
                                                      <p className="timeline-date">
                                                        <h5>
                                                          {workflow?.approval_status ? (
                                                            <BadgeStatus
                                                              value={
                                                                workflow?.approval_status
                                                              }
                                                              color={
                                                                STATUS_COLORS[
                                                                workflow
                                                                  ?.approval_status
                                                                ]
                                                              }
                                                            />
                                                          ) : null}
                                                        </h5>
                                                      </p>
                                                    </Col>
                                                    <Col xs={12}>
                                                      <div
                                                        className="timeline-box shadow pt-0"
                                                        style={{
                                                          minWidth: "90%",
                                                        }}
                                                      >
                                                        <div>
                                                          <div className="text-muted">
                                                            <div className="pt-4">
                                                              <Row>
                                                                <Col
                                                                  lg={3}
                                                                  sm={6}
                                                                >
                                                                  <div>
                                                                    <p className="mb-2 text-uppercase fw-medium">
                                                                      Step Name
                                                                      :
                                                                    </p>
                                                                    <h5 className="fs-15 mb-0">
                                                                      <Badge color="primary">
                                                                        {
                                                                          workflow?.step_name
                                                                        }
                                                                      </Badge>
                                                                    </h5>
                                                                  </div>
                                                                </Col>
                                                                <Col
                                                                  lg={3}
                                                                  sm={6}
                                                                >
                                                                  <div>
                                                                    <p className="mb-2 text-uppercase fw-medium">
                                                                      Role :
                                                                    </p>
                                                                    <h5 className="fs-15 mb-0">
                                                                      {workflow?.role_name?.map(
                                                                        (
                                                                          role: string,
                                                                          i: number,
                                                                        ) => (
                                                                          <span
                                                                            key={
                                                                              i
                                                                            }
                                                                            className="badge text-uppercase bg-info-subtle text-info me-1"
                                                                          >
                                                                            {
                                                                              role
                                                                            }
                                                                          </span>
                                                                        ),
                                                                      )}
                                                                    </h5>
                                                                  </div>
                                                                </Col>
                                                                <Col
                                                                  lg={3}
                                                                  sm={6}
                                                                >
                                                                  <div>
                                                                    <p className="mb-2 text-uppercase fw-medium">
                                                                      {(() => {
                                                                        switch (
                                                                        workflow?.step_name
                                                                        ) {
                                                                          case "Submission":
                                                                            return "Submitted By:";
                                                                          case "Closed":
                                                                            return "Closed By:";
                                                                          case "Rejected":
                                                                            return "Rejected By:";
                                                                          case "Revoked":
                                                                            return "Revoked By:";
                                                                          default:
                                                                            return "Reviewed By:";
                                                                        }
                                                                      })()}
                                                                    </p>
                                                                    <div className="d-flex align-items-center">
                                                                      <div className="flex-shrink-0">
                                                                        {workflow?.approved_by_name ? (
                                                                          workflow?.approved_by_iamge_url ? (
                                                                            <img
                                                                              src={
                                                                                workflow?.approved_by_iamge_url
                                                                              }
                                                                              alt=""
                                                                              className="avatar-xs rounded-circle"
                                                                            />
                                                                          ) : (
                                                                            <div className="flex-shrink-0 avatar-xs me-2">
                                                                              <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                                                                                <b>
                                                                                  {workflow?.approved_by_name.charAt(
                                                                                    0,
                                                                                  )}
                                                                                </b>
                                                                              </div>
                                                                            </div>
                                                                          )
                                                                        ) : null}
                                                                      </div>
                                                                      <div className="flex-grow-1 ms-2 name">
                                                                        <div>
                                                                          <b>
                                                                            {
                                                                              workflow?.approved_by_name
                                                                            }
                                                                          </b>
                                                                        </div>
                                                                        {workflow?.approved_role_name ? (
                                                                          <div className="badge text-uppercase bg-info-subtle text-info me-1">
                                                                            {
                                                                              workflow?.approved_role_name
                                                                            }
                                                                          </div>
                                                                        ) : (
                                                                          ""
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </Col>
                                                                <Col
                                                                  lg={3}
                                                                  sm={6}
                                                                >
                                                                  <div>
                                                                    <p className="mb-2 text-uppercase fw-medium">
                                                                      Approval
                                                                      Required :
                                                                    </p>
                                                                    <h5 className="fs-15 mb-0">
                                                                      {workflow?.is_required ? (
                                                                        <>Yes</>
                                                                      ) : (
                                                                        <>No</>
                                                                      )}
                                                                    </h5>
                                                                  </div>
                                                                </Col>
                                                              </Row>
                                                            </div>
                                                            {workflow?.approval_datetime ? (
                                                              <div className="pt-3  mt-4 mb-3">
                                                                <Row>
                                                                  <Col
                                                                    lg={3}
                                                                    sm={6}
                                                                  >
                                                                    <div>
                                                                      <p className="mb-2 text-uppercase fw-medium">
                                                                        Approved
                                                                        Date :
                                                                      </p>
                                                                      <h5 className="fs-15 mb-0">
                                                                        {formatDateTime(
                                                                          workflow?.approval_datetime,
                                                                          "datetime",
                                                                        )}
                                                                      </h5>
                                                                    </div>
                                                                  </Col>
                                                                  <Col
                                                                    lg={4}
                                                                    sm={4}
                                                                  >
                                                                    <div>
                                                                      <p className="mb-2 text-uppercase fw-medium">
                                                                        Action :
                                                                      </p>
                                                                      <h5 className="fs-15 mb-0">
                                                                        {
                                                                          workflow?.comments
                                                                        }
                                                                      </h5>
                                                                    </div>
                                                                  </Col>
                                                                  <Col
                                                                    lg={5}
                                                                    sm={5}
                                                                  >
                                                                    <div>
                                                                      <p className="mb-2 text-uppercase fw-medium">
                                                                        Location
                                                                        :
                                                                      </p>
                                                                      <h5 className="fs-15 mb-0">
                                                                        {
                                                                          workflow?.location
                                                                        }
                                                                      </h5>
                                                                    </div>
                                                                  </Col>
                                                                </Row>
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}

                                                            {workflow?.user_comments &&
                                                              workflow
                                                                ?.user_comments
                                                                .length > 0 ? (
                                                              <div className="pt-3 border-top border-top-dashed mt-0">
                                                                <Row>
                                                                  <Col
                                                                    lg={12}
                                                                    sm={12}
                                                                  >
                                                                    <div>
                                                                      <p className="mb-3 text-uppercase fw-medium">
                                                                        Comments
                                                                        :
                                                                      </p>
                                                                      {workflow?.user_comments?.map(
                                                                        (
                                                                          comment: any,
                                                                          index: number,
                                                                        ) => {
                                                                          return (
                                                                            <>
                                                                              <div className="d-flex mb-1">
                                                                                <div className="flex-shrink-0">
                                                                                  {comment?.commented_by_full_name ? (
                                                                                    comment?.commented_by_image_url ? (
                                                                                      <img
                                                                                        src={
                                                                                          comment?.commented_by_image_url
                                                                                        }
                                                                                        alt=""
                                                                                        className="avatar-xs rounded-circle"
                                                                                      />
                                                                                    ) : (
                                                                                      <div className="flex-shrink-0 avatar-xs me-2">
                                                                                        <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                                                                                          <b>
                                                                                            {comment?.commented_by_full_name.charAt(
                                                                                              0,
                                                                                            )}
                                                                                          </b>
                                                                                        </div>
                                                                                      </div>
                                                                                    )
                                                                                  ) : null}
                                                                                </div>
                                                                                <div className="flex-grow-1 ms-3">
                                                                                  <h5 className="fs-13">
                                                                                    {
                                                                                      comment?.commented_by_full_name
                                                                                    }{" "}
                                                                                    <small className="text-muted ms-2">
                                                                                      {
                                                                                        comment?.comments_datetime_text
                                                                                      }
                                                                                    </small>
                                                                                  </h5>
                                                                                  <p className="text-muted fs-14">
                                                                                    {
                                                                                      comment?.user_comments
                                                                                    }
                                                                                  </p>
                                                                                </div>
                                                                              </div>
                                                                            </>
                                                                          );
                                                                        },
                                                                      )}
                                                                    </div>
                                                                  </Col>
                                                                </Row>
                                                              </div>
                                                            ) : null}

                                                            {workflow?.user_attachment &&
                                                              workflow
                                                                ?.user_attachment
                                                                .length > 0 ? (
                                                              <div className="pt-3 border-top border-top-dashed mt-0">
                                                                <Row className="g-2 mb-1">
                                                                  <p className="mb-2 text-uppercase fw-medium">
                                                                    Attachments
                                                                    :
                                                                  </p>
                                                                  {workflow?.user_attachment?.map(
                                                                    (
                                                                      attachment: any,
                                                                      index: number,
                                                                    ) => {
                                                                      const url =
                                                                        new URL(
                                                                          attachment?.file,
                                                                        );
                                                                      const fileName =
                                                                        url.pathname
                                                                          .split(
                                                                            "/",
                                                                          )
                                                                          .pop();
                                                                      return (
                                                                        <div
                                                                          style={{
                                                                            width:
                                                                              "200px",
                                                                          }}
                                                                          className="d-flex me-2 border border-dashed p-2 rounded position-relative"
                                                                        >
                                                                          <div className="flex-shrink-0 avatar-xs">
                                                                            <div className="avatar-title bg-danger-subtle text-danger fs-15 rounded">
                                                                              <i className="ri-file-zip-line"></i>
                                                                            </div>
                                                                          </div>
                                                                          <div className="flex-shrink-0"></div>
                                                                          <div className="flex-grow-1 overflow-hidden ms-2">
                                                                            <h6 className="text-truncate mb-0">
                                                                              <Link
                                                                                to="#"
                                                                                onClick={() =>
                                                                                  handleFileDownload(
                                                                                    attachment.file_url,
                                                                                  )
                                                                                }
                                                                                className="stretched-link"
                                                                              >
                                                                                {
                                                                                  fileName
                                                                                }
                                                                              </Link>
                                                                            </h6>
                                                                            <small>
                                                                              {
                                                                                attachment?.uploaded_at_text
                                                                              }
                                                                            </small>
                                                                          </div>
                                                                        </div>
                                                                      );
                                                                    },
                                                                  )}
                                                                </Row>
                                                              </div>
                                                            ) : null}

                                                            {IsEditable() &&
                                                              (workflow?.approval_datetime ||
                                                                isNextAction(
                                                                  workFlowData?.next_approval_id,
                                                                  workflow,
                                                                )) ? (
                                                              <Row>
                                                                <div className="pt-3 border-top border-top-dashed mt-3">
                                                                  <Formik
                                                                    key={index} // Key for the Formik instance
                                                                    initialValues={{
                                                                      [`user_comments-${index}`]:
                                                                        "",
                                                                      [`approval_list_id-${index}`]:
                                                                        workflow.id,
                                                                    }}
                                                                    validationSchema={Yup.object(
                                                                      {
                                                                        [`user_comments-${index}`]:
                                                                          Yup.string().required(
                                                                            "Comment is required",
                                                                          ),
                                                                      },
                                                                    )}
                                                                    onSubmit={(
                                                                      values,
                                                                      {
                                                                        resetForm,
                                                                      },
                                                                    ) =>
                                                                      postComments(
                                                                        values,
                                                                        resetForm,
                                                                      )
                                                                    }
                                                                  >
                                                                    {({
                                                                      errors,
                                                                      touched,
                                                                    }) => (
                                                                      <YupForm className="mt-0">
                                                                        <Row className="g-3">
                                                                          <Col
                                                                            xs={
                                                                              12
                                                                            }
                                                                          >
                                                                            <div
                                                                              className="d-flex"
                                                                              style={{
                                                                                justifyContent:
                                                                                  "space-between",
                                                                              }}
                                                                            >
                                                                              <label
                                                                                htmlFor={`user_comments-${index}`}
                                                                                className="form-label text-body"
                                                                              >
                                                                                Add
                                                                                Comments
                                                                              </label>
                                                                              <span className="text-end">
                                                                                <button
                                                                                  type="button"
                                                                                  disabled={
                                                                                    postCommentsAttachmentLoading
                                                                                  }
                                                                                  onClick={() =>
                                                                                    // @ts-ignore
                                                                                    document
                                                                                      .getElementById(
                                                                                        `file-input-${index}`,
                                                                                      )
                                                                                      .click()
                                                                                  }
                                                                                  className="btn btn-ghost-secondary btn-icon waves-effect me-1 shadow-none"
                                                                                >
                                                                                  {postCommentsAttachmentLoading ? (
                                                                                    <span className="d-flex align-items-center">
                                                                                      <>
                                                                                        <Spinner size="sm" />
                                                                                      </>
                                                                                    </span>
                                                                                  ) : (
                                                                                    <i className="ri-attachment-line fs-16"></i>
                                                                                  )}
                                                                                </button>

                                                                                <Button
                                                                                  type="submit"
                                                                                  className="btn btn-sm btn-info"
                                                                                  disabled={
                                                                                    postCommentsLoading
                                                                                  }
                                                                                >
                                                                                  {postCommentsLoading ? (
                                                                                    <span className="d-flex align-items-center">
                                                                                      <>
                                                                                        <Spinner size="sm" />
                                                                                        &nbsp;Loading....
                                                                                      </>
                                                                                    </span>
                                                                                  ) : (
                                                                                    <>
                                                                                      Post
                                                                                      Comments
                                                                                    </>
                                                                                  )}
                                                                                </Button>
                                                                              </span>
                                                                            </div>
                                                                            <Field
                                                                              as="textarea"
                                                                              className={`form-control bg-light border-light ${errors[
                                                                                `comments-${index}`
                                                                              ] &&
                                                                                touched[
                                                                                `comments-${index}`
                                                                                ]
                                                                                ? "is-invalid"
                                                                                : ""
                                                                                }`}
                                                                              id={`user_comments-${index}`}
                                                                              name={`user_comments-${index}`} // Unique name for each textarea
                                                                              rows={
                                                                                2
                                                                              }
                                                                              placeholder="Enter your comment..."
                                                                            />

                                                                            {/* Hidden File Input */}
                                                                            <input
                                                                              id={`file-input-${index}`}
                                                                              name={`attachment`}
                                                                              type="file"
                                                                              className="d-none"
                                                                              onChange={async (
                                                                                e,
                                                                              ) => {
                                                                                const fileInput =
                                                                                  e.currentTarget;
                                                                                if (
                                                                                  fileInput &&
                                                                                  fileInput.files
                                                                                ) {
                                                                                  const file =
                                                                                    fileInput
                                                                                      .files[0];
                                                                                  if (
                                                                                    file
                                                                                  ) {
                                                                                    console.log(
                                                                                      "File workflow:",
                                                                                      workflow.id,
                                                                                    );
                                                                                    const attachment =
                                                                                    {
                                                                                      file: file,
                                                                                      approval_list_id:
                                                                                        workflow.id,
                                                                                    };
                                                                                    await postCommentsAttachment(
                                                                                      attachment,
                                                                                    );
                                                                                    // Handle the file here (e.g., upload or set to Formik state)
                                                                                    console.log(
                                                                                      "File selected:",
                                                                                      file,
                                                                                    );
                                                                                    // For example, you could use setFieldValue if you want to add it to Formik values
                                                                                    // setFieldValue(`attachment-${index}`, file);
                                                                                  }
                                                                                }
                                                                              }}
                                                                            />

                                                                            {/* Hidden Field for approval list ID */}
                                                                            <Field
                                                                              type="hidden"
                                                                              name={`approval_list_id-${index}`}
                                                                              value={
                                                                                workflow.id
                                                                              }
                                                                            />
                                                                            {errors[
                                                                              `user_comments-${index}`
                                                                            ] &&
                                                                              touched[
                                                                              `user_comments-${index}`
                                                                              ] && (
                                                                                <div className="invalid-feedback">
                                                                                  {String(
                                                                                    errors[
                                                                                    `user_comments-${index}`
                                                                                    ],
                                                                                  )}
                                                                                </div>
                                                                              )}

                                                                            {/* Hidden Field */}
                                                                            <Field
                                                                              type="hidden"
                                                                              name={`approval_list_id-${index}`}
                                                                              value={
                                                                                workflow.id
                                                                              }
                                                                            />
                                                                          </Col>
                                                                        </Row>
                                                                      </YupForm>
                                                                    )}
                                                                  </Formik>
                                                                </div>
                                                              </Row>
                                                            ) : (
                                                              <></>
                                                            )}

                                                            {IsEditable() &&
                                                              workflow?.approval_status ===
                                                              "Pending Approval" &&
                                                              isNextAction(
                                                                workFlowData?.next_approval_id,
                                                                workflow,
                                                              ) ? (
                                                              <div className="pt-3 border-top border-top-dashed mt-4">
                                                                <Row>
                                                                  <Col
                                                                    lg={12}
                                                                    sm={12}
                                                                  >
                                                                    <div
                                                                      className="d-flex"
                                                                      style={{
                                                                        justifyContent:
                                                                          "flex-end",
                                                                      }}
                                                                    >
                                                                      <Button
                                                                        color="success"
                                                                        onClick={() =>
                                                                          handleAcceptApproval(
                                                                            resource.id,
                                                                            workflow,
                                                                          )
                                                                        }
                                                                        className="btn sm me-2"
                                                                        disabled={
                                                                          !isRoleAllowed(
                                                                            workFlowData?.next_approval_id,
                                                                            workflow,
                                                                          ) ||
                                                                          createApprovelLoading
                                                                        }
                                                                      >
                                                                        Approve
                                                                      </Button>
                                                                      <Button
                                                                        color="danger"
                                                                        onClick={() =>
                                                                          handleRejectApproval(
                                                                            resource.id,
                                                                            workflow,
                                                                          )
                                                                        }
                                                                        className="btn me-2"
                                                                        disabled={
                                                                          !isRoleAllowed(
                                                                            workFlowData.next_approval_id,
                                                                            workflow,
                                                                          )
                                                                        }
                                                                      >
                                                                        Reject
                                                                      </Button>
                                                                    </div>
                                                                  </Col>
                                                                </Row>
                                                              </div>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </div>
                                            </div>
                                          </Row>
                                        );
                                      },
                                    )}
                                  </CardBody>
                                </Card>
                              </Col>
                            ) : (
                              <div className="text-center">
                                No Active Approvals
                              </div>
                            )}
                          </>
                        );
                      },
                    )}
                  </>
                ) : (
                  <div className="text-center">No Active Approvals</div>
                )}
              </TabPane>

              <TabPane tabId="2" id="product">
                {/* <h5 className="py-2">Rejected Approvals</h5> */}
                {workFlowData?.resource_approval_request?.filter(
                  (item: any) => item.is_active === false,
                )?.length > 0 ? (
                  <>
                    {/* <h5 className="py-2">Approvals</h5> */}
                    {workFlowData?.resource_approval_request?.map(
                      (resource: any, index: any) => {
                        return (
                          <>
                            {resource?.is_active === false ? (
                              <Col xl={12} lg={12}>
                                <Accordion
                                  open={openAccordionItem}
                                  toggle={toggleAccordion}
                                  className="custom-accordionwithicon custom-accordion-border accordion-border-box accordion-secondary"
                                >
                                  <AccordionItem key={index}>
                                    <AccordionHeader
                                      className="d-flex align-items-center"
                                      targetId={`${index}`}
                                    >
                                      <h4
                                        className="card-title flex-grow-1 mb-0 d-flex"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>
                                          <span className="text-muted">
                                            Permit No:
                                          </span>{" "}
                                          {resource?.approval_id}
                                        </div>
                                        <div>
                                          {
                                            resource?.approval_request_datetime_text
                                          }
                                        </div>
                                        <h5 className="text-end">
                                          {resource?.approval_request_status ? (
                                            <BadgeStatus
                                              value={
                                                resource?.approval_request_status
                                              }
                                              color={
                                                STATUS_COLORS[
                                                resource
                                                  ?.approval_request_status
                                                ]
                                              }
                                            />
                                          ) : null}
                                        </h5>
                                      </h4>
                                      &nbsp;&nbsp;&nbsp;
                                    </AccordionHeader>
                                    <AccordionBody accordionId={`${index}`}>
                                      <Card>
                                        <CardBody
                                          style={{ background: "#dfdfdf" }}
                                        >
                                          {resource?.resource_approval?.map(
                                            (workflow: any, index: any) => {
                                              return (
                                                <Row>
                                                  <div>
                                                    <div className="timeline-2">
                                                      <div className="timeline-year text-primary">
                                                        <p className="shadow">
                                                          Level{" "}
                                                          {
                                                            workflow?.approval_level
                                                          }
                                                        </p>
                                                      </div>
                                                      <div className="timeline-continue">
                                                        <Row className="timeline-right">
                                                          <Col xs={12}>
                                                            <p className="timeline-date">
                                                              <h5>
                                                                {workflow?.approval_status ? (
                                                                  <BadgeStatus
                                                                    value={
                                                                      workflow?.approval_status
                                                                    }
                                                                    color={
                                                                      STATUS_COLORS[
                                                                      workflow
                                                                        ?.approval_status
                                                                      ]
                                                                    }
                                                                  />
                                                                ) : null}
                                                              </h5>
                                                            </p>
                                                          </Col>
                                                          <Col xs={12}>
                                                            <div
                                                              className="timeline-box shadow pt-0"
                                                              style={{
                                                                minWidth: "90%",
                                                              }}
                                                            >
                                                              <div>
                                                                <div className="text-muted">
                                                                  <div className="pt-4">
                                                                    <Row>
                                                                      <Col
                                                                        lg={3}
                                                                        sm={6}
                                                                      >
                                                                        <div>
                                                                          <p className="mb-2 text-uppercase fw-medium">
                                                                            Step
                                                                            Name
                                                                            :
                                                                          </p>
                                                                          <h5 className="fs-15 mb-0">
                                                                            <Badge color="primary">
                                                                              {
                                                                                workflow?.step_name
                                                                              }
                                                                            </Badge>
                                                                          </h5>
                                                                        </div>
                                                                      </Col>
                                                                      <Col
                                                                        lg={3}
                                                                        sm={6}
                                                                      >
                                                                        <div>
                                                                          <p className="mb-2 text-uppercase fw-medium">
                                                                            Role
                                                                            :
                                                                          </p>
                                                                          <h5 className="fs-15 mb-0">
                                                                            {workflow?.role_name?.map(
                                                                              (
                                                                                role: string,
                                                                                i: number,
                                                                              ) => (
                                                                                <span
                                                                                  key={
                                                                                    i
                                                                                  }
                                                                                  className="badge text-uppercase bg-info-subtle text-info me-1"
                                                                                >
                                                                                  {
                                                                                    role
                                                                                  }
                                                                                </span>
                                                                              ),
                                                                            )}
                                                                          </h5>
                                                                        </div>
                                                                      </Col>
                                                                      <Col
                                                                        lg={3}
                                                                        sm={6}
                                                                      >
                                                                        <div>
                                                                          <p className="mb-2 text-uppercase fw-medium">
                                                                            Reviewed
                                                                            By :
                                                                          </p>
                                                                          <div className="d-flex align-items-center">
                                                                            <div className="flex-shrink-0">
                                                                              {workflow?.approved_by_name ? (
                                                                                workflow?.approved_by_iamge_url ? (
                                                                                  <img
                                                                                    src={
                                                                                      workflow?.approved_by_iamge_url
                                                                                    }
                                                                                    alt=""
                                                                                    className="avatar-xs rounded-circle"
                                                                                  />
                                                                                ) : (
                                                                                  <div className="flex-shrink-0 avatar-xs me-2">
                                                                                    <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                                                                                      <b>
                                                                                        {workflow?.approved_by_name.charAt(
                                                                                          0,
                                                                                        )}
                                                                                      </b>
                                                                                    </div>
                                                                                  </div>
                                                                                )
                                                                              ) : null}
                                                                            </div>
                                                                            <div className="flex-grow-1 ms-2 name">
                                                                              <b>
                                                                                {
                                                                                  workflow?.approved_by_name
                                                                                }
                                                                              </b>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </Col>
                                                                      <Col
                                                                        lg={3}
                                                                        sm={6}
                                                                      >
                                                                        <div>
                                                                          <p className="mb-2 text-uppercase fw-medium">
                                                                            Approval
                                                                            Required
                                                                            :
                                                                          </p>
                                                                          <h5 className="fs-15 mb-0">
                                                                            {workflow?.approval_required ? (
                                                                              <>
                                                                                Yes
                                                                              </>
                                                                            ) : (
                                                                              <>
                                                                                No
                                                                              </>
                                                                            )}
                                                                          </h5>
                                                                        </div>
                                                                      </Col>
                                                                    </Row>
                                                                  </div>
                                                                  <div className="pt-3  mt-4 mb-3">
                                                                    <Row>
                                                                      <Col
                                                                        lg={3}
                                                                        sm={6}
                                                                      >
                                                                        <div>
                                                                          <p className="mb-2 text-uppercase fw-medium">
                                                                            Approved
                                                                            Date
                                                                            :
                                                                          </p>
                                                                          <h5 className="fs-15 mb-0">
                                                                            {
                                                                              workflow?.approval_datetime_text
                                                                            }
                                                                          </h5>
                                                                        </div>
                                                                      </Col>
                                                                      <Col
                                                                        lg={4}
                                                                        sm={4}
                                                                      >
                                                                        <div>
                                                                          <p className="mb-2 text-uppercase fw-medium">
                                                                            Action
                                                                            :
                                                                          </p>
                                                                          <h5 className="fs-15 mb-0">
                                                                            {
                                                                              workflow?.comments
                                                                            }
                                                                          </h5>
                                                                        </div>
                                                                      </Col>
                                                                      <Col
                                                                        lg={5}
                                                                        sm={5}
                                                                      >
                                                                        <div>
                                                                          <p className="mb-2 text-uppercase fw-medium">
                                                                            Location
                                                                            :
                                                                          </p>
                                                                          <h5 className="fs-15 mb-0">
                                                                            {
                                                                              workflow?.location
                                                                            }
                                                                          </h5>
                                                                        </div>
                                                                      </Col>
                                                                    </Row>
                                                                  </div>
                                                                  {workflow?.user_comments &&
                                                                    workflow
                                                                      ?.user_comments
                                                                      .length >
                                                                    0 ? (
                                                                    <div className="pt-3 border-top border-top-dashed mt-0">
                                                                      <Row>
                                                                        <Col
                                                                          lg={
                                                                            12
                                                                          }
                                                                          sm={
                                                                            12
                                                                          }
                                                                        >
                                                                          <div>
                                                                            <p className="mb-4 text-uppercase fw-medium">
                                                                              Comments
                                                                              :
                                                                            </p>
                                                                            {workflow?.user_comments?.map(
                                                                              (
                                                                                comment: any,
                                                                                index: number,
                                                                              ) => {
                                                                                return (
                                                                                  <>
                                                                                    <div className="d-flex mb-1">
                                                                                      <div className="flex-shrink-0">
                                                                                        {comment?.commented_by_full_name ? (
                                                                                          comment?.commented_by_image_url ? (
                                                                                            <img
                                                                                              src={
                                                                                                comment?.commented_by_image_url
                                                                                              }
                                                                                              alt=""
                                                                                              className="avatar-xs rounded-circle"
                                                                                            />
                                                                                          ) : (
                                                                                            <div className="flex-shrink-0 avatar-xs me-2">
                                                                                              <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                                                                                                <b>
                                                                                                  {comment?.commented_by_full_name.charAt(
                                                                                                    0,
                                                                                                  )}
                                                                                                </b>
                                                                                              </div>
                                                                                            </div>
                                                                                          )
                                                                                        ) : null}
                                                                                      </div>
                                                                                      <div className="flex-grow-1 ms-3">
                                                                                        <h5 className="fs-13">
                                                                                          {
                                                                                            comment?.commented_by_full_name
                                                                                          }{" "}
                                                                                          <small className="text-muted ms-2">
                                                                                            {
                                                                                              comment?.comments_datetime_text
                                                                                            }
                                                                                          </small>
                                                                                        </h5>
                                                                                        <p className="text-muted fs-14">
                                                                                          {
                                                                                            comment?.user_comments
                                                                                          }
                                                                                        </p>
                                                                                      </div>
                                                                                    </div>
                                                                                  </>
                                                                                );
                                                                              },
                                                                            )}
                                                                          </div>
                                                                        </Col>
                                                                      </Row>
                                                                    </div>
                                                                  ) : null}
                                                                  {workflow?.user_attachment &&
                                                                    workflow
                                                                      ?.user_attachment
                                                                      .length >
                                                                    0 ? (
                                                                    <div className="pt-3 border-top border-top-dashed mt-0">
                                                                      <Row className="g-2 mb-2">
                                                                        <p className="mb-2 text-uppercase fw-medium">
                                                                          Attachments
                                                                          :
                                                                        </p>
                                                                        {workflow?.user_attachment?.map(
                                                                          (
                                                                            attachment: any,
                                                                            index: number,
                                                                          ) => {
                                                                            const url =
                                                                              new URL(
                                                                                attachment?.file,
                                                                              );
                                                                            const fileName =
                                                                              url.pathname
                                                                                .split(
                                                                                  "/",
                                                                                )
                                                                                .pop();
                                                                            return (
                                                                              <div
                                                                                style={{
                                                                                  width:
                                                                                    "200px",
                                                                                }}
                                                                                className="d-flex me-2 border border-dashed p-2 rounded position-relative"
                                                                              >
                                                                                <div className="flex-shrink-0 avatar-xs">
                                                                                  <div className="avatar-title bg-danger-subtle text-danger fs-15 rounded">
                                                                                    <i className="ri-file-zip-line"></i>
                                                                                  </div>
                                                                                </div>
                                                                                <div className="flex-shrink-0"></div>
                                                                                <div className="flex-grow-1 overflow-hidden ms-2">
                                                                                  <h6 className="text-truncate mb-0">
                                                                                    <Link
                                                                                      to="#"
                                                                                      onClick={() =>
                                                                                        handleFileDownload(
                                                                                          attachment.file_url,
                                                                                        )
                                                                                      }
                                                                                      className="stretched-link"
                                                                                    >
                                                                                      {
                                                                                        fileName
                                                                                      }
                                                                                    </Link>
                                                                                  </h6>
                                                                                  <small>
                                                                                    {
                                                                                      attachment?.uploaded_at_text
                                                                                    }
                                                                                  </small>
                                                                                </div>
                                                                              </div>
                                                                            );
                                                                          },
                                                                        )}
                                                                      </Row>
                                                                    </div>
                                                                  ) : null}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </Col>
                                                        </Row>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Row>
                                              );
                                            },
                                          )}
                                        </CardBody>
                                      </Card>
                                    </AccordionBody>
                                  </AccordionItem>
                                </Accordion>
                              </Col>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      },
                    )}
                  </>
                ) : (
                  <div className="text-center">No Rejected Approvals</div>
                )}
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </>

      {/* Confirm Approve Modal */}
      <Modal
        backdrop={"static"}
        isOpen={confirmApproveModal}
        toggle={toggleConfirmApproveModal}
      >
        <Form onSubmit={(e) => handleApprove(e)}>
          <ModalHeader>Confirm Approval</ModalHeader>
          <ModalBody>
            <p>
              Are you sure you want to approve Action{" "}
              <b color="text-primary">{selectedApprovalWorkflow?.step_name}</b>?
            </p>
            <FormGroup>
              <Label for="approvalComment">Comment</Label>
              <Input
                required
                type="textarea"
                name="approvalComment"
                id="approvalComment"
                placeholder="Add a comment"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              color="success"
              disabled={createApprovelLoading}
            >
              {createApprovelLoading ? (
                <Spinner size="sm" />
              ) : (
                "Confirm Approval"
              )}
            </Button>
            <Button
              color="secondary"
              onClick={toggleConfirmApproveModal}
              disabled={createApprovelLoading}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      {/* Confirm Close Modal */}
      <Modal
        backdrop={"static"}
        isOpen={confirmCloseModal}
        toggle={toggleConfirmCloseModal}
      >
        <Form onSubmit={(e) => handleClosed(e)}>
          <ModalHeader>Close Report</ModalHeader>
          <ModalBody>
            <p>
              Are you sure you want to Close the Report{" "}
              <b color="text-primary">
                {selectedApprovalWorkflow?.approval_id}
              </b>
              ?
            </p>
            <FormGroup>
              <Label for="approvalComment">Add Comment to Close Report</Label>
              <Input
                required
                type="textarea"
                name="approvalComment"
                id="approvalComment"
                placeholder="Add a comment to Close Report"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              color="success"
              disabled={createCloseRevokeLoading}
            >
              {createCloseRevokeLoading ? (
                <Spinner size="sm" />
              ) : (
                "Close Permit"
              )}
            </Button>
            <Button
              color="secondary"
              onClick={toggleConfirmCloseModal}
              disabled={createCloseRevokeLoading}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      {/* Confirm Revoke Modal */}
      <Modal
        backdrop={"static"}
        isOpen={confirmRevokeModal}
        toggle={toggleConfirmRevokeModal}
      >
        <Form onSubmit={(e) => handleRevoked(e)}>
          <ModalHeader>Revoke Report</ModalHeader>
          <ModalBody>
            <p>
              Are you sure you want to Revoke the Report{" "}
              <b color="text-primary">
                {selectedApprovalWorkflow?.approval_id}
              </b>
              ?
            </p>
            <FormGroup>
              <Label for="approvalComment">Add Comment to Revoke Report</Label>
              <Input
                required
                type="textarea"
                name="approvalComment"
                id="approvalComment"
                placeholder="Add a comment to Close Report"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              color="success"
              disabled={createCloseRevokeLoading}
            >
              {createCloseRevokeLoading ? (
                <Spinner size="sm" />
              ) : (
                "Revoke Permit"
              )}
            </Button>
            <Button
              color="secondary"
              onClick={toggleConfirmRevokeModal}
              disabled={createCloseRevokeLoading}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      {/* Nested Reject Modal */}
      <Modal
        backdrop={"static"}
        isOpen={nestedRejectModal}
        toggle={toggleNestedRejectModal}
      >
        <Form onSubmit={(e) => handleSubmitReject(e)}>
          <ModalHeader>Confirm Rejection</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="reason">Reason for Rejection</Label>
              <Input
                type="textarea"
                name="reason"
                id="reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                required
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              type="submit"
              disabled={createApprovelLoading}
            >
              {createApprovelLoading ? (
                <Spinner size="sm" />
              ) : (
                <> Confirm Rejection</>
              )}
            </Button>
            <Button
              color="secondary"
              onClick={toggleNestedRejectModal}
              disabled={createApprovelLoading}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      {/* Confirm Close Modal */}
      <Modal
        backdrop={"static"}
        isOpen={confirmCancelModal}
        toggle={toggleConfirmCancelModal}
      >
        <Form onSubmit={(e) => handleCancel(e)}>
          <ModalHeader>Cancel Permit</ModalHeader>
          <ModalBody>
            <p>
              Are you sure you want to Cancel the Permit{" "}
              <b color="text-primary">{permitNo}</b>?
            </p>
            <FormGroup>
              <Label for="approvalComment">Add Comment to Cancel Permit</Label>
              <Input
                required
                type="textarea"
                name="approvalComment"
                id="approvalComment"
                placeholder="Add a comment to Cancel Report"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              color="success"
              disabled={isLoadingCancelPermit}
            >
              {isLoadingCancelPermit ? <Spinner size="sm" /> : "Cancel Permit"}
            </Button>
            <Button
              color="secondary"
              onClick={toggleConfirmCancelModal}
              disabled={isLoadingCancelPermit}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <ValidationAlertStatic
        modalBackDrop={modalBackDrop}
        setModalBackDrop={setModalBackDrop}
        validationMessages={validationMessages}
        previousPageNavLink="/homeworkpermit-list"
      />
    </React.Fragment>
  );
};

export default SafetyAppOverviewTab;
