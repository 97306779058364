export const getTextColor = (backgroundColor: string) => {
  // Create a new div element and set its background color to parse RGB value
  const color = document.createElement("div");
  color.style.color = backgroundColor;
  document.body.appendChild(color);
  const computedColor = window.getComputedStyle(color).color;
  document.body.removeChild(color);

  // Extract RGB values from the computed color string
  const rgb = computedColor.match(/\d+/g);
  if (rgb) {
    const [r, g, b] = rgb.map(Number);
    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Return white text for dark backgrounds and black text for light backgrounds
    return luminance > 0.5 ? "#000000" : "#FFFFFF";
  }
  return "#000000"; // Default to black if parsing fails
};

export const STATUS_COLORS: Record<string, string> = {
  Draft: "#ffc107", // Yellow
  Resubmission: "#ffc107", // Yellow
  Submitted: "#0d6efd", // Blue
  Approved: "#28a745", // Green
  Revoked: "#dc3545", // Red
  Expired: "#6c757d", // Gray
  Deleted: "#d63384", // Pink
  Closed: "#6f42c1", // Purple
  Rejected: "#dc3545", // Red
  "Pending Approval": "#6c757d", // Gray
  Active: "#28a745", // Green
  Inactive: "#ffc107", // Yellow
  Ended: "#28a745", // Green,
  Cancelled: "#F29F58", // Green
  Linked: "#28a745",
  Reported: "#28a745",
  "Awaiting Action": "#ffc107",
  Resolved: "#0d6efd",
};

export const PAGE_ACCESS_COLORS: Record<string, string> = {
  Add: "#6f42c1", // Purple
  Edit: "#ffc107", // Yellow
  Delete: "#dc3545", // Red
  "View All": "#28a745", // Green
  "View Only": "#0d6efd", // Blue
  "Download Report": "#6c757d", // Gray
  "View Members Password": "#28a745",
  "Allow Members Password Rest": "#6c757d",
};

export const SEVERITY_COLORS: Record<string, string> = {
  High: "#dc3545",
  Medium: "#ffc107",
  Low: "#6c757d",
};

export const generateApprovalUrl = (permit_no: string) => {
  const lowerPermitNo = permit_no.toLowerCase();

  if (lowerPermitNo.includes("_hot_")) {
    return `hotwork-permit-details`;
  } else if (lowerPermitNo.includes("_gw_")) {
    return `generalwork-permit-details`;
  } else if (lowerPermitNo.includes("_wah_")) {
    return `workatheight-permit-details`;
  } else if (lowerPermitNo.includes("_exc_")) {
    return `excavation-permit-details`;
  } else if (lowerPermitNo.includes("_lif_")) {
    return `lifting-permit-details`;
  } else if (lowerPermitNo.includes("_cos_")) {
    return `confinedspace-permit-details`;
  }
  else if (lowerPermitNo.includes("_pil_")) {
    return `piling-permit-details`;
  }
  else if (lowerPermitNo.includes("_pipe_")) {
    return `pipe-jacking-permit-details`;
  } else {
    return `/`; // Default URL
  }
};

export const notificationIcons = {
  MEMBERS: "user",
  TOOLBOX_MEETING: "file-text",
  WORK_AT_HEIGHT_PERMIT: "file-text",
  LIFTING_PERMIT: "file-text",
  EXCAVATION_PERMIT: "file-text",
  CONFINED_SPACE_PERMIT: "file-text",
  GENERAL_WORK_PERMIT: "file-text",
  HOT_WORK_PERMIT: "file-text",
  WORK_AT_HEIGHT_PERMIT_APPROVAL: "file-text",
  LIFTING_PERMIT_APPROVAL: "file-text",
  EXCAVATION_PERMIT_APPROVAL: "file-text",
  CONFINED_SPACE_PERMIT_APPROVAL: "file-text",
  GENERAL_WORK_PERMIT_APPROVAL: "file-text",
  HOT_WORK_PERMIT_APPROVAL: "file-text",
};

export const allowClosePermit = (
  workflowApproval: any,
  selectedRoleName: any,
  getEmployeeUUID: string,
) => {
  if (!workflowApproval?.resource_approval_request) {
    return false;
  }

  let currentUser: boolean = false;
  let roleList: string[] = [];

  workflowApproval.resource_approval_request.forEach((approval: any) => {
    if (approval.is_active) {
      // Check condition: approval_status === 'Submitted' && approved_by_uuid === getEmployeeUUID
      const submittedApprovals = approval.resource_approval.filter(
        (ra: any) =>
          ra.approval_status === "Submitted" &&
          ra.approved_by_uuid === getEmployeeUUID,
      );

      if (submittedApprovals.length > 0) {
        // roleList = submittedApprovals.flatMap((ra: any) => ra.role_name);
        // return; // If the condition is met, exit early
        currentUser = true;
      }

      // Check condition: is_allow_close and role_name inclusion
      const closeApprovals = approval.resource_approval.filter(
        (ra: any) => ra.is_allow_close,
      );
      closeApprovals.forEach((ra: any) => {
        if (ra.role_name.includes(selectedRoleName)) {
          roleList.push(...ra.role_name);
        }
      });
    }
  });

  if (currentUser) {
    return true;
  }

  // Remove duplicates
  const uniqueRoles = [...new Set(roleList)];

  // Return true if roles are found or false if not
  return uniqueRoles.length > 0;
};

export const allowRevokePermit = (
  workflowApproval: any,
  selectedRoleName: any,
  getEmployeeUUID: string,
) => {
  if (!workflowApproval?.resource_approval_request) {
    return false;
  }

  let currentUser: boolean = false;
  let roleList: string[] = [];

  workflowApproval.resource_approval_request.forEach((approval: any) => {
    if (approval.is_active) {
      // Check condition: approval_status === 'Submitted' && approved_by_uuid === getEmployeeUUID
      const submittedApprovals = approval.resource_approval.filter(
        (ra: any) =>
          ra.approval_status === "Submitted" &&
          ra.approved_by_uuid === getEmployeeUUID,
      );

      if (submittedApprovals.length > 0) {
        // roleList = submittedApprovals.flatMap((ra: any) => ra.role_name);
        // return; // If the condition is met, exit early
        currentUser = true;
      }

      // Check condition: is_allow_close and role_name inclusion
      const closeApprovals = approval.resource_approval.filter(
        (ra: any) => ra.is_allow_revoke,
      );
      closeApprovals.forEach((ra: any) => {
        if (ra.role_name.includes(selectedRoleName)) {
          roleList.push(...ra.role_name);
        }
      });
    }
  });

  // if (currentUser) {
  //   return true;
  // }

  // Remove duplicates
  const uniqueRoles = [...new Set(roleList)];

  // Return true if roles are found or false if not
  return uniqueRoles.length > 0;
};
