import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  Input,
  Label,
  Spinner,
  CardFooter,
  Table,
  Col,
} from "reactstrap";
import Select from "react-select";
import {
  useGetWorkflowsQuery,
  useCreateWorkflowMutation,
  useGetRolesQuery,
  useGetWorkflowByIdQuery,
  useUpdateWorkflowMutation,
} from "slices/workflow/WorkFlowSlice";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay-ts";

interface RoleOption {
  value: number;
  label: string;
}

interface Step {
  id: number;
  stepName: string;
  selectedRoles: RoleOption[];
  isRequired: boolean;
}

interface WorkFlowProps {
  setShowAddComponent: (show: boolean) => void;
  workflowId?: string | null;
}

const WorkFlowAdd: React.FC<WorkFlowProps> = ({
  setShowAddComponent,
  workflowId,
}) => {
  const [workFlowData, setWorkFlowData] = useState<{ workflow_name: string }>({
    workflow_name: "",
  });
  const [steps, setSteps] = useState<Step[]>([
    { id: 1, stepName: "", selectedRoles: [], isRequired: false },
  ]);
  const [isDirty, setIsDirty] = useState(false);

  const { data: getRoleInfo, isLoading: loadingRoleInfo } =
    useGetRolesQuery(undefined);
  const [postWorkFlowFn, { isLoading: workFlowLoading }] =
    useCreateWorkflowMutation();
  const [patchWorkFlowFn, { isLoading: loadingpatchWorkFlowFn }] =
    useUpdateWorkflowMutation();
  const {
    data: workFlowDetailsById,
    refetch: workFlowRefetchById,
    isLoading: loadingWorkFlowFn,
  } = useGetWorkflowByIdQuery(workflowId!, {
    skip: !workflowId,
  });
  const { refetch: refetchWorkflow } = useGetWorkflowsQuery("SAFETY_APP");

  useEffect(() => {
    if (workflowId) {
      workFlowRefetchById();
    }
  }, [workflowId, workFlowRefetchById]);

  useEffect(() => {
    if (workFlowDetailsById) {
      setWorkFlowData({ workflow_name: workFlowDetailsById.workflow_name });
      setSteps(
        workFlowDetailsById.approvals
          ? workFlowDetailsById.approvals.map(
              (approval: any, index: number) => ({
                id: index + 1,
                stepName: approval.step_name,
                selectedRoles: approval.role
                  .map((roleId: number) => {
                    const role = getRoleInfo?.find(
                      (role: any) => role.id === roleId,
                    );
                    return role ? { value: role.id, label: role.name } : null;
                  })
                  .filter(
                    (role: RoleOption | null) => role !== null,
                  ) as RoleOption[],
                isRequired: approval.is_required,
              }),
            )
          : [{ id: 1, stepName: "", selectedRoles: [], isRequired: false }],
      );
    }
  }, [workFlowDetailsById, getRoleInfo]);

  const addStep = () => {
    const newStep: Step = {
      id: steps.length + 1,
      stepName: "",
      selectedRoles: [],
      isRequired: false,
    };
    setSteps([...steps, newStep]);
    setIsDirty(true);
  };

  const deleteStep = (index: number) => {
    const updatedSteps = steps.filter((_, i) => i !== index);
    setSteps(updatedSteps);
    setIsDirty(true);
  };

  const prepareWorkflowData = () => {
    const approvals = steps.map((step, index) => ({
      level: index + 1,
      step_name: step.stepName,
      role: step.selectedRoles.map((role) => role.value),
      is_required: true,
      // is_required: step.isRequired,
    }));
    return {
      workflow_name: workFlowData.workflow_name,
      category_name: "SAFETY_APP",
      approvals: approvals,
    };
  };

  const saveWorkflow = (e: any) => {
    e.preventDefault();
    const workflowData = prepareWorkflowData();
    if (workflowId) {
      patchWorkFlowFn({ id: workflowId, ...workflowData })
        .unwrap()
        .then(() => {
          setIsDirty(false);
          setWorkFlowData({ workflow_name: "" });
          setSteps([
            { id: 1, stepName: "", selectedRoles: [], isRequired: false },
          ]);
          setShowAddComponent(false);
          toast.success("Workflow Updated");
          refetchWorkflow();
        });
    } else {
      postWorkFlowFn(workflowData)
        .unwrap()
        .then(() => {
          setIsDirty(false);
          setWorkFlowData({ workflow_name: "" });
          setSteps([
            { id: 1, stepName: "", selectedRoles: [], isRequired: false },
          ]);
          setShowAddComponent(false);
          toast.success("Workflow Added");
          refetchWorkflow();
        });
    }
  };

  const handleStepChange = (index: number, field: keyof Step, value: any) => {
    setSteps((prevSteps) =>
      prevSteps.map((step, i) =>
        i === index ? { ...step, [field]: value } : step,
      ),
    );
    setIsDirty(true);
  };

  const handleMultiSelectChange = (
    index: number,
    selectedOptions: RoleOption[],
  ) => {
    setSteps((prevSteps) =>
      prevSteps.map((step, i) =>
        i === index ? { ...step, selectedRoles: selectedOptions } : step,
      ),
    );
    setIsDirty(true);
  };

  const handleCheckboxChange = (index: number, isChecked: boolean) => {
    setSteps((prevSteps) =>
      prevSteps.map((step, i) =>
        i === index ? { ...step, isRequired: isChecked } : step,
      ),
    );
    setIsDirty(true);
  };

  return (
    <Card>
      <form onSubmit={(e) => saveWorkflow(e)}>
        <CardHeader>
          <div
            className="d-flex align-items-center"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>
              <Label for="workflowName">Workflow Name</Label>
              <Input
                id="workflowName"
                value={workFlowData.workflow_name}
                required
                onChange={(e) =>
                  setWorkFlowData({ workflow_name: e.target.value })
                }
              />
            </div>
            &nbsp;&nbsp;&nbsp;
            <div>
              <Button
                className="btn btn-primary btn-md shadow-none mt-4"
                onClick={addStep}
              >
                Add Step
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <Table>
            <thead>
              <tr>
                <th>Level</th>
                {/* <th>Is Required</th> */}
                <th>Step Name</th>
                <th>Roles</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {steps.map((step, index) => (
                <tr key={step.id} className="mb-3">
                  <td width={200}>Level {index + 1} Approval</td>
                  {/* <td width={150}>
                    <Input
                      id={`isRequired-${index}`}
                      type="checkbox"
                      checked={step.isRequired}
                      onChange={(e) =>
                        handleCheckboxChange(index, e.target.checked)
                      }
                    />
                  </td> */}
                  <td width={200}>
                    <Input
                      id={`stepName-${index}`}
                      required
                      value={step.stepName}
                      onChange={(e) =>
                        handleStepChange(index, "stepName", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <Select
                      id={`stepRoles-${index}`}
                      required
                      isMulti
                      value={step.selectedRoles}
                      onChange={(selectedOptions: any) =>
                        handleMultiSelectChange(
                          index,
                          selectedOptions as RoleOption[],
                        )
                      }
                      options={getRoleInfo?.map((role: any) => ({
                        value: role.id,
                        label: role.name,
                      }))}
                      closeMenuOnSelect={false}
                    />
                  </td>
                  <td>
                    <Button
                      className="text-white d-inline-block btn-sm"
                      onClick={() => deleteStep(index)}
                      type="submit"
                      style={{ marginTop: "3px" }}
                    >
                      <i className="ri-delete-bin-5-fill fs-16"></i>{" "}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
        <CardFooter className="d-flex align-items-center">
          <Button
            color="primary"
            disabled={workFlowLoading || loadingpatchWorkFlowFn}
          >
            {" "}
            {loadingpatchWorkFlowFn && <Spinner size="sm" className="me-2" />}
            {workflowId ? "Update" : "Save"}
          </Button>
          &nbsp;
          <Button
            color="danger"
            disabled={workFlowLoading || loadingpatchWorkFlowFn}
            onClick={() => setShowAddComponent(false)}
          >
            Cancel
          </Button>
        </CardFooter>
      </form>
    </Card>
  );
};

export default WorkFlowAdd;
