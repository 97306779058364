import { createApi } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "helpers/jwt-token-access/customBaseQuery";
import {
  WorkLocationType,
  WorkPositionType,
  ProjectType,
  EmployeeDropDownType,
  EmployeeDocumentType,
  EmployeeSignatureType,
} from "./type";

const convertToFormData = (
  obj: Record<string, any> | undefined | null,
  parentKey: string = "",
): FormData => {
  const formData = new FormData();

  const appendData = (key: string, value: any) => {
    if (value instanceof File) {
      formData.append(key, value);
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        if (item instanceof File) {
          formData.append(`${key}[${index}]`, item);
        } else {
          formData.append(`${key}[${index}]`, item);
        }
      });
    } else if (value && typeof value === "object") {
      if (value instanceof Date) {
        formData.append(key, value.toISOString());
      } else {
        Object.keys(value).forEach((subKey) => {
          appendData(`${key}[${subKey}]`, value[subKey]);
        });
      }
    } else {
      formData.append(key, value);
    }
  };

  if (obj) {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      appendData(parentKey ? `${parentKey}[${key}]` : key, value);
    });
  }

  return formData;
};

const convertToRoleFormData = (obj: any) => {
  const formData = new FormData();
  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      obj[key].forEach((item: any) => formData.append(`${key}[]`, item));
    } else {
      formData.append(key, obj[key]);
    }
  });
  return formData;
};

export const employeeSlice = createApi({
  reducerPath: "api",
  baseQuery: customBaseQuery,
  tagTypes: [
    "GetEmployees",
    "GetEmployeesUUID",
    "GetEmployeesDropDown",
    "GetEmployeePersonalInfo",
    "GetEmergencyContactInfo",
    "GetDigitalSignatureByEmployeeUUID",
  ],
  endpoints: (builder) => ({
    getEmployees: builder.query({
      query: (page) => `/employee/api/v1/employee_info/?page=${page.page}&page_size=${page.pageSize}&search=${page.search}&projects=${page.projects}&position=${page.position}&sub_company_name=${page.sub_company_name}`,
      providesTags: ['GetEmployees'],
    }),
    createEmployee: builder.mutation({
      query: (newEmployee) => ({
        url: "/employee/api/v1/employee_onboarding/",
        method: "POST",
        body: newEmployee,
      }),
      invalidatesTags: [
        { type: "GetEmployees" },
        { type: "GetEmployeesDropDown" },
      ],
    }),
    getEmployeeByUuid: builder.query({
      query: (employee_uuid) =>
        `/employee/api/v1/employee_info/${employee_uuid}/`,
      providesTags: ["GetEmployeesUUID"],
    }),
    patchEmployee: builder.mutation({
      query: ({ employee_uuid, updatedData }) => {
        console.log("updatedData", updatedData);
        const formData = convertToFormData(updatedData);
        return {
          url: `/employee/api/v1/employee_view/${employee_uuid}/`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: [
        { type: "GetEmployees" },
        { type: "GetEmployeesUUID" },
        { type: "GetEmployeesDropDown" },
      ],
    }),
    patchEmployeeProject: builder.mutation({
      query: ({ employee_uuid, updatedData }) => {
        console.log("updatedData", updatedData);
        const formData = convertToFormData(updatedData);
        return {
          url: `/employee/api/v1/employee_project_update/${employee_uuid}/`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: [
        { type: "GetEmployees" },
        { type: "GetEmployeesUUID" },
        { type: "GetEmployeesDropDown" },
      ],
    }),
    patchProjectTeamEmployeeAssigned: builder.mutation({
      query: ({ project_id, updatedData }) => {
        return {
          url: `/employee/api/v1/employee_project_team_assigned/${project_id}`,
          method: "PATCH",
          body: updatedData,
        };
      },
      invalidatesTags: [
        { type: "GetEmployees" },
        { type: "GetEmployeesUUID" },
        { type: "GetEmployeesDropDown" },
      ],
    }),
    deleteProjectTeamEmployeeAssigned: builder.mutation({
      query: ({ project_id, updatedData }) => {
        return {
          url: `/employee/api/v1/employee_project_team_assigned_delete/${project_id}`,
          method: "DELETE",
          body: updatedData,
        };
      },
      invalidatesTags: [
        { type: "GetEmployees" },
        { type: "GetEmployeesUUID" },
        { type: "GetEmployeesDropDown" },
      ],
    }),
    getProjects: builder.query<ProjectType[], void>({
      query: () => "/organization/api/v1/projects/",
    }),
    getWorkLocations: builder.query<WorkLocationType[], void>({
      query: () => "/employee/api/v1/employee_work_location/",
    }),
    getEmployeeDropDown: builder.query<EmployeeDropDownType[], void>({
      query: () => "/employee/api/v1/employee_drop_down/",
      providesTags: ["GetEmployeesDropDown"],
    }),
    getEmployeeDropDownByProject: builder.query<EmployeeDropDownType[], any>({
      query: (project_id_list) =>
        `/employee/api/v1/employee_drop_down/?project_id=${project_id_list}`,
      providesTags: ["GetEmployeesDropDown"],
    }),
    getEmployeeDropDownByRole: builder.query<EmployeeDropDownType[], any>({
      query: (role_keys) =>
        `/employee/api/v1/employee_drop_down/?role_keys=${role_keys}`,
      providesTags: ["GetEmployeesDropDown"],
    }),
    getEmployeeDropDownByUUId: builder.query<EmployeeDropDownType[], any>({
      query: (employee_uuid) =>
        `/employee/api/v1/employee_drop_down/?employee_uuid=${employee_uuid}`,
      providesTags: ["GetEmployeesDropDown"],
    }),
    getEmployeeDropDownByPosition: builder.query<EmployeeDropDownType[], any>({
      query: (position) =>
        `/employee/api/v1/employee_drop_down/?position=${position}`,
      providesTags: ["GetEmployeesDropDown"],
    }),
    getEmployeeDropDownByProjectPosition: builder.query<
      EmployeeDropDownType[],
      any
    >({
      query: ({ projectId, position, teams }) =>
        `/employee/api/v1/employee_drop_down/?project_id=${projectId}&position=${position}&teams=${teams}`,
      providesTags: ["GetEmployeesDropDown"],
    }),
    getPositions: builder.query<WorkPositionType[], void>({
      query: () => "/employee/api/v1/employee_role_policy/",
    }),

    // New endpoints
    getEmployeePersonalInfo: builder.query({
      query: (employee_uuid) =>
        `/employee/api/v1/employee_personal_info/${employee_uuid}/`,
      providesTags: ["GetEmployeePersonalInfo"],
    }),
    patchEmployeePersonalInfo: builder.mutation({
      query: ({ employee_uuid, ...updateData }) => ({
        url: `/employee/api/v1/employee_personal_info/${employee_uuid}/`,
        method: "PATCH",
        body: updateData,
      }),
      invalidatesTags: [
        { type: "GetEmployees" },
        { type: "GetEmployeePersonalInfo" },
      ],
    }),
    getEmergencyContactInfo: builder.query({
      query: (employee_uuid) =>
        `/employee/api/v1/emergency_contact_info/${employee_uuid}/`,
      providesTags: ["GetEmergencyContactInfo"],
    }),
    patchEmergencyContactInfo: builder.mutation({
      query: ({ employee_uuid, ...updateData }) => ({
        url: `/employee/api/v1/emergency_contact_info/${employee_uuid}/`,
        method: "PATCH",
        body: updateData,
      }),
      invalidatesTags: [
        { type: "GetEmployees" },
        { type: "GetEmergencyContactInfo" },
      ],
    }),
    getEmployeeCountryOfIssue: builder.query({
      query: () => "/employee/api/v1/employee_country_of_issue/",
    }),

    patchEmployeeRole: builder.mutation({
      query: ({ employee_uuid, updateData }) => {
        const formData = convertToRoleFormData(updateData);
        return {
          url: `/employee/api/v1/employee_role_update/${employee_uuid}/`,
          method: "PATCH",
          body: formData,
        };
      },
    }),

    getDocumentsByEmployeeUuid: builder.query<
      EmployeeDocumentType[],
      string | undefined
    >({
      query: (employee_uuid) =>
        `/employee/api/v1/employee_documents/?employee__employee_uuid=${employee_uuid}`,
    }),

    // Post a new document using FormData
    postDocument: builder.mutation({
      query: (newDocument) => {
        const formData = convertToFormData(newDocument);
        return {
          url: "/employee/api/v1/employee_documents/",
          method: "POST",
          body: formData,
        };
      },
    }),

    getDocumentById: builder.query({
      query: (document_id) =>
        `/employee/api/v1/employee_documents/${document_id}/`,
    }),

    // Patch (update) a document by document ID using FormData
    patchDocumentById: builder.mutation({
      query: ({ id, formData }) => {
        const userData = convertToFormData(formData);
        return {
          url: `/employee/api/v1/employee_documents/${id}/`,
          method: "PATCH",
          body: userData,
        };
      },
    }),

    deleteDocumentById: builder.mutation({
      query: (id) => ({
        url: `/employee/api/v1/employee_documents/${id}/`,
        method: "DELETE",
      }),
    }),

    // Get employee profile image by UUID
    getEmployeeProfileImage: builder.query({
      query: (employee_uuid) =>
        `/employee/api/v1/employee_profile_image/${employee_uuid}/`,
    }),

    // Patch (update) employee profile image by UUID
    patchEmployeeProfileImage: builder.mutation({
      query: ({ employee_uuid, updateData }) => {
        const formData = convertToFormData(updateData);
        return {
          url: `/employee/api/v1/employee_profile_image/${employee_uuid}/`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
    getEmployeeByUsernameExist: builder.mutation({
      query: (username) => ({
        url: `/employee/api/v1/employee_onboard_verify_user/?username=${encodeURIComponent(username)}`,
        method: "GET",
        responseHandler: async (response: any) => {
          const data = await response.json();
          if (data.length > 0) {
            return false;
          }
          return true;
        },
      }),
    }),
    // Get employee UserName PassWord by UUID
    getEmployeeUserNamePassWord: builder.mutation({
      query: (employee_uuid) =>
        `/user_management/username-password/?employee_uuid=${employee_uuid}`,
    }),

    // New query to get audit logs
    getAuditLogs: builder.query({
      query: ({ model_name, object_id }) =>
        `/user_management/audit-logs/?model_name=${model_name}&object_id=${object_id}`,
    }),
    getImportDownloadLink: builder.query({
      query: (filename) =>
        `/user_management/download-import-excel/?file_name=${encodeURIComponent(
          filename,
        )}`,
    }),
    postImportDocument: builder.mutation({
      //@ts-ignore
      queryFn: async (newDocument, _api, _extraOptions, baseQuery) => {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 300000); // 5-minute timeout

        try {
          const result = await baseQuery({
            url: "/user_management/import-excel-validation/",
            method: "POST",
            body: newDocument,
            signal: controller.signal,
          });

          clearTimeout(timeoutId);

          if (result.error) {
            return { error: result.error };
          }

          return { data: result.data };
        } catch (error) {
          clearTimeout(timeoutId);
          return {
            error: {
              status: "FETCH_ERROR",
              message: "Request timed out or failed",
            },
          };
        }
      },
    }),

    postBulkImportDocument: builder.mutation({
      //@ts-ignore
      queryFn: async (newDocument, _api, _extraOptions, baseQuery) => {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 300000); // 5-minute timeout

        try {
          const result = await baseQuery({
            url: "/user_management/bulk-import-excel/",
            method: "POST",
            body: newDocument,
            signal: controller.signal,
          });

          clearTimeout(timeoutId);
          if (result.error) {
            return { error: result.error };
          }

          return { data: result.data };
        } catch (error) {
          clearTimeout(timeoutId);
          return {
            error: {
              status: "FETCH_ERROR",
              message: "Request timed out or failed",
            },
          };
        }
      },
    }),

    getUserPermissionsByRoleId: builder.query({
      query: (role_id) => `/user_management/user-permissions/${role_id}`,
    }),
    getUserPermissionsByRoleIdAction: builder.mutation({
      query: (role_id) => ({
        url: `/user_management/user-permissions/${role_id}`,
        method: "GET",
        responseHandler: async (response: any) => {
          const data = await response.json();
          console.log("data", data);
          return data;
        },
      }),
    }),
    postDigitalSignature: builder.mutation({
      query: (signature) => {
        return {
          url: "/employee/api/v1/employee_signature/",
          method: "POST",
          body: signature,
        };
      },
      invalidatesTags: [
        { type: "GetEmployeesUUID" },
        { type: "GetDigitalSignatureByEmployeeUUID" },
      ],
    }),
    getDigitalSignatureByEmployeeUuid: builder.query<
      EmployeeSignatureType[],
      string | undefined
    >({
      query: (employee_uuid) =>
        `/employee/api/v1/employee_signature/?employee__employee_uuid=${employee_uuid}`,
      providesTags: ["GetDigitalSignatureByEmployeeUUID"],
    }),
    deleteSignatureById: builder.mutation({
      query: (id) => ({
        url: `/employee/api/v1/employee_signature/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        { type: "GetEmployeesUUID" },
        { type: "GetDigitalSignatureByEmployeeUUID" },
      ],
    }),
    getDeviceRedeemToken: builder.query({
      query: () => `/user_management/get-redeem-token/`,
    }),
    updateDeviceType: builder.mutation({
      query: (payload) => {
        return {
          url: "/user_management/assign-redeem-token/",
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: [{ type: "GetEmployeesUUID" }],
    }),
    getEmployeesRoles: builder.query({
      query:()=>`/employee/api/v1/employee_role_policy/`,
    })
  }),
});

export const {
  useGetEmployeesQuery,
  useCreateEmployeeMutation,
  useGetEmployeeByUuidQuery,
  usePatchEmployeeMutation,
  usePatchEmployeeProjectMutation,
  usePatchProjectTeamEmployeeAssignedMutation,
  useDeleteProjectTeamEmployeeAssignedMutation,
  useGetProjectsQuery,
  useGetWorkLocationsQuery,
  useGetEmployeeDropDownQuery,
  useGetEmployeeDropDownByProjectQuery,
  useGetEmployeeDropDownByRoleQuery,
  useGetEmployeeDropDownByUUIdQuery,
  useGetEmployeeDropDownByPositionQuery,
  useGetEmployeeDropDownByProjectPositionQuery,
  useGetPositionsQuery,
  useGetEmployeePersonalInfoQuery,
  usePatchEmployeePersonalInfoMutation,
  useGetEmergencyContactInfoQuery,
  usePatchEmergencyContactInfoMutation,
  useGetEmployeeCountryOfIssueQuery,
  usePatchEmployeeRoleMutation,
  useGetDocumentsByEmployeeUuidQuery,
  usePostDocumentMutation,
  useGetDocumentByIdQuery,
  usePatchDocumentByIdMutation,
  useDeleteDocumentByIdMutation,
  useGetEmployeeProfileImageQuery,
  usePatchEmployeeProfileImageMutation,
  useGetEmployeeByUsernameExistMutation,
  useGetImportDownloadLinkQuery,
  useGetEmployeeUserNamePassWordMutation,
  useGetAuditLogsQuery,
  usePostImportDocumentMutation,
  usePostBulkImportDocumentMutation,
  useGetUserPermissionsByRoleIdQuery,
  useGetUserPermissionsByRoleIdActionMutation,
  usePostDigitalSignatureMutation,
  useGetDigitalSignatureByEmployeeUuidQuery,
  useDeleteSignatureByIdMutation,

  useGetEmployeesRolesQuery,
  useGetDeviceRedeemTokenQuery,
  useUpdateDeviceTypeMutation,
} = employeeSlice;
