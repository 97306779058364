import PropTypes from "prop-types";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

import { logoutUser } from "../../slices/thunks";

//redux
import { useDispatch } from "react-redux";

import withRouter from "../../Components/Common/withRouter";

const Logout = () => {
  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  return <Navigate to="/login" />;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);
