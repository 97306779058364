import React, { useMemo, useState, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import BreadCrumb from "Components/Common/BreadCrumb";
import { useGetProjectsByDropDownQuery } from "slices/projects/projectSlice";
import { useGenerateHomeworkPermitPDFMutation } from "slices/safetyApp/safetyappSlice";

import { useGetNearMissIncidentReportQuery } from "slices/incidentReport/incidentReportSlice";
import TableContainer from "Components/Common/TableContainer";
import Loader from "Components/Common/Loader";
import { Tooltip } from "react-tooltip";
import { BadgeStatus } from "helpers/badgeStatus";
import { formatDateTime } from "helpers/date_utils";
import { STATUS_COLORS, SEVERITY_COLORS } from "helpers/common_helper";
import { IsValidPermissions } from "Routes/AuthProtected";

const NearMissReportListView = () => {
  const [page, setPageState] = useState<{
    page: number;
    pageSize: number;
    search: string;
    status: string;
    start_date_of_work: string;
    end_date_of_work: string;
  }>({
    page: 1,
    pageSize: 10,
    search: "",
    status: "",
    start_date_of_work: "",
    end_date_of_work: "",
  });
  const {
    data: toolboxMeetings,
    isLoading,
    isFetching,
  } = useGetNearMissIncidentReportQuery(page);
  const { data: projectData } = useGetProjectsByDropDownQuery(undefined);
  const [generatePDF] = useGenerateHomeworkPermitPDFMutation();
  const navigate = useNavigate();

  // State for Modal and Selected Project
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<string | null>(null);
  // Toggle Modal
  const toggleModal = useCallback(() => {
    setIsModalOpen((prev) => !prev);
  }, []);

  // Map Projects to Dropdown Options
  const projectOptions = useMemo(() => {
    return (
      projectData?.map((project: any) => ({
        value: project.id,
        label: `${project.project_name} (${project.project_short_name})`,
      })) || []
    );
  }, [projectData]);

  // Redirect to Home Work Permit Wizard with Project ID in the URL
  const handleCreateNewToolBox = () => {
    if (selectedProject) {
      navigate(`/nearmiss-report-wizard/${selectedProject}`);
    }
  };

  // Define Columns for Table
  const columns = useMemo(
    () => [
      {
        Header: "Incidents ID",
        accessor: "incident_no",
        Cell: ({
          row: {
            original: { incident_no },
          },
        }: any) => (
          <Link
            to={`/nearmiss-report-detail-view/${incident_no}/`}
            className="text-primary d-inline-block"
          >
            {incident_no}
          </Link>
        ),
      },
      {
        Header: "Project Name",
        accessor: "project_name",
      },
      {
        Header: "Incident Date",
        accessor: "incident_date", // Start Date
        Cell: ({
          row: {
            original: { incident_date, incident_time },
          },
        }: any) => (
          <>
            {formatDateTime(incident_date, "date")}{" "}
            {formatDateTime(incident_time, "time")}
          </>
        ),
      },
      {
        Header: "Reported Date",
        accessor: "date_reported", // Start Date
        Cell: ({
          row: {
            original: { date_reported },
          },
        }: any) => <>{formatDateTime(date_reported, "datetime")}</>,
      },
      {
        Header: "Severity",
        accessor: "severity_name",
        Cell: (cell: { value: any }) => (
          <BadgeStatus value={cell.value} color={SEVERITY_COLORS[cell.value]} />
        ),
      },
      {
        Header: "Status",
        accessor: "status_name",
        Cell: (cell: { value: any }) => (
          <BadgeStatus value={cell.value} color={STATUS_COLORS[cell.value]} />
        ),
      },
      {
        Header: "Reported By",
        accessor: "reported_by_name",
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({
          row: {
            original: { incident_no, report_status_name, project },
          },
        }: any) => (
          <div className="d-flex gap-2">
            <Link
              to={`/nearmiss-report-detail-view/${incident_no}/`}
              className="text-primary"
            >
              <i className="ri-eye-fill fs-16"></i>
            </Link>

            <Link
              to={`/nearmiss-report-wizard/${project}/${incident_no}/`}
              className="text-warning"
            >
              <i className="ri-pencil-fill fs-16"></i>
            </Link>

            {/* <li className="list-inline-item edit" title="Export Permit">
                <Link
                  to={`#`}
                  className="text-primary d-inline-block edit-item-btn export-task-status-class"
                  onClick={() => generatePDF(incident_no)}
                >
                  <i className="ri-download-fill"></i>
                </Link></li> <></> */}
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <React.Fragment>
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".view-task-status-class"
        place="bottom"
        content="View"
      />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Near Miss List" pageTitle="Safety App" />
          <Row>
            <Col lg={12}>
              <Card id="toolboxMeetingList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <Col sm>
                      <div>
                        <h5 className="card-title mb-0">
                          Near Miss Report List
                        </h5>
                      </div>
                    </Col>
                    <Col sm="auto">
                      <div>
                        {IsValidPermissions(["ADD_NEAR_MISS_INCIDENT"]) && (
                          <Button color="success" onClick={toggleModal}>
                            <i className="ri-add-line align-bottom me-1"></i>
                            Add Near Miss Report
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={toolboxMeetings?.results || []}
                      isLoading={isFetching}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={page.pageSize}
                      className="custom-header-css"
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                      theadClass="table-light text-muted"
                      thClass="table-light text-muted"
                      SearchPlaceholder="Search for Near Miss Report..."
                      isPagination={false}
                      isServerPagination={true}
                      // isToolBoxFilter={true}
                      serverPaginationProps={{
                        count: toolboxMeetings?.count || 0,
                        next: toolboxMeetings?.next || null,
                        page_size: toolboxMeetings?.page_size || 10,
                        previous: toolboxMeetings?.previous || null,
                        current_page: toolboxMeetings?.current_page || 1,
                        total_page: toolboxMeetings?.total_page || 0,
                      }}
                      setPageState={setPageState}
                    />
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal isOpen={isModalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            Create Near Miss Report
          </ModalHeader>
          <ModalBody>
            <Label for="projectSelect">Project</Label>
            <Input
              type="select"
              id="projectSelect"
              onChange={(e) => setSelectedProject(e.target.value)}
              value={selectedProject || ""}
            >
              <option value="">Select Project</option>
              {projectOptions.map((project: any) => (
                <option key={project.value} value={project.value}>
                  {project.label}
                </option>
              ))}
            </Input>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={handleCreateNewToolBox}
              disabled={!selectedProject}
            >
              Create Near Miss Report
            </Button>
            <Button color="danger" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default NearMissReportListView;
