import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Col,
  Container,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Tooltip } from "react-tooltip";
import { ServiceContext } from "context/ServiceContextProvider";
import classnames from "classnames";
import SimpleBar from "simplebar-react";
import {
  useGetUserNotificationQuery,
  usePatchNotificationMutation,
} from "slices/notification/NotificationSlice";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { notificationIcons } from "../../helpers/common_helper";

const NotificationList = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("1");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // Check if create_task is true in the URL search params
    if (searchParams.get("primary") === "true") {
      setActiveTab("1");
    }
    if (searchParams.get("general") === "true") {
      setActiveTab("2");
    }
  }, [searchParams]);

  const [page, setPageState] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 1,
    pageSize: 10,
  });

  const {
    data: allNotification,
    isLoading: isNotificationLoading,
    refetch: refetchNotification,
  } = useGetUserNotificationQuery(page);

  const [updateNotification] = usePatchNotificationMutation();

  useEffect(() => {
    refetchNotification();
  }, [page]);

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const selfNotifications =
    allNotification?.results?.filter(
      (item: any) => item.message_type === "Self",
    ) || [];
  const generalNotifications =
    allNotification?.results?.filter(
      (item: any) => item.message_type === "General",
    ) || [];

  const handleNotificationClick = async (notification: any) => {
    const { id, code, notify_id } = notification;

    updateNotification({
      notify_id: id,
      updateData: { is_read: true },
    });

    if (code === "MEMBERS") {
      navigate(`/profile/${notify_id}`);
    } else if (code === "TOOLBOX_MEETING") {
      navigate(`/toolbox-meeting-detail-view/${notify_id}/`);
    } else if (code === "HOT_WORK_PERMIT") {
      navigate(`/hotwork-permit-details/${notify_id}/`);
    } else if (code === "GENERAL_WORK_PERMIT") {
      navigate(`/generalwork-permit-details/${notify_id}/`);
    } else if (code === "WORK_AT_HEIGHT_PERMIT") {
      navigate(`/workatheight-permit-details/${notify_id}/`);
    } else if (code === "EXCAVATION_PERMIT") {
      navigate(`/excavation-permit-details/${notify_id}/`);
    } else if (code === "LIFTING_PERMIT") {
      navigate(`/lifting-permit-details/${notify_id}/`);
    } else if (code === "CONFINED_SPACE_PERMIT") {
      navigate(`/confinedspace-permit-details/${notify_id}/`);
    } else if (code === "HOT_WORK_PERMIT_APPROVAL") {
      navigate(`/hotwork-permit-details/${notify_id}?approval=true`);
    } else if (code === "GENERAL_WORK_PERMIT_APPROVAL") {
      navigate(`/generalwork-permit-details/${notify_id}?approval=true`);
    } else if (code === "WORK_AT_HEIGHT_PERMIT_APPROVAL") {
      navigate(`/workatheight-permit-details/${notify_id}?approval=true`);
    } else if (code === "EXCAVATION_PERMIT_APPROVAL") {
      navigate(`/excavation-permit-details/${notify_id}?approval=true`);
    } else if (code === "LIFTING_PERMIT_APPROVAL") {
      navigate(`/lifting-permit-details/${notify_id}?approval=true`);
    } else if (code === "CONFINED_SPACE_PERMIT_APPROVAL") {
      navigate(`/confinedspace-permit-details/${notify_id}?approval=true`);
    } else if (code === "TOOLBOX_MEETING") {
      navigate(`/toolbox-meeting-detail-view/${notify_id}/`);
    } else {
      console.warn("Unhandled notification code:", code);
    }
  };

  const renderNotificationItem = (notification: any) => {
    //@ts-ignore
    const icon = notificationIcons[notification.code] || "user";

    return (
      <div
        className="mb-2"
        key={notification.id}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "aliceblue",
          padding: "10px",
        }}
      >
        <div className="d-flex">
          <div className="flex-shrink-0">
            <FeatherIcon
              icon={icon}
              className="me-3 text-bg-secondary avatar-xs rounded-circle"
            />
          </div>
          <div className="flex-grow-1">
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                handleNotificationClick(notification);
              }}
              className="stretched-link"
            >
              <h6 className="mt-0 mb-2 lh-base fw-bold">
                {notification.title}
              </h6>
              <h6 className="mt-0 mb-2 lh-base text-muted">
                {notification.message}
              </h6>
            </Link>
            <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
              <span>
                <i className="mdi mdi-clock-outline"></i>{" "}
                {notification.created_at_text}
              </span>
            </p>
          </div>
        </div>
        <div>
          <span
            className={`badge ${notification.is_read ? "bg-success" : "bg-danger"} text-white`}
            style={{
              fontSize: "0.75rem",
              padding: "0.25em 0.5em",
              borderRadius: "0.25rem",
            }}
          >
            {notification.is_read ? "Read" : "Unread"}
          </span>
        </div>
      </div>
    );
  };

  const handlePreviousPage = () => {
    if (page.page > 1) {
      setPageState((prev) => ({ ...prev, page: prev.page - 1 }));
    }
  };

  const handleNextPage = () => {
    if (allNotification?.results?.length === page.pageSize) {
      setPageState((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };

  if (isNotificationLoading) {
    return (
      <Container fluid>
        <BreadCrumb title="Mobile App" pageTitle="Dashboard" />
        <Row>
          <Spinner />
        </Row>
      </Container>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Notification" pageTitle="Dashboard" />
          <Row>
            <Col>
              <Card className="mt-4">
                <CardBody>
                  <CardTitle tag="h5">
                    <h4>Notifications</h4>
                  </CardTitle>
                  <Row>
                    <Col md={12}>
                      <div>
                        <Nav className="nav-tabs dropdown-tabs nav-tabs-custom mt-3">
                          <NavItem>
                            <NavLink
                              href="#"
                              className={classnames({
                                active: activeTab === "1",
                              })}
                              onClick={() => toggleTab("1")}
                            >
                              Primary{" "}
                              {allNotification?.summary?.primary > 0 ? (
                                <>({allNotification?.summary?.primary})</>
                              ) : null}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              href="#"
                              className={classnames({
                                active: activeTab === "2",
                              })}
                              onClick={() => toggleTab("2")}
                            >
                              General{" "}
                              {allNotification?.summary?.general > 0 ? (
                                <>({allNotification?.summary?.general})</>
                              ) : null}
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent activeTab={activeTab}>
                          <TabPane tabId="1" className="py-2 ps-2">
                            <SimpleBar className="pe-2">
                              {selfNotifications.length > 0 ? (
                                selfNotifications.map(renderNotificationItem)
                              ) : (
                                <div className="text-center text-muted">
                                  No Primary Notifications
                                </div>
                              )}
                            </SimpleBar>
                          </TabPane>
                          <TabPane tabId="2" className="py-2 ps-2">
                            <SimpleBar className="pe-2">
                              {generalNotifications.length > 0 ? (
                                generalNotifications.map(renderNotificationItem)
                              ) : (
                                <div className="text-center text-muted">
                                  No General Notifications
                                </div>
                              )}
                            </SimpleBar>
                          </TabPane>
                        </TabContent>

                        {/* Pagination Controls */}
                        <div className="d-flex justify-content-between align-items-center mt-3">
                          <button
                            className="btn btn-primary"
                            onClick={handlePreviousPage}
                            disabled={page.page === 1}
                          >
                            Previous
                          </button>
                          <span>Page {page.page}</span>
                          <button
                            className="btn btn-primary"
                            onClick={handleNextPage}
                            disabled={
                              allNotification?.results?.length < page.pageSize
                            }
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NotificationList;
