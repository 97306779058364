import React, { useState, useCallback, useEffect } from "react";
import { Container, Col, Row, Spinner } from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import Summary from "./Section";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useGetLiftingWorkPermitByNumberQuery } from "slices/safetyApp/safetyappSlice";

const LiftingPermitDetails = () => {
  document.title = "Lifting Permit Details";
  //Dropzone file upload
  const { hwPermitNo: hwPermitNoFromURL } = useParams<{ hwPermitNo: string }>();

  const {
    data: getLiftingPermitById,
    isLoading,
    refetch: refetchLiftingworkPermit,
  } = useGetLiftingWorkPermitByNumberQuery(hwPermitNoFromURL);

  useEffect(() => {
    refetchLiftingworkPermit();
  }, []);

  if (isLoading) {
    return (
      <React.Fragment>
        <Container fluid>
          <div className="page-content" style={{ height: "80vh" }}>
            {" "}
            {/* Full viewport height */}
            <Container fluid className="h-100">
              <Row className="h-100 d-flex justify-content-center align-items-center">
                <Col lg={12} className="text-center">
                  <Spinner color="primary"></Spinner> <br /> <br />
                  <span className="text-muted">
                    Fetching Lifting Permit Information...
                  </span>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Container fluid>
        <BreadCrumb
          title="Lifting Permit Details"
          pageTitle="Tasks"
          style={{ "margin-top": "70px" }}
        />

        <Summary getLiftingPermitById={getLiftingPermitById} />
        <ToastContainer closeButton={false} limit={1} />
      </Container>
    </React.Fragment>
  );
};

export default LiftingPermitDetails;
