import React, { useState } from "react";
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";

interface TaskProps {
  getTaskById: any;
}

const TaskTracking: React.FC<TaskProps> = ({ getTaskById }) => {
  return (
    <React.Fragment>
      <div className="card mb-3">
        <div className="card-body">
          <div className="d-flex mb-3">
            <h6 className="card-title mb-0 flex-grow-1">Assigned To</h6>
          </div>
          <ul className="list-unstyled vstack gap-3 mb-0">
            {getTaskById?.get_employee_assignee_obj.map((item: any) => (
              <li className="border rounded border-dashed p-2">
                <div className="d-flex align-items-center">
                  {item.assignee_img_url ? (
                    <div className="flex-shrink-0">
                      <img
                        src={item.assignee_img_url}
                        alt=""
                        className="avatar-xs rounded-circle"
                      />
                    </div>
                  ) : (
                    <div className="flex-shrink-0 avatar-xs">
                      <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                        {item.assignee_name.charAt(0)}
                      </div>
                    </div>
                  )}

                  <div className="flex-grow-1 ms-2">
                    <h6 className="mb-1">
                      <Link to="/pages-profile" className="text-body">
                        {item.assignee_name}
                      </Link>
                    </h6>
                    <p className="text-muted mb-0">{item.assignee_position}</p>
                  </div>
                </div>
              </li>
            ))}

            {!getTaskById?.get_employee_assignee_obj.length && (
              <span className="text-center text-muted">
                No Members Assigned
              </span>
            )}
          </ul>
        </div>
      </div>

      <Card>
        <CardBody>
          <h5 className="card-title mb-3">Attachments</h5>
          <div className="vstack gap-2">
            {getTaskById?.get_attachment_urls.map((item: any) => {
              const fileName = item.split("/").pop().split("?")[0];
              const fileExtension = fileName.split(".").pop().toLowerCase();
              const imageExtensions = ["png", "jpg", "jpeg"];
              const iconClass = imageExtensions.includes(fileExtension)
                ? "ri-image-line"
                : "ri-folder-zip-line";

              return (
                <div
                  className="border rounded border-dashed p-2"
                  key={fileName}
                >
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar-sm">
                        <div className="avatar-title bg-light text-secondary rounded fs-24">
                          <i className={iconClass}></i>
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow-1 overflow-hidden">
                      <h5 className="fs-13 mb-1">
                        <Link
                          to="#"
                          className="text-body text-truncate d-block"
                        >
                          {fileName}
                        </Link>
                      </h5>
                      <div>2.2MB</div>
                    </div>
                    <div className="flex-shrink-0 ms-2">
                      <div className="d-flex gap-1">
                        <button
                          type="button"
                          className="btn btn-icon text-muted btn-sm fs-18 shadow-none"
                        >
                          <i className="ri-download-2-line"></i>
                        </button>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            tag="button"
                            className="btn btn-icon text-muted btn-sm fs-18 dropdown shadow-none"
                            type="button"
                          >
                            <i className="ri-more-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            <li>
                              <DropdownItem>
                                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                                Rename
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem>
                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                                Delete
                              </DropdownItem>
                            </li>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            {!getTaskById?.get_attachment_urls.length && (
              <span className="text-center text-muted">
                No attachments available
              </span>
            )}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default TaskTracking;
