import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  FormGroup,
  Label,
  FormFeedback,
  Table,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from "reactstrap";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { formatDateTime } from "helpers/date_utils";
import {
  useGetNearMissIncidentReportByNumberQuery,
  useDeleteNearMissIncidentDocumentMutation,
  useUploadNearMissIncidentDocumentMutation,
} from "slices/incidentReport/incidentReportSlice";
import { Link } from "react-router-dom";

interface Step2PreviewSubmitProps {
  previousStep: () => void;
  location: { latitude: number | null; longitude: number | null };
  nextStep: () => void;
}

const Step2ToolBoxAttendance: React.FC<Step2PreviewSubmitProps> = ({
  previousStep,
  location,
  nextStep,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(
    null,
  );
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { incidentId, selectedProject } = useParams<{
    incidentId: string;
    selectedProject: string;
  }>();

  const [formData, setFormData] = useState({
    comments: "",
    name: "",
    attachment: null as File | null,
  });

  const [updateDocumentAttachment, { isLoading: patchLoading }] =
    useUploadNearMissIncidentDocumentMutation();
  const [deleteDocumentAttachment, { isLoading: deleteLoading }] =
    useDeleteNearMissIncidentDocumentMutation();
  const {
    data: incidentData,
    isFetching: isIncidentFetching,
    isLoading: isIncidentLoading,
    refetch: refetchIncidentReport,
  } = useGetNearMissIncidentReportByNumberQuery(incidentId);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Document name is required"),
    comments: Yup.string().required("Comments is required"),
    attachment: Yup.mixed().required("Please upload a file"),
  });

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleAddDocument = () => {
    setSelectedDocumentId(null);
    setIsEditing(false);
    handleModalToggle();
  };

  const formik = useFormik({
    initialValues: {
      name: formData.name || "",
      comments: formData.comments || "",
      // expiry_date: formData.expiry_date || "",
      attachment: null as File | null,
      incident: incidentData?.id,
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log("values", values);
      try {
        const payload = {
          ...values,
        };

        await updateDocumentAttachment({
          formData: payload,
        }).unwrap();
        formik.resetForm();
        refetchIncidentReport();
        handleModalToggle();
      } catch (error) {
        console.error("Error submitting document:", error);
      }
    },
    enableReinitialize: true,
  });

  const handleDeleteConfirmation = (id: string) => {
    setSelectedDocumentId(id);
    setDeleteModalOpen(true);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target.files;
    console.log("fileInput", fileInput);
    if (fileInput && fileInput.length > 0) {
      formik.setFieldValue("attachment", fileInput[0]);
    }
  };

  const handleConfirmDelete = async () => {
    if (selectedDocumentId) {
      try {
        await deleteDocumentAttachment({
          id: selectedDocumentId.toString(),
        }).unwrap();
        refetchIncidentReport();
        setDeleteModalOpen(false);
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    }
  };

  const handleFileDownload = async (url: string) => {
    try {
      // Create a URL object
      const urlObject = new URL(url);
      const pathname = urlObject.pathname;
      let filename = pathname.split("/").pop();

      // Handle the case where filename is undefined
      if (!filename) {
        console.error("Filename could not be extracted from the URL.");
        return; // Optionally handle this case differently
      }

      // Fetch the file
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Failed to download file: ${response.statusText}`);
      }

      // Convert response to blob
      const blob = await response.blob();

      // Create an object URL for the blob
      const fileURL = window.URL.createObjectURL(blob);

      // Create an anchor element and trigger the download
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = filename; // Assign a string value
      link.click();

      // Cleanup: Revoke the object URL to free up memory
      window.URL.revokeObjectURL(fileURL);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <div className="d-flex align-items-center">
            <h5 className="card-title flex-grow-1 mb-0">Near Miss Documents</h5>

            <Button color="danger" onClick={handleAddDocument}>
              <i className="ri-upload-2-fill me-1 align-bottom"></i> Add
              Document
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg={12}>
              {incidentData?.documents && incidentData?.documents.length > 0 ? (
                <div className="table-responsive">
                  <Table className="table-borderless align-middle mb-0">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">File</th>
                        <th scope="col">File Name</th>
                        <th scope="col">Comments</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(incidentData?.documents || []).map((item: any) => {
                        const fileName = item.attachment
                          .split("/")
                          .pop()
                          .split("?")[0];
                        const fileExtension = fileName
                          .split(".")
                          .pop()
                          .toLowerCase();
                        const imageExtensions = ["png", "jpg", "jpeg"];
                        const iconClass = imageExtensions.includes(
                          fileExtension,
                        )
                          ? "ri-image-line"
                          : "ri-folder-zip-line";

                        return (
                          <tr key={item.id}>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="avatar-lg">
                                  <div
                                  // className={`avatar-title bg-${iconClass.includes("image")
                                  //     ? "primary"
                                  //     : "secondary"
                                  //   }-subtle text-${iconClass.includes("image")
                                  //     ? "primary"
                                  //     : "secondary"
                                  //   } rounded fs-20`}
                                  >
                                    {/* <i className={iconClass}></i> */}
                                    <img
                                      src={item.attachment_url}
                                      style={{ width: "100%" }}
                                      alt="upload"
                                    />
                                  </div>
                                </div>
                                {/* <div className="ms-3 flex-grow-1">
                                  <h6 className="fs-15 mb-0">
                                    <Link to="#">{fileName}</Link>
                                  </h6>
                                </div> */}
                              </div>
                            </td>
                            <td>{item?.name}</td>
                            <td>{item?.comments}</td>
                            <td>
                              <UncontrolledDropdown direction="start">
                                <DropdownToggle
                                  tag="a"
                                  className="btn btn-light btn-icon"
                                  id="dropdownMenuLink15"
                                  role="button"
                                >
                                  <i className="ri-equalizer-fill"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    onClick={() =>
                                      handleFileDownload(item.attachment_url)
                                    }
                                  >
                                    <i className="ri-download-2-fill me-2 align-middle text-muted" />
                                    Download
                                  </DropdownItem>
                                  {/* <DropdownItem
                                      onClick={() => handleEditDocument(item.id)}
                                    >
                                      <i className="ri-edit-2-fill me-2 align-middle text-muted" />
                                      Edit
                                    </DropdownItem> */}
                                  <DropdownItem divider />
                                  {/* {IsValidPermissions(['DELETE_MEMBERS']) && */}
                                  <DropdownItem
                                    onClick={() =>
                                      handleDeleteConfirmation(item.id)
                                    }
                                  >
                                    <i className="ri-delete-bin-5-line me-2 align-middle text-muted" />
                                    Delete
                                  </DropdownItem>
                                  {/* } */}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  No documents available.
                </div>
              )}
            </Col>
            {/* Navigation Buttons */}
            <div className="d-flex justify-content-between mt-4">
              <Button color="secondary" onClick={previousStep}>
                Previous
              </Button>
              <Button
                color="primary"
                onClick={nextStep}
                disabled={patchLoading}
              >
                Next
              </Button>
            </div>
          </Row>
        </CardBody>
      </Card>

      <Modal isOpen={isModalOpen} toggle={handleModalToggle} centered>
        <ModalHeader toggle={handleModalToggle}>
          {isEditing ? "Edit Document" : "Add Document"}
        </ModalHeader>
        <ModalBody>
          {/* <LoadingOverlay active={isDocumentLoading} spinner text="Loading..."> */}
          {/* Ensure form has onSubmit handler */}
          <form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label htmlFor="document-name">Document Name</Label>
              <Input
                id="document-name"
                name="name"
                placeholder="Enter document name"
                onChange={formik.handleChange}
                value={formik.values.name}
                invalid={!!formik.errors.name}
              />
              {formik.errors.name && (
                <FormFeedback>{formik.errors.name}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="comments-description">Comments</Label>
              <Input
                type="textarea"
                id="comments-description"
                name="comments"
                placeholder="Enter description"
                onChange={formik.handleChange}
                value={formik.values.comments}
                invalid={!!formik.errors.comments}
              />
              {formik.errors.comments && (
                <FormFeedback>{formik.errors.comments}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="document-attachment">Attachment</Label>
              <Input
                type="file"
                id="document-attachment"
                name="attachment"
                onChange={handleFileChange}
                required
                // invalid={!!formik.errors.attachment}
              />
              {/* {formik.errors.attachment && (
                    <FormFeedback>{formik.errors.attachment}</FormFeedback>
                  )} */}
            </FormGroup>
            {/* Add a submit button within the form */}
            <Button color="primary" type="submit" disabled={patchLoading}>
              {patchLoading ? <Spinner size="sm" /> : "Submit"}
            </Button>
            &nbsp;&nbsp;
            <Button
              color="secondary"
              onClick={handleModalToggle}
              disabled={patchLoading}
            >
              Cancel
            </Button>
          </form>
          {/* </LoadingOverlay> */}
        </ModalBody>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={deleteModalOpen}
        toggle={() => setDeleteModalOpen(false)}
        centered
      >
        <ModalHeader toggle={() => setDeleteModalOpen(false)}>
          Confirm Delete
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete this document? This action cannot be
          undone.
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => setDeleteModalOpen(false)}
            disabled={deleteLoading}
          >
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={handleConfirmDelete}
            disabled={deleteLoading}
          >
            {deleteLoading ? <Spinner size="sm" /> : "Delete"}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Step2ToolBoxAttendance;
