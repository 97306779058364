import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Spinner,
  Row,
  Col,
  CardHeader,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useGetPrimaryCompanyQuery,
  useGetPrimaryCompanyByIdQuery,
  useUpdatePrimaryCompanyMutation,
} from "slices/pageAccess/PageAccessSlice";
import { UncontrolledTooltip } from "reactstrap";

const validationSchema = Yup.object({
  title: Yup.string().required("Company name is required"),
  company_name: Yup.string().required("Display Name is required"),
  company_code: Yup.string().required("Company Code is required"),
  address1: Yup.string().required("Address 1 is required"),
  contact_number: Yup.string().required("Contact Number is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
});

const PrimaryCompanyTab: React.FC = () => {
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(
    null,
  );
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const {
    data: primaryCompanyData,
    refetch,
    isLoading,
  } = useGetPrimaryCompanyQuery(undefined);
  const { data: companyDetails } = useGetPrimaryCompanyByIdQuery(
    selectedCompanyId,
    { skip: !selectedCompanyId },
  );
  const [updatePrimaryCompany] = useUpdatePrimaryCompanyMutation();

  const formik = useFormik({
    initialValues: {
      title: "",
      company_name: "",
      company_code: "",
      address1: "",
      address2: "",
      country: "",
      zip_code: "",
      contact_number: "",
      email: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (selectedCompanyId) {
        await updatePrimaryCompany({
          id: selectedCompanyId,
          updateData: values,
        }).unwrap();
        refetch();
        toggleModal();
      }
    },
  });

  useEffect(() => {
    if (companyDetails) {
      formik.setValues(companyDetails);
    }
  }, [companyDetails]);

  const toggleModal = () => setModalOpen(!modalOpen);

  const handleEditCompany = (companyId: number) => {
    setSelectedCompanyId(companyId);
    toggleModal();
  };

  return (
    <div className="container mt-4">
      <h5 className="mb-4">Company Profile</h5>
      {isLoading ? (
        <Spinner />
      ) : (
        <Row>
          {primaryCompanyData?.length > 0 ? (
            primaryCompanyData.map((c: any) => (
              <Col md={10} sm={8} key={c.id} className="mb-4">
                <Card>
                  <CardHeader className="d-flex align-items-center">
                    <i className="ri-building-fill fs-4"></i>
                    <h4 className="card-title flex-grow-1 mb-0">
                      {" "}
                      Primary Company
                    </h4>
                    <div className="flex-shrink-0">
                      <Button
                        onClick={() => handleEditCompany(c.id!)}
                        className="btn btn-soft-info btn-md shadow-none"
                      >
                        Edit
                      </Button>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <table className="table mb-0">
                      <tbody>
                        <tr>
                          <td className="fw-medium">Company Title</td>
                          <td>{c.title}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">Display Name</td>
                          <td>{c.company_name}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">Company Code</td>
                          <td>{c.company_code}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">Address 1</td>
                          <td>{c.address1}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">Address 2</td>
                          <td>{c.address2}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">Country</td>
                          <td>{c.country}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">Zip Code</td>
                          <td>{c.zip_code}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">Contact Number</td>
                          <td>{c.contact_number}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">Email</td>
                          <td>{c.email}</td>
                        </tr>
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              </Col>
            ))
          ) : (
            <Col>
              <p className="text-center">No Companies Found</p>
            </Col>
          )}
        </Row>
      )}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit Company</ModalHeader>
        <ModalBody>
          <form onSubmit={formik.handleSubmit}>
            {(
              Object.keys(formik.initialValues) as Array<
                keyof typeof formik.values
              >
            ).map((key) => (
              <FormGroup key={key}>
                <Label for={key}>
                  {key === "title"
                    ? "Company Title"
                    : key === "company_name"
                      ? "Display Name"
                      : key
                          .replace("_", " ")
                          .replace(/\b\w/g, (char) => char.toUpperCase())}{" "}
                  {validationSchema.describe().fields[
                    key
                    //@ts-ignore
                  ]?.tests.some((test: any) => test.name === "required") && (
                    <span className="text-danger">*</span>
                  )}
                  {key === "company_name" && (
                    <>
                      <i
                        id="displayNameTooltip"
                        className="ri-information-fill p-2 ms-1 text-secondary"
                        style={{ cursor: "pointer" }}
                      ></i>
                      <UncontrolledTooltip
                        placement="top"
                        target="displayNameTooltip"
                        innerClassName="custom-tooltip"
                      >
                        Display Name is used to show in the dropdown for all
                        Permits and Reports.
                      </UncontrolledTooltip>
                    </>
                  )}
                  {key === "company_code" && (
                    <>
                      <i
                        id="companyCodeTooltip"
                        className="ri-information-fill p-2 ms-1 text-secondary"
                        style={{ cursor: "pointer" }}
                      ></i>
                      <UncontrolledTooltip
                        placement="top"
                        target="companyCodeTooltip"
                        innerClassName="custom-tooltip"
                      >
                        Company Code is used in Permits and Reports to generate
                        the number sequence. <br /> <strong>Note:</strong> Any
                        changes may affect the Permits and Reports number
                        sequence.
                      </UncontrolledTooltip>
                    </>
                  )}
                </Label>
                <Input
                  id={key}
                  name={key}
                  value={formik.values[key]}
                  onChange={formik.handleChange}
                />
                {formik.errors[key] && formik.touched[key] && (
                  <div className="text-danger">{formik.errors[key]}</div>
                )}
              </FormGroup>
            ))}
            <ModalFooter>
              <Button color="primary" type="submit">
                Save
              </Button>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PrimaryCompanyTab;
