import { createApi } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "helpers/jwt-token-access/customBaseQuery";

export const dashboardSlice = createApi({
  reducerPath: "dashboard",
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    getDashboardSummary: builder.query({
      query: () => "/dashboard/v1/admin-dashboard-summary/",
    }),
    getReportSummary: builder.query({
      query: () => "/dashboard/v1/admin-report-summary/",
    }),
    getToolBoxSummary: builder.query({
      query: () => "/dashboard/v1/admin-toolbox-summary/",
    }),
  }),
});

export const {
  useGetDashboardSummaryQuery,
  useGetReportSummaryQuery,
  useGetToolBoxSummaryQuery,
} = dashboardSlice;
