import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Badge,
} from "reactstrap";
import classnames from "classnames";
import { formatDateTime } from "helpers/date_utils";

interface HealthDeclarationProps {
  toolboxMeetingByIdData: any;
}

const HealthDeclaration: React.FC<HealthDeclarationProps> = ({
  toolboxMeetingByIdData,
}) => {
  const health_declaration_id =
    toolboxMeetingByIdData?.health_declaration &&
    toolboxMeetingByIdData?.health_declaration.length > 0
      ? toolboxMeetingByIdData?.health_declaration[0].id
      : 0;
  const [verticalTab, setVerticalTab] = useState(health_declaration_id);
  const toggleVertical = (tab: any) => {
    if (verticalTab !== tab) {
      setVerticalTab(tab);
    }
  };

  const filteredData = toolboxMeetingByIdData?.health_declaration.filter(
    (health: any) => health.id === verticalTab,
  );
  return (
    <React.Fragment>
      <Row>
        <Col xl={12} lg={12}>
          <Card>
            <CardBody>
              <h5 className="mt-2">Health Declaration Details</h5>
              <hr />
              <Row className="mt-4">
                <Col md={12}>
                  {toolboxMeetingByIdData?.health_declaration?.length > 0 ? (
                    <Row>
                      <Col md={3}>
                        <Nav pills className="flex-column" id="v-pills-tab">
                          {/* Dynamically Render Team Tabs */}
                          {toolboxMeetingByIdData?.health_declaration.map(
                            (health: any) => (
                              <NavItem key={health.id}>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    "mb-2": true,
                                    active: verticalTab === health.id,
                                  })}
                                  onClick={() => toggleVertical(health.id)}
                                  id={`v-pills-${health.id}-tab`}
                                >
                                  {formatDateTime(
                                    health?.declaration_date,
                                    "datetime",
                                  )}
                                </NavLink>
                              </NavItem>
                            ),
                          )}
                        </Nav>
                      </Col>

                      <Col md={9}>
                        <TabContent
                          activeTab={verticalTab}
                          className="text-muted mt-4 mt-md-0"
                          id="v-pills-tabContent"
                        >
                          {toolboxMeetingByIdData?.health_declaration?.map(
                            (health: any) => {
                              const statusFit = health?.statuses?.filter(
                                (status: any) => status.health_status === 1,
                              );
                              const statusNotFit = health?.statuses?.filter(
                                (status: any) => status.health_status === 2,
                              );
                              return (
                                <TabPane tabId={health.id} key={health.id}>
                                  {/* <h5>Members in {health.id}</h5> */}
                                  <div className="text-muted">
                                    <div>
                                      <Row>
                                        <Col lg={6} sm={6}>
                                          <div>
                                            <p className="mb-2 text-uppercase fw-medium">
                                              Declared By :
                                            </p>
                                            <h5 className="fs-15 mb-0">
                                              <p>
                                                {health?.declared_by_name} (
                                                {health?.declared_by_number}) -{" "}
                                                {health?.declared_by_position}
                                              </p>
                                            </h5>
                                          </div>
                                        </Col>
                                        <Col lg={6} sm={6}>
                                          <div>
                                            <p className="mb-2 text-uppercase fw-medium">
                                              Declared Date/Time :
                                            </p>
                                            <h5 className="fs-15 mb-0">
                                              {formatDateTime(
                                                health?.declaration_date,
                                                "datetime",
                                              )}
                                            </h5>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>

                                    <div className="pt-3 border-top border-top-dashed mt-4">
                                      <Row>
                                        <Col lg={6} sm={6}>
                                          <div>
                                            <p className="mb-2 text-uppercase fw-medium">
                                              Declared Fit :{" "}
                                              <Badge color="success fs-12">
                                                {statusFit.length}
                                              </Badge>
                                            </p>
                                          </div>
                                        </Col>
                                        <Col lg={6} sm={6}>
                                          <div>
                                            <p className="mb-2 text-uppercase fw-medium">
                                              Declared Not Fit :{" "}
                                              <Badge color="danger fs-12">
                                                {statusNotFit.length}
                                              </Badge>
                                            </p>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>

                                    <br />
                                  </div>

                                  <Table
                                    striped
                                    className="border-top border-top-dashed mt-4"
                                  >
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Employee Name</th>
                                        <th>Number</th>
                                        <th>NRIC</th>
                                        <th>Position</th>
                                        <th>Health Status</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {filteredData &&
                                      filteredData.length > 0 ? (
                                        filteredData.map((health: any) =>
                                          health.statuses.map(
                                            (status: any, index: number) => {
                                              return (
                                                <tr key={index}>
                                                  <td>{index + 1}</td>
                                                  <td>
                                                    {status.employee_full_name}
                                                  </td>
                                                  <td>
                                                    {status.employee_number}
                                                  </td>
                                                  <td>{status.nric}</td>
                                                  <td>{status.position}</td>
                                                  <td>
                                                    {status.health_status ===
                                                    1 ? (
                                                      <Badge color="success fs-12 text-uppercase">
                                                        Fit
                                                      </Badge>
                                                    ) : (
                                                      <Badge color="danger fs-12 text-uppercase">
                                                        Not Fit
                                                      </Badge>
                                                    )}
                                                  </td>
                                                </tr>
                                              );
                                            },
                                          ),
                                        )
                                      ) : (
                                        <tr>
                                          <td
                                            colSpan={5}
                                            className="text-center"
                                          >
                                            No members are assigned to the Team
                                            yet
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </Table>
                                </TabPane>
                              );
                            },
                          )}
                        </TabContent>
                      </Col>
                    </Row>
                  ) : (
                    <div className="text-center text-muted">
                      No Health Declaration added Yet
                    </div>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default HealthDeclaration;
