import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  FormGroup,
  Spinner,
  FormFeedback,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  useGetProjectsQuery,
  usePatchEmployeeProjectMutation,
} from "slices/employee/employeeSlice";

interface EmployeeDocumentProps {
  employee_uuid: string | undefined;
  employeeData: any;
  isModalOpen: boolean;
  handleModalToggle: any;
}

const EmployeeProjectModal: React.FC<EmployeeDocumentProps> = ({
  employee_uuid,
  employeeData,
  isModalOpen,
  handleModalToggle,
}) => {
  const { data: projectsData } = useGetProjectsQuery(undefined);

  const [employeeProfileData, setEmployeeProfileData] = useState({
    projects_m2m: [],
  });

  const [formData, setFormData] = useState({
    projects_m2m: [],
  });

  console.log("employeeProfileData", employeeProfileData);

  const [patchEmployee, { isLoading: patchEmployeeloading }] =
    usePatchEmployeeProjectMutation();

  useEffect(() => {
    if (employeeData) {
      setEmployeeProfileData({
        projects_m2m: employeeData.project_list
          ? employeeData.project_list.map((project: { id: number }) =>
              project.id.toString(),
            )
          : [],
      });
    }
  }, [employeeData]);

  type ProjectOption = {
    value: string;
    label: string;
  };

  interface OptionType {
    value: string;
    label: string;
    id: string;
    name: string;
    project_name: string;
    employee_fullname: string;
  }

  const projectOptions: ProjectOption[] =
    projectsData?.map((project) => ({
      value: project.id.toString(),
      label: project.project_name,
    })) || [];

  const validationSchema = Yup.object({
    projects_m2m: Yup.array().optional().nullable(),
  });

  const handleMultiSelectChange = (
    name: string,
    selectedOptions: OptionType[] | null,
  ): void => {
    setEmployeeProfileData({
      ...employeeProfileData,
      [name]: selectedOptions?.map((option) => option.value) || [],
    });

    formik.setFieldValue(
      name,
      selectedOptions?.map((option) => option.value) || [],
    );
  };

  const formik = useFormik({
    initialValues: {
      projects_m2m: employeeData.project_list
        ? employeeData.project_list.map((project: { id: number }) =>
            project.id.toString(),
          )
        : [],
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const updatedData = {
          ...values,
        };
        await patchEmployee({ employee_uuid, updatedData });
        toast.success("Employee Projects updated successfully!");
        await handleModalToggle();
      } catch (error) {
        console.error("Error submitting document:", error);
        toast.error("Failed to update Employee Projects!");
      }
    },
    enableReinitialize: true,
  });

  return (
    <Modal isOpen={isModalOpen} toggle={handleModalToggle} centered>
      <ModalHeader toggle={handleModalToggle}>Assign Projects</ModalHeader>
      <ModalBody>
        <LoadingOverlay active={false} spinner text="Loading...">
          {/* Ensure form has onSubmit handler */}
          <form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <div className="mb-3">
                <Label htmlFor="projects_m2m" className="form-label">
                  Projects
                </Label>
                <Select
                  className={`form-select ${formik.touched.projects_m2m && formik.errors.projects_m2m ? "is-invalid" : ""}`}
                  name="projects_m2m"
                  isMulti
                  options={projectOptions}
                  value={projectOptions.filter((option: ProjectOption) =>
                    // @ts-ignore
                    employeeProfileData.projects_m2m.includes(option.value),
                  )}
                  onChange={(selectedOptions: OptionType[] | null) =>
                    handleMultiSelectChange("projects_m2m", selectedOptions)
                  }
                />

                {formik.errors.projects_m2m &&
                  typeof formik.errors.projects_m2m === "string" && (
                    <FormFeedback>{formik.errors.projects_m2m}</FormFeedback>
                  )}
              </div>
            </FormGroup>
            {/* Add a submit button within the form */}
            <Button
              color="primary"
              type="submit"
              disabled={patchEmployeeloading}
            >
              {patchEmployeeloading ? <Spinner size="sm" /> : "Submit"}
            </Button>
            &nbsp;&nbsp;
            <Button color="secondary" onClick={handleModalToggle}>
              Cancel
            </Button>
          </form>
        </LoadingOverlay>
      </ModalBody>
    </Modal>
  );
};

export default EmployeeProjectModal;
