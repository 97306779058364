import axios from "axios";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Table,
  Spinner,
  Alert,
} from "reactstrap";
import {
  usePostImportDocumentMutation,
  usePostBulkImportDocumentMutation,
} from "slices/employee/employeeSlice";
import LoadingOverlay from "react-loading-overlay-ts";

const ImportResourceExcelModal = ({
  show,
  onCloseClick,
  import_type,
  project_id,
  refetch,
}: any) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [responseData, setResponseData] = useState<any>(null);
  const [isValid, setIsValid] = useState<boolean | null>(null);

  // Mutation hooks
  const [postImportDocument, { isLoading: postLoading }] =
    usePostImportDocumentMutation();
  const [postBulkImportDocument, { isLoading: bulkLoading }] =
    usePostBulkImportDocumentMutation();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  // Automatically upload the file when it's selected
  useEffect(() => {
    if (file) {
      handleUploadClick();
    }
  }, [file]);

  // Reset the modal state
  const resetModal = () => {
    setFile(null);
    setResponseData(null);
    setIsValid(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset file input
    }
  };

  // Call resetModal when modal is closed
  const handleClose = () => {
    resetModal();
    onCloseClick();
  };

  // Handle validation (initial upload)
  const handleUploadClick = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("import_type", import_type);

    try {
      const response = await postImportDocument(formData).unwrap();
      setResponseData(response?.result);
      setIsValid(response.is_valid);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleBulkImportClick = async () => {
    if (!responseData || !isValid) return;

    // Format responseData into a 2D array without the column keys
    const formattedData = responseData.map((row: any[]) =>
      row.map((col: any) => ({
        column: col.column, // Keep the column name if needed
        value: col.value, // Keep the value
        error: col.error, // Keep the error if needed
      })),
    );

    const payload = {
      json_data: {
        project_id: Number(project_id),
        data: formattedData, // Wrap the 2D array inside an object with "data" key
      },
      import_type: import_type, // Keep the import_type
    };

    try {
      await postBulkImportDocument(payload).unwrap();
      refetch();
      handleClose();
      console.log("Bulk import successful");
    } catch (error) {
      console.error("Error in bulk import:", error);
    }
  };

  // Render the validation result table
  const renderTable = () => {
    if (!responseData) return null;

    return (
      <Table responsive className="table-borderless align-middle mb-0">
        <thead className="table-light">
          <tr>
            {responseData[0]?.map((col: any, index: number) => (
              <th key={index} style={{ width: "200px" }} scope="col">
                {col?.column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {responseData?.map((row: any[], rowIndex: number) => (
            <tr key={rowIndex}>
              {row?.map((col: any, colIndex: number) => (
                <td style={{ width: "250px" }} key={colIndex}>
                  {col?.value}
                  {col?.error && (
                    <span style={{ fontSize: "10px" }} className="text-danger">
                      <br />
                      {col?.error}
                    </span>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  // Set modal size based on responseData (if results are available, use 'xl', otherwise 'md')
  const modalSize = responseData ? "xl" : "md";

  return (
    <Modal isOpen={show} toggle={onCloseClick} size={modalSize} centered={true}>
      <ModalHeader toggle={onCloseClick}></ModalHeader>
      <LoadingOverlay
        active={postLoading || bulkLoading}
        spinner
        text="Processing..."
      >
        <ModalBody className="py-3 px-5">
          {/* Conditionally render this section */}
          {!responseData && (
            <div className="mt-2 text-center">
              <i className="ri-file-text-line display-5 text-success"></i>
              <div className="mt-4 pt-2 fs-15 mx-2 mx-sm-6">
                <h4>Import Bulk Inventory Detail</h4>
                <p className="text-muted mx-4 mb-0">
                  Upload Inventory in Excel format <br />
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}user_management/download-import-excel/?file_name=bulk_resource_inventory_import.xlsx`}
                    style={{ textDecoration: "underline" }}
                  >
                    Click here to Download sample Excel
                  </a>
                </p>
              </div>
            </div>
          )}

          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            {responseData && (
              <div className="mt-4">
                <h5>
                  Validation Result{" "}
                  {isValid ? (
                    <Alert color="success">
                      <p className="text-success">
                        Validation Success Please Click Bulk Import Button
                      </p>
                    </Alert>
                  ) : (
                    <Alert color="danger">
                      <p className="text-danger">
                        Validation Failed Please Correct The Below Error Fields{" "}
                      </p>
                    </Alert>
                  )}
                </h5>
                {renderTable()}
              </div>
            )}
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={handleClose}
              disabled={postLoading || bulkLoading}
            >
              Close
            </button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            {!isValid && (
              <button
                onClick={() => fileInputRef.current?.click()}
                className="btn w-sm btn-success"
                disabled={postLoading || bulkLoading}
              >
                Choose File
              </button>
            )}
            {isValid && (
              <button
                className="btn w-sm btn-success"
                onClick={handleBulkImportClick}
                disabled={bulkLoading}
              >
                {bulkLoading ? (
                  <>
                    <Spinner size="sm" /> Uploading...
                  </>
                ) : (
                  "Bulk Import"
                )}
              </button>
            )}
          </div>
        </ModalBody>
      </LoadingOverlay>
    </Modal>
  );
};

ImportResourceExcelModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  import_type: PropTypes.any,
  project_id: PropTypes.any,
  refetch: PropTypes.any,
};

export default ImportResourceExcelModal;
