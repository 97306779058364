import {
  Row,
  Col,
  Modal,
  Form,
  ModalBody,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
  Button,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import { useGetAllTaskStatusQuery } from "slices/tasks/taskSlice";

interface TaskFormModalProps {
  modal: any;
  toggle: any;
  isEdit: boolean;
  selectedFiles: any;
  setselectedFiles: any;
  isLoadingCreateTask: boolean;
  setModal: any;
  task: any;
  createTask: any;
  updateTask: any;
  refetchTasks: any;
}

const ChangeStatusFormModal: React.FC<TaskFormModalProps> = ({
  modal,
  toggle,
  isEdit,
  isLoadingCreateTask,
  selectedFiles,
  setselectedFiles,
  setModal,
  task,
  createTask,
  updateTask,
  refetchTasks,
}) => {
  const { data: taskStatus } = useGetAllTaskStatusQuery(undefined);

  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      task_status: task?.task_status || null,
    },
    validationSchema: Yup.object({
      task_status: Yup.number().required("Please Enter Task Status").integer(),
    }),
    onSubmit: async (values) => {
      // Handle form submission
      let data = values;
      if (selectedFiles.length > 0) {
        data = { ...values, ...{ files: selectedFiles } };
      }

      try {
        await updateTask({ id: task?.id, newTask: data }).unwrap();
        toast.success("Task Status updated successfully!");
        refetchTasks();
        validation.resetForm();
        setselectedFiles([]);
        toggle();
      } catch (error) {
        toast.error("Error updating Task");
      }
    },
  });

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      centered
      size="md"
      className="border-0"
      modalClassName="modal fade zoomIn"
    >
      <ModalHeader className="p-3 bg-info-subtle" toggle={toggle}>
        Update Task Status:{" "}
        <span className="text-muted">[ {task?.title} ]</span>
      </ModalHeader>
      <Form
        className="tablelist-form"
        onSubmit={(e: any) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <ModalBody className="modal-body">
          <Row className="g-3">
            <Col lg={12}>
              <Label for="ticket-status" className="form-label">
                Change Status
              </Label>
              <Input
                name="task_status"
                type="select"
                className="form-select"
                id="ticket-status"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.task_status || ""}
                invalid={
                  validation.touched.task_status &&
                  validation.errors.task_status
                    ? true
                    : false
                }
              >
                <option value="">Status</option>
                {taskStatus?.map((options: any) => (
                  <option key={options.id} value={options.id}>
                    {options.status_name}
                  </option>
                ))}
              </Input>
              {validation.touched.task_status &&
              validation.errors.task_status ? (
                <FormFeedback type="invalid">
                  {validation.errors.task_status}
                </FormFeedback>
              ) : null}
            </Col>
          </Row>
        </ModalBody>
        <div className="modal-footer">
          <div className="hstack gap-2 justify-content-end">
            <Button
              type="button"
              disabled={isLoadingCreateTask}
              onClick={() => {
                setModal(false);
                validation.resetForm();
                setselectedFiles([]);
              }}
              className="btn-light"
            >
              Close
            </Button>
            <Button
              color="success"
              className="btn-load"
              disabled={isLoadingCreateTask}
              type="submit"
            >
              {isLoadingCreateTask ? (
                <span className="d-flex align-items-center">
                  <Spinner size="sm" className="flex-shrink-0" />
                  <span className="flex-grow-1 ms-2">Loading...</span>
                </span>
              ) : (
                "Update Task Status"
              )}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export { ChangeStatusFormModal };
