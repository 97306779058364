import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  FormGroup,
  Label,
  FormFeedback,
  Table,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useUploadIncidentDocumentMutation,
  useDeleteIncidentDocumentMutation,
  useGetIncidentReportByNumberQuery,
} from "slices/incidentReport/incidentReportSlice";
import ImageLightbox from "Components/Common/ImageLightbox";

interface ToolBoxDocumentProps {
  getToolboxMeetingById: any;
}

const DocumentsTab: React.FC<ToolBoxDocumentProps> = ({
  getToolboxMeetingById,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(
    null,
  );
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [formData, setFormData] = useState({
    comments: "",
    name: "",
    attachment: null as File | null,
  });

  const [updateDocumentAttachment, { isLoading: patchLoading }] =
    useUploadIncidentDocumentMutation();
  const [deleteDocumentAttachment, { isLoading: deleteLoading }] =
    useDeleteIncidentDocumentMutation();
  const {
    data: incidentReportDataById,
    isLoading,
    isFetching,
    refetch: refetchTooboxById,
  } = useGetIncidentReportByNumberQuery(getToolboxMeetingById);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Document name is required"),
    comments: Yup.string().required("Comments is required"),
    attachment: Yup.mixed().required("Please upload a file"),
  });

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleAddDocument = () => {
    setSelectedDocumentId(null);
    setIsEditing(false);
    handleModalToggle();
  };

  const formik = useFormik({
    initialValues: {
      name: formData.name || "",
      comments: formData.comments || "",
      attachment: null as File | null,
      incident: incidentReportDataById?.id,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const payload = {
          ...values,
        };

        await updateDocumentAttachment({
          formData: payload,
        }).unwrap();
        formik.resetForm();
        refetchTooboxById();
        handleModalToggle();
      } catch (error) {
        console.error("Error submitting document:", error);
      }
    },
    enableReinitialize: true,
  });

  const handleDeleteConfirmation = (id: string) => {
    setSelectedDocumentId(id);
    setDeleteModalOpen(true);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target.files;
    console.log("fileInput", fileInput);
    if (fileInput && fileInput.length > 0) {
      formik.setFieldValue("attachment", fileInput[0]);
    }
  };

  const handleConfirmDelete = async () => {
    if (selectedDocumentId) {
      try {
        await deleteDocumentAttachment({
          id: selectedDocumentId.toString(),
        }).unwrap();
        refetchTooboxById();
        // refetch();
        setDeleteModalOpen(false);
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    }
  };

  const extractDocumentName = (url: string) => {
    const parts = url.split("/");
    return parts[parts.length - 1];
  };

  const imageDocuments = getToolboxMeetingById?.documents?.filter((doc: any) =>
    doc.attachment?.match(/\.(jpeg|jpg|png|gif)$/i),
  );

  const slides = imageDocuments?.map((doc: any) => ({
    src: doc.attachment_url,
    title: extractDocumentName(doc.attachment),
  }));

  const handleFileDownload = async (url: string) => {
    try {
      // Create a URL object
      const urlObject = new URL(url);
      const pathname = urlObject.pathname;
      let filename = pathname.split("/").pop();

      // Handle the case where filename is undefined
      if (!filename) {
        console.error("Filename could not be extracted from the URL.");
        return; // Optionally handle this case differently
      }

      // Fetch the file
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Failed to download file: ${response.statusText}`);
      }

      // Convert response to blob
      const blob = await response.blob();

      // Create an object URL for the blob
      const fileURL = window.URL.createObjectURL(blob);

      // Create an anchor element and trigger the download
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = filename; // Assign a string value
      link.click();

      // Cleanup: Revoke the object URL to free up memory
      window.URL.revokeObjectURL(fileURL);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <div className="d-flex align-items-center">
            <h5 className="card-title flex-grow-1 mb-0">Accident Documents</h5>
            <Button color="danger" onClick={handleAddDocument}>
              <i className="ri-upload-2-fill me-1 align-bottom"></i> Add
              Document
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg={12}>
              {incidentReportDataById?.documents &&
              incidentReportDataById?.documents.length > 0 ? (
                <div className="table-responsive">
                  <Table className="table-borderless align-middle mb-0">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">File</th>
                        <th scope="col">File Name</th>
                        <th scope="col">Comments</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(incidentReportDataById?.documents || []).map(
                        (item: any) => {
                          const fileName = item.attachment
                            .split("/")
                            .pop()
                            .split("?")[0];
                          const fileExtension = fileName
                            .split(".")
                            .pop()
                            .toLowerCase();
                          const imageExtensions = ["png", "jpg", "jpeg"];
                          const iconClass = imageExtensions.includes(
                            fileExtension,
                          )
                            ? "ri-image-line"
                            : "ri-folder-zip-line";

                            const isImage = item.attachment.match(
                              /\.(jpeg|jpg|png|gif)$/i,
                            );

                          return (
                            <tr key={item.id}>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="avatar-sm">
                                    <div
                                      className={`avatar-title bg-${
                                        iconClass.includes("image")
                                          ? "primary"
                                          : "secondary"
                                      }-subtle text-${
                                        iconClass.includes("image")
                                          ? "primary"
                                          : "secondary"
                                      } rounded fs-20`}
                                    >
                                      <i className={iconClass}></i>
                                    </div>
                                  </div>
                                  <div className="ms-3 flex-grow-1">
                                    <h6 className="fs-15 mb-0">
                                      <Link to="#">{fileName}</Link>
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>{item?.name}</td>
                              <td>{item?.comments}</td>
                              <td>
                                <UncontrolledDropdown direction="start">
                                  <DropdownToggle
                                    tag="a"
                                    className="btn btn-light btn-icon"
                                    id="dropdownMenuLink15"
                                    role="button"
                                  >
                                    <i className="ri-equalizer-fill"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={() =>
                                        handleFileDownload(item.attachment_url)
                                      }
                                    >
                                      <i className="ri-download-2-fill me-2 align-middle text-muted" />
                                      Download
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => {
                                        if (isImage) {
                                          const imgIndex = imageDocuments.findIndex(
                                            (imgDoc: any) =>
                                              imgDoc.attachment_url ===
                                            item.attachment_url,
                                          );
                                          setCurrentImageIndex(imgIndex);
                                          setLightboxOpen(true);
                                        }
                                      }}
                                    >
                                      <i className="ri-eye-fill me-2 align-middle text-muted" />
                                      Preview
                                    </DropdownItem>
                                    {/* <DropdownItem
                                    onClick={() => handleEditDocument(item.id)}
                                  >
                                    <i className="ri-edit-2-fill me-2 align-middle text-muted" />
                                    Edit
                                  </DropdownItem> */}
                                    <DropdownItem divider />
                                    {/* {IsValidPermissions(['DELETE_MEMBERS']) && */}
                                    <DropdownItem
                                      onClick={() =>
                                        handleDeleteConfirmation(item.id)
                                      }
                                    >
                                      <i className="ri-delete-bin-5-line me-2 align-middle text-muted" />
                                      Delete
                                    </DropdownItem>
                                    {/* } */}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        },
                      )}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  No documents available.
                </div>
              )}
            </Col>
            <ImageLightbox
              open={lightboxOpen}
              slides={slides}
              currentIndex={currentImageIndex}
              onClose={() => setLightboxOpen(false)}
            />
          </Row>
        </CardBody>
      </Card>

      <Modal isOpen={isModalOpen} toggle={handleModalToggle} centered>
        <ModalHeader toggle={handleModalToggle}>
          {isEditing ? "Edit Document" : "Add Document"}
        </ModalHeader>
        <ModalBody>
          {/* <LoadingOverlay active={isDocumentLoading} spinner text="Loading..."> */}
          {/* Ensure form has onSubmit handler */}
          <form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label htmlFor="document-name">Document Name</Label>
              <Input
                id="document-name"
                name="name"
                placeholder="Enter document name"
                onChange={formik.handleChange}
                value={formik.values.name}
                invalid={!!formik.errors.name}
              />
              {formik.errors.name && (
                <FormFeedback>{formik.errors.name}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="comments-description">Comments</Label>
              <Input
                type="textarea"
                id="comments-description"
                name="comments"
                placeholder="Enter description"
                onChange={formik.handleChange}
                value={formik.values.comments}
                invalid={!!formik.errors.comments}
              />
              {formik.errors.comments && (
                <FormFeedback>{formik.errors.comments}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="document-attachment">Attachment</Label>
              <Input
                type="file"
                id="document-attachment"
                name="attachment"
                onChange={handleFileChange}
                required
                // invalid={!!formik.errors.attachment}
              />
              {/* {formik.errors.attachment && (
                  <FormFeedback>{formik.errors.attachment}</FormFeedback>
                )} */}
            </FormGroup>
            {/* Add a submit button within the form */}
            <Button color="primary" type="submit" disabled={patchLoading}>
              {patchLoading ? <Spinner size="sm" /> : "Submit"}
            </Button>
            &nbsp;&nbsp;
            <Button
              color="secondary"
              onClick={handleModalToggle}
              disabled={patchLoading}
            >
              Cancel
            </Button>
          </form>
          {/* </LoadingOverlay> */}
        </ModalBody>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={deleteModalOpen}
        toggle={() => setDeleteModalOpen(false)}
        centered
      >
        <ModalHeader toggle={() => setDeleteModalOpen(false)}>
          Confirm Delete
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete this document? This action cannot be
          undone.
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => setDeleteModalOpen(false)}
            disabled={deleteLoading}
          >
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={handleConfirmDelete}
            disabled={deleteLoading}
          >
            {deleteLoading ? <Spinner size="sm" /> : "Delete"}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default DocumentsTab;
