import React, { useEffect, useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Col,
  Row,
  Input,
  Form,
  Label,
  FormGroup,
  Spinner,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { format } from "date-fns";
import LoadingOverlay from "react-loading-overlay-ts";
import { useParams, useNavigate } from "react-router-dom";
import {
  useCreateNearMissIncidentReportMutation,
  useUpdateNearMissIncidentReportMutation,
  useGetNearMissIncidentReportByNumberQuery,
} from "slices/incidentReport/incidentReportSlice";
import { ServiceContext } from "context/ServiceContextProvider";
import { useGetprojectByIdQuery } from "slices/projects/projectSlice";

interface Step1IncidentReportProps {
  nextStep: () => void;
  geoPermission: boolean;
  location: { latitude: number | null; longitude: number | null };
}

const Step1IncidentReport: React.FC<Step1IncidentReportProps> = ({
  nextStep,
  geoPermission,
  location,
}) => {
  const { incidentId, selectedProject } = useParams<{
    incidentId: string;
    selectedProject: string;
  }>();
  const navigate = useNavigate();
  const { getEmployeeID } = useContext(ServiceContext);

  const {
    data: incidentData,
    isFetching: isIncidentFetching,
    isLoading: isIncidentLoading,
  } = useGetNearMissIncidentReportByNumberQuery(incidentId);
  const { data: projectData } = useGetprojectByIdQuery(selectedProject);

  const [createIncidentReport, { isLoading: createLoading }] =
    useCreateNearMissIncidentReportMutation();
  const [updateIncidentReport, { isLoading: updateLoading }] =
    useUpdateNearMissIncidentReportMutation();

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Add 1 to month since it's zero-indexed
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formik = useFormik({
    initialValues: {
      reported_by: getEmployeeID,
      incident_date: getCurrentDate(),
      incident_time: "",
      project: selectedProject,
      description: "",
      severity: "low",
      contact_number: "",
      location: "",
      latitude: location.latitude ? location.latitude.toString() : "",
      longitude: location.longitude ? location.longitude.toString() : "",
      status: 1,
    },
    validationSchema: Yup.object({
      project: Yup.string().required("Project is required"),
      description: Yup.string().required("Description is required"),
      severity: Yup.string()
        .oneOf(["low", "medium", "high", "critical"], "Invalid severity")
        .required("Severity is required"),
      // contact_number: Yup.string().required("Contact number is required"),
      incident_date: Yup.string().required("Incident date is required"),
      incident_time: Yup.string().required("Incident time is required"),
    }),
    onSubmit: async (values) => {
      console.log("Submitting form", values);
      console.log("Formik errors", formik.errors);
      try {
        const payload = {
          ...values,
          latitude: location.latitude ? location.latitude.toString() : "",
          longitude: location.longitude ? location.longitude.toString() : "",
        };

        let response;
        if (incidentId) {
          response = await updateIncidentReport({
            incident_no: incidentId,
            updatedReportData: payload,
          }).unwrap();
        } else {
          response = await createIncidentReport(payload).unwrap();
          navigate(
            `/nearmiss-report-wizard/${selectedProject}/${response.incident_no}`,
          );
        }

        formik.resetForm();
        nextStep();
      } catch (error) {
        console.error("Failed to submit the form", error);
      }
    },
  });

  const handleNext = () => {
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        formik.handleSubmit();
      } else {
        console.log("Form has validation errors", errors);
      }
    });
  };

  useEffect(() => {
    if (!isIncidentLoading && incidentData) {
      formik.setValues({
        reported_by: incidentData?.reported_by || getEmployeeID,
        incident_date: incidentData?.incident_date || getCurrentDate(),
        incident_time: incidentData?.incident_time || "",
        project: selectedProject,
        description: incidentData?.description || "",
        severity: incidentData?.severity || "low",
        contact_number: incidentData?.contact_number || "",
        location: incidentData?.location || "",
        latitude: incidentData?.latitude || location.latitude?.toString() || "",
        longitude:
          incidentData?.longitude || location.longitude?.toString() || "",
        status: incidentData?.status || 1,
      });
    }
  }, [isIncidentLoading, incidentData, location]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <LoadingOverlay
        active={createLoading || updateLoading}
        spinner
        text="Submitting..."
      >
        <Row>
          {/* Project Name (read-only) */}
          <Col md={6}>
            <FormGroup>
              <Label for="project_name">Project Name</Label>
              <Input
                id="project_name"
                name="project_name"
                type="text"
                value={projectData?.project_name || ""}
                readOnly
                disabled
              />
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="severity">
                Severity <span className="text-danger">*</span>
              </Label>
              <Input
                id="severity"
                name="severity"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.severity}
              >
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
              </Input>
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="incident_date">
                Incident Date <span className="text-danger">*</span>
              </Label>
              <Flatpickr
                id="incident_date"
                name="incident_date"
                className={`form-control ${formik.touched.incident_date && formik.errors.incident_date ? "is-invalid" : ""}`}
                options={{
                  // minDate: "today",
                  maxDate: "today",
                }}
                value={formik.values.incident_date}
                onChange={(date: Date[]) => {
                  formik.setFieldValue(
                    "incident_date",
                    format(date[0], "yyyy-MM-dd"),
                  );
                }}
              />
              {formik.errors.incident_date ? (
                <div className="text-danger">{formik.errors.incident_date}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="incident_time">
                Incident Time <span className="text-danger">*</span>
              </Label>
              <Input
                id="incident_time"
                name="incident_time"
                type="time"
                onChange={formik.handleChange}
                value={formik.values.incident_time}
                invalid={
                  formik.touched.incident_time && !!formik.errors.incident_time
                }
              />
              {formik.errors.incident_time ? (
                <div className="text-danger">
                  {formik.errors.incident_time as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={12}>
            <FormGroup>
              <Label for="description">
                Description <span className="text-danger">*</span>
              </Label>
              <Input
                id="description"
                name="description"
                type="textarea"
                onChange={formik.handleChange}
                value={formik.values.description}
                invalid={!!formik.errors.description}
              />
              {formik.errors.description ? (
                <div className="text-danger">
                  {formik.errors.description as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={12}>
            <Button
              style={{ float: "right" }}
              type="button"
              color="primary"
              onClick={handleNext}
              disabled={createLoading || updateLoading}
            >
              {createLoading || updateLoading ? <Spinner size="sm" /> : "Next"}
            </Button>
          </Col>
        </Row>
      </LoadingOverlay>
    </Form>
  );
};

export default Step1IncidentReport;
