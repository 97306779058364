import React, { useContext, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Col,
  Row,
  Input,
  Form,
  Label,
  FormGroup,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import { format, addDays } from "date-fns";
import {
  useCreateConfinedSpacePermitMutation,
  useUpdateConfinedSpacePermitMutation,
  useGetConfinedSpacePermitByNumberQuery,
} from "slices/safetyApp/safetyappSlice";
import { useGetEmployeeDropDownQuery } from "slices/employee/employeeSlice";
import { useGetCompaniesQuery } from "slices/InspectionReport/InspectionReportSlice";

import LoadingOverlay from "react-loading-overlay-ts";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { useParams, useNavigate } from "react-router-dom";
import { ServiceContext } from "context/ServiceContextProvider";

interface Step1ConfinedSpacePermitProps {
  selectedProject: string;
  projectsData: any;
  nextStep: () => void;
}

interface SupervisorOption {
  value: number;
  label: string;
}

interface FormValues {
  work_performed_by: string;
  project: string;
  name_of_supervisor: number[];
  names_of_workers_involved_in_confined_space_work: string;
  contact_number: string;
  type_of_equipment_used: string;
  work_location: string;
  work_description: string;
  start_date_of_work: string;
  start_time_of_work: string;
  end_date_of_work: string;
  end_time_of_work: string;
}

const Step1ConfinedSpacePermit: React.FC<Step1ConfinedSpacePermitProps> = ({
  selectedProject,
  projectsData,
  nextStep,
}) => {
  const { getEmployeeUUID } = useContext(ServiceContext);
  const { hwPermitNo: hwPermitNoFromURL } = useParams<{ hwPermitNo: string }>();
  const navigate = useNavigate();

  const { data: companiesData } = useGetCompaniesQuery();
  const { data: supervisorsData, isLoading: supervisorsDataLoading } =
    useGetEmployeeDropDownQuery();
  const [createExcavationPermit, { isLoading: excavationPermitisLoading }] =
    useCreateConfinedSpacePermitMutation();
  const [updateExcavationPermit, { isLoading: patchisLoading }] =
    useUpdateConfinedSpacePermitMutation();

  const {
    data: hwPermitData,
    isLoading: isPermitLoading,
    isFetching: isPermitFetching,
    refetch: refetchConfinedSpacePermit,
  } = useGetConfinedSpacePermitByNumberQuery(hwPermitNoFromURL);

  const supervisorOptions: SupervisorOption[] =
    supervisorsData?.map((supervisor) => ({
      value: supervisor.id,
      label: supervisor.employee_fullname,
    })) || [];

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Add 1 to month since it's zero-indexed
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      work_performed_by: hwPermitData?.work_performed_by || "",
      project: selectedProject,
      name_of_supervisor: hwPermitData?.name_of_supervisor || [],
      names_of_workers_involved_in_confined_space_work:
        hwPermitData?.names_of_workers_involved_in_confined_space_work || "",
      contact_number: hwPermitData?.contact_number || "",
      type_of_equipment_used: hwPermitData?.type_of_equipment_used || "",
      work_location: hwPermitData?.work_location || "",
      work_description: hwPermitData?.work_description || "",
      start_date_of_work: hwPermitData?.start_date_of_work || getCurrentDate(),
      start_time_of_work: hwPermitData?.start_time_of_work || "",
      end_date_of_work: hwPermitData?.end_date_of_work || "",
      end_time_of_work: hwPermitData?.end_time_of_work || "",
    },
    validationSchema: Yup.object({
      name_of_supervisor: Yup.array().min(
        1,
        "At least one supervisor is required",
      ),
      // work_performed_by: Yup.string()
      //   .max(250, "Max 250 characters")
      //   .required("Work performed by is required"),
      work_location: Yup.string().optional(),
      work_description: Yup.string().optional(),
      contact_number: Yup.string().optional(),
      names_of_workers_involved_in_confined_space_work: Yup.string().optional(),
      start_date_of_work: Yup.string()
        .required("Start date is required")
        .test(
          "is-not-in-past",
          "Start date cannot be in the past",
          function (value) {
            console.log("value", value);

            if (!value) return true; // Skip validation if no date is provided
            const inputDate = moment(value, "YYYY-MM-DD").startOf("day"); // Only date part
            const today = moment().startOf("day");
            return inputDate.isSameOrAfter(today); // Compare only dates
          },
        ),
      start_time_of_work: Yup.string().required("Start time is required"),
      end_date_of_work: Yup.date()
        .required("End date is required")
        .test(
          "is-same-or-next-day",
          "End date must be the same or the next day as the start date",
          function (value) {
            const { start_date_of_work } = this.parent; // Access other fields
            if (!start_date_of_work || !value) return true; // Skip if either date is empty

            const startDate = moment(start_date_of_work, "YYYY-MM-DD").startOf(
              "day",
            );
            const endDate = moment(value, "YYYY-MM-DD").startOf("day");

            // Check if the endDate is same or next day compared to startDate
            return (
              endDate.isSame(startDate) ||
              endDate.isSame(startDate.add(1, "day"))
            );
          },
        ),
      end_time_of_work: Yup.string()
        .required("End time is required")
        .test(
          "is-valid-time",
          "End time must be at least 1 hour after start time",
          function (value) {
            const { start_date_of_work, start_time_of_work, end_date_of_work } =
              this.parent;

            // Return true if any required field is missing (skipping validation)
            if (
              !start_date_of_work ||
              !start_time_of_work ||
              !end_date_of_work ||
              !value
            ) {
              return true;
            }

            // Parse start and end date-time strings
            const formattedStartDate = moment(
              new Date(start_date_of_work),
            ).format("DD/MM/YYYY");
            const formattedStartTime = start_time_of_work.slice(0, 5);
            const startDateTime = moment(
              `${formattedStartDate} ${formattedStartTime}`,
              "DD/MM/YYYY HH:mm",
              true,
            );

            const formattedEndDate = moment(new Date(end_date_of_work)).format(
              "DD/MM/YYYY",
            );
            const formattedEndTime = value.slice(0, 5);
            const endDateTime = moment(
              `${formattedEndDate} ${formattedEndTime}`,
              "DD/MM/YYYY HH:mm",
              true,
            );

            // Ensure dates are valid
            if (!startDateTime.isValid() || !endDateTime.isValid()) {
              console.error("Invalid date inputs:", {
                startDateTime,
                endDateTime,
              });
              return false;
            }

            // Compare end time: ensure it's at least 1 hour after start time
            return endDateTime.isSameOrAfter(
              startDateTime.clone().add(1, "hour"),
            );
          },
        ),
    }),
    onSubmit: async (values) => {
      try {
        const payload = { ...values };
        let response;

        if (hwPermitNoFromURL) {
          response = await updateExcavationPermit({
            cs_permit_no: hwPermitNoFromURL,
            updatedPermitData: payload,
          }).unwrap();
        } else {
          response = await createExcavationPermit(payload).unwrap();
          localStorage.setItem("hwPermitNo", response.cs_permit_no);
          navigate(
            `/confinedspace-permit-wizard/${selectedProject}/${response.cs_permit_no}`,
          );
        }
        formik.resetForm();
        nextStep();
      } catch (error) {
        console.error("Failed to submit the form", error);
      }
    },
  });

  useEffect(() => {
    if (supervisorsData && getEmployeeUUID) {
      const defaultSupervisor = supervisorsData.find(
        (supervisor) => supervisor.employee_uuid === getEmployeeUUID,
      );
      if (defaultSupervisor) {
        formik.setFieldValue("name_of_supervisor", [defaultSupervisor.id]);
      }
    }
  }, [supervisorsData, getEmployeeUUID]);

  useEffect(() => {
    if (hwPermitNoFromURL) {
      refetchConfinedSpacePermit();
    }
  }, [hwPermitNoFromURL, refetchConfinedSpacePermit]);

  useEffect(() => {
    // Ensure data is fetched and not currently loading
    if (!isPermitLoading && hwPermitData) {
      const {
        work_performed_by,
        name_of_supervisor,
        names_of_workers_involved_in_confined_space_work,
        contact_number,
        type_of_equipment_used,
        work_location,
        work_description,
        start_date_of_work,
        start_time_of_work,
        end_date_of_work,
        end_time_of_work,
      } = hwPermitData;

      // Set form values with fallback defaults
      formik.setValues({
        work_performed_by: work_performed_by || "",
        project: selectedProject,
        name_of_supervisor: name_of_supervisor || [],
        names_of_workers_involved_in_confined_space_work:
          names_of_workers_involved_in_confined_space_work || "",
        contact_number: contact_number || "",
        type_of_equipment_used: type_of_equipment_used || "",
        work_location: work_location || "",
        work_description: work_description || "",
        start_date_of_work: start_date_of_work || getCurrentDate(),
        start_time_of_work: start_time_of_work || "",
        end_date_of_work: end_date_of_work || "",
        end_time_of_work: end_time_of_work || "",
      });
    }
  }, [isPermitLoading, isPermitFetching, hwPermitData]);

  const handleSupervisorChange = (selectedOptions: SupervisorOption[]) => {
    const selectedIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    formik.setFieldValue("name_of_supervisor", selectedIds);
  };

  const handleSupervisorOthersChange = (tags: string[]) => {
    formik.setFieldValue(
      "names_of_workers_involved_in_confined_space_work",
      tags.join(","),
    );
  };

  const handleSupervisorContactNumberChange = (tags: string[]) => {
    formik.setFieldValue("contact_number", tags.join(","));
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <LoadingOverlay active={isPermitLoading} spinner text="Fetching...">
        <Row>
          <Col md={12}>
            <Label className="card-title"> Site Information</Label>
            <hr />
          </Col>

          {/* <Col md={6}>
            <FormGroup>
              <Label for="work_performed_by">
                Work Performed By <span className="text-danger">*</span>
              </Label>
              <Input
                id="work_performed_by"
                name="work_performed_by"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.work_performed_by}
                invalid={
                  formik.touched.work_performed_by &&
                  !!formik.errors.work_performed_by
                }
              />
              {formik.touched.work_performed_by &&
                formik.errors.work_performed_by ? (
                <div className="text-danger">
                  {formik.errors.work_performed_by as string}
                </div>
              ) : null}
            </FormGroup>
          </Col> */}

          <Col md={6}>
            <FormGroup>
              <Label for="name_of_hotwork_supervisor">
                Work Supervised By <span className="text-danger">*</span>
              </Label>
              <Select
                id="name_of_supervisor"
                isMulti
                options={supervisorOptions}
                value={supervisorOptions.filter((option) =>
                  formik.values.name_of_supervisor.includes(option.value),
                )}
                onChange={handleSupervisorChange}
                isLoading={supervisorsDataLoading}
                isDisabled={true}
              />
              {formik.touched.name_of_supervisor &&
              formik.errors.name_of_supervisor ? (
                <div className="text-danger">
                  {formik.errors.name_of_supervisor as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          {/* Project Name (read-only) */}
          <Col md={6}>
            <FormGroup>
              <Label for="project_name">Project Name</Label>
              <Input
                id="project_name"
                name="project_name"
                type="text"
                value={projectsData?.project_name || ""}
                readOnly
                disabled
              />
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="names_of_workers_involved_in_confined_space_work">
                Name Of Other Workers{" "}
              </Label>
              <TagsInput
                addKeys={[9, 13, 188]}
                value={
                  formik.values.names_of_workers_involved_in_confined_space_work
                    ? formik.values.names_of_workers_involved_in_confined_space_work.split(
                        ",",
                      )
                    : []
                }
                // value={formik.values.work_supervisor_others}
                onChange={handleSupervisorOthersChange}
                inputProps={{
                  placeholder: "Add a Workers",
                  style: {
                    padding: "6px",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    minWidth: "150px",
                    border: "none",
                    outline: "none",
                    flex: 1,
                  },
                }}
              />
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="supervisor_contact_number_others">
                Contact Number
              </Label>
              <TagsInput
                addKeys={[9, 13, 188]}
                value={
                  formik.values.contact_number
                    ? formik.values.contact_number.split(",")
                    : []
                }
                // value={formik.values.supervisor_contact_number_others}
                onChange={handleSupervisorContactNumberChange}
                inputProps={{
                  placeholder: "Add contact number",
                  style: {
                    padding: "6px",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    minWidth: "150px",
                    border: "none",
                    outline: "none",
                    flex: 1,
                  },
                }}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="type_of_equipment_used">Equipment Used</Label>
              <TagsInput
                addKeys={[9, 13, 188]}
                value={
                  formik.values.type_of_equipment_used
                    ? formik.values.type_of_equipment_used.split(",")
                    : []
                }
                onChange={(tags) =>
                  formik.setFieldValue("type_of_equipment_used", tags.join(","))
                }
                inputProps={{
                  placeholder: "Add equipment",
                  style: {
                    padding: "6px",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    minWidth: "150px",
                    border: "none",
                    outline: "none",
                    flex: 1,
                  },
                }}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="start_date_of_work">
                Start Date <span className="text-danger">*</span>
              </Label>
              <Flatpickr
                id="start_date_of_work"
                name="start_date_of_work"
                className={`form-control ${formik.touched.start_date_of_work && formik.errors.start_date_of_work ? "is-invalid" : ""}`}
                options={{
                  minDate: "today",
                  maxDate: "today",
                }}
                value={formik.values.start_date_of_work}
                onChange={(date: Date[]) => {
                  formik.setFieldValue(
                    "start_date_of_work",
                    format(date[0], "yyyy-MM-dd"),
                  );
                }}
              />
              {formik.touched.start_date_of_work &&
              formik.errors.start_date_of_work ? (
                <div className="text-danger">
                  {formik.errors.start_date_of_work}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="start_time_of_work">
                Start Time <span className="text-danger">*</span>
              </Label>
              <Input
                id="start_time_of_work"
                name="start_time_of_work"
                type="time"
                onChange={formik.handleChange}
                value={formik.values.start_time_of_work}
                invalid={
                  formik.touched.start_time_of_work &&
                  !!formik.errors.start_time_of_work
                }
              />
              {formik.touched.start_time_of_work &&
              formik.errors.start_time_of_work ? (
                <div className="text-danger">
                  {formik.errors.start_time_of_work as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="end_date_of_work">
                End Date <span className="text-danger">*</span>
              </Label>
              <Flatpickr
                id="end_date_of_work"
                name="end_date_of_work"
                className={`form-control ${formik.touched.end_date_of_work && formik.errors.end_date_of_work ? "is-invalid" : ""}`}
                options={{
                  minDate: "today",
                  maxDate: addDays(new Date(), 1),
                }}
                value={formik.values.end_date_of_work}
                onChange={(date: Date[]) => {
                  formik.setFieldValue(
                    "end_date_of_work",
                    format(date[0], "yyyy-MM-dd"),
                  );
                }}
              />
              {formik.touched.end_date_of_work &&
              formik.errors.end_date_of_work ? (
                <div className="text-danger">
                  {formik.errors.end_date_of_work}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label for="end_time_of_work">
                End Time <span className="text-danger">*</span>
              </Label>
              <Input
                id="end_time_of_work"
                name="end_time_of_work"
                type="time"
                onChange={formik.handleChange}
                value={formik.values.end_time_of_work}
                invalid={
                  formik.touched.end_time_of_work &&
                  !!formik.errors.end_time_of_work
                }
              />
              {formik.touched.end_time_of_work &&
              formik.errors.end_time_of_work ? (
                <div className="text-danger">
                  {formik.errors.end_time_of_work as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="location">Location</Label>
              <TagsInput
                addKeys={[9, 13, 188]}
                value={
                  formik.values.work_location
                    ? formik.values.work_location.split(",")
                    : []
                }
                onChange={(tags) =>
                  formik.setFieldValue("work_location", tags.join(","))
                }
                inputProps={{
                  placeholder: "Add locations",
                  style: {
                    padding: "6px",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    minWidth: "150px",
                    border: "none",
                    outline: "none",
                    flex: 1,
                  },
                }}
              />
            </FormGroup>
          </Col>

          <Col md={12}>
            <FormGroup>
              <Label for="work_description">Work Description</Label>
              <Input
                id="work_description"
                name="work_description"
                type="textarea"
                onChange={(e) =>
                  formik.setFieldValue("work_description", e.target.value)
                }
                value={formik.values.work_description}
                invalid={
                  formik.touched.work_description &&
                  !!formik.errors.work_description
                }
              />
              {formik.touched.work_description &&
              formik.errors.work_description ? (
                <div className="text-danger">
                  {formik.errors.work_description as string}
                </div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={12}>
            <Button
              style={{ float: "right" }}
              type="submit"
              color="primary"
              disabled={excavationPermitisLoading || patchisLoading}
            >
              {excavationPermitisLoading || patchisLoading ? (
                <Spinner size="sm" />
              ) : (
                "Next"
              )}
            </Button>
          </Col>
        </Row>
      </LoadingOverlay>
    </Form>
  );
};

export default Step1ConfinedSpacePermit;
