import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Spinner,
} from "reactstrap";
import {
  useGetCompaniesQuery,
  useCreateInspectionReportMutation,
  useUpdateInspectionReportMutation,
  useGetInspectionReportByIdQuery,
  useUpdateInspectionReportByIdMutation,
} from "slices/InspectionReport/InspectionReportSlice";
import {
  useGetProjectsQuery,
  useGetEmployeeDropDownQuery,
} from "slices/employee/employeeSlice";
import classnames from "classnames";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import Logo from "assets/images/puretech-logo-sm.png";

type CreateReportResult = {
  id: number;
  error?: FetchBaseQueryError;
};

type CreateReportRowResult = {
  id: number;
  location: number;
  inspection_sub_company: string;
  name_of_person_incharge: number;
  designation: string;
  comments_report1: string;
  attachment1: null;
  error?: FetchBaseQueryError;
};

// Define the types for form data
type FormData = {
  parent_company: number;
  project: number;
  type_of_inspection: string;
  start_date: string;
  end_date: string;
  due_date: string;
  report_status: number;
  inspector_employee_uuid: string;
};

export interface InspectionReportRowDetail {
  inspection_report: number;
  inspection_sub_company: string;
  location: string;
  name_of_person_incharge: number;
  designation: string;
  comments_report1: string | null;
  attachment1: string | null;
  inspection_row_id: number | null;
}

const ReportFormWizard: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(1);
  const [reportId, setReportId] = useState<number | null>(null);
  const [formData, setFormData] = useState<FormData>({
    parent_company: 0,
    project: 0,
    type_of_inspection: "",
    start_date: "",
    end_date: "",
    due_date: "",
    report_status: 1,
    inspector_employee_uuid: "",
  });
  const [selectedCompanyLogo, setSelectedCompanyLogo] = useState<string | null>(
    null,
  );
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [editingReportId, setEditingReportId] = useState<number | null>(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // Fetch data
  const { data: companies } = useGetCompaniesQuery();
  const { data: projects } = useGetProjectsQuery(undefined);
  const { data: employees } = useGetEmployeeDropDownQuery(undefined);
  const [createInspectionReport, { isLoading: postReportloading }] =
    useCreateInspectionReportMutation();
  const {
    data: reportData,
    isLoading,
    isError,
    refetch: reportDataRefetch,
  } = useGetInspectionReportByIdQuery(reportId || 0, { skip: !reportId });
  const [
    updateInspectionReportById,
    { isLoading: InspectionReportRowbyIdLoading },
  ] = useUpdateInspectionReportMutation();
  const [
    updateInspectionReportRowbyId,
    { isLoading: patchReportRowByIdloading },
  ] = useUpdateInspectionReportByIdMutation();

  // Modal state
  const [modal, setModal] = useState(false);
  const [modalFormData, setModalFormData] = useState<any>({
    location: "",
    inspection_sub_company: "",
    name_of_person_incharge: "",
    designation: "",
    comments_report1: "",
    attachment1: null,
  });

  const toggleModal = () => setModal(!modal);

  useEffect(() => {
    // Retrieve the authUser object from sessionStorage and parse it
    const authUser = sessionStorage.getItem("authUser");
    if (authUser) {
      try {
        const parsedAuthUser = JSON.parse(authUser);
        const employeeUUID = parsedAuthUser.employee_uuid || "";
        setFormData((prev) => ({
          ...prev,
          inspector_employee_uuid: employeeUUID,
        }));
      } catch (error) {
        console.error("Failed to parse authUser from session storage:", error);
      }
    }
  }, []);

  useEffect(() => {
    if (reportData) {
      setFormData({
        parent_company: reportData.parent_company,
        project: reportData.project,
        type_of_inspection: reportData.type_of_inspection,
        start_date: reportData.start_date,
        end_date: reportData.end_date,
        due_date: reportData.due_date,
        report_status: reportData.report_status,
        inspector_employee_uuid: reportData.inspector_employee_uuid,
      });
    }
  }, [reportData]);

  useEffect(() => {
    if (companies && formData.parent_company) {
      const selectedCompany = companies.find(
        (company) => company.id === formData.parent_company,
      );
      if (selectedCompany) {
        setSelectedCompanyLogo(selectedCompany.logo_url);
      }
    }
  }, [formData.parent_company, companies]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: parseInt(value, 10),
    }));
  };

  const handleNext = async () => {
    try {
      if (reportId) {
        await updateInspectionReportById({
          id: reportId,
          reportData: formData,
        }).unwrap();
      } else {
        // Create new report
        const result = (await createInspectionReport(
          formData,
        ).unwrap()) as CreateReportResult;
        setActiveTab(activeTab + 1);
        console.log("CreateReportResult", result);

        if (result) {
          console.log("Report created with ID:", result.id);
          setReportId(result.id);
          reportDataRefetch();
        }
      }
      setActiveTab(activeTab + 1);
    } catch (error) {
      console.error("Error updating or creating report:", error);
    }
  };

  const handleModalInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setModalFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleModalSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setModalFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileDrop = (files: File[]) => {
    if (files.length > 0) {
      const file = files[0];
      setModalFormData((prev: any) => ({
        ...prev,
        attachment1: file,
      }));

      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmitModal = async () => {
    try {
      if (reportId) {
        // Constructing the data to match the expected API format
        const inspectionReportDetail: any = {
          ...(editingReportId !== null && {
            inspection_row_id: editingReportId,
          }),
          inspection_report: reportId,
          location: modalFormData.location,
          inspection_sub_company: modalFormData.inspection_sub_company,
          name_of_person_incharge: modalFormData.name_of_person_incharge,
          designation: modalFormData.designation,
          comments_report1: modalFormData.comments_report1 || null,
          attachment1: modalFormData.attachment1 || null,
        };

        await updateInspectionReportRowbyId({
          id: reportId,
          reportData: inspectionReportDetail,
          // ...(editingReportId !== null && { inspection_row_id: editingReportId }),
        }).unwrap();
        toggleModal();
        reportDataRefetch();
        setModalFormData({
          location: "",
          inspection_sub_company: "",
          name_of_person_incharge: "",
          designation: "",
          comments_report1: "",
          attachment1: null,
        });
      }
      setEditingReportId(null);
    } catch (error) {
      console.error("Error updating report in modal:", error);
    }
  };

  const handlePrevious = () => {
    if (reportId) {
      setFormData(reportData as FormData); // Assuming reportData contains the data fields
    }
    setActiveTab(activeTab - 1);
  };

  const toggleTab = (tab: number) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const [openAccordionItem, setOpenAccordionItem] = useState<string>("");

  // Function to toggle accordion items
  const toggleAccordion = (id: string) => {
    setOpenAccordionItem((prev) => (prev === id ? "" : id));
  };

  const handleFinish = async (e: any) => {
    e.preventDefault();
    try {
      const payload = {
        report_status: 3,
      };

      await updateInspectionReportById({
        id: reportId,
        reportData: payload,
      }).unwrap();
      setShowSuccessMessage(true);
    } catch (error) {
      console.error("Error updating report status:", error);
      // Handle error (optional)
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Forms" breadcrumbItem="Wizard" />
        <Row>
          <Col xl={12}>
            <Card>
              <CardBody className="form-steps">
                <Form>
                  <div className="step-arrow-nav mb-4">
                    <Nav
                      className="nav-pills custom-nav nav-justified"
                      role="tablist"
                    >
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === 1 })}
                          // onClick={() => toggleTab(1)}
                        >
                          <span className="step-title me-2">
                            <i className="ri-hotel-line fs-14"></i>
                          </span>
                          Company Info
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === 2 })}
                          // onClick={() => toggleTab(2)}
                        >
                          <span className="step-title me-2">
                            <i className="ri-article-line"></i>
                          </span>
                          Report Info
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === 3 })}
                          // onClick={() => toggleTab()}
                        >
                          <span className="step-title me-2">
                            <i className="ri-file-copy-2-line"></i>
                          </span>
                          OverView
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>

                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={1}>
                      <div>
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="parent_company">
                                Parent Company
                              </Label>
                              <Input
                                type="select"
                                id="parent_company"
                                name="parent_company"
                                value={formData.parent_company}
                                onChange={handleSelectChange}
                              >
                                <option value="">Select Parent Company</option>
                                {companies?.map((company) => (
                                  <option key={company.id} value={company.id}>
                                    {company.company_name}
                                  </option>
                                ))}
                              </Input>
                            </div>
                          </Col>
                          <Col lg={6}>
                            {selectedCompanyLogo && (
                              <div className="mb-3 d-flex align-item-center">
                                <img
                                  src={selectedCompanyLogo}
                                  alt="Selected Company Logo"
                                  style={{ maxHeight: "100px" }}
                                />
                              </div>
                            )}
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="project">Project</Label>
                              <Input
                                type="select"
                                id="project"
                                name="project"
                                value={formData.project}
                                onChange={handleSelectChange}
                              >
                                <option value="">Select Project</option>
                                {projects?.map((project: any) => (
                                  <option key={project.id} value={project.id}>
                                    {project.project_name}
                                  </option>
                                ))}
                              </Input>
                            </div>
                          </Col>
                          <Col lg={6}></Col>

                          <Col lg={4}>
                            <div className="mb-3">
                              <Label htmlFor="start_date">Start Date</Label>
                              <Input
                                type="date"
                                id="start_date"
                                name="start_date"
                                value={formData.start_date}
                                onChange={handleInputChange}
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label htmlFor="end_date">End Date</Label>
                              <Input
                                type="date"
                                id="end_date"
                                name="end_date"
                                value={formData.end_date}
                                onChange={handleInputChange}
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label htmlFor="due_date">Due Date</Label>
                              <Input
                                type="date"
                                id="due_date"
                                name="due_date"
                                value={formData.due_date}
                                onChange={handleInputChange}
                              />
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="mb-3">
                              <Label htmlFor="type_of_inspection">
                                Type of Inspection
                              </Label>
                              <Input
                                type="textarea"
                                id="type_of_inspection"
                                name="type_of_inspection"
                                value={formData.type_of_inspection}
                                onChange={handleInputChange}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>

                    <TabPane tabId={2}>
                      <div>
                        <div className="d-flex align-items-center mb-3">
                          <h4 className="card-title flex-grow-1 mb-0">
                            Report Info
                          </h4>
                          <Button
                            color="btn btn-soft-primary btn-md shadow-none"
                            onClick={() => {
                              toggleModal();
                            }}
                          >
                            Add Record
                          </Button>
                        </div>
                        {reportData?.inspection_report_detail &&
                        reportData?.inspection_report_detail.length > 0 ? (
                          <Accordion
                            open={openAccordionItem}
                            toggle={toggleAccordion}
                            className="custom-accordionwithicon custom-accordion-border accordion-border-box accordion-success"
                          >
                            {reportData?.inspection_report_detail?.map(
                              (detail, index) => (
                                <AccordionItem key={index}>
                                  <AccordionHeader
                                    className="d-flex align-items-center"
                                    targetId={`${index}`}
                                  >
                                    <h4 className="card-title flex-grow-1 mb-0">
                                      SubCompany-{detail.inspection_sub_company}
                                    </h4>
                                    <Button
                                      className="btn btn-soft-info btn-md shadow-none"
                                      onClick={() => {
                                        setModalFormData(detail);
                                        setEditingReportId(detail?.id);
                                        toggleModal();
                                      }}
                                    >
                                      Edit
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;
                                  </AccordionHeader>
                                  <AccordionBody accordionId={`${index}`}>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="table-card">
                                          <table className="table mb-0 mt-3">
                                            <tbody>
                                              <tr>
                                                <td
                                                  className="fw-medium"
                                                  style={{ width: "300px" }}
                                                >
                                                  {" "}
                                                  Name of Person Incharge
                                                </td>
                                                <td>
                                                  {" "}
                                                  {
                                                    detail.name_of_person_incharge_name
                                                  }
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="fw-medium">
                                                  Sub Company
                                                </td>
                                                <td>
                                                  {" "}
                                                  {
                                                    detail.inspection_sub_company
                                                  }
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="fw-medium">
                                                  Designation
                                                </td>
                                                <td>{detail.designation}</td>
                                              </tr>
                                              <tr>
                                                <td className="fw-medium">
                                                  Comments
                                                </td>
                                                <td>
                                                  {detail.comments_report1}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <p>
                                          <strong>Attachment:</strong>{" "}
                                          {detail.attachment1_url && (
                                            <div>
                                              <img
                                                src={detail?.attachment1_url}
                                                alt="Attachment Preview"
                                                style={{ maxWidth: "400px" }}
                                              />
                                            </div>
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </AccordionBody>
                                </AccordionItem>
                              ),
                            )}
                          </Accordion>
                        ) : (
                          <div className="d-flex align-items-center justify-content-center">
                            <div>
                              <p>No inspection report details found.</p>
                              <Button
                                className="btn btn-soft-info btn-md shadow-none mx-5"
                                onClick={() => {
                                  toggleModal();
                                }}
                              >
                                Add Record
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </TabPane>

                    <TabPane tabId={3}>
                      <div>
                        {!showSuccessMessage ? (
                          <>
                            {reportData ? (
                              <div>
                                <Card>
                                  <div className="bg-warning-subtle">
                                    <CardBody className="pb-0 px-4">
                                      <Row className="mb-3">
                                        <div className="col-md">
                                          <Row className="align-items-center g-3">
                                            <div className="col-md-auto">
                                              <div className="avatar-md">
                                                <div className="avatar-title bg-white rounded-circle">
                                                  <img
                                                    src={Logo}
                                                    alt=""
                                                    className="avatar-xs"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md">
                                              <div>
                                                <h4 className="fw-bold">
                                                  {isLoading
                                                    ? "Loading..."
                                                    : isError
                                                      ? "Error"
                                                      : reportData?.parent_company_name}
                                                </h4>
                                                <div className="hstack gap-3 flex-wrap">
                                                  <div>
                                                    <i className="ri-building-line align-bottom me-1"></i>{" "}
                                                    {isLoading
                                                      ? "Loading..."
                                                      : isError
                                                        ? "Error"
                                                        : reportData?.project_name}
                                                  </div>
                                                  <div className="vr"></div>
                                                  <div>
                                                    Start Date :{" "}
                                                    <span className="fw-medium">
                                                      {isLoading
                                                        ? "Loading..."
                                                        : isError
                                                          ? "Error"
                                                          : reportData?.start_date}
                                                    </span>
                                                  </div>
                                                  <div className="vr"></div>
                                                  <div>
                                                    Due Date :{" "}
                                                    <span className="fw-medium">
                                                      {isLoading
                                                        ? "Loading..."
                                                        : isError
                                                          ? "Error"
                                                          : reportData?.due_date}
                                                    </span>
                                                  </div>
                                                  <div className="vr"></div>
                                                  <div className="badge rounded-pill bg-info fs-12">
                                                    {isLoading
                                                      ? "Loading..."
                                                      : isError
                                                        ? "Error"
                                                        : reportData?.report_status}
                                                  </div>
                                                  <div className="badge rounded-pill bg-danger fs-12">
                                                    High
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Row>
                                        </div>
                                        {/* <div className="col-md-auto">
                                          <div className="hstack gap-1 flex-wrap">
                                            <button
                                              type="button"
                                              className="btn py-0 fs-16 favourite-btn active shadow-none"
                                            >
                                              <i className="ri-star-fill"></i>
                                            </button>
                                            <button
                                              type="button"
                                              className="btn py-0 fs-16 text-body shadow-none"
                                            >
                                              <i className="ri-share-line"></i>
                                            </button>
                                            <button
                                              type="button"
                                              className="btn py-0 fs-16 text-body shadow-none"
                                            >
                                              <i className="ri-flag-line"></i>
                                            </button>
                                          </div>
                                        </div> */}
                                      </Row>
                                    </CardBody>
                                  </div>
                                </Card>
                                <hr />
                                <div className="row bg-white rounded py-3 px-md-3 px-0 mt-3">
                                  {reportData?.inspection_report_detail?.map(
                                    (item, index) => (
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="table-card">
                                            <table className="table mb-0 mt-3">
                                              <tbody>
                                                <tr>
                                                  <td
                                                    className="fw-medium"
                                                    style={{ width: "300px" }}
                                                  >
                                                    {" "}
                                                    Name of Person Incharge
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    {
                                                      item?.name_of_person_incharge_name
                                                    }
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td className="fw-medium">
                                                    Location
                                                  </td>
                                                  <td> {item?.location}</td>
                                                </tr>
                                                <tr>
                                                  <td className="fw-medium">
                                                    Sub Company
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    {
                                                      item?.inspection_sub_company
                                                    }
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td className="fw-medium">
                                                    Designation
                                                  </td>
                                                  <td>{item?.designation}</td>
                                                </tr>
                                                <tr>
                                                  <td className="fw-medium">
                                                    Comments
                                                  </td>
                                                  <td>
                                                    {item?.comments_report1}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <p>
                                            <strong>Attachment:</strong>{" "}
                                            {item?.attachment1_url && (
                                              <div>
                                                <img
                                                  src={item?.attachment1_url}
                                                  alt="Attachment Preview"
                                                  style={{ maxWidth: "400px" }}
                                                />
                                              </div>
                                            )}
                                          </p>
                                        </div>
                                        <hr />
                                      </div>
                                    ),
                                  )}
                                </div>
                                <div className="text-center mt-4">
                                  <button
                                    className="btn btn-primary"
                                    onClick={handleFinish}
                                  >
                                    Submit Report
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <p>Loading overview...</p>
                            )}
                          </>
                        ) : (
                          <div className="text-center mt-4">
                            <div className="avatar-md mt-5 mb-4 mx-auto">
                              <div className="avatar-title bg-light text-success display-4 rounded-circle">
                                <i className="ri-checkbox-circle-fill"></i>
                              </div>
                            </div>
                            <h5>Well Done!</h5>
                            <p className="text-muted">
                              You successfully completed the report creation
                              process. Your Report ID: {reportData?.report_id}
                            </p>
                            <Link
                              className="primary"
                              to={`/report-view/${reportData?.id}`}
                            >
                              View Report
                            </Link>
                          </div>
                        )}
                      </div>
                    </TabPane>
                  </TabContent>
                </Form>
                {!showSuccessMessage ? (
                  <div className="d-flex justify-content-between mt-3 ">
                    <Button
                      color="secondary"
                      onClick={handlePrevious}
                      disabled={activeTab === 1}
                      hidden={activeTab === 3}
                    >
                      Previous
                    </Button>
                    <Button
                      color="primary"
                      onClick={handleNext}
                      disabled={activeTab === 2 && !reportData}
                      hidden={activeTab === 3}
                    >
                      {postReportloading || InspectionReportRowbyIdLoading ? (
                        <span className="d-flex align-items-center">
                          <Spinner size="sm" className="flex-shrink-0" />
                          <span className="flex-grow-1 ms-2">Loading...</span>
                        </span>
                      ) : (
                        <>{activeTab === 3 ? "Finish" : "Next"}</>
                      )}
                    </Button>
                  </div>
                ) : (
                  <div></div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal isOpen={modal} size="lg" toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit Details</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={6}>
              <div className="form-group mb-3">
                <label className="text-label">
                  Location <span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="location"
                  value={modalFormData.location}
                  onChange={handleModalInputChange}
                  className="form-control"
                  placeholder="Location"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <Label className="text-label" for="inspection_sub_company">
                  Sub Company
                </Label>
                <Input
                  type="text"
                  id="inspection_sub_company"
                  name="inspection_sub_company"
                  value={modalFormData.inspection_sub_company}
                  onChange={handleModalInputChange}
                />
              </div>
              <div className="form-group mb-3">
                <Label htmlFor="name_of_person_incharge" className="form-label">
                  Name of Person in Charge
                </Label>
                <Input
                  type="select"
                  className="form-select"
                  id="name_of_person_incharge"
                  name="name_of_person_incharge"
                  value={modalFormData.name_of_person_incharge}
                  onChange={handleModalSelectChange}
                >
                  <option value="">Select Employee</option>
                  {employees &&
                    employees.map((employee: any) => (
                      <option key={employee.id} value={employee.id}>
                        {employee.first_name} {employee.last_name}
                      </option>
                    ))}
                </Input>
              </div>
              <div className="form-group mb-3">
                <Label for="designation">Designation</Label>
                <Input
                  type="text"
                  id="designation"
                  name="designation"
                  value={modalFormData.designation}
                  onChange={handleModalInputChange}
                />
              </div>
              <div className="form-group mb-3">
                <Label for="comments_report1">Comments</Label>
                <Input
                  type="textarea"
                  id="comments_report1"
                  name="comments_report1"
                  value={modalFormData.comments_report1}
                  onChange={handleModalInputChange}
                />
              </div>
            </Col>
            <Col lg={6}>
              <Label for="attachment1">Attachment</Label>
              <Dropzone onDrop={handleFileDrop}>
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    style={{
                      border: "2px dashed #007bff",
                      borderRadius: "4px",
                      padding: "20px",
                      textAlign: "center",
                    }}
                    className="dropzone dz-clickable"
                  >
                    <input {...getInputProps()} />

                    <div className="mb-3">
                      <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                    </div>
                    <h4>Drop files here or click to upload.</h4>
                    {selectedImage && (
                      <img
                        src={selectedImage}
                        alt="Preview"
                        style={{
                          width: "100%",
                          maxHeight: "200px",
                          objectFit: "contain",
                        }}
                      />
                    )}
                  </div>
                )}
              </Dropzone>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleSubmitModal}>
            {patchReportRowByIdloading ? (
              <span className="d-flex align-items-center">
                <Spinner size="sm" className="flex-shrink-0" />
                <span className="flex-grow-1 ms-2">Loading...</span>
              </span>
            ) : (
              <>Save</>
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ReportFormWizard;
