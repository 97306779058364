import { getTextColor } from "../helpers/common_helper";

export const BadgeStatus = (props: any) => {
  return (
    <span
      className="badge text-uppercase"
      style={{
        backgroundColor: props.color,
        color: getTextColor(props.color),
      }}
    >
      {props.value}
    </span>
  );
};
