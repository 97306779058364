import React from "react";
import { Card, CardBody, CardHeader, Button, Badge } from "reactstrap";
import { EmployeeData } from "../EmployeeProfile";
import { IsValidPermissions } from "../../../Routes/AuthProtected";

interface WorkProfilelProps {
  employeeProfileData: EmployeeData | null;
  setCardView: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
}

export const WorkProfile: React.FC<WorkProfilelProps> = ({
  employeeProfileData,
  setCardView,
}) => {
  // Split the skills string into an array of individual skills
  const skillsString = employeeProfileData?.skills || "";
  const skillsArray = skillsString ? skillsString.split(",") : [];

  const projectsString = employeeProfileData?.project_name || "";
  const projectsArray = projectsString ? projectsString.split(",") : [];
  return (
    <React.Fragment>
      <Card>
        <CardHeader className="d-flex align-items-center">
          <i className="ri-user-fill fs-4"></i>
          <h4 className="card-title flex-grow-1 mb-0"> Work Profile</h4>
          <div className="flex-shrink-0">
            {IsValidPermissions(["EDIT_MEMBERS"]) && (
              <Button
                onClick={() => {
                  setCardView({ profileEdit: true });
                }}
                className="btn btn-soft-info btn-md shadow-none"
              >
                Edit
              </Button>
            )}
          </div>
        </CardHeader>
        <CardBody>
          <div className="table-card">
            <table className="table mb-0">
              <tbody>
                <tr>
                  <td className="fw-medium" style={{ width: "300px" }}>
                    Full Name
                  </td>
                  <td>{employeeProfileData?.employee_fullname}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Employee Number</td>
                  <td>{employeeProfileData?.employee_number}</td>
                </tr>
                <tr>
                  <td className="fw-medium">E-mail</td>
                  <td>{employeeProfileData?.work_email}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Mobile</td>
                  <td>{employeeProfileData?.work_phone}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Joining Date</td>
                  <td>{employeeProfileData?.employee_start_date}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Position</td>
                  <td>{employeeProfileData?.position_name}</td>
                </tr>
                <tr>
                  <td className="fw-medium">NRIC / FIN</td>
                  <td>{employeeProfileData?.nric_mask}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Skills</td>
                  <td>
                    {skillsArray.map((skill, index) => (
                      <>
                        <div
                          key={index}
                          className="badge bg-primary-subtle text-primary"
                        >
                          {skill}
                        </div>{" "}
                      </>
                    ))}
                  </td>
                </tr>
                {employeeProfileData?.is_external_employee ? (
                  <>
                    <tr>
                      <td className="fw-medium">External Employee</td>
                      <td>
                        <Badge color="primary">Yes</Badge>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-medium">External Company</td>
                      <td>{employeeProfileData?.sub_company_name}</td>
                    </tr>
                  </>
                ) : null}
                {/* <tr>
                  <td className="fw-medium">Onboarding Mentor</td>
                  <td> {employeeProfileData?.onboarding_mentor_name}</td>
                </tr>
                <tr>
                  <td className="fw-medium">Reporting Manager</td>
                  <td> {employeeProfileData?.direct_manager_name}</td>
                </tr> */}
                {/* <tr>
                  <td className="fw-medium"> Project</td>
                  <td>
                    {projectsArray.map((skill, index) => (
                      <>
                      <div
                        key={index}
                        className="badge bg-primary-subtle text-primary"
                      >
                        {skill}
                      </div>{" "}
                      </>
                    ))}
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
