export const dateTimeHandler = (isoTimestamp: string): string => {
  if (isoTimestamp) {
    const date = new Date(isoTimestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  return "";
};

export const Title = [
  { id: 1, name: "Mr" },
  { id: 2, name: "Mrs" },
  { id: 3, name: "Ms" },
  { id: 4, name: "Miss" },
  { id: 5, name: "Dr" },
];

export const Gender = [
  { id: 1, name: "Male" },
  { id: 2, name: "Female" },
  { id: 3, name: "Others" },
];

export default Gender;

export const MaritialStatus = [
  { id: 1, name: "Single" },
  { id: 2, name: "Married" },
  { id: 3, name: "Divorced" },
  { id: 4, name: "Widowed" },
  { id: 5, name: "Separated" },
  { id: 6, name: "Domestic Partnership" },
  { id: 7, name: "Civil Union" },
];

export type ReportStatusId = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export const REPORT_STATUS: Record<ReportStatusId, string> = {
  1: "Draft",
  2: "In Progress",
  3: "Assigned",
  4: "Pending Review",
  5: "In Review",
  6: "Completed",
  7: "Cancelled",
  8: "On Hold",
};

export const SAFETY_REPORT_STATUS = [
  { id: 1, status_name: "Draft" },
  // { id: 2, status_name: "In Progress" },
  { id: 3, status_name: "Submitted" },
  // { id: 4, status_name: "In Review" },
  { id: 5, status_name: "Cancelled" },
  { id: 6, status_name: "Closed" },
  { id: 7, status_name: "Resubmission" },
  { id: 8, status_name: "Revoked" },
  // { id: 9, status_name: "Expired" },
  { id: 10, status_name: "Approved" },
];

export const TOOLBOX_REPORT_STATUS = [
  { id: 1, status_name: "Draft" },
  // { id: 2, status_name: "Created" },
  { id: 3, status_name: "Submitted" },
  { id: 4, status_name: "Linked" },
  { id: 5, status_name: "Cancelled" },
  { id: 6, status_name: "Closed" },
  // { id: 7, status_name: "Resubmission" },
  // { id: 8, status_name: "Expired" },
  // { id: 9, status_name: "Approved" },
  // { id: 10, status_name: "Ended" },
];

export const PROJECT_STATUS =[
  {id:1, status_name:"Active"},
  {id:2,status_name:"Inactive"}
]

