import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Container,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import OverviewTab from "./OverviewTab";
import DocumentsTab from "./DocumentsTab";
import ActivitiesTab from "Components/Common/ActivitesTab";
import { useGetAuditLogsQuery } from "slices/employee/employeeSlice";
import { getTextColor, STATUS_COLORS } from "helpers/common_helper";
import { Tooltip } from "react-tooltip";
import {
  useGenerateHomeworkPermitPDFMutation,
  useDeleteExcavationPermitMutation,
} from "slices/safetyApp/safetyappSlice";
import { useNavigate } from "react-router-dom";
import SafetyAppOverviewTab from "pages/SafetyApp/WorkFlowApprovels/WorkFlowApprovelsOverview/OverviewTab";
import { useGetSafetyAppApprovalByPermitIdQuery } from "slices/workflow/WorkFlowSlice";
import { BadgeStatus } from "helpers/badgeStatus";
import { IsValidPermissions } from "Routes/AuthProtected";
import { allowRevokePermit, allowClosePermit } from "helpers/common_helper";
import { ServiceContext } from "context/ServiceContextProvider";

interface TaskProps {
  getExcavationPermitById: any;
  setTask: any;
  toggle: any;
}

const Summary: React.FC<TaskProps> = ({
  getExcavationPermitById,
  setTask,
  toggle,
}) => {
  const context = useContext(ServiceContext);
  const { selectedRoleName, getEmployeeUUID } = context;

  const { data: auditLogs, isLoading: auditLogsLoading } = useGetAuditLogsQuery(
    { model_name: "hwt_work_permit", object_id: getExcavationPermitById?.id },
  );
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [confirmCloseModal, setConfirmCloseModal] = useState(false);
  const [confirmRevokeModal, setConfirmRevokeModal] = useState(false);
  const [confirmCancelModal, setConfirmCancelModal] = useState(false);
  const toggleConfirmCloseModal = () =>
    setConfirmCloseModal(!confirmCloseModal);
  const toggleConfirmRevokeModal = () =>
    setConfirmRevokeModal(!confirmRevokeModal);
  const toggleConfirmCancelModal = () =>
    setConfirmCancelModal(!confirmCancelModal);
  const [approvalData, setApprovalData] = useState<any>([]);
  const {
    data: workFlowData,
    isLoading: workFlowLoading,
    refetch: refetchProject,
  } = useGetSafetyAppApprovalByPermitIdQuery(
    getExcavationPermitById?.ew_permit_no,
  );

  useEffect(() => {
    if (getExcavationPermitById?.ew_permit_no) {
      refetchProject();
    }
  }, [getExcavationPermitById?.ew_permit_no, refetchProject]);

  useEffect(() => {
    if (workFlowData) {
      setApprovalData(workFlowData);
    }
  }, [workFlowData]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const approval = queryParams.get("approval");
    if (approval === "true") {
      toggleTab("2");
    }
  }, [location]);

  const [generatePDF, { isLoading: isLoadingGeneratePDF }] =
    useGenerateHomeworkPermitPDFMutation();
  const [cancelPermit, { isLoading: isLoadingCancelPermit }] =
    useDeleteExcavationPermitMutation();

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const Status = (props: any) => {
    return (
      <React.Fragment>
        <span
          className="badge text-uppercase"
          style={{
            backgroundColor: props.color,
            color: getTextColor(props.color),
          }}
        >
          {props.value}
        </span>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".edit-task-class"
        place="bottom"
        content="Edit Permit"
      />
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".revoke-report-class"
        place="bottom"
        content="Revoke Report"
      />
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".close-report-class"
        place="bottom"
        content="Close Report"
      />
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".cancel-report-class"
        place="bottom"
        content="Cancel Permit"
      />
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".upadte-task-status-class"
        place="bottom"
        content="Update Task Status"
      />
      <Tooltip
        style={{ zIndex: 10 }}
        anchorSelect=".export-task-status-class"
        place="bottom"
        content="Export Permit"
      />

      <div className="page-content pt-3">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card className="mt-n4 mx-n4">
                <div>
                  <CardBody className="pb-0 px-4">
                    <Row className="mb-3">
                      <div className="col-md">
                        <Row className="align-items-center g-3">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h4 className="fw-bold">
                                {" "}
                                Excavation Permit Information
                              </h4>
                            </div>

                            <div>
                              {isPageLoading ? (
                                <>
                                  <Spinner size="sm" className="me-2" /> Loading
                                  Location Information...
                                </>
                              ) : (
                                <>
                                  {IsValidPermissions([
                                    "DOWNLOAD_EXCAVATION_PERMIT",
                                  ]) ? (
                                    <>
                                      {getExcavationPermitById?.report_status_name ===
                                        "Closed" ||
                                      getExcavationPermitById?.report_status_name ===
                                        "Approved" ? (
                                        <button
                                          type="button"
                                          className="btn py-0 fs-16 text-body shadow-none me-3 export-task-status-class"
                                          onClick={() =>
                                            generatePDF(
                                              getExcavationPermitById?.ew_permit_no,
                                            )
                                          }
                                        >
                                          {isLoadingGeneratePDF ? (
                                            <Spinner
                                              size="xs"
                                              className="me-2"
                                            />
                                          ) : (
                                            <i className="ri-download-2-fill"></i>
                                          )}
                                        </button>
                                      ) : null}
                                    </>
                                  ) : null}

                                  {getExcavationPermitById?.report_status_name ===
                                    "Approved" &&
                                  allowRevokePermit(
                                    workFlowData,
                                    selectedRoleName,
                                    getEmployeeUUID,
                                  ) ? (
                                    <button
                                      className="btn btn-sm btn-primary add-btn me-1 revoke-report-class"
                                      onClick={() => toggleConfirmRevokeModal()}
                                    >
                                      <i className="ri-door-lock-box-line align-bottom me-1"></i>{" "}
                                      Revoke Permit
                                    </button>
                                  ) : null}

                                  {getExcavationPermitById?.report_status_name ===
                                    "Approved" &&
                                  allowClosePermit(
                                    workFlowData,
                                    selectedRoleName,
                                    getEmployeeUUID,
                                  ) ? (
                                    <button
                                      className="btn btn-sm btn-primary add-btn me-1 close-report-class"
                                      onClick={() => toggleConfirmCloseModal()}
                                    >
                                      <i className="ri-door-lock-box-line align-bottom me-1"></i>{" "}
                                      Close Permit
                                    </button>
                                  ) : null}

                                  {IsValidPermissions([
                                    "EDIT_EXCAVATION_PERMIT",
                                  ]) ? (
                                    <>
                                      {getExcavationPermitById?.report_status_name ===
                                        "Draft" ||
                                      getExcavationPermitById?.report_status_name ===
                                        "Resubmission" ? (
                                        <button
                                          className="btn btn-sm btn-primary add-btn me-1 edit-task-class"
                                          onClick={() => {
                                            navigate(
                                              `/excavation-permit-wizard/${getExcavationPermitById?.project}/${getExcavationPermitById?.ew_permit_no}`,
                                            );
                                          }}
                                        >
                                          <i className="ri-pencil-fill align-bottom me-1"></i>{" "}
                                          Edit Permit
                                        </button>
                                      ) : null}

                                      {getExcavationPermitById?.report_status_name ===
                                        "Draft" ||
                                      getExcavationPermitById?.report_status_name ===
                                        "Resubmission" ? (
                                        <button
                                          className="btn btn-sm btn-warning add-btn me-1 cancel-report-class"
                                          onClick={() =>
                                            toggleConfirmCancelModal()
                                          }
                                        >
                                          <i className="ri-close-circle-line align-bottom me-1"></i>{" "}
                                          Cancel Permit
                                        </button>
                                      ) : null}
                                    </>
                                  ) : null}
                                </>
                              )}
                            </div>
                          </div>
                          <hr />
                          <div className="col-md">
                            <div className="d-flex justify-content-between">
                              <div>
                                <h4 className="fw-bold">
                                  Permit No :{" "}
                                  <span className="text-muted">
                                    ({getExcavationPermitById?.ew_permit_no})
                                  </span>
                                </h4>
                              </div>

                              <div className="hstack gap-3 flex-wrap">
                                {getExcavationPermitById?.report_status_name ? (
                                  <>
                                    <div className="vr"></div>
                                    <div>
                                      Submission Status :{" "}
                                      <BadgeStatus
                                        value={
                                          getExcavationPermitById?.report_status_name
                                        }
                                        color={
                                          STATUS_COLORS[
                                            getExcavationPermitById
                                              ?.report_status_name
                                          ]
                                        }
                                      />
                                    </div>
                                  </>
                                ) : null}
                                {getExcavationPermitById?.approval_status_name ? (
                                  <>
                                    <div className="vr"></div>
                                    <div>
                                      Approval Status :{" "}
                                      <BadgeStatus
                                        value={
                                          getExcavationPermitById?.approval_status_name
                                        }
                                        color={
                                          STATUS_COLORS[
                                            getExcavationPermitById
                                              ?.approval_status_name
                                          ]
                                        }
                                      />
                                    </div>
                                  </>
                                ) : null}
                                <div className="vr"></div>
                                <Status
                                  value={
                                    getExcavationPermitById?.task_status_name
                                  }
                                  color={
                                    getExcavationPermitById?.task_status_color
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </Row>

                    <Nav
                      className="nav-tabs-custom border-bottom-0"
                      role="tablist"
                      style={{ background: "lavender" }}
                    >
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "1" },
                            "fw-semibold",
                          )}
                          onClick={() => {
                            toggleTab("1");
                          }}
                          href="#"
                        >
                          Overview
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "2" },
                            "fw-semibold",
                          )}
                          onClick={() => {
                            toggleTab("2");
                          }}
                          href="#"
                        >
                          Approval
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "3" },
                            "fw-semibold",
                          )}
                          onClick={() => {
                            toggleTab("3");
                          }}
                          href="#"
                        >
                          Documents
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === "4" },
                            "fw-semibold",
                          )}
                          onClick={() => {
                            toggleTab("4");
                          }}
                          href="#"
                        >
                          Activities
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardBody>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <TabContent activeTab={activeTab} className="text-muted">
                <TabPane tabId="1">
                  <OverviewTab
                    getExcavationPermitById={getExcavationPermitById}
                  />
                </TabPane>
                <TabPane tabId="2">
                  <SafetyAppOverviewTab
                    permitNo={getExcavationPermitById?.ew_permit_no}
                    workFlowData={approvalData}
                    workFlowLoading={workFlowLoading}
                    refetchProject={refetchProject}
                    confirmCloseModal={confirmCloseModal}
                    confirmRevokeModal={confirmRevokeModal}
                    toggleConfirmCloseModal={toggleConfirmCloseModal}
                    toggleConfirmRevokeModal={toggleConfirmRevokeModal}
                    toggleConfirmCancelModal={toggleConfirmCancelModal}
                    confirmCancelModal={confirmCancelModal}
                    isLoadingCancelPermit={isLoadingCancelPermit}
                    cancelPermit={cancelPermit}
                    permitType={"EXCAVATION_PERMIT"}
                    setIsPageLoading={setIsPageLoading}
                  />
                </TabPane>
                <TabPane tabId="3">
                  <DocumentsTab
                    getExcavationPermitById={getExcavationPermitById}
                  />
                </TabPane>
                <TabPane tabId="4">
                  <ActivitiesTab
                    activities={auditLogs}
                    isLoading={auditLogsLoading}
                  />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Summary;
