import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import { notificationIcons } from "../../helpers/common_helper";
import {
  useGetUserNotificationQuery,
  usePatchNotificationMutation,
  usePactchNotificationCountMutation,
} from "slices/notification/NotificationSlice";

//import images
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";

//SimpleBar
import SimpleBar from "simplebar-react";
import FeatherIcon from "feather-icons-react";

const NotificationDropdown = () => {
  const navigate = useNavigate();

  const [isNotificationDropdown, setIsNotificationDropdown] =
    useState<boolean>(false);
  const toggleNotificationDropdown = () => {
    setIsNotificationDropdown(!isNotificationDropdown);
  };

  const [page, setPageState] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 1,
    pageSize: 10,
  });

  const [currentUser, setCurrentUser] = useState(null);

  const {
    data: allNotification,
    // isLoading: isNotificationLoading,
    // isFetching: isNotificationFetching,
    refetch: refetchNotification,
    // error: errorNotification,
  } = useGetUserNotificationQuery(page); // , { pollingInterval: 10000 });
  const [updateNotification] = usePatchNotificationMutation();

  const [updateNotificationCount, { isLoading: isUpdating }] =
    usePactchNotificationCountMutation();

  useEffect(() => {
    const fetchAuthUser = () => {
      const authUserString = localStorage.getItem("authUser");
      if (authUserString) {
        setCurrentUser(JSON.parse(authUserString));
      }
    };
    fetchAuthUser();
  }, []);

  // Tab state
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleNotificationReadClick = () => {
    updateNotificationCount({
      //@ts-ignore
      employee_uuid: currentUser?.employee_uuid,
    }).unwrap();
    refetchNotification();
  };

  const handleNotificationClick = async (notification: any) => {
    const { id, code, notify_id } = notification;

    updateNotification({
      notify_id: id,
      updateData: { is_read: true },
    });

    if (code === "MEMBERS") {
      navigate(`/profile/${notify_id}`);
    } else if (code === "TOOLBOX_MEETING") {
      navigate(`/toolbox-meeting-detail-view/${notify_id}/`);
    } else if (code === "HOT_WORK_PERMIT") {
      navigate(`/hotwork-permit-details/${notify_id}/`);
    } else if (code === "GENERAL_WORK_PERMIT") {
      navigate(`/generalwork-permit-details/${notify_id}/`);
    } else if (code === "WORK_AT_HEIGHT_PERMIT") {
      navigate(`/workatheight-permit-details/${notify_id}/`);
    } else if (code === "EXCAVATION_PERMIT") {
      navigate(`/excavation-permit-details/${notify_id}/`);
    } else if (code === "LIFTING_PERMIT") {
      navigate(`/lifting-permit-details/${notify_id}/`);
    } else if (code === "CONFINED_SPACE_PERMIT") {
      navigate(`/confinedspace-permit-details/${notify_id}/`);
    } else if (code === "HOT_WORK_PERMIT_APPROVAL") {
      navigate(`/hotwork-permit-details/${notify_id}?approval=true`);
    } else if (code === "GENERAL_WORK_PERMIT_APPROVAL") {
      navigate(`/generalwork-permit-details/${notify_id}?approval=true`);
    } else if (code === "WORK_AT_HEIGHT_PERMIT_APPROVAL") {
      navigate(`/workatheight-permit-details/${notify_id}?approval=true`);
    } else if (code === "EXCAVATION_PERMIT_APPROVAL") {
      navigate(`/excavation-permit-details/${notify_id}?approval=true`);
    } else if (code === "LIFTING_PERMIT_APPROVAL") {
      navigate(`/lifting-permit-details/${notify_id}?approval=true`);
    } else if (code === "CONFINED_SPACE_PERMIT_APPROVAL") {
      navigate(`/confinedspace-permit-details/${notify_id}?approval=true`);
    } else if (code === "TOOLBOX_MEETING") {
      navigate(`/toolbox-meeting-detail-view/${notify_id}/`);
    } else {
      console.warn("Unhandled notification code:", code);
    }
  };

  // Filtered notifications
  const selfNotifications =
    allNotification?.results?.filter(
      (item: any) => item.message_type === "Self",
    ) || [];
  const generalNotifications =
    allNotification?.results?.filter(
      (item: any) => item.message_type === "General",
    ) || [];

  const renderNotificationItem = (notification: any) => {
    //@ts-ignore
    const icon = notificationIcons[notification.code] || "user";

    return (
      <div
        key={notification.id}
        className="text-reset notification-item d-block dropdown-item position-relative"
      >
        <div className="d-flex">
          <div className="flex-shrink-0">
            <FeatherIcon
              icon={icon}
              className="me-3 text-bg-secondary avatar-xs rounded-circle"
            />
          </div>

          <div
            className="flex-grow-1"
            style={{ justifyContent: "space-between", display: "flex" }}
          >
            <div>
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleNotificationClick(notification);
                }}
                className="stretched-link"
              >
                <h6 className="mt-0 mb-2 lh-base">{notification.message}</h6>
              </Link>
              <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                <span>
                  <i className="mdi mdi-clock-outline"></i>{" "}
                  {notification.created_at_text}
                </span>
              </p>
            </div>

            <div>
              <span
                className={`badge ${notification.is_read ? "bg-success" : "bg-danger"} text-white`}
                style={{
                  fontSize: "0.75rem",
                  padding: "0.25em 0.5em",
                  borderRadius: "0.25rem",
                }}
              >
                {notification.is_read ? "Read" : "Unread"}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isNotificationDropdown}
        toggle={toggleNotificationDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none"
        >
          <i className="bx bx-bell fs-22"></i>
          <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
            {allNotification?.summary?.overall}
          </span>
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-lg dropdown-menu-end p-0"
          style={{ width: "500px" }}
        >
          <div className="dropdown-head bg-primary bg-pattern rounded-top">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 fs-16 fw-semibold text-white">
                    {" "}
                    Notifications{" "}
                  </h6>
                </Col>
                <div className="col-auto dropdown-tabs">
                  <span className="badge bg-light-subtle text-body fs-13">
                    {allNotification?.summary?.overall} New
                  </span>
                </div>
              </Row>
            </div>

            <div className="px-2 pt-2">
              <Nav className="nav-tabs dropdown-tabs nav-tabs-custom">
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    Primary ({allNotification?.summary?.primary})
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    General ({allNotification?.summary?.general})
                  </NavLink>
                </NavItem>
                <NavItem className="ml-auto mx-4"></NavItem>
                {allNotification?.summary?.overall > 0 && (
                  <NavItem className="ml-auto mx-4">
                    <Button
                      className="btn btn-sm btn-success text-white fs-13 p-2"
                      onClick={(e) => {
                        e.preventDefault();
                        handleNotificationReadClick();
                      }}
                    >
                      {isUpdating ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Marking as read...
                        </>
                      ) : (
                        <>Mark as read ({allNotification?.summary?.overall})</>
                      )}
                    </Button>
                  </NavItem>
                )}
              </Nav>
            </div>
          </div>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1" className="py-2 ps-2">
              <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                {selfNotifications.map(renderNotificationItem)}
                <div className="my-3 text-center">
                  <button
                    onClick={() => navigate(`/notification?primary=true`)}
                    type="button"
                    className="btn btn-soft-success waves-effect waves-light"
                  >
                    View All Notifications{" "}
                    <i className="ri-arrow-right-line align-middle"></i>
                  </button>
                </div>
              </SimpleBar>
            </TabPane>

            <TabPane tabId="2" className="py-2 ps-2">
              <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                {generalNotifications.map(renderNotificationItem)}
                <div className="my-3 text-center">
                  <button
                    onClick={() => navigate(`/notification?general=true`)}
                    type="button"
                    className="btn btn-soft-success waves-effect waves-light"
                  >
                    View All Messages{" "}
                    <i className="ri-arrow-right-line align-middle"></i>
                  </button>
                </div>
              </SimpleBar>
            </TabPane>
          </TabContent>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;
