export const formatDateTime = (value: string, type: string) => {
  if (!value) return null;

  let userTimeZone = "Asia/Singapore";
  const locale = "en-SG";

  const userSettings = localStorage.getItem("userSettings");

  if (userSettings) {
    const user = JSON.parse(userSettings);
    // console.log('user----', user.USER_TIMEZONE)
    userTimeZone = user.USER_TIMEZONE;
  }

  // Get browser's locale and timezone
  // const { locale, timeZone } = Intl.DateTimeFormat().resolvedOptions();
  // console.log("timezone-----", locale, timeZone);

  if (type === "date") {
    const options: any = { day: "2-digit", month: "short", year: "numeric" };
    const date = new Date(value);
    return date.toLocaleDateString(locale, options);
  }

  if (type === "time") {
    const [hours, minutes] = value.split(":");
    return `${hours}:${minutes}`;
  }

  if (type === "datetime") {
    console.log("convert before vale: ", value);
    const date = new Date(value);
    // Correct date and time options
    const dateTimeOptions = {
      day: "2-digit", // "2-digit" is the correct option
      month: "short", // "short" is the correct option
      year: "numeric", // "numeric" is the correct option
      hour: "2-digit", // "2-digit" is the correct option
      minute: "2-digit", // "2-digit" is the correct option
      hour12: false, // 24-hour format
      // timeZone, // Set the timezone
      timeZone: userTimeZone,
    };

    const formattedDateTime = new Intl.DateTimeFormat(
      locale,
      //@ts-ignore
      dateTimeOptions,
    ).format(date);
    console.log("convert after vale: ", formattedDateTime);
    return formattedDateTime;
  }
  return value;
};
