import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import Loader from "Components/Common/Loader";
import TableContainer from "Components/Common/TableContainer";
import { BadgeStatus } from "helpers/badgeStatus";
import { STATUS_COLORS } from "helpers/common_helper";
import { useGetToolBoxSummaryQuery } from "slices/dashboardProject/dashboardSlice";

const ActiveToolboxSummary = () => {
  const {
    data: toolboxSummary,
    isLoading: isToolBoxLoading,
    isFetching: isToolBoxFetching,
    // refetch: refetchProject,
    // error: projectError,
  } = useGetToolBoxSummaryQuery(undefined);

  const columns = useMemo(
    () => [
      {
        Header: "ToolBox No",
        accessor: "toolbox_id",
        filterable: false,
        Cell: ({
          row: {
            original: { toolbox_id },
          },
        }: any) => (
          <Link
            to={`/toolbox-meeting-detail-view/${toolbox_id}/`}
            className="text-primary d-inline-block"
          >
            {toolbox_id}
          </Link>
        ),
      },
      {
        Header: "Report Status",
        accessor: "report_status_name",
        Cell: (cell: { value: any }) => (
          <>
            {cell.value ? (
              <BadgeStatus
                value={cell.value}
                color={STATUS_COLORS[cell.value]}
              />
            ) : null}
          </>
        ),
      },
      // {
      //   Header: "Type",
      //   accessor: "report_type",
      //   filterable: false,
      //   Cell: (cell: { value: any }) => (
      //     <>
      //     {cell.value
      //       .replace(/_/g, " ")
      //       .toLowerCase()
      //       .replace(/\b\w/g, (char: any) => char.toUpperCase())}
      //       </>
      //   )
      // },
      {
        Header: "Conducted By",
        accessor: "conducted_by_name",
        filterable: false,
      },
    ],
    [],
  );

  return (
    <React.Fragment>
      <Col xl={6}>
        <Card className="card-height-100">
          <CardHeader className="d-flex align-items-center">
            <h4 className="card-title flex-grow-1 mb-0">
              Open ToolBox Meeting
            </h4>
            {/* <div className="flex-shrink-0">
                            <Link to="#" className="btn btn-soft-info btn-sm shadow-none">Export Report</Link>
                        </div> */}
          </CardHeader>
          <CardBody className="min-width-height-400">
            <div className="table-responsive table-card">
              {isToolBoxLoading ? (
                <div className="mt-5">
                  <Loader />
                </div>
              ) : (
                <TableContainer
                  columns={columns}
                  data={toolboxSummary || []}
                  isLoading={isToolBoxFetching}
                  isGlobalFilter={false}
                  isAddUserList={false}
                  customPageSize={50}
                  className="custom-header-css"
                  divClass="min-width-height-400 table table-nowrap table-centered align-middle"
                  tableClass="align-middle table-nowrap mb-0"
                  theadClass="table-light text-muted"
                  thClass="table-light text-muted"
                  SearchPlaceholder="Search for Projects..."
                  isPagination={false}
                  isServerPagination={false}
                />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default ActiveToolboxSummary;
