import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardImg,
  Col,
  Row,
  CardTitle,
  Badge,
} from "reactstrap";
import { BadgeStatus } from "helpers/badgeStatus";
import { STATUS_COLORS, SEVERITY_COLORS } from "helpers/common_helper";
import { formatDateTime } from "helpers/date_utils";
import ImageLightbox from "Components/Common/ImageLightbox";

interface OverviewProps {
  toolboxMeetingByIdData: any;
}

const imageExtensions = ["jpeg", "jpg", "png"];

const fileIconMap: { [key: string]: string } = {
  pdf: "ri-file-pdf-line",
  doc: "ri-file-word-line",
  docx: "ri-file-word-line",
  zip: "ri-folder-zip-line",
  rar: "ri-folder-zip-line",
  default: "ri-file-line",
};

const OverviewTab: React.FC<OverviewProps> = ({ toolboxMeetingByIdData }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const truncateFileName = (fileName: string, maxLength = 20) => {
    if (fileName.length > maxLength) {
      const truncatedName = fileName.substring(0, maxLength) + "...";
      return truncatedName;
    }
    return fileName;
  };

  const extractDocumentName = (url: string) => {
    const parts = url?.split("/");
    return parts[parts?.length - 1];
  };

  // Extract file extension and return corresponding icon or image
  const getFileTypeIconOrImage = (documentUrl: string) => {
    const fileName = documentUrl.split("/").pop()?.split("?")[0] || "";
    const fileExtension = fileName.split(".").pop()?.toLowerCase() || "default";

    if (imageExtensions.includes(fileExtension)) {
      // Show the actual image if it's an image file
      return (
        <CardImg
          top
          width="150px"
          height="150px"
          src={documentUrl}
          alt={fileName}
          style={{ position: "relative" }}
        />
      );
    } else {
      // Return appropriate icon for non-image files
      const iconClass = fileIconMap[fileExtension] || fileIconMap["default"];
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "150px" }}
        >
          <i className={`${iconClass} ri-4x`} />
        </div>
      );
    }
  };

  const imageDocuments = toolboxMeetingByIdData?.documents?.filter((doc: any) =>
    doc.attachment?.match(/\.(jpeg|jpg|png|gif)$/i),
  );

  const slides = imageDocuments?.map((doc: any) => ({
    src: doc.attachment_url,
    title: extractDocumentName(doc.attachment),
  }));

  return (
    <React.Fragment>
      <Row>
        <Col xl={12} lg={12}>
          <Card>
            <CardBody>
              <div className="text-muted">
                <h5 className="mt-2">Accident Report Details</h5>
                <div className="pt-3 border-top border-top-dashed mt-4">
                  <Row>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Accident Report No :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {toolboxMeetingByIdData?.incident_no}
                        </h5>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Project :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {toolboxMeetingByIdData?.project_name}
                        </h5>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Reported By:
                        </p>
                        <h5 className="fs-15 mb-0">
                          {toolboxMeetingByIdData?.reported_by_name}
                        </h5>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Report Status :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {toolboxMeetingByIdData?.status_name ? (
                            <BadgeStatus
                              value={toolboxMeetingByIdData?.status_name}
                              color={
                                STATUS_COLORS[
                                  toolboxMeetingByIdData?.status_name
                                ]
                              }
                            />
                          ) : null}
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="pt-3 border-top border-top-dashed mt-4">
                  <Row>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Incident Date Time :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {formatDateTime(
                            toolboxMeetingByIdData?.incident_date,
                            "date",
                          )}{" "}
                          {formatDateTime(
                            toolboxMeetingByIdData?.incident_time,
                            "time",
                          )}
                        </h5>
                      </div>
                    </Col>

                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Reported Date Time :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {formatDateTime(
                            toolboxMeetingByIdData?.date_reported,
                            "datetime",
                          )}
                        </h5>
                      </div>
                    </Col>

                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Severity :
                        </p>
                        <h5 className="fs-15 mb-0">
                          <BadgeStatus
                            value={toolboxMeetingByIdData?.severity_name}
                            color={
                              SEVERITY_COLORS[
                                toolboxMeetingByIdData?.severity_name
                              ]
                            }
                          />
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="pt-3 border-top border-top-dashed mt-4">
                  <Row>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium">
                          Description :
                        </p>
                        <h5 className="fs-15 mb-0">
                          {toolboxMeetingByIdData?.description}
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              {/* Display Documents */}
              <h5>Accident Report Documents</h5>
              <hr />
              <Row>
                {toolboxMeetingByIdData?.documents?.map(
                  (document: any, index: number) => {
                    const isImage = document.attachment.match(
                      /\.(jpeg|jpg|png|gif)$/i,
                    );
                    return (
                      <Col key={index} md={3} className="mb-4">
                        <Card
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (isImage) {
                              const imgIndex = imageDocuments.findIndex(
                                (imgDoc: any) =>
                                  imgDoc.attachment_url ===
                                  document.attachment_url,
                              );
                              setCurrentImageIndex(imgIndex);
                              setLightboxOpen(true);
                            }
                          }}
                        >
                          {getFileTypeIconOrImage(document.attachment_url)}
                          <CardBody>
                            {/* Extract document name from URL */}
                            <CardTitle tag="h5">
                              {truncateFileName(
                                extractDocumentName(document.attachment),
                              )}
                            </CardTitle>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  },
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ImageLightbox
        open={lightboxOpen}
        slides={slides}
        currentIndex={currentImageIndex}
        onClose={() => setLightboxOpen(false)}
      />
    </React.Fragment>
  );
};

export default OverviewTab;
