import { createApi } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "helpers/jwt-token-access/customBaseQuery";

export const notificationSlice = createApi({
  reducerPath: "notificationApi",
  baseQuery: customBaseQuery,
  tagTypes: [],
  endpoints: (builder) => ({
    getUserNotification: builder.query({
      query: (page) =>
        `/notification/api/v1/notify/?page=${page.page}&page_size=${page.pageSize}`,
    }),
    pactchNotificationCount:builder.mutation({
      query:({employee_uuid})=>`/notification/api/v1/update_notification?employee_uuid=${employee_uuid}`
    }),
    patchNotification: builder.mutation({
      query: ({ notify_id, updateData }) => {
        return {
          url: `/notification/api/v1/notify/${notify_id}/`,
          method: "PATCH",
          body: updateData,
        };
      },
    }),
  }),
});

export const { useGetUserNotificationQuery, usePatchNotificationMutation,usePactchNotificationCountMutation } =
  notificationSlice;
